import {Col, Row, Upload} from "antd";
import {useEffect, useState} from "react";
import useToast from "../../hooks/useToast";
import {useTranslation} from "react-i18next";
import {faCopy} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import useStore from "../../hooks/useStore";

const FormerSupplies = () => {
    const {t} = useTranslation('parameters')
    const [fileUploaded, setFileUploaded] = useState<boolean>(false);
    const [fileUploading, setFileUploading] = useState<boolean>(false);
    const [files, setFiles] = useState<File[]>([]);
    const toast = useToast()
    const [store] = useStore()
    const [enrollmentId, setEnrollmentId] = useState<string>("");
    const [certificateFileId, setCertificateFileId] = useState<string>('');

    useEffect(() => {
        //@ts-ignore
        getEnrollmentId();
        certificateExistenceCheck();
    }, [])

    const props = {
        name: 'files',
        multiple: false,
        maxCount: 1,
        onRemove: (file: any) => {
            setFiles([])
        },
        beforeUpload: (_file: File) => {
            const isLt1M = _file.size / 1024 / 1024 < 1;
            if (!isLt1M) {
                toast('فایل نباید از یک مگابایت بیشتر باشد','error');
                return;
            }
            setFiles([_file]);
            return false;
        }
    };

    const getEnrollmentId = () => {
        store.apiContainer?.api.viewEnrollmentId().then(({data, status}) => {
            if (status === 200) {
                setEnrollmentId(data.eid);
            }
            else {
                toast('مشکل در دریافت اطلاعات بلاکچین', 'error');
            }
        }).catch((response: any) => {
            toast(response.data?.msg, 'error');
        })
    }

    const handleUpload = () => {
        if (files.length === 0) return;
        setFileUploading(true);
        store.apiContainer?.api.addFile({file: files[0]}).then(({data, status}) => {
            if (status === 200) {
                toast("فایل با موفقیت آپلود شد", "success")
                setFileUploaded(true)
                let dataObject = data as { id: string };
                if (dataObject.id) {
                    store.apiContainer?.api.enrollBoardMember({csrFileId: dataObject.id}).then((response) => {
                        if (response.status === 200) {
                            response.data.certificateFileId && setCertificateFileId(response.data.certificateFileId);
                            toast("فایل مجوز قابل دانلود است", "success");
                        }
                        else {
                            toast("مشکل در آپلود فایل", "error");
                        }
                    }).catch((response) => {
                        toast(response?.data?.msg || response?.data?.message, 'error');
                    })
                }
            }
            else {
                toast("مشکل در آپلود فایل", "error")
            }
            setFileUploading(false);
        }).catch((response) => {
            toast((response.data?.msg) ? response.data.msg : "مشکل درآپلود فایل", 'error');
            setFileUploading(false);
        })
    }

    const certificateExistenceCheck = () => {
        store.apiContainer?.api.viewCertificateFileId().then(({data, status}) => {
            data.fileId && setCertificateFileId(data.fileId);
        })
    }

    const downloadCertificate = () => {
        store.apiContainer?.api.getFile(certificateFileId, {base64: true}).then(({data}) => {
            const a = document.createElement("a");
            a.href = "data:image/jpg;base64, " + data;
            a.download = "certificate.csr";
            a.click();
        })
    }

    return (
        <>
            <Row gutter={[16, 16]}>
                <Col span={24} lg={24} md={24} sm={24} xs={24}>
                    <div className={'dashboard-grid'}>
                        <Row className={'header2'}>
                            <Col span={6}>
                                {t('upload.formerSuppplies')}
                            </Col>
                            <Col span={18}
                                 style={{borderBottom: "1px solid #e9e9f0", height: "10px"}}/>
                        </Row>
                        <Row justify={"center"} align={'middle'}>
                            <Col lg={12} sm={24}>
                                <Row justify={"center"}>
                                    <Col lg={12} sm={12} xs={24}>
                                        <Upload {...props} className={'upload'}
                                                style={{width: '100%', textAlign: 'center'}}>
                                            <div style={{
                                                margin: "10% auto",
                                                border: "1px dashed #D0C9DF",
                                                borderRadius: "5px",
                                                padding: '40px 20px'
                                            }}>
                                                <p>فایل از نوع CSR</p>
                                                <p>ابتدا فایل را وارد کنید</p>
                                                <p>حداکثر حجم فایل 1MB</p>
                                            </div>
                                        </Upload>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={12} sm={24} xs={24}>
                                <Row justify={"center"}>
                                    <div
                                        style={{
                                            height: "30px",
                                            textAlign: "left",
                                            color: "#9C9CBD",
                                            left: 0,
                                            fontSize: "20px",
                                            display: "inline-block",
                                            margin: '15px 5px'
                                        }}>
                                        <button
                                            className={"clipboard-icon"}
                                            onClick={() => {
                                                navigator.clipboard.writeText(enrollmentId);
                                                toast('کپی شد', 'success')
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faCopy}/> {enrollmentId}
                                        </button>
                                    </div>

                                </Row>
                                {/*<div className={"upload-side-icon"} style={{marginRight: "10px"}}>
                                    <img src={UploadFrame} alt={''}
                                         style={{
                                             width: "200px",
                                             position: "absolute",
                                             left: "50%",
                                             top: "50%",
                                             transform: "translate(-50%, -50%)"
                                         }}/>
                                    <FontAwesomeIcon
                                        icon={faCloudUploadAlt}
                                        style={{
                                            fontSize: "80px",
                                            position: "absolute",
                                            left: "50%",
                                            top: "50%",
                                            transform: "translate(-50%, -50%)"
                                        }}
                                    />
                                </div>*/}
                            </Col>
                        </Row>
                        <br/>
                    </div>
                </Col>
            </Row><br/>
            <Row justify={"center"}>
                <Col>
                    <button
                        className={'form-submit-button'}
                        onClick={handleUpload}
                        disabled={files.length === 0}
                    >
                        {fileUploading ? 'در حال ارسال' : 'آپلود'}
                    </button>
                </Col>
                <Col>
                    <button
                        className={'form-submit-button'}
                        onClick={downloadCertificate}
                        disabled={!certificateFileId}
                    >
                        دانلود certificate
                    </button>
                </Col>
            </Row>
        </>
    )
}

export default FormerSupplies;
