import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Col, Row, Modal, Button, Input} from "antd";
import CustomTable from "../../components/Table";
import useStore, {storeActions} from "../../hooks/useStore";
import useToast from '../../hooks/useToast';
import {ColumnProps} from "antd/lib/table/Column";
import TableActions from "../../components/TableActions";
import KeyA from "../../api/KeyA";
import {AxiosResponse} from "axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faUser} from "@fortawesome/free-solid-svg-icons";
import BtnClearFilter from '../../components/BtnClearFilter';
import InputSearch from '../../components/InputSearch';
import BtnFilter from '../../components/BtnFilter';
import BtnExcel from '../../components/BtnExcel';
import * as XLSX from 'xlsx';
import InputExcel from '../../components/InputExcel';
type TransactionStatus =
    | "CREATED"
    | "MODIFIED"
    | "CANCELED"
    | "EXPIRED"
    | "PENDING_FOR_PAY"
    | "PENDING_FOR_VERIFY_DOCS"
    | "PENDING_FOR_VERIFY_MEMBERSHIP"
    | "PENDING_FOR_VERIFY_TRANSACTION"
    | "PENDING_FOR_CREATE_IN_BC"
    | "PENDING_FOR_CEO_SIGN"
    | "PENDING_FOR_SIGN_IN_BC"
    | "REJECT_BC_SIGN"
    | "REJECT_BC"
    | "APPROVED_BC"
    | "REJECT_SIGN"
    | "REJECT_MEMBERSHIP";
const transaction_dictionary: { [key in TransactionStatus]: string } = {
    CREATED: "ساخته شده",
    MODIFIED: "ویرایش شده",
    CANCELED: "ملغی",
    EXPIRED: "منقضی",
    PENDING_FOR_PAY: "در انتظار پرداخت",
    PENDING_FOR_VERIFY_DOCS: "در انتظار تایید مدارک",
    PENDING_FOR_VERIFY_MEMBERSHIP: "در انتظار تایید عضویت",
    PENDING_FOR_VERIFY_TRANSACTION: "در انتظار تایید تراکنش",
    PENDING_FOR_CREATE_IN_BC: "در انتظار ساخت در بلاکچین",
    PENDING_FOR_CEO_SIGN: "در انتظار امضا",
    PENDING_FOR_SIGN_IN_BC: "در انتظار امضا در بلاکچین",
    REJECT_BC_SIGN: "رد امضا بلاکچین",
    REJECT_BC: "رد بلاکچین",
    APPROVED_BC: "تایید بلاکچین",
    REJECT_SIGN: "رد امضا",
    REJECT_MEMBERSHIP: "رد عضویت",
}

const PendingBuyRequests = () => {
    const {t} = useTranslation('identification');
    const [PendingRequestsTotalElements, setPendingRequestsTotalElements] = useState<number>(0)
    const [pendingRequests, setPendingRequests] = useState<any[]>([]);
    const [pendingRequestsLoading, setPendingRequestsLoading] = useState<boolean>(true);
    const [tableSettings, setTableSettings] = useState({});
    const [getPinModalVisible, setGetPinModalVisible] = useState<boolean>(false);
    const [pinModalLoading, setPinModalLoading] = useState<boolean>(false);

    const [targetId, setTargetId] = useState<string>('');
    const [targetData, setTargetData] = useState<string>('');

    const [pin, setPin] = useState<string>('');

    const [rejectModalVisible, setRejectModalVisible] = useState<boolean>(false);
    const [rejectReason, setRejectReason] = useState<string>('');
    const [rejectingRequest, setRejectingRequest] = useState<boolean>(false);

    const [verifyTransactionModalVisible, setVerifyTransactionModalVisible] = useState<boolean>(false);
    const [rejectTransactionModalVisible, setRejectTransactionModalVisible] = useState<boolean>(false);
    const [membershipRejectReason, setMembershipRejectReason] = useState<string>('');
    const [noramlApproveRejectLoading, setNoramlApproveRejectLoading] = useState<boolean>(false);

    const [membershipModalVisible, setMembershipModalVisible] = useState<boolean>(false);
    const [normalTransactionRejectionReason, setNormalTransactionRejectionReason] = useState<string>('');
    const [membershipRequestLoading, setMembershipRequestLoading] = useState<boolean>(false);
    const [userId, setUserId] = useState<string>('');
    const [nameSearch, setNameSearch] = useState<string>("");
    const [familySearch, setFamilySearch] = useState<string>("");
    const [ncExcel, setNcExcel] = useState<any>();
    const [mobileExcel, setMobileExcel] = useState<any>()


    const toast = useToast();
    const [authedApiState] = useStore()

    useEffect(() => {
        loadPendingRequests({page: 0, size: 5});
    }, [])

    const loadPendingRequests = ({page, size, sort, asc}:
                                     { page: number, size?: number, sort?: string, asc?: boolean }) => {
        const query = {
            page, size: size ? size : 5,
            sort: sort ? sort : 'requestDate', asc: asc ? asc : false
        }
        setTableSettings(query);

        setPendingRequests([])
        setPendingRequestsLoading(true)
        authedApiState.apiContainer?.api.transactionsList("pending", "buyissue", query).then(({data, status}) => {
            //@ts-ignore
            setPendingRequestsTotalElements(data.totalElements)
            if (status === 200) {
                //@ts-ignore
                setPendingRequests(data.content?.map((item: any) => {
                    return {
                        name: item.firstName + ' ' + item.lastName,
                        createdDate: item.createdDate?.replace(/-/g, "/"),
                        accountType: t('accountType.' + item.accountType),
                        userType: t('userType.' + item.userType),
                        identityStatus: item.approvedDate ? 'تایید شده' : (item.rejectDate?'رد شده':'در حال بررسی'),
                        paymentMethod: item.paymentMethod==='ONLINE' ? 'درگاه بانکی' : 'فیش بانکی',
                        transactionStatus: Object(transaction_dictionary).hasOwnProperty(item.transactionStatus) ?
                            //@ts-ignore
                            transaction_dictionary[item.transactionStatus] : "",
                        requestDate: item.requestDate,
                        companyName: item.companyName?item.companyName:'---',
                        amount: item.amount,
                        data: item.data?.replace(/-/g, "/"),
                        totalPrice: item.totalPrice,
                        actions: item
                    }
                }));
                setNameSearch("");
                setFamilySearch("");
            } else {
                toast(t('notification.request.fail'), 'error');
            }
            setPendingRequestsLoading(false)
        }).catch((response) => {
            toast(response?.data?.msg || response?.data?.message, 'error');
            setPendingRequestsLoading(false);
        })
    };

    const signData = (pin: string, data: string) => {
        return new Promise<string>((resolve, reject) => {
            let msg = '';
            let cmd: string = 'error';
            new KeyA().KeyA3_Sign_PKCS("k3pkcs11", pin, data, 64,
                ({command, message}: { command: string, message: string }) => {
                    cmd = command;
                    msg = message;
                })

            setTimeout(() => {
                if (cmd === 'success') resolve(msg)
                if (cmd === 'error') reject(msg)
            }, 5000)

        })

    }

    useEffect(() => {
        setPin(pin);
    }, [pin])

    const throww = ($reason: string) => {
        throw $reason
    }

    const approveRejectWithSign = async (signMethod: "ENDORSE" | "REJECT") => {
        let clonedData = JSON.parse(targetData);
        if(!Object(clonedData).hasOwnProperty("signMethod")) {
            toast(t('مشکل در اطلاعات'), 'error');
            setPin('');
            return;
        }
        clonedData.signMethod = signMethod;
        const dataToSign = JSON.stringify(clonedData);

        try {
            if (!pin) {
                throww('pinNotFound');
                return;
            }

            setPinModalLoading(true);
            const theSignedData = await signData(pin, dataToSign)
            if (!theSignedData) {
                throww(theSignedData);
            }

            const {data: d, status} = await authedApiState.apiContainer?.api
                .signToken({
                    transactionId: targetId,
                    signMethod: signMethod,
                    data: dataToSign,
                    signedData: theSignedData
                }) as AxiosResponse

            if (status === 200) {
                toast(t('notification.request.approve.success'), 'success');
                reloadTable()
            } else {
                toast(t('notification.request.approve.fail'), 'error');
            }
            setPinModalLoading(false);
        } catch (error) {
            setPinModalLoading(false);
            if (typeof error === 'string') {
                switch (error) {
                    case 'C_Login: PIN_INCORRECT':
                    case 'pinNotFound':
                        setPin('');
                        toast('رمز اشتباه است', 'error');
                        break;
                    case 'undefinedError':
                        toast('خطای نامشخص از توکن', 'error')
                        break;
                    case 'pinError':
                        toast('رمز اشتباه', 'error')
                        break;
                    case 'C_Login: PIN_LOCKED':
                        toast('به علت ورود بیش از ۱۰ بار رمز اشتباه توکن قفل شد! لطفا آن را بازگشایی کنید.', 'error')
                        break;
                    case 'C_Initialize: CRYPTOKI_ALREADY_INITIALIZED':
                        toast('در حال تلاش مجدد...', 'warning');
                        await approveRejectWithSign(signMethod);
                        break;
                    case 'Slot count is 0':
                        toast('مشکل در ارتباط با توکن. از وصل بودن توکن اطمینان حاصل کنید.', 'error');
                        break;
                    default:
                        toast('خطا نامشخص', 'error');
                }
            } else {
                // @ts-ignore
                if (error?.status) {
                    // @ts-ignore
                    if (error.status === 400) {
                        // @ts-ignore
                        toast(t(error.data?.msg || 'notification.request.approve.fail'), 'error');
                    } else {
                        // @ts-ignore
                        toast(t(error.data?.msg || 'notification.request.approve.fail'), 'error');
                    }
                } else {
                    toast(t('خطای نامشخص لطفا از وصل بودن توکن، درایور و افزونه آن اطمینان حاصل فرمایید'), 'error');
                }
            }
            setGetPinModalVisible(false);
        }

    }

    const approveUserRequest = () => {
        setNoramlApproveRejectLoading(true);
        return new Promise((resolve, reject) => {
            authedApiState.apiContainer?.api.verifyTransaction(targetId).then(({data, status}) => {
                if (status === 200) {
                    toast(t('notification.request.approve.success'), 'success');
                    resolve(data);
                } else {
                    toast(t('notification.request.approve.fail'), 'error');
                    reject(data);
                }
                setNoramlApproveRejectLoading(false)
                reloadTable();
            }).catch((response: any) => {
                toast(response.data?.message, 'error');
                setNoramlApproveRejectLoading(false)
                reject(response)
            })
        })
    }

    const rejectUserRequest = () => {
        const body = {transactionId: targetId, reason: normalTransactionRejectionReason}
        setNoramlApproveRejectLoading(true);
        return new Promise((resolve, reject) => {
            authedApiState.apiContainer?.api.rejectToken(body).then(({data, status}) => {
                if (status === 200) {
                    toast(t('notification.request.approve.success'), 'success');
                    resolve(data);
                } else {
                    toast(t('notification.request.approve.fail'), 'error');
                    reject(data);
                }
                setNoramlApproveRejectLoading(false)
                reloadTable();
            }).catch((response: any) => {
                toast(response.data?.message, 'error');
                setNoramlApproveRejectLoading(false)
                reject(response)
            })
        })
    }

    const reloadTable = () => {
        //@ts-ignore
        loadPendingRequests(tableSettings)
       
    }

    const approveMembership = () => {
        setMembershipRequestLoading(true);
        authedApiState.apiContainer?.api.verifyMembership(userId).then(({data, status}) => {
            if (status === 200) {
                toast(t('notification.request.approve.success'), 'success');
            } else {
                toast(t('notification.request.approve.fail'), 'error');
            }
            setMembershipRequestLoading(false);
            setMembershipModalVisible(false);
            reloadTable();
        }).catch((response: any) => {
            toast(response.data?.message, 'error');
            setMembershipRequestLoading(false);
            setMembershipModalVisible(false);
        })
    }

    const rejectMembership = () => {
        const body = {
            userId: userId,
            reason: membershipRejectReason
        }
        setMembershipRequestLoading(true);
        authedApiState.apiContainer?.api.rejectMembership(body).then(({data, status}) => {
            if (status === 200) {
                toast(t('notification.request.approve.success'), 'success');
            } else {
                toast(t('notification.request.approve.fail'), 'error');
            }
            setMembershipRequestLoading(false);
            setMembershipModalVisible(false);
            reloadTable();
        }).catch((response: any) => {
            toast(response.data?.message, 'error');
            setMembershipRequestLoading(false);
            setMembershipModalVisible(false);
        })
    }

    const pendingRequestsColumn: ColumnProps<Record<string, string>>[] = [
        {
            title: 'تاریخ‌درخواست',
            key: 'createdDate',
            dataIndex: 'createdDate',
            sorter: true
        },
        {
            title: 'نام‌و‌نام‌خانوادگی',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'متقاضی',
            dataIndex: 'accountType',
            key: 'accountType',
            sorter: true,
            responsive: ['xxl', 'xl', 'lg', 'sm']
        },
        {
            title: 'وضعیت هویت',
            key: 'identityStatus',
            dataIndex: 'identityStatus',
            responsive: ['xxl', 'xl', 'lg', 'sm']

        },
        {
            title: 'نام‌شرکت',
            key: 'companyName',
            dataIndex: 'companyName',
            responsive: ['xxl', 'xl', 'lg', 'sm']
        },
        {
            title: 'نوع سهام‌داری',
            key: 'userType',
            dataIndex: 'userType',
            sorter: true,
            responsive: ['xxl', 'xl', 'lg', 'sm']
        },
        {
            title: 'تعداد رزرو',
            key: 'amount',
            dataIndex: 'amount',
            sorter: true,
            responsive: ['xxl', 'xl', 'lg', 'sm'],
            render: item => item?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        {
            title: 'مبلغ پرداختی (ریال)',
            key: 'totalPrice',
            dataIndex: 'totalPrice',
            sorter: true,
            responsive: ['xxl', 'xl', 'lg', 'sm'],
            render: item => item?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        {
            title: 'وضعیت تراکنش',
            key: 'transactionStatus',
            dataIndex: 'transactionStatus',
            sorter: true,
            responsive: ['xxl', 'xl', 'lg', 'sm']
        },
        {
            title: 'اقدامات',
            key: 'actions',
            dataIndex: 'actions',
            render: (item) => (
                <>
                    <Row>
                        <Col span={6}>
                            <Button
                                disabled={item.transactionStatus !== "PENDING_FOR_VERIFY_MEMBERSHIP"}
                                type={"text"} onClick={() => {
                                setUserId(item.userId);
                                setMembershipModalVisible(true);
                            }}>
                                <FontAwesomeIcon icon={faUser}/>
                            </Button>
                        </Col>
                        <Col span={18}>
                            <TableActions
                                onApprove={approveRejectWithSign}
                                onReject={rejectUserRequest}
                                onView={() => {}}
                                approveParams={{}}
                                rejectParams={[]}
                                viewParams={{}}
                                approveBtnDsbl={
                                    (item.transactionStatus !== 'PENDING_FOR_VERIFY_TRANSACTION' &&
                                        item.transactionStatus !== 'PENDING_FOR_CEO_SIGN')
                                }
                                rejectBtnDsbl={
                                    (item.transactionStatus !== 'PENDING_FOR_VERIFY_TRANSACTION' &&
                                        item.transactionStatus !== 'PENDING_FOR_CEO_SIGN')
                                }
                                record={item}
                                viewBtnDsbl={true}
                                id={item.id}
                                onFinish={reloadTable}
                                onApproveClick={() => {
                                    setTargetId(item.id);
                                    if (item.transactionStatus === "PENDING_FOR_VERIFY_TRANSACTION") {
                                        setVerifyTransactionModalVisible(true);
                                    } else if (item.transactionStatus === "PENDING_FOR_CEO_SIGN") {
                                        setTargetData(item.dataToSign);
                                        setGetPinModalVisible(true);
                                    }
                                }}
                                onRejectClick={() => {
                                    setTargetId(item.id);
                                    if (item.transactionStatus === "PENDING_FOR_VERIFY_TRANSACTION") {
                                        setRejectTransactionModalVisible(true);
                                    } else if (item.transactionStatus === "PENDING_FOR_CEO_SIGN") {
                                        setRejectReason('');
                                        setTargetData(item.dataToSign);
                                        setRejectModalVisible(true);
                                    }
                                }}
                            />
                        </Col>
                    </Row>

                </>
            )
        }
    ]

    ////////////////////////////////////////سرچ براساس نام
 const loadFiltername = ({
    page,
    size,
    sort,
    asc,
    type,
    search
  }: {
    page?: number;
    size?: number;
    sort?: string;
    asc?: boolean; 
    type:number;
    search?:string;
  }) => {
    const query = {
      page,
      size: size ? size : 5,
      sort: sort ? sort : "requestDate",
      asc: asc ? asc : false,
      search:search ? search : nameSearch?nameSearch:familySearch,
      type:type
    };
    setTableSettings(query);

    setPendingRequests([])
    setPendingRequestsLoading(true)
    authedApiState.apiContainer?.api
      .searchLastNameOrNameBuyListRequests("pending", query)
      .then(({ data, status }) => {
         //@ts-ignore
         setPendingRequestsTotalElements(data.totalElements)
         if (status === 200) {
             //@ts-ignore
             setPendingRequests(data.content?.map((item: any) => {
                 return {
                     name: item.firstName + ' ' + item.lastName,
                     createdDate: item.createdDate?.replace(/-/g, "/"),
                     accountType: t('accountType.' + item.accountType),
                     userType: t('userType.' + item.userType),
                     identityStatus: item.approvedDate ? 'تایید شده' : (item.rejectDate?'رد شده':'در حال بررسی'),
                     paymentMethod: item.paymentMethod==='ONLINE' ? 'درگاه بانکی' : 'فیش بانکی',
                     transactionStatus: Object(transaction_dictionary).hasOwnProperty(item.transactionStatus) ?
                         //@ts-ignore
                         transaction_dictionary[item.transactionStatus] : "",
                     requestDate: item.requestDate,
                     companyName: item.companyName?item.companyName:'---',
                     amount: item.amount,
                     data: item.data?.replace(/-/g, "/"),
                     totalPrice: item.totalPrice,
                     actions: item
                 }
             }));
            
         } else {
             toast(t('notification.request.fail'), 'error');
         }
         setPendingRequestsLoading(false)
     }).catch((response) => {
         toast(response?.data?.msg || response?.data?.message, 'error');
         setPendingRequestsLoading(false);
     })
 };

    /////////////////////////////////////اکسل 
const loadFilterExcel = ({
    search,
    type,
  }: {
    type?: number; 
    search?:string;
  }) => {
    const query = {
      type:type,
      search:search ? search :mobileExcel ?mobileExcel :ncExcel
    };
    authedApiState.apiContainer?.api
      .DwExcelInBuylistPending("pending", query)
      .then(({ data, status }) => {
        if (status === 200) {
          DwFilterExcel(data.id)
        }
      })
      .catch((response: any) => {
        toast(response?.data?.msg || response?.data?.message, "error");
      });
  };
  const DwFilterExcel = async (data1: string) => {
    try {
      const response = await authedApiState.apiContainer?.api.DownloadExcel(data1);
      if (response) {
        const { data, status } = response;
  
        if (status === 200 && data) 
        {
          const excelData = data.map((item: any) => Object.values(item)); // Convert data to array of arrays
          const headers = ['تاریخ‌درخواست', 'نام', 'نام خانوادگی','متقاضی','کدملی','موبایل','نام‌شرکت']; // Set the headers for columns
          const workbook = XLSX.utils.book_new();
          XLSX.utils.sheet_add_aoa(workbook, [headers, ...excelData], {origin: 0}); // Add headers and data to worksheet
          XLSX.utils.book_append_sheet(workbook, workbook, 'Sheet1'); // Add worksheet to workbook
  
          const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' }); // Generate Excel buffer
  
          const blobData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }); // Convert buffer to Blob
  
          const url = window.URL.createObjectURL(blobData);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'downloaded_file.xlsx'; // Set the appropriate file name and extension
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  ////////////////**********////////////////////////////
  const [focusedInput, setFocusedInput] = useState<string | null>(null);

  const handleFocusChange = (placeholder: string) => {
    setFocusedInput(placeholder);
  };

  const handleClearFilter = () => {
    setFocusedInput(null);
    reloadTable();
  };
    return (
        <>

<Row gutter={[16, 16]}  style={{ marginBottom: "20px", alignItems: "baseline" }}>
            
              <InputSearch
                handleChange={(e) => setNameSearch(e.target.value)}
                onFocusChange={handleFocusChange}
                placeholder="سرچ براساس نام"
                val={nameSearch}
                isDisabled={focusedInput ==="سرچ براساس نام خانوادگی" }
              />
              <BtnFilter
                handleChange={() =>loadFiltername({ page: 0 ,type:1}) }
                textBtn="جستجو"
                type="search"
              />
              <InputSearch
                handleChange={(e) => setFamilySearch(e.target.value)}
                onFocusChange={handleFocusChange}
                placeholder="سرچ براساس نام خانوادگی"
                val={familySearch}
                isDisabled={focusedInput ==="سرچ براساس نام" }

              />
              <BtnFilter
                handleChange={() =>loadFiltername({ page: 0,type:2 }) }
                textBtn="جستجو"
                type="search"
              />
              
              <BtnClearFilter
                handleChange={() => handleClearFilter()}
                textBtn="حذف فیلتر"
                type="remove"
              />
            </Row>
            <Row gutter={[16, 16]}>
                <Col span={24} lg={24} md={24} sm={24} xs={24}>
                    <div className={'dashboard-grid'}>
                        {focusedInput === "سرچ براساس نام خانوادگی"?
                          <CustomTable
                          rowClass={"verified-person-table-row"}
                          rowClassTriggerProperty={"verified_membership"}
                          loading={pendingRequestsLoading}
                          className='special-styled-table'
                          paginationBottom={"bottomCenter"}
                          data={pendingRequests}
                          columns={pendingRequestsColumn}
                          total={PendingRequestsTotalElements}
                          onChange={() =>loadFiltername({ page: 0 ,type:2}) }
                          defaultSort={"requestDate"}
                      />
                        :focusedInput === "سرچ براساس نام"?
                        <CustomTable
                        rowClass={"verified-person-table-row"}
                        rowClassTriggerProperty={"verified_membership"}
                        loading={pendingRequestsLoading}
                        className='special-styled-table'
                        paginationBottom={"bottomCenter"}
                        data={pendingRequests}
                        columns={pendingRequestsColumn}
                        total={PendingRequestsTotalElements}
                        onChange={() =>loadFiltername({ page: 0 ,type:1}) }
                        defaultSort={"requestDate"}
                    />
                        :  <CustomTable
                        rowClass={"verified-person-table-row"}
                        rowClassTriggerProperty={"verified_membership"}
                        loading={pendingRequestsLoading}
                        className='special-styled-table'
                        paginationBottom={"bottomCenter"}
                        data={pendingRequests}
                        columns={pendingRequestsColumn}
                        total={PendingRequestsTotalElements}
                        onChange={loadPendingRequests}
                        defaultSort={"requestDate"}
                    />}
                      
                    </div>
                </Col>
            </Row>
            <Row gutter={[16, 16]}  style={{ marginTop: "10px", alignItems: "baseline" }}>
              <InputExcel
                handleChange={(e) => setNcExcel(e.target.value)}
                placeholder="دانلود براساس کدملی"
                val={ncExcel}
              />
              <BtnExcel
                handleChange={() =>loadFilterExcel({type:3})}
                textBtn="جستجو"
                
              />
                <InputExcel
                handleChange={(e) => setMobileExcel(e.target.value)}
                placeholder="دانلود براساس موبایل"
                val={mobileExcel}
              />
              <BtnExcel
                handleChange={() =>loadFilterExcel({type:4})}
                textBtn="جستجو"
                
              />
              <BtnClearFilter
                handleChange={() => {
                  setMobileExcel("");
                  setNcExcel("");
                }}
                textBtn="حذف فیلتر"
                type="remove"
              />
            </Row>
            <Modal
                title={"امضا و تایید تراکنش"}
                visible={getPinModalVisible}
                onCancel={() => {
                    setGetPinModalVisible(false)
                }}
                footer={[
                    <Button key="back" onClick={() => {
                        setGetPinModalVisible(false)
                    }}>
                        لغو
                    </Button>,
                    <Button disabled={pin.length < 4} key="submit" type="primary" loading={pinModalLoading}
                            onClick={() => approveRejectWithSign('ENDORSE')}>
                        امضا و تایید تراکنش
                    </Button>
                ]}
            >
                <div>
                    <br/>
                    <Input.Password
                        value={pin}
                        placeholder={"رمز توکن را وارد کنید"}
                        onChange={(event) => {
                            setPin(event.target.value)
                        }}/>
                </div>
            </Modal>

            <Modal
                title={"رد تراکنش"}
                visible={rejectModalVisible}
                onCancel={() => {
                    setRejectModalVisible(false)
                }}
                footer={[
                    <Button key="back" onClick={() => {
                        setRejectModalVisible(false)
                    }}>
                        لغو
                    </Button>,
                    <Button disabled={rejectReason.length < 5} key="submit" type="primary" danger
                            loading={rejectingRequest}
                            onClick={() => approveRejectWithSign('REJECT')}>
                        امضا و رد تراکنش
                    </Button>
                ]}
            >
                <div>
                    <Input
                        value={rejectReason}
                        placeholder={"علت رد درخواست"}
                        onChange={(event) => {
                            setRejectReason(event.target.value)
                        }}/>
                </div>
            </Modal>

            <Modal
                title={"تایید و رد عضویت"}
                visible={membershipModalVisible}
                onCancel={() => {
                    setMembershipModalVisible(false)
                }}
                footer={[
                    <Button
                        disabled={membershipRejectReason.length > 0}
                        loading={membershipRequestLoading && membershipRejectReason.length === 0}
                        type="primary" onClick={() => {
                        approveMembership()
                    }}>
                        تایید عضویت
                    </Button>,
                    <Button
                        disabled={membershipRejectReason.length === 0}
                        key="submit" type="primary" danger
                        loading={membershipRequestLoading && membershipRejectReason.length > 0}
                        onClick={() => rejectMembership()}>
                        رد عضویت
                    </Button>
                ]}
            >
                <div>
                    <Input
                        value={membershipRejectReason}
                        placeholder={"در صورت رد علت را وارد کنید"}
                        onChange={(event) => {
                            setMembershipRejectReason(event.target.value)
                        }}/>
                </div>
            </Modal>


            <Modal
                title={"تایید عادی تراکنش"}
                visible={verifyTransactionModalVisible}
                onCancel={() => {
                    setVerifyTransactionModalVisible(false)
                }}
                footer={[
                    <Button
                        type="primary" onClick={() => {
                        setVerifyTransactionModalVisible(false)
                    }}>
                        لغو
                    </Button>,
                    <Button
                        key="submit" type="primary" danger
                        loading={noramlApproveRejectLoading}
                        onClick={() => approveUserRequest()}>
                        تایید تراکنش
                    </Button>
                ]}
            >
                آیا مطمئن هستید؟
            </Modal>

            <Modal
                title={"رد عادی تراکنش"}
                visible={rejectTransactionModalVisible}
                onCancel={() => {
                    setRejectTransactionModalVisible(false)
                }}
                footer={[
                    <Button
                        type="primary" onClick={() => {
                        setRejectTransactionModalVisible(false)
                    }}>
                        لغو
                    </Button>,
                    <Button
                        disabled={normalTransactionRejectionReason.length === 0}
                        key="submit" type="primary" danger
                        loading={noramlApproveRejectLoading}
                        onClick={() => rejectUserRequest()}>
                        رد تراکنش
                    </Button>
                ]}
            >
                <div>
                    <Input
                        value={normalTransactionRejectionReason}
                        placeholder={"علت رد را وارد کنید"}
                        onChange={(event) => {
                            setNormalTransactionRejectionReason(event.target.value)
                        }}/>
                </div>
            </Modal>
        </>
    )
}

export default PendingBuyRequests;
