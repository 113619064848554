import { Col } from "antd";

const BtnExcel = ({
  handleChange,
  textBtn,
}: {
  handleChange: () => void;
  textBtn: string;
}) => {
  return (
    <>
      {/* <Col lg={2} sm={24} xs={24}> */}
      <button
        style={{
          padding: "12px 12px",

          background:
            "linear-gradient(90deg, rgba(7,94,51,1) 0%, rgba(110,186,155,0.8855742980786064) 100%)", // تغییر رنگ پس زمینه
          color: "#fff", // تغییر رنگ متن
          border: "none", // حذف حاشیه
          marginRight: "5px",
          marginTop: "2px",
          marginBottom: "2px",
        }}
        onClick={handleChange}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-file-earmark-arrow-down"
          viewBox="0 0 16 16"
        >
          <path d="M8.5 6.5a.5.5 0 0 0-1 0v3.793L6.354 9.146a.5.5 0 1 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 10.293z" />
          <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
        </svg>
      </button>
    </>
  );
};

export default BtnExcel;
