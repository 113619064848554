import React from 'react';
import Styled from './styles';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
  const { t } = useTranslation('layouts');

  return (
    <Styled.Container>
      <Styled.Title>{t('notfound.title')}</Styled.Title>
      <Styled.Subtitle>{t('notfound.subtitle')}</Styled.Subtitle>
    </Styled.Container>
  );
};

export default NotFound;
