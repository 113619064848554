import Email from '../../assets/images/email.png';
import Logo from '../../assets/images/logo_new.svg';
import Phone from '../../assets/images/phone.png';
import Instagram from '../../assets/images/social_instagram.png';
import Linkedin from '../../assets/images/social_linkedin.png';
import Twitter from '../../assets/images/social_twitter.png';
import Youtube from '../../assets/images/social_youtube.png';
import moment from 'moment-jalaali';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Styled from './styles';

moment.loadPersian({ dialect: 'persian-modern' });

const Footer = () => {
  const { t } = useTranslation('layouts');

  return (
    <Styled.Container>
      <div className={'top'}>
        <Styled.Content>
          <div className={'menus'}>
            <Styled.Menu>
              <Styled.Header>{t('footer.menu.cooperative')}</Styled.Header>
              <Styled.Item to={'/'}>{t('footer.menu.about')}</Styled.Item>
              <Styled.Item to={'/'}>{t('footer.menu.sponsors')}</Styled.Item>
            </Styled.Menu>
            <Styled.Menu>
              <Styled.Header>{t('footer.menu.investment')}</Styled.Header>
              <Styled.Item to={'/'}>{t('footer.menu.facilities')}</Styled.Item>
              <Styled.Item to={'/'}>
                {t('footer.menu.register_types')}
              </Styled.Item>
            </Styled.Menu>
            <Styled.Menu>
              <Styled.Header>{t('footer.menu.support')}</Styled.Header>
              <Styled.Item to={'/'}>{t('footer.menu.faq')}</Styled.Item>
              <Styled.Item to={'/'}>{t('footer.menu.rules')}</Styled.Item>
            </Styled.Menu>
          </div>
          <Styled.Contact>
            <img src={Logo} alt={''} />
            <div className={'address'}>{t('footer.contact.address')}</div>
            <div className={'social'}>
              <a
                href={'https://instagram.com'}
                target={'_blank'}
                rel={'noreferrer'}
              >
                <img src={Instagram} alt={''} />
              </a>
              <a
                href={'https://twitter.com'}
                target={'_blank'}
                rel={'noreferrer'}
              >
                <img src={Twitter} alt={''} />
              </a>
              <a
                href={'https://linkedin.com'}
                target={'_blank'}
                rel={'noreferrer'}
              >
                <img src={Linkedin} alt={''} />
              </a>
              <a
                href={'https://youtube.com'}
                target={'_blank'}
                rel={'noreferrer'}
              >
                <img src={Youtube} alt={''} />
              </a>
            </div>
          </Styled.Contact>
        </Styled.Content>
      </div>
      <div className={'bottom'}>
        <div className={'copy'}>
          {`${moment().jYear()} ${t('footer.copyright')}`}
        </div>
        <div className={'contact'}>
          <div className={'item'}>
            <img src={Phone} alt={''} />
            {t('footer.phone')}
          </div>
          <div className={'item'}>
            <img src={Email} alt={''} />
            {t('footer.email')}
          </div>
        </div>
      </div>
    </Styled.Container>
  );
};

export default Footer;
