import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";
import CustomTable from "../../components/Table";
import useStore from "../../hooks/useStore";
import useToast from "../../hooks/useToast";
import { ColumnProps } from "antd/lib/table/Column";
import TableActions from "../../components/TableActions";
import BtnClearFilter from "../../components/BtnClearFilter";
import InputSearch from "../../components/InputSearch";
import BtnFilter from "../../components/BtnFilter";
import BtnExcel from "../../components/BtnExcel";
import * as XLSX from 'xlsx';
import InputExcel from "../../components/InputExcel";

const OrderedRequests = () => {
  const { t } = useTranslation("identification");
  const [
    OrderedRequestsTotalElements,
    setOrderedRequestsTotalElements,
  ] = useState<number>(0);
  const [orderedRequests, setOrderedRequests] = useState<any[]>([]);
  const [orderedRequestsLoading, setOrderedRequestsLoading] = useState<boolean>(
    true
  );
  const [tableSettings, setTableSettings] = useState<{}>({});
  const [nameSearch, setNameSearch] = useState<string>("");
  const [familySearch, setFamilySearch] = useState<string>("");
  const [ncExcel, setNcExcel] = useState<any>();
  const [mobileExcel, setMobileExcel] = useState<any>();

  const toast = useToast();
  const [authedApiState] = useStore();

  useEffect(() => {
    loadOrderedRequests({ page: 0 });
  }, []);

        const loadOrderedRequests = ({page, size, sort, asc}:
            { page: number, size?: number, sort?: string, asc?: boolean }) => {
        const query = {
        page, size: size ? size : 5,
        sort: sort ? sort : 'orderTotalPrice', asc: asc ? asc : false
        }
        setTableSettings(query);
        setOrderedRequests([])
        setOrderedRequestsLoading(true)
        authedApiState.apiContainer?.api.usersDocsList('ordered', query).then(({data, status}) => {
        //@ts-ignore
        setOrderedRequestsTotalElements(data.totalElements)
        if (status === 200) {
        //@ts-ignore
        setOrderedRequests(data.content?.map((item: any) => {
        return {
        name: item.firstName + ' ' + item.lastName,
        accountType: t('accountType.' + item.accountType),
        userType: t('userType.' + item.userType),
        requestDate: item.requestDate.replace(/-/g, "/"),
        amount: item.amount,
        companyName: item.companyName?item.companyName:'---',
        orderTotalPrice: item.orderTotalPrice,
        actions: item,
        state: item.approveDate ? "تایید شده" : "در دست بررسی"
        }
        }));
        } else {
        toast(t('notification.request.approve.fail'), 'error');
        }
        setOrderedRequestsLoading(false)
        }).catch((response: any) => {
        toast(response.data?.msg, 'error');
        setOrderedRequestsLoading(false)
        })
        };

  const approveUserRequest = (userId: string) => {
    return new Promise((resolve, reject) => {
      authedApiState.apiContainer?.api
        .approveAllDoc({ userId })
        .then(({ data, status }) => {
          if (status === 200) {
            resolve(data);
            toast(t("notification.request.approve.success"), "success");
          } else {
            toast(t("notification.request.approve.fail"), "error");
            reject(data);
          }
        })
        .catch((response: any) => {
          toast(response.data?.message, "error");
          reject(response);
        });
    });
  };

  const rejectUserRequest = (userId: string, reason: string) => {
    return new Promise((resolve, reject) => {
      const body = { userId, reason };
      authedApiState.apiContainer?.api
        .rejectUser(body)
        .then(({ data, status }) => {
          if (status === 200) {
            resolve(data);
            toast(t("notification.request.approve.success"), "success");
          } else {
            toast(t("notification.request.approve.fail"), "error");
            reject(data);
          }
        })
        .catch((response: any) => {
          toast(response.data?.msg, "error");
          reject(response);
        });
    });
  };

  const orderedRequestsColumn: ColumnProps<Record<string, string>>[] = [
    {
      title: "تاریخ‌درخواست",
      key: "requestDate",
      dataIndex: "requestDate",
      sorter: true,
    },
    {
      title: "نام‌و‌نام‌خانوادگی",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "متقاضی",
      dataIndex: "accountType",
      key: "accountType",
      sorter: true,
      responsive: ["xxl", "xl", "lg", "sm"],
    },
    {
      title: "وضعیت‌هویت",
      key: "state",
      dataIndex: "state",
      responsive: ["xxl", "xl", "lg", "sm"],
    },
    {
      title: "نام‌شرکت",
      key: "companyName",
      dataIndex: "companyName",
      responsive: ["xxl", "xl", "lg", "sm"],
    },
    {
      title: "نوع‌سهام‌داری",
      key: "userType",
      dataIndex: "userType",
      sorter: true,
      responsive: ["xxl", "xl", "lg", "sm"],
    },
    {
      title: "تعداد‌سهم‌رزرو",
      key: "amount",
      dataIndex: "amount",
      sorter: true,
      responsive: ["xxl", "xl", "lg", "sm"],
      render: (item) => item?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    },
    {
      title: "مبلغ ‌پرداختی (ریال)",
      key: "orderTotalPrice",
      dataIndex: "orderTotalPrice",
      sorter: true,
      responsive: ["xxl", "xl", "lg", "sm"],
      render: (item) => item?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    },
    {
      title: "اقدامات",
      key: "actions",
      dataIndex: "actions",
      render: (item) => (
        <TableActions
          onApprove={approveUserRequest}
          onReject={rejectUserRequest}
          onView={() => {}}
          approveParams={{}}
          rejectParams={[]}
          viewParams={{}}
          record={item}
          approveBtnDsbl={item.approvedDate}
          rejectBtnDsbl={item.rejectDate}
          viewBtnDsbl={true}
          id={item.userId}
          onFinish={reloadTable}
        />
      ),
    },
  ];

  const reloadTable = () => {
    //@ts-ignore
    loadOrderedRequests(tableSettings);

    setNameSearch("");
    setFamilySearch("");
  };

    ////////////////////////////////////////سرچ براساس نام
  const loadFiltername = ({
    name,
    page,
    size,
    sort,
    asc,
  }: {
    page?: number;
    size?: number;
    sort?: string;
    asc?: boolean;
    name?: string; 
  }) => {
    const query = {
      page,
      size: size ? size : 5,
      sort: sort ? sort : "requestDate",
      asc: asc ? asc : false,
      name: name ? name : nameSearch,
    };
    setTableSettings(query);
        setOrderedRequests([])
        setOrderedRequestsLoading(true)
    authedApiState.apiContainer?.api
      .searchNamePrePurchaseRequestIdentityVerification("ordered", query)
      .then(({ data, status }) => {
          //@ts-ignore
        setOrderedRequestsTotalElements(data.totalElements)
        if (status === 200) {
        //@ts-ignore
        setOrderedRequests(data.content?.map((item: any) => {

        return {
        name: item.firstName + ' ' + item.lastName,
        accountType: t('accountType.' + item.accountType),
        userType: t('userType.' + item.userType),
        requestDate: item.requestDate.replace(/-/g, "/"),
        amount: item.amount,
        companyName: item.companyName?item.companyName:'---',
        orderTotalPrice: item.orderTotalPrice,
        actions: item,
        state: item.approveDate ? "تایید شده" : "در دست بررسی"
        }
        }));
        } else {
        toast(t('notification.request.approve.fail'), 'error');
        }
        setOrderedRequestsLoading(false)
        }).catch((response: any) => {
        toast(response.data?.msg, 'error');
        setOrderedRequestsLoading(false)
        })
        };

  ////////////////////////////////////////سرچ براساس نام خانوادگی
       const loadFilterLastname = ({
    last,
    page,
    size,
    sort,
    asc,
  }: {
    page?: number;
    size?: number;
    sort?: string;
    asc?: boolean;
    last?: string; 
  }) => {
    const query = {
      page,
      size: size ? size : 5,
      sort: sort ? sort : "requestDate",
      asc: asc ? asc : false,
      last: last ? last : familySearch,
    };
    setTableSettings(query);
    setOrderedRequests([])
    setOrderedRequestsLoading(true)
    authedApiState.apiContainer?.api
      .searchLastNamePrePurchaseRequestIdentityVerification("ordered", query)
      .then(({ data, status }) => {
              //@ts-ignore
        setOrderedRequestsTotalElements(data.totalElements)
        if (status === 200) {
        //@ts-ignore
        setOrderedRequests(data.content?.map((item: any) => {

        return {
        name: item.firstName + ' ' + item.lastName,
        accountType: t('accountType.' + item.accountType),
        userType: t('userType.' + item.userType),
        requestDate: item.requestDate.replace(/-/g, "/"),
        amount: item.amount,
        companyName: item.companyName?item.companyName:'---',
        orderTotalPrice: item.orderTotalPrice,
        actions: item,
        state: item.approveDate ? "تایید شده" : "در دست بررسی"
        }
        }));
        } else {
        toast(t('notification.request.approve.fail'), 'error');
        }
        setOrderedRequestsLoading(false)
        }).catch((response: any) => {
        toast(response.data?.msg, 'error');
        setOrderedRequestsLoading(false)
        })
        };

 /////////////////////////////////////اکسل 
    const loadFilterExcel = ({
      search,
      type,
    }: {
      type?: number; 
      search?:string;
    }) => {
      const query = {
        type:type,
        search:search ? search :mobileExcel ?mobileExcel :ncExcel
      };
      authedApiState.apiContainer?.api
        .DwExcelInIdentityPending("ordered", query)
        .then(({ data, status }) => {
          if (status === 200) {
            DwFilterExcel(data.id)
          }
        })
        .catch((response: any) => {
          toast(response?.data?.msg || response?.data?.message, "error");
        });
    };

    const DwFilterExcel = async (data1: string) => {
      try {
        const response = await authedApiState.apiContainer?.api.DownloadExcel(data1);
        if (response) {
          const { data, status } = response;

          if (status === 200 && data) 
          {
            const excelData = data.map((item: any) => Object.values(item)); // Convert data to array of arrays
            const headers = ['تاریخ‌درخواست', 'نام', 'نام خانوادگی','متقاضی','کدملی','موبایل','نام‌شرکت']; // Set the headers for columns
            const workbook = XLSX.utils.book_new();
            XLSX.utils.sheet_add_aoa(workbook, [headers, ...excelData], {origin: 0}); // Add headers and data to worksheet
            XLSX.utils.book_append_sheet(workbook, workbook, 'Sheet1'); // Add worksheet to workbook

            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' }); // Generate Excel buffer

            const blobData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }); // Convert buffer to Blob

            const url = window.URL.createObjectURL(blobData);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'downloaded_file.xlsx'; // Set the appropriate file name and extension
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

/////////////////////************////////////////
const [focusedInput, setFocusedInput] = useState<string | null>(null);

const handleFocusChange = (placeholder: string) => {
  setFocusedInput(placeholder);
};

const handleClearFilter = () => {
  setFocusedInput(null);
  reloadTable();
};

  return (
    <>
     <Row gutter={[16, 16]}  style={{ marginBottom: "20px", alignItems: "baseline" }}>
              <InputSearch
                handleChange={(e) => setNameSearch(e.target.value)}
                onFocusChange={handleFocusChange}
                placeholder="سرچ براساس نام  "
                val={nameSearch}
                isDisabled={ focusedInput ==="سرچ براساس نام خانوادگی" }
              />
              <BtnFilter
                handleChange={() =>loadFiltername({ page: 0 }) }
                textBtn="جستجو"
                type="search"
              />
              <InputSearch
                handleChange={(e) => setFamilySearch(e.target.value)}
                onFocusChange={handleFocusChange}
                placeholder="سرچ براساس نام خانوادگی"
                val={familySearch}
                isDisabled={ focusedInput ==="سرچ براساس نام  " }
              />
              <BtnFilter
                handleChange={() =>loadFilterLastname({ page: 0 }) }
                textBtn="جستجو"
                type="search"
              />
              <BtnClearFilter
                handleChange={() => handleClearFilter()}
                textBtn="حذف فیلتر"
                type="remove"
              />
            </Row>
      <Row gutter={[16, 16]}>
        <Col span={24} lg={24} md={24} sm={24} xs={24}>
          <div className={"dashboard-grid"}>
            {focusedInput === "سرچ براساس نام  "?
             <CustomTable
             loading={orderedRequestsLoading}
             className="special-styled-table"
             paginationBottom={"bottomCenter"}
             data={orderedRequests}
             columns={orderedRequestsColumn}
             total={OrderedRequestsTotalElements}
             onChange={loadFiltername}
             defaultSort={"orderTotalPrice"}
           />
            :focusedInput === "سرچ براساس نام خانوادگی"?
            <CustomTable
             loading={orderedRequestsLoading}
             className="special-styled-table"
             paginationBottom={"bottomCenter"}
             data={orderedRequests}
             columns={orderedRequestsColumn}
             total={OrderedRequestsTotalElements}
             onChange={loadFilterLastname}
             defaultSort={"orderTotalPrice"}
           />
            :<CustomTable
            loading={orderedRequestsLoading}
            className="special-styled-table"
            paginationBottom={"bottomCenter"}
            data={orderedRequests}
            columns={orderedRequestsColumn}
            total={OrderedRequestsTotalElements}
            onChange={loadOrderedRequests}
            defaultSort={"orderTotalPrice"}
          />}
            
          </div>
        </Col>
      </Row>
      <Row gutter={[16, 16]}  style={{ marginTop: "10px", alignItems: "baseline" }}>
              <InputExcel
                handleChange={(e) => setNcExcel(e.target.value)}
                placeholder="دانلود براساس کدملی"
                val={ncExcel}
              />
              <BtnExcel
                handleChange={() =>loadFilterExcel({type:3})}
                textBtn="جستجو"              
              />
                <InputExcel
                handleChange={(e) => setMobileExcel(e.target.value)}
                placeholder="دانلود براساس موبایل"
                val={mobileExcel}
              />
              <BtnExcel
                handleChange={() =>loadFilterExcel({type:4})}
                textBtn="جستجو"               
              />
              <BtnClearFilter
                handleChange={() => {
                  setMobileExcel("");
                  setNcExcel("");
                }}
                textBtn="حذف فیلتر"
                type="remove"
              />
            </Row>
    </>
  );
};

export default OrderedRequests;
