import { css } from 'styled-components';

class MediaQuery {
  static styling = (size, style, down = true) => {
    const _breakPoints = {
      xs: '48em',
      sm: '60em',
      md: '75em',
      lg: '100em',
      xl: '120em'
    };

    const _width = _breakPoints[size] || size;

    return down
      ? css`
          @media screen and (max-width: ${_width}) {
            ${style}
          }
        `
      : css`
          @media screen and (min-width: ${_width}) {
            ${style}
          }
        `;
  };
}

export default MediaQuery;



