import React, {useState} from 'react';
import Styled from './styles';

const Header = ({ title, subtitle, star, noPadding }) => {

  return (
    <Styled.Container noPadding={noPadding}>
      {star && (
        <Styled.Star>
          {[...Array(5)].map((_star, _index) => (
            <span
              key={'star_' + _index}
              className={`star star-${_index + 1}`}
            />
          ))}
        </Styled.Star>
      )}
      {title && (
        <div className={'title'}>
          {title.color && (
            <Styled.Title colorful={true}>{title.color}</Styled.Title>
          )}
          {title.solid && (
            <Styled.Title colorful={false}>{title.solid}</Styled.Title>
          )}
        </div>
      )}
      {subtitle && <Styled.Subtitle>{subtitle}</Styled.Subtitle>}
    </Styled.Container>
  );
};

export default Header;
