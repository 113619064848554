import {DatePicker as DatePickerJalali} from "antd-jalali";
import React, {useEffect, useState} from "react";
import dayjs from "dayjs";
import jalaliday from "jalaliday";
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'

dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)
dayjs.extend(jalaliday)
dayjs('jalali')

const PersianRangePicker = ({
                                hasYearPreset,
                                hasMonthPreset,
                                hasWeekPreset,
                                hasToday,
                                onChange,
                                format = "YYYY/MM/DD",
                                id = "",
                                name = "",
                                className = "",
                                value,
                                allowClear = false
                            }: {
    hasYearPreset: boolean,
    hasMonthPreset: boolean,
    hasWeekPreset: boolean,
    hasToday: boolean,
    onChange?: ({from, to}: { from: dayjs.Dayjs|'', to: dayjs.Dayjs|'' }) => void,
    format?: string,
    id?: string,
    name?: string,
    className?: string,
    value?: dayjs.Dayjs[],
    allowClear?: boolean
}) => {
    const currentYear = [
        dayjs().calendar('jalali').startOf('year').locale('fa'),
        dayjs().calendar('jalali').endOf('year').locale('fa')
    ]
    const currentMonth = [
        dayjs().calendar('jalali').startOf('month').locale('fa'),
        dayjs().calendar('jalali').endOf('month').locale('fa')
    ]
    const currentWeek = [
        dayjs().calendar('jalali').startOf('week').locale('fa'),
        dayjs().calendar('jalali').endOf('week').locale('fa')
    ]
    const today = [
        dayjs().calendar('jalali').startOf('day').locale('fa'),
        dayjs().calendar('jalali').endOf('day').locale('fa')
    ]

    const [ranges, setRanges] = useState<{}>();

    useEffect(() => {
        let tmpRanges: Record<string, dayjs.Dayjs[]> = {}
        if (hasYearPreset) tmpRanges["سال جاری"] = currentYear;
        if (hasMonthPreset) tmpRanges["ماه جاری"] = currentMonth;
        if (hasWeekPreset) tmpRanges["هفته جاری"] = currentWeek;
        if (hasToday) tmpRanges["امروز"] = today;
        setRanges(tmpRanges);
        //@ts-ignore
    }, []);


    const changeHandler = (data: dayjs.Dayjs[]) => {
        if(data)
            onChange && onChange({from: data[0], to: data[1]})
        else onChange && onChange({from: "", to: ""})
    }

    return (
        <DatePickerJalali.RangePicker
            id={id}
            name={name}
            className={className}
            ranges={ranges}
            format={format}
            bordered={false}
            allowClear={allowClear}
            onChange={(val: DatePickerJalali) => changeHandler(val)}
            value={
                [
                    (value && value[0] ? value[0] : dayjs()).calendar('jalali').locale('fa'),
                    (value && value[1] ? value[1] : dayjs()).calendar('jalali').locale('fa')
                ]
            }
        />
    )
}
export default PersianRangePicker;
