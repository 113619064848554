import {Input} from "antd";
import React, {ChangeEvent, useEffect, useState} from "react";
import {nanoid} from 'nanoid'

export interface InputProps {
    value?: number | undefined | null;
    onChange?: (value: number | undefined) => void;
    style?: React.CSSProperties,
    label: string,
    maxLength?: number,
    withCommas?: boolean,
    disable?:boolean,
}

const NumberInput = ({label, value, onChange, maxLength, withCommas,disable}: InputProps) => {
    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        if(event.target.value.length === 0){
            onChange && onChange(undefined);
            return;
        }
        const e = '۰'.charCodeAt(0);
        const str = event.target.value.replace(/,/g,'').replace(/[۰-۹]/g, (t: string) => (t.charCodeAt(0) - e).toString() );
        let number = Number.parseInt(str)
        onChange && onChange(number)
    }
    const [id, setId] = useState<string>()
    useEffect(() => {
        setId(nanoid())
    }, [])

    return (
        <>
            <div style={{position: "relative"}}>
                <label
                    htmlFor={id}
                    style={{
                        backgroundColor: "white",
                        paddingLeft: "5px",
                        paddingRight: "5px",
                        top: "-12px",
                        right: "15px",
                        position: "absolute",
                        zIndex: 10,
                        color: "#c4c4c4",
                        fontSize: "16px"
                    }}
                >
                    {label}
                </label>
                <Input
                    disabled={disable}
                    maxLength={maxLength}
                    value={value?(withCommas?value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):value.toString()):''}
                    style={{
                        lineHeight: "50px",
                        borderRadius: "5px",
                        border: "1px solid #D0C9DF",
                        boxSizing: "border-box",
                        fontSize: "16px",
                        color: "#5D55A1",
                        direction: 'rtl',
                    }}
                    id={id}
                    onChange={(e) => {
                        changeHandler(e)
                    }}
                />
            </div>
        </>
    )
}
export default NumberInput;
