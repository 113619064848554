import styled from 'styled-components';

const Content = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  flex: 1;
  background: #f0f0f8;
  overflow: hidden;
`;

const Styled = {
  Content
};

export default Styled;
