import { Col, Input } from "antd";

const InputExcel = ({
  val,
  handleChange,

  placeholder,
}: {
  val: string;
  handleChange: React.ChangeEventHandler<HTMLInputElement>;

  placeholder: string;
}) => {
  return (
    <>
      <Col lg={5} sm={15} xs={15}>
        <Input
          type="text"
          placeholder={placeholder}
          value={val}
          onChange={handleChange}
          style={{
           padding: "9px 29px 10px 11px",
            marginRight: "13px",
            marginLeft:"13px",
            borderRadius: "0%",
            border: "1px solid #ccc", // اضافه کردن حاشیه
            fontSize: "16px", // اضافه کردن اندازه فونت
          }}
        />
      </Col>
    </>
  );
};

export default InputExcel;
