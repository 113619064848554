import React from 'react';
import {Redirect, Route, Switch, useLocation} from 'react-router-dom';
import Login from "../pages/Login";

const Public = ({isAuthenticated}: { isAuthenticated: boolean }) => {
    const location = useLocation()
    if (isAuthenticated) {
        if (location.pathname.indexOf('login')>=0)
            return <Redirect to={'/dashboard/'}/>
        else
            return <></>
    }
    else {
        return (
            <Switch>
                <Route exact={true} path={'/login'} component={Login}/>
            </Switch>
        )
    }
}

export default Public;
