import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Col, Row, Button, Input, Modal} from "antd";
import CustomTable from "../../components/Table";
import useStore from "../../hooks/useStore";
import {ColumnProps} from "antd/lib/table/Column";
import TableActions from "../../components/TableActions";
import useToast from "../../hooks/useToast";

const PendingCertRequests = () => {
    const {t} = useTranslation('dashboard');
    const toast = useToast();
    const [pendingRequestsTotalElements, setPendingRequestsTotalElements] = useState<number>(0)
    const [pendingRequests, setPendingRequests] = useState<any[]>([]);
    const [pendingRequestsLoading, setPendingRequestsLoading] = useState<boolean>(true);
    const [tableSettings, setTableSettings] = useState({})
    const [authedApiState] = useStore()

    const [rejectModalVisible, setRejectModalVisible] = useState(false)
    const [rejecting, setRejecting] = useState(false)
    const [targetId, setTargetId] = useState(0)
    const [rejectReason, setRejectReason] = useState('')

    useEffect(() => {
        loadPendingRequests({page: 0});
    }, [])

    const loadPendingRequests = ({page, size, sort, asc}:
                                     { page: number, size?: number, sort?: string, asc?: boolean }) => {
        const query = {
            page, size: size ? size : 5,
            sort: sort ? sort : 'requestDate', asc:asc?asc:false
        }
        setTableSettings(query);
        setPendingRequests([])
        setPendingRequestsLoading(true)
        authedApiState.apiContainer?.api.allStockPapers1("pending", query).then(({data, status}) => {
            //@ts-ignore
            setPendingRequestsTotalElements(data.totalElements)
            if (status === 200) {
                //@ts-ignore
                setPendingRequests(data.content?.map((item: any) => {
                    return {
                        fullName: item.fullName,
                        accountType: t('accountType.' + item.accountType),
                        userType: t('userType.' + item.userType),
                        requestDate: item.requestDate.replace(/-/g, "/"),
                        companyName: item.companyName?item.companyName:'---',
                        amount: item.amount,
                        actions: item
                    }
                }));
                setPendingRequestsLoading(false)
            } else {
                toast(t('notification.request.fail'), 'error');
            }
        }).catch((response) => {
            toast(response?.data?.msg || response?.data?.message, 'error');
            setPendingRequestsLoading(false);
        })
    };

    const approveUserRequest = (id: string) => {
        return new Promise((resolve, reject) => {
            authedApiState.apiContainer?.api.stockPaperAccept(id).then(({data, status}) => {
                if (status === 200) {
                    toast(t('notification.request.approve.success'), 'success');
                    resolve(data)
                } else {
                    toast(t('notification.request.approve.fail'), 'error');
                    reject(data);
                }
            }).catch((response: any) => {
                toast(response.data?.msg, 'error');
                reject(response);
            })
        })
    }

    const rejectUserRequest = () => {
        const body = {id:targetId, reason:rejectReason}
        return new Promise((resolve, reject) => {
            //@ts-ignore
            authedApiState.apiContainer?.api.stockPaperReject(body).then(({data, status}) => {
                if (status === 200) {
                    resolve(data);
                    toast(t('notification.request.approve.success'), 'success');
                } else {
                    toast(t('notification.request.approve.fail'), 'error');
                    reject(data);
                }
            }).catch((response: any) => {
                toast(response.data?.msg, 'error');
                reject(response)
            })
        })
    }

    const pendingRequestsColumn: ColumnProps<Record<string, string>>[] = [
        {
            title: 'تاریخ‌درخواست',
            key: 'requestDate',
            dataIndex: 'requestDate',
        },
        {
            title: 'نام‌و‌نام‌خانوادگی',
            dataIndex: 'fullName',
            key: 'fullName',
        },
        {
            title: 'متقاضی',
            dataIndex: 'accountType',
            key: 'accountType',
            responsive: ['xxl', 'xl', 'lg', 'sm']
        },
        {
            title: 'نام‌شرکت',
            key: 'companyName',
            dataIndex: 'companyName',
            responsive: ['xxl', 'xl', 'lg', 'sm']
        },
        {
            title: 'نوع سهام‌داری',
            key: 'userType',
            dataIndex: 'userType',
            responsive: ['xxl', 'xl', 'lg', 'sm']
        },
        {
            title: 'تعداد رزرو',
            key: 'amount',
            dataIndex: 'amount',
            responsive: ['xxl', 'xl', 'lg', 'sm'],
            render: item => item?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        {
            title: 'اقدامات',
            key: 'actions',
            dataIndex: 'actions',
            render: (item) => (
                <>
                    <TableActions onApprove={approveUserRequest}
                                  onReject={()=>{}}
                                  onView={() => {}}
                                  approveParams={{}}
                                  rejectParams={[]}
                                  viewParams={{}}
                                  approveBtnDsbl={item.approvedDate}
                                  rejectBtnDsbl={false}
                                  record={item}
                                  viewBtnDsbl={true}
                                  id={item.id}
                                  onFinish={reloadTable}
                                  hasViewBtn={false}
                                  onRejectClick={() => {
                                      setTargetId(item.id);
                                      setRejectReason('');
                                      setRejectModalVisible(true);
                                  }}
                    />
                </>
            )
        }
    ]

    const reloadTable = () => {
        //@ts-ignore
        loadPendingRequests(tableSettings)
    }

    return (
        <>
            <Modal
                visible={rejectModalVisible}
                onCancel={() => {
                    setRejectModalVisible(false);
                }}
                title="رد درخواست"
                footer={[
                    <Button
                        onClick={() => {
                            setRejectModalVisible(false)
                        }}
                    >
                        لغو
                    </Button>,
                    <Button type="primary" danger disabled={rejectReason.length < 10} loading={rejecting}
                            onClick={() => {
                                rejectUserRequest().then(() => {
                                    setRejectModalVisible(false);
                                }).catch(() => {
                                    setRejectModalVisible(false)
                                })
                            }}>
                        رد درخواست
                    </Button>
                ]}
            >
                <Row justify={"center"}>
                    <Col span={24}>
                        <Input placeholder={'علت رد درخواست'} value={rejectReason} onChange={(event) => {
                            setRejectReason(event.target.value);
                        }}/>
                    </Col>
                </Row>
            </Modal>
            <Row gutter={[16, 16]}>
                <Col span={24} lg={24} md={24} sm={24} xs={24}>
                    <div className={'dashboard-grid'}>
                        <CustomTable
                            loading={pendingRequestsLoading}
                            className='special-styled-table'
                            paginationBottom={"bottomCenter"}
                            data={pendingRequests}
                            columns={pendingRequestsColumn}
                            total={pendingRequestsTotalElements}
                            onChange={loadPendingRequests}
                            defaultSort={"requestDate"}
                        />
                    </div>
                </Col>
            </Row>
        </>
    )
};

export default PendingCertRequests;
