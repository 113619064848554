import {ClientApi,ApiHelper} from "../api/clientApi";
import React from 'react'
import createStore from 'use-global-hook-ts'
import {Modal} from 'antd';
import jwt_decode from "jwt-decode";

import {ExclamationCircleOutlined} from '@ant-design/icons';
import {UserDTO} from "../api/api";

const {confirm} = Modal;


type User = Partial<UserDTO>;



interface IAppState {
    loggedIn: boolean,
    token?: string,
    role?: string,
    tokenExp?: Date,
    tokenIssuedAt?: Date,
    apiContainer?: ClientApi,
    user?: User,
    pin?: string;
}

const initialState: IAppState = {
    loggedIn: false,
    user: {firstName: '', lastName: '', mobile: ''}
}

const {useGlobal, store} = createStore<IAppState>(React, initialState, {
    debug: false,
    persistTree: {
        loggedIn: true,
        token: true,
        tokenExp: true,
        role: true
    },
})

export const storeActions = {
    login(token: string) {
        const decoded : {exp: number, iat: number, role:string} = jwt_decode(token);
        store.setState({
            loggedIn: true,
            role: decoded.role,
            token: token,
            tokenIssuedAt: new Date(decoded.iat),
            tokenExp: new Date(decoded.exp),
            apiContainer: new ClientApi(token, this.logout),
        });
    },
    setUser(user: User){
        store.setState({user})
    },
    notifyActivityTriggered(){

    },
    setPin(pin: string){
        store.state.pin = pin;
    },
    initiate() {
        if (store.state.token && store.state.loggedIn) {
            store.setState({
                apiContainer: new ClientApi(store.state.token, this.logout)
            });

            const checkShouldRemainLogin = () => {
                let date = new Date()
                date.setMinutes(date.getMinutes() + 2);
                if (!store.state.tokenExp)
                    return
            }
            setInterval(() => {
                checkShouldRemainLogin();
            }, 20000)
            checkShouldRemainLogin();
        }
    },
    logout() {
        store.setState({
            loggedIn: undefined,
            role: undefined,
            token: undefined,
            apiContainer: undefined,
            user: undefined
        })
    }
}
export const authedApiStore = store

const useStore = useGlobal
export default useStore;

