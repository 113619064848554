import React from 'react';
import Loading from "../layouts/loading/loading";

const DashboardStatItem = (
    {topTitle, content, subtitle = "", loading}:
        {topTitle: string, content: React.ReactElement, subtitle: string, loading: boolean}) => {
    return(
        <>
            <div className={'header'}>
                {topTitle}
            </div>
            <div className={'element-content'}>
                {
                    loading ? (<Loading/>):
                        (
                            <>
                                <div className={"element-content-title"}>
                                    {content}
                                </div>
                                <div className={"element-content-subtitle"}>
                                    {subtitle}
                                </div>
                            </>
                        )
                }

            </div>
        </>
    )
};

export default DashboardStatItem;
