import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Col, Row} from "antd";
import CustomTable from "../../components/Table";
import useStore from "../../hooks/useStore";
import useToast from '../../hooks/useToast';
import {ColumnProps} from "antd/lib/table/Column";
import TableActions from "../../components/TableActions";
import BtnClearFilter from '../../components/BtnClearFilter';
import InputSearch from '../../components/InputSearch';
import BtnFilter from '../../components/BtnFilter';
import BtnExcel from '../../components/BtnExcel';
import * as XLSX from 'xlsx';
import InputExcel from '../../components/InputExcel';


const AllRequests = () => {
    const {t} = useTranslation('identification');
    const [allRequestsTotalElements, setAllRequestsTotalElements] = useState<number>(0)
    const [allRequests, setAllRequests] = useState<any[]>([]);
    const [allRequestsLoading, setAllRequestsLoading] = useState<boolean>(true);
    const [tableSettings, setTableSettings] = useState({});
    const [ncSearch, setNcSearch] = useState<any>();
    const [nameSearch, setNameSearch] = useState<string>("");
    const [familySearch, setFamilySearch] = useState<string>("");
    const [mobileSearch, setMobileSearch] = useState<any>();
    const [ncExcel, setNcExcel] = useState<any>();
    const [mobileExcel, setMobileExcel] = useState<any>();

    const toast = useToast();
    const [authedApiState] = useStore()

    useEffect(() => {
        loadAllRequests({page: 0});
    }, [])

    const loadAllRequests = ({page, size, sort, asc}:
                                     { page: number, size?: number, sort?: string, asc?: boolean }) => {
        const query = {
            page, size: size ? size : 5,
            sort: sort ? sort : 'requestDate', asc:asc?asc:false
        }
        setTableSettings(query);
        setAllRequests([])
        setAllRequestsLoading(true)
        authedApiState.apiContainer?.api.usersDocsList('all', query).then(({data, status}) => {
            //@ts-ignore
            setAllRequestsTotalElements(data.totalElements)
            if (status === 200) {
                //@ts-ignore
                setAllRequests(data.content?.map((item: any) => {
                    return {
                        name: item.firstName + ' ' + item.lastName,
                        accountType: t('accountType.' + item.accountType),
                        nationalCode: item.nationalCode|| '---',
                        mobile: item.mobile|| '---',
                        requestDate: item.requestDate.replace(/-/g, "/"),
                        approvedDate: item.approvedDate,
                        rejectDate: item.rejectDate,
                        companyName: item.companyName|| '---',
                        incompleteFields: item.incompleteFields,
                        incompleteDocs: item.incompleteDocs,
                        actions: item
                    }
                }));
            } else {
                toast(t('notification.request.fail'), 'error');
            }
            setAllRequestsLoading(false)
        }).catch((response) => {
            toast(response?.data?.msg || response?.data?.message, 'error');
            setAllRequestsLoading(false);
        })
    };

    const approveUserRequest = (userId: string) => {
        return new Promise((resolve, reject) => {
            authedApiState.apiContainer?.api.approveAllDoc({userId}).then(({data, status}) => {
                if (status === 200) {
                    resolve(data);
                    toast(t('notification.request.approve.success'), 'success');
                } else {
                    toast(t('notification.request.approve.fail'), 'error');
                    reject(data);
                }
            }).catch((response: any) => {
                toast(response.data?.msg, 'error');
                reject(response);
            })
        })
    }

    const rejectUserRequest = (userId: string, reason: string) => {
        return new Promise((resolve, reject) => {
            const body = {userId, reason}
            authedApiState.apiContainer?.api.rejectUser(body).then(({data, status}) => {
                if (status === 200) {
                    resolve(data);
                    toast(t('notification.request.approve.success'), 'success');
                } else {
                    toast(t('notification.request.approve.fail'), 'error');
                    reject(data);
                }
            }).catch((response: any) => {
                toast(response.data?.message, 'error');
                reject(response)
            })
        })
    }

    const allRequestsColumn: ColumnProps<Record<string, string>>[] = [
        {
            title: 'تاریخ‌درخواست',
            key: 'requestDate',
            dataIndex: 'requestDate',
        },
        {
            title: 'نام‌و‌نام‌خانوادگی',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'متقاضی',
            dataIndex: 'accountType',
            key: 'accountType',
            responsive: ['xxl', 'xl', 'lg', 'md'],
        },
        {
            title: 'کدملی',
            key: 'nationalCode',
            dataIndex: 'nationalCode',
            responsive: ['xxl', 'xl', 'lg', 'md'],
        },
        {
            title: 'موبایل',
            key: 'mobile',
            dataIndex: 'mobile',
            responsive: ['xxl', 'xl', 'lg', 'md'],
        },
        {
            title: 'نام‌شرکت',
            key: 'companyName',
            dataIndex: 'companyName',
            responsive: ['xxl', 'xl', 'lg', 'md'],
        },
        {
            title: 'اطلاعات تکمیل نشده',
            key: 'incompleteFields',
            dataIndex: 'incompleteFields',
            responsive: ['xxl', 'xl', 'lg', 'md'],
            render: (incompleteFields) => (
                <>
                    <div style={{color: "#F461B7"}}>
                        {
                            incompleteFields.length === 1 ? incompleteFields[0] :
                                (incompleteFields.length !== 0 && incompleteFields.length > 1 ? incompleteFields[0] + " و ..." : "---") 
                        },
                    </div>
                </>
            )
        },
        {
            title: 'مدارک تکمیل نشده',
            key: 'incompleteDocs',
            dataIndex: 'incompleteDocs',
            responsive: ['xxl', 'xl', 'lg', 'md'],
            render: (incompleteDocs) => (
                <>
                  <div style={{ color: '#F461B7' }}>
                  {
                       incompleteDocs.length === 1 ? t("docs." + incompleteDocs[0]) :
                              (incompleteDocs.length > 1 && incompleteDocs.length !== 0 ?
                                   t("docs." + incompleteDocs[0]) + " و ..." : "---")
                 }
                  </div>
                </>
              )
          },
        {
            title: 'اقدامات',
            key: 'actions',
            dataIndex: 'actions',
            render: (item) => (
                <>
                    <TableActions
                        onApprove={approveUserRequest}
                        onReject={rejectUserRequest}
                        onView={() => {
                        }}
                        approveParams={{}}
                        rejectParams={[]}
                        viewParams={{}}
                        record={item}
                        approveBtnDsbl={item.approvedDate}
                        rejectBtnDsbl={item.rejectDate}
                        viewBtnDsbl={true}
                        id={item.userId}
                        onFinish={reloadTable}
                    />
                </>
            )
        }
    ]

    const reloadTable = () =>{
        //@ts-ignore
        loadAllRequests(tableSettings)
        setNcSearch("");
        setMobileSearch("");
        setNameSearch("");
        setFamilySearch("");   
    }

  ////////////////////////////////////////سرچ براساس کد ملی
   const loadFilterNc = ({
    nc,
    page,
    size,
    sort,
    asc,
  }: {
    page?: number;
    size?: number;
    sort?: string;
    asc?: boolean;
    nc?: number; 
  }) => {
    const query = {
      page,
      size: size ? size : 5,
      sort: sort ? sort : "requestDate",
      asc: asc ? asc : false,
      nc: nc ? nc : ncSearch ,
    };
    setTableSettings(query);
    setAllRequests([])
    setAllRequestsLoading(true)
    authedApiState.apiContainer?.api
      .searchNcAllRequestsForAuthentication(query)
      .then(({ data, status }) => {
          //@ts-ignore
          setAllRequestsTotalElements(data.totalElements)
          if (status === 200) {
              //@ts-ignore
              setAllRequests(data.content?.map((item: any) => {
                  return {
                      name: item.firstName + ' ' + item.lastName,
                      accountType: t('accountType.' + item.accountType),
                      nationalCode: item.nationalCode|| '---',
                      mobile: item.mobile|| '---',
                      requestDate: item.requestDate.replace(/-/g, "/"),
                      approvedDate: item.approvedDate,
                      rejectDate: item.rejectDate,
                      companyName: item.companyName|| '---',
                      incompleteFields: item.incompleteFields,
                      incompleteDocs: item.incompleteDocs,
                      actions: item
                  }
              }));
          } else {
              toast(t('notification.request.fail'), 'error');
          }
          setAllRequestsLoading(false)
      }).catch((response) => {
          toast(response?.data?.msg || response?.data?.message, 'error');
          setAllRequestsLoading(false);
      })
  };

  ////////////////////////////////////////سرچ براساس موبایل
  const loadFilterMob = ({
    mob,
    page,
    size,
    sort,
    asc,
  }: {
    page?: number;
    size?: number;
    sort?: string;
    asc?: boolean;
    mob?: number; 
  }) => {
    const query = {
      page,
      size: size ? size : 5,
      sort: sort ? sort : "requestDate",
      asc: asc ? asc : false,
      mob: mob ? mob : mobileSearch ,
    };
       setTableSettings(query);
        setAllRequests([])
        setAllRequestsLoading(true)
    authedApiState.apiContainer?.api
      .searchMobAllRequestsForAuthentication("all", query)
      .then(({ data, status }) => {
           //@ts-ignore
           setAllRequestsTotalElements(data.totalElements)
           if (status === 200) {
               //@ts-ignore
               setAllRequests(data.content?.map((item: any) => {
                   return {
                       name: item.firstName + ' ' + item.lastName,
                       accountType: t('accountType.' + item.accountType),
                       nationalCode: item.nationalCode|| '---',
                       mobile: item.mobile|| '---',
                       requestDate: item.requestDate.replace(/-/g, "/"),
                       approvedDate: item.approvedDate,
                       rejectDate: item.rejectDate,
                       companyName: item.companyName|| '---',
                       incompleteFields: item.incompleteFields,
                       incompleteDocs: item.incompleteDocs,
                       actions: item
                   }
               }));
           } else {
               toast(t('notification.request.fail'), 'error');
           }

           setAllRequestsLoading(false)
       }).catch((response) => {
           toast(response?.data?.msg || response?.data?.message, 'error');
           setAllRequestsLoading(false);
       })
   };

 ////////////////////////////////////////سرچ براساس نام
 const loadFiltername = ({
  name,
  page,
  size,
  sort,
  asc,
}: {
  page?: number;
  size?: number;
  sort?: string;
  asc?: boolean;
  name?: string; 
}) => {
  const query = {
    page,
    size: size ? size : 5,
    sort: sort ? sort : "requestDate",
    asc: asc ? asc : false,
    name: name ? name : nameSearch,
  };
  setTableSettings(query);
  setAllRequests([])
  setAllRequestsLoading(true)
  authedApiState.apiContainer?.api
    .searchNameAllRequestsForAuthentication("all", query)
    .then(({ data, status }) => {
       //@ts-ignore
       setAllRequestsTotalElements(data.totalElements)
       if (status === 200) {
           //@ts-ignore
           setAllRequests(data.content?.map((item: any) => {
               return {
                   name: item.firstName + ' ' + item.lastName,
                   accountType: t('accountType.' + item.accountType),
                   nationalCode: item.nationalCode|| '---',
                   mobile: item.mobile|| '---',
                   requestDate: item.requestDate.replace(/-/g, "/"),
                   approvedDate: item.approvedDate,
                   rejectDate: item.rejectDate,
                   companyName: item.companyName|| '---',
                   incompleteFields: item.incompleteFields,
                   incompleteDocs: item.incompleteDocs,
                   actions: item
               }
           }));
       } else {
           toast(t('notification.request.fail'), 'error');
       }
       setAllRequestsLoading(false)
   }).catch((response) => {
       toast(response?.data?.msg || response?.data?.message, 'error');
       setAllRequestsLoading(false);
   })
};

////////////////////////////////////////سرچ براساس نام خانوادگی
const loadFilterLastname = ({
  last,
  page,
  size,
  sort,
  asc,
}: {
  page?: number;
  size?: number;
  sort?: string;
  asc?: boolean;
  last?: string; 
}) => {
  const query = {
    page,
    size: size ? size : 5,
    sort: sort ? sort : "requestDate",
    asc: asc ? asc : false,
    last: last ? last : familySearch,
  };
  setTableSettings(query);
  setAllRequests([])
  setAllRequestsLoading(true)
  authedApiState.apiContainer?.api
    .searchLastNameAllRequestsForAuthentication("all", query)
    .then(({ data, status }) => {
         //@ts-ignore
         setAllRequestsTotalElements(data.totalElements)
         if (status === 200) {
             //@ts-ignore
             setAllRequests(data.content?.map((item: any) => {
                 return {
                     name: item.firstName + ' ' + item.lastName,
                     accountType: t('accountType.' + item.accountType),
                     nationalCode: item.nationalCode|| '---',
                     mobile: item.mobile|| '---',
                     requestDate: item.requestDate.replace(/-/g, "/"),
                     approvedDate: item.approvedDate,
                     rejectDate: item.rejectDate,
                     companyName: item.companyName|| '---',
                     incompleteFields: item.incompleteFields,
                     incompleteDocs: item.incompleteDocs,
                     actions: item
                 }
             }));
         } else {
             toast(t('notification.request.fail'), 'error');
         }
         setAllRequestsLoading(false)
     }).catch((response) => {
         toast(response?.data?.msg || response?.data?.message, 'error');
         setAllRequestsLoading(false);
     })
 };

 /////////////////////////////////////اکسل 
const loadFilterExcel = ({
  search,
  type,
}: {
  type?: number; 
  search?:string;
}) => {
  const query = {
    type:type,
    search:search ? search :mobileExcel ?mobileExcel :ncExcel
  };
  authedApiState.apiContainer?.api
    .DwExcelInIdentityPending("all", query)
    .then(({ data, status }) => {
      if (status === 200) {
        DwFilterExcel(data.id)
      }
    })
    .catch((response: any) => {
      toast(response?.data?.msg || response?.data?.message, "error");
    });
};

const DwFilterExcel = async (data1: string) => {
  try {
    const response = await authedApiState.apiContainer?.api.DownloadExcel(data1);
    if (response) {
      const { data, status } = response;

      if (status === 200 && data) 
      {
        const excelData = data.map((item: any) => Object.values(item)); // Convert data to array of arrays
        const headers = ['تاریخ‌درخواست', 'نام', 'نام خانوادگی','متقاضی','کدملی','موبایل','نام‌شرکت']; // Set the headers for columns
        const workbook = XLSX.utils.book_new();
        XLSX.utils.sheet_add_aoa(workbook, [headers, ...excelData], {origin: 0}); // Add headers and data to worksheet
        XLSX.utils.book_append_sheet(workbook, workbook, 'Sheet1'); // Add worksheet to workbook

        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' }); // Generate Excel buffer

        const blobData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }); // Convert buffer to Blob

        const url = window.URL.createObjectURL(blobData);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'downloaded_file.xlsx'; // Set the appropriate file name and extension
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      }
    }
  } catch (error) {
    console.error(error);
  }
};

////////////////////***********//////////
const [focusedInput, setFocusedInput] = useState<string | null>(null);

const handleFocusChange = (placeholder: string) => {
  setFocusedInput(placeholder);
};

const handleClearFilter = () => {
  setFocusedInput(null);
  reloadTable();
};

    return (
        <>
        <Row gutter={[16, 16]}  style={{ marginBottom: "20px", alignItems: "baseline" }}>
              <InputSearch
                handleChange={(e) => setNcSearch(e.target.value)}
                onFocusChange={handleFocusChange}
                placeholder="سرچ براساس کدملی"
                val={ncSearch}
                isDisabled={focusedInput ==="سرچ براساس نام" || focusedInput ==="سرچ براساس نام خانوادگی" || focusedInput === "سرچ براساس موبایل"}
              />
              <BtnFilter
                handleChange={() =>loadFilterNc({ page: 0 }) }
                textBtn="جستجو"
                type="search"
              />
              <InputSearch
                handleChange={(e) => setNameSearch(e.target.value)}
                onFocusChange={handleFocusChange}
                placeholder="سرچ براساس نام  "
                val={nameSearch}
                isDisabled={focusedInput ==="سرچ براساس کدملی" || focusedInput ==="سرچ براساس نام خانوادگی" || focusedInput === "سرچ براساس موبایل"}
              />
              <BtnFilter
                handleChange={() =>loadFiltername({ page: 0 }) }
                textBtn="جستجو"
                type="search"
              />
              <InputSearch
                handleChange={(e) => setFamilySearch(e.target.value)}
                onFocusChange={handleFocusChange}
                placeholder="سرچ براساس نام خانوادگی"
                val={familySearch}
                isDisabled={focusedInput ==="سرچ براساس کدملی" || focusedInput ==="سرچ براساس نام" || focusedInput === "سرچ براساس موبایل"}

              />
              <BtnFilter
                handleChange={() =>loadFilterLastname({ page: 0 }) }
                textBtn="جستجو"
                type="search"
              />
                <InputSearch
                handleChange={(e) => setMobileSearch(e.target.value)}
                onFocusChange={handleFocusChange}
                placeholder="سرچ براساس موبایل"
                val={mobileSearch}
                isDisabled={focusedInput ==="سرچ براساس کدملی" || focusedInput ==="سرچ براساس نام" || focusedInput === "سرچ براساس نام خانوادگی"}
              />
              <BtnFilter
                handleChange={() =>loadFilterMob({ page: 0 }) }
                textBtn="جستجو"
                type="search"
              />
              <BtnClearFilter
                handleChange={() => handleClearFilter()}
                textBtn="حذف فیلتر"
                type="remove"
              />
            </Row>
            <Row gutter={[16, 16]}>
                <Col span={24} lg={24} md={24} sm={24} xs={24}>
                    <div className={'dashboard-grid'}>
                    {focusedInput === "سرچ براساس کدملی" ?
                    <CustomTable
                    rowClass={"verified-person-table-row"}
                    rowClassCounterPart={"unverified-person-table-row"}
                    rowClassTriggerProperty={"approvedDate"}
                    rowClassCounterTriggerProp={"rejectDate"}
                    loading={allRequestsLoading}
                    className='special-styled-table'
                    paginationBottom={"bottomCenter"}
                    data={allRequests}
                    columns={allRequestsColumn}
                    total={allRequestsTotalElements}
                    onChange={loadFilterNc}
                    defaultSort={"requestDate"}
                />
                    :focusedInput === "سرچ براساس نام"?
                    <CustomTable
                    rowClass={"verified-person-table-row"}
                    rowClassCounterPart={"unverified-person-table-row"}
                    rowClassTriggerProperty={"approvedDate"}
                    rowClassCounterTriggerProp={"rejectDate"}
                    loading={allRequestsLoading}
                    className='special-styled-table'
                    paginationBottom={"bottomCenter"}
                    data={allRequests}
                    columns={allRequestsColumn}
                    total={allRequestsTotalElements}
                    onChange={loadFiltername}
                    defaultSort={"requestDate"}
                />
                    :focusedInput === "سرچ براساس نام خانوادگی"?
                    <CustomTable
                    rowClass={"verified-person-table-row"}
                    rowClassCounterPart={"unverified-person-table-row"}
                    rowClassTriggerProperty={"approvedDate"}
                    rowClassCounterTriggerProp={"rejectDate"}
                    loading={allRequestsLoading}
                    className='special-styled-table'
                    paginationBottom={"bottomCenter"}
                    data={allRequests}
                    columns={allRequestsColumn}
                    total={allRequestsTotalElements}
                    onChange={loadFilterLastname}
                    defaultSort={"requestDate"}
                />
                    :focusedInput === "سرچ براساس موبایل"?
                    <CustomTable
                    rowClass={"verified-person-table-row"}
                    rowClassCounterPart={"unverified-person-table-row"}
                    rowClassTriggerProperty={"approvedDate"}
                    rowClassCounterTriggerProp={"rejectDate"}
                    loading={allRequestsLoading}
                    className='special-styled-table'
                    paginationBottom={"bottomCenter"}
                    data={allRequests}
                    columns={allRequestsColumn}
                    total={allRequestsTotalElements}
                    onChange={loadFilterMob}
                    defaultSort={"requestDate"}
                />
                    : <CustomTable
                    rowClass={"verified-person-table-row"}
                    rowClassCounterPart={"unverified-person-table-row"}
                    rowClassTriggerProperty={"approvedDate"}
                    rowClassCounterTriggerProp={"rejectDate"}
                    loading={allRequestsLoading}
                    className='special-styled-table'
                    paginationBottom={"bottomCenter"}
                    data={allRequests}
                    columns={allRequestsColumn}
                    total={allRequestsTotalElements}
                    onChange={loadAllRequests}
                    defaultSort={"requestDate"}
                />}                     
                    </div>
                </Col>
            </Row>
            <Row gutter={[16, 16]}  style={{ marginTop: "10px", alignItems: "baseline" }}>
              <InputExcel
                handleChange={(e) => setNcExcel(e.target.value)}
                placeholder="دانلود براساس کدملی"
                val={ncExcel}
              />
              <BtnExcel
                handleChange={() =>loadFilterExcel({type:3})}
                textBtn="جستجو"               
              />
                <InputExcel
                handleChange={(e) => setMobileExcel(e.target.value)}
                placeholder="دانلود براساس موبایل"
                val={mobileExcel}
              />
              <BtnExcel
                handleChange={() =>loadFilterExcel({type:4})}
                textBtn="جستجو"               
              />
              <BtnClearFilter
                handleChange={() => {
                  setMobileExcel("");
                  setNcExcel("");
                }}
                textBtn="حذف فیلتر"
                type="remove"
              />
            </Row>
        </>
    )
};

export default AllRequests;
