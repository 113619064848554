import React from 'react';

const Column = ({
                    value,
                    maxValue,
                    color,
                    columnsCount,
                    label,
                }: { value: number, maxValue: number, color: string, columnsCount: number, label: string}) => {
    return <div className='line-chart-column-container' style={{height: '100%', width: 100/columnsCount + '%'}}>
        <div style={{height: 'calc(100% - 50px)', position: 'relative', marginTop: '20px'}}>
            <div className='line-chart-value-label' style={{color: color,position: 'absolute',bottom:  '' + value * 100 / maxValue + '%'}}>{value}</div>
            <div className='line-chart-column'
                 style={{height: '' + value * 100 / maxValue + '%', backgroundColor: color}}/>
        </div>
        <div className='line-chart-label' style={{height: '30px'}}>
            <div className='line-chart-label-badge' style={{backgroundColor: color}}/>
            {label}
        </div>
    </div>
}

const ColumnChart = ({
                         data,
                         style
                     }: { data: { value: number, color: string, label: string }[], style?: React.CSSProperties }) => {
    if(!data)
        return <div/>
    const maxValue = data.reduce((acm, item) => item.value > acm ? item.value : acm, 0)
    return <div className="line-chart" style={style}>
        {data.map((item,index) => {
            return <Column
                key={index}
                value={item.value}
                maxValue={maxValue ? maxValue : 1}
                color={item.color}
                columnsCount={data.length}
                label={item.label}
            />
        })}
    </div>
};

export default ColumnChart;
