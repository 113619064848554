//@ts-nocheck TODO
import Header from '../../layouts/Header';
import React from 'react';
import {useTranslation} from 'react-i18next';
import Styled from './styles';
import Module from './modules';
import moment from "jalali-moment";

const Login: React.FC = () => {

    const {t} = useTranslation('login');

    return (
        <Styled.Container>
            <Header
                subtitle=''
                noPadding={false}
                title={{color: t('title.color'), solid: t('title.solid')}}
                star={true}
            />
            <Module.Login/>
        </Styled.Container>
    );
};

export default Login;
