export const DEFAULTS = {
  data: {
    captcha: '',
    username: '',
    mobile: '',
    password: '',
    pin: ''
  },
  validation: {
    username: false,
    mobile: false,
    password: false,
    pin: false
  }
};
