import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Col, Row} from "antd";
import CustomTable from "../../components/Table";
import useStore from "../../hooks/useStore";
import useToast from '../../hooks/useToast';
import {ColumnProps} from "antd/lib/table/Column";
import TableActions from "../../components/TableActions";
import BtnClearFilter from '../../components/BtnClearFilter';
import InputSearch from '../../components/InputSearch';
import BtnFilter from '../../components/BtnFilter';
import BtnExcel from '../../components/BtnExcel';
import * as XLSX from 'xlsx';
import InputExcel from '../../components/InputExcel';


const PendingRequests = () => {
    const {t} = useTranslation('dashboard');
    const [PendingRequestsTotalElements, setPendingRequestsTotalElements] = useState<number>(0)
    const [pendingRequests, setPendingRequests] = useState<any[]>([]);
    const [pendingRequestsLoading, setPendingRequestsLoading] = useState<boolean>(true);
    const toast = useToast();
    const [authedApiState] = useStore()
    const [loading, setLoading] = useState<boolean>(true)
    const [ncSearch, setNcSearch] = useState<any>();
    const [nameSearch, setNameSearch] = useState<string>("");
    const [familySearch, setFamilySearch] = useState<string>("");
    const [mobileSearch, setMobileSearch] = useState<any>();
    const [tableSettings, setTableSettings] = useState({})
    const [ncExcel, setNcExcel] = useState<any>();
    const [mobileExcel, setMobileExcel] = useState<any>();

    useEffect(() => {
        setLoading(false);
        loadPendingRequests({page: 0});
    }, [])

    const loadPendingRequests = ({page, size, sort, asc}:
                                     { page: number, size?: number, sort?: string, asc?: boolean }) => {
        const query = {
            page, size: size ? size : 5,
            sort: sort ? sort : 'requestDate', asc: asc ? asc : false
        }

        setTableSettings(query);

        setPendingRequests([])
        setPendingRequestsLoading(true);
        authedApiState.apiContainer?.api.usersDocsList('pending', query).then(({data, status}) => {
            //@ts-ignore
            setPendingRequestsTotalElements(data.totalElements)
            if (status === 200) {
                //@ts-ignore
                setPendingRequests(data.content?.map((item: any) => {
                    return {
                        name: item.firstName + ' ' + item.lastName,
                        accountType: item.accountType === 'Legal' ? 'حقوقی' : 'حقیقی',
                        nationalCode: item.nationalCode|| '---',
                        approvedDate: item.approvedDate,
                        rejectDate: item.rejectDate,
                        mobile: item.mobile|| '---',
                        requestDate: item.requestDate.replace(/-/g, "/"),
                        companyName: item.companyName|| '---',
                        incompleteFields: item.incompleteFields,
                        incompleteDocs: item.incompleteDocs,
                        actions: item
                    } 
                }));
            } else {
                toast(t('notification.request.fail'), 'error');
            }

            setPendingRequestsLoading(false)
        }).catch((response) => {
            toast(response.message?.length > 0? response.message:t('notification.request.some.error'), 'error');
            setPendingRequestsLoading(false);
        })
    };

    const approveUserRequest = (userId: string) => {
        return new Promise((resolve, reject) => {

            authedApiState.apiContainer?.api.approveAllDoc({userId}).then(({data, status}) => {
                if (status === 200) {
                    resolve(data);
                    toast(t('notification.request.approve.success'), 'success');
                } else {
                    toast(t('notification.request.approve.fail'), 'error');
                    reject(data);
                }
            }).catch((response: any) => {
                toast(response.data?.msg, 'error');
                reject(response);
            })
        })
    }

    const rejectUserRequest = (userId: string, reason: string) => {
        return new Promise((resolve, reject) => {
            const body = {userId, reason}
            authedApiState.apiContainer?.api.rejectUser(body).then(({data, status}) => {
                if (status === 200) {
                    resolve(data);
                    toast(t('notification.request.approve.success'), 'success');
                } else {
                    toast(t('notification.request.approve.fail'), 'error');
                    reject(data);
                }
            }).catch((response: any) => {
                toast(response.data?.msg, 'error');
                reject(response);
            })
        })
    }

    const reloadTable = () => {
        //@ts-ignore
        loadPendingRequests(tableSettings)
        setNcSearch("");
        setNameSearch("");
        setFamilySearch("");
        setMobileSearch("");
    }

    const pendingRequestsColumn: ColumnProps<Record<string, string>>[] = [
        {
          title: "تاریخ‌درخواست",
          key: "requestDate",
          dataIndex: "requestDate",
          sorter: true,
        },
        {
          title: "نام‌و‌نام‌خانوادگی",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "متقاضی",
          dataIndex: "accountType",
          key: "accountType",
          sorter: true,
          responsive: ['xxl', 'xl', 'lg', 'sm']
        },
        {
          title: "کدملی",
          key: "nationalCode",
          dataIndex: "nationalCode",
          responsive: ["xxl", "xl", "lg", "sm"],
        },
        {
          title: "موبایل",
          key: "mobile",
          dataIndex: "mobile",
          responsive: ["xxl", "xl", "lg", "sm"],
        },
        {
          title: "نام‌شرکت",
          key: "companyName",
          dataIndex: "companyName",
          responsive: ["xxl", "xl", "lg", "sm"],
        },
        {
          title: "اطلاعات تکمیل نشده",
          key: "incompleteFields",
          dataIndex: "incompleteFields",
          responsive: ["xxl", "xl", "lg", "sm"],
          render: (incompleteFields) => (
            <>
              <div style={{ color: "#F461B7" }}>
                {incompleteFields.length === 1
                  ? incompleteFields[0]
                  : incompleteFields.length !== 0 && incompleteFields.length > 1
                  ? incompleteFields[0] + " و ..."
                  : "---"}
              </div>
            </>
          ),
        },
        {
          title: "مدارک تکمیل نشده",
          key: "incompleteDocs",
          dataIndex: "incompleteDocs",
          responsive: ["xxl", "xl", "lg", "sm"],
          render: (incompleteDocs) => (
            <>
              <div style={{ color: "#F461B7" }}>
                {incompleteDocs.length === 1
                  ? t("docs." + incompleteDocs[0])
                  : incompleteDocs.length > 1 && incompleteDocs.length !== 0
                  ? t("docs." + incompleteDocs[0]) + " و ..."
                  : "---"}
              </div>
            </>
          ),
        },
        {
          title: "اقدامات",
          key: "actions",
          dataIndex: "actions",
          render: (item) => (
            <>
              <TableActions
                onApprove={approveUserRequest}
                onReject={rejectUserRequest}
                onView={() => {}}
                approveParams={{}}
                rejectParams={[]}
                viewParams={{}}
                approveBtnDsbl={item.approvedDate}
                rejectBtnDsbl={item.rejectDate}
                record={item}
                viewBtnDsbl={true}
                id={item.userId}
                onFinish={reloadTable}
              />
            </>
          ),
        },
      ];
 ////////////////////////////////////////سرچ براساس کد ملی

  const loadFilterNc = ({
    nc,
    page,
    size,
    sort,
    asc,
  }: {
    page?: number;
    size?: number;
    sort?: string;
    asc?: boolean;
    nc?: number; 
  }) => {
    const query = {
      page,
      size: size ? size : 5,
      sort: sort ? sort : "requestDate",
      asc: asc ? asc : false,
      nc: nc ? nc : ncSearch ,
    };
    setTableSettings(query);

        setPendingRequests([])
        setPendingRequestsLoading(true);
    authedApiState.apiContainer?.api
      .searchNcInNewAuthenticationRequests("pending", query)
      .then(({ data, status }) => {
        //@ts-ignore
        setPendingRequestsTotalElements(data.totalElements)
        if (status === 200) {
            //@ts-ignore
            setPendingRequests(data.content?.map((item: any) => {
                return {
                    name: item.firstName + ' ' + item.lastName,
                    accountType: item.accountType === 'Legal' ? 'حقوقی' : 'حقیقی',
                    nationalCode: item.nationalCode|| '---',
                    approvedDate: item.approvedDate,
                    rejectDate: item.rejectDate,
                    mobile: item.mobile|| '---',
                    requestDate: item.requestDate.replace(/-/g, "/"),
                    companyName: item.companyName|| '---',
                    incompleteFields: item.incompleteFields,
                    incompleteDocs: item.incompleteDocs,
                    actions: item
                }
            }));
        } else {
            toast(t('notification.request.fail'), 'error');
        }

        setPendingRequestsLoading(false)
    }).catch((response) => {
        toast(response.message?.length > 0? response.message:t('notification.request.some.error'), 'error');
        setPendingRequestsLoading(false);
    })
};
  ////////////////////////////////////////سرچ براساس موبایل
  const loadFilterMob = ({
    mob,
    page,
    size,
    sort,
    asc,
  }: {
    page?: number;
    size?: number;
    sort?: string;
    asc?: boolean;
    mob?: number; 
  }) => {
    const query = {
      page,
      size: size ? size : 5,
      sort: sort ? sort : "requestDate",
      asc: asc ? asc : false,
      mob: mob ? mob : mobileSearch ,
    };
    setTableSettings(query);

    setPendingRequests([])
    setPendingRequestsLoading(true);
    authedApiState.apiContainer?.api
      .searchMobInNewAuthenticationRequests("pending", query)
      .then(({ data, status }) => {
          //@ts-ignore
          setPendingRequestsTotalElements(data.totalElements)
          if (status === 200) {
              //@ts-ignore
              setPendingRequests(data.content?.map((item: any) => {
                  return {
                      name: item.firstName + ' ' + item.lastName,
                      accountType: item.accountType === 'Legal' ? 'حقوقی' : 'حقیقی',
                      nationalCode: item.nationalCode|| '---',
                      approvedDate: item.approvedDate,
                      rejectDate: item.rejectDate,
                      mobile: item.mobile|| '---',
                      requestDate: item.requestDate.replace(/-/g, "/"),
                      companyName: item.companyName|| '---',
                      incompleteFields: item.incompleteFields,
                      incompleteDocs: item.incompleteDocs,
                      actions: item
                  }
              }));
          } else {
              toast(t('notification.request.fail'), 'error');
          }

          setPendingRequestsLoading(false)
      }).catch((response) => {
          toast(response.message?.length > 0? response.message:t('notification.request.some.error'), 'error');
          setPendingRequestsLoading(false);
      })
  };
 ////////////////////////////////////////سرچ براساس نام
 const loadFiltername = ({
  name,
  page,
  size,
  sort,
  asc,
}: {
  page?: number;
  size?: number;
  sort?: string;
  asc?: boolean;
  name?: string; 
}) => {
  const query = {
    page,
    size: size ? size : 5,
    sort: sort ? sort : "requestDate",
    asc: asc ? asc : false,
    name: name ? name : nameSearch,
  };
  setTableSettings(query);

  setPendingRequests([])
  setPendingRequestsLoading(true);
  authedApiState.apiContainer?.api
    .searchNameInNewAuthenticationRequests("pending", query)
    .then(({ data, status }) => {
     //@ts-ignore
     setPendingRequestsTotalElements(data.totalElements)
     if (status === 200) {
         //@ts-ignore
         setPendingRequests(data.content?.map((item: any) => {
             return {
                 name: item.firstName + ' ' + item.lastName,
                 accountType: item.accountType === 'Legal' ? 'حقوقی' : 'حقیقی',
                 nationalCode: item.nationalCode|| '---',
                 approvedDate: item.approvedDate,
                 rejectDate: item.rejectDate,
                 mobile: item.mobile|| '---',
                 requestDate: item.requestDate.replace(/-/g, "/"),
                 companyName: item.companyName|| '---',
                 incompleteFields: item.incompleteFields,
                 incompleteDocs: item.incompleteDocs,
                 actions: item
             }
         }));
     } else {
         toast(t('notification.request.fail'), 'error');
     }

     setPendingRequestsLoading(false)
 }).catch((response) => {
     toast(response.message?.length > 0? response.message:t('notification.request.some.error'), 'error');
     setPendingRequestsLoading(false);
 })
};
////////////////////////////////////////سرچ براساس نام خانوادگی
const loadFilterLastname = ({
  last,
  page,
  size,
  sort,
  asc,
}: {
  page?: number;
  size?: number;
  sort?: string;
  asc?: boolean;
  last?: string; 
}) => {
  const query = {
    page,
    size: size ? size : 5,
    sort: sort ? sort : "requestDate",
    asc: asc ? asc : false,
    last: last ? last : familySearch,
  };
  setTableSettings(query);

        setPendingRequests([])
        setPendingRequestsLoading(true);
  authedApiState.apiContainer?.api
    .searchLastNameInNewAuthenticationRequests("pending", query)
    .then(({ data, status }) => {
       //@ts-ignore
       setPendingRequestsTotalElements(data.totalElements)
       if (status === 200) {
           //@ts-ignore
           setPendingRequests(data.content?.map((item: any) => {
               return {
                   name: item.firstName + ' ' + item.lastName,
                   accountType: item.accountType === 'Legal' ? 'حقوقی' : 'حقیقی',
                   nationalCode: item.nationalCode|| '---',
                   approvedDate: item.approvedDate,
                   rejectDate: item.rejectDate,
                   mobile: item.mobile|| '---',
                   requestDate: item.requestDate.replace(/-/g, "/"),
                   companyName: item.companyName|| '---',
                   incompleteFields: item.incompleteFields,
                   incompleteDocs: item.incompleteDocs,
                   actions: item
               }
           }));
       } else {
           toast(t('notification.request.fail'), 'error');
       }

       setPendingRequestsLoading(false)
   }).catch((response) => {
       toast(response.message?.length > 0? response.message:t('notification.request.some.error'), 'error');
       setPendingRequestsLoading(false);
   })
};

/////////////////////////////////////اکسل 
const loadFilterExcel = ({
  search,
  type,
  page,
  size,
  sort,
  asc,
}: {
  page?: number;
  size?: number;
  sort?: string;
  asc?: boolean;
  type?: number; 
  search?:string;
}) => {
  const query = {
    page,
    size: size ? size : 5,
    sort: sort ? sort : "requestDate",
    asc: asc ? asc : false,
    type:type,
    search:search ? search :mobileExcel ?mobileExcel :ncExcel
  };
  authedApiState.apiContainer?.api
    .DwExcelInIdentityPending("pending", query)
    .then(({ data, status }) => {
      if (status === 200) {
        DwFilterExcel(data.id)
      }
    })
    .catch((response: any) => {
      toast(response?.data?.msg || response?.data?.message, "error");
    });
};

const DwFilterExcel = async (data1: string) => {
  try {
    const response = await authedApiState.apiContainer?.api.DownloadExcel(data1);
    if (response) {
      const { data, status } = response;

      if (status === 200 && data) 
      {
        const excelData = data.map((item: any) => Object.values(item)); // Convert data to array of arrays
        const headers = ['نام', 'نام خانوادگی','کدملی','مدارک تکمیل نشده','اطلاعات تکمیل نشده','تاریخ رد درخواست','متقاضی','تاریخ‌درخواست','موبایل']; // Set the headers for columns
        const workbook = XLSX.utils.book_new();
        XLSX.utils.sheet_add_aoa(workbook, [headers, ...excelData], {origin: 0}); // Add headers and data to worksheet
        XLSX.utils.book_append_sheet(workbook, workbook, 'Sheet1'); // Add worksheet to workbook

        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' }); // Generate Excel buffer

        const blobData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }); // Convert buffer to Blob

        const url = window.URL.createObjectURL(blobData);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'downloaded_file.xlsx'; // Set the appropriate file name and extension
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      }
    }
  } catch (response:any) {
    toast(response?.data?.msg || response?.data?.message, "error");
  }
};

/////////////////////*************///////////
const [focusedInput, setFocusedInput] = useState<string | null>(null);

const handleFocusChange = (placeholder: string) => {
  setFocusedInput(placeholder);
};

const handleClearFilter = () => {
  setFocusedInput(null);
  reloadTable();
};

    return (
        <>
              <Row gutter={[16, 16]}  style={{ marginBottom: "20px", alignItems: "baseline" }}>
              <InputSearch
                handleChange={(e) => setNcSearch(e.target.value)}
                onFocusChange={handleFocusChange}
                placeholder="سرچ براساس کدملی"
                val={ncSearch}
                isDisabled={focusedInput ==="سرچ براساس نام" || focusedInput ==="سرچ براساس نام خانوادگی" || focusedInput === "سرچ براساس موبایل"}
              />
              <BtnFilter
                handleChange={() =>loadFilterNc({ page: 0 }) }
                textBtn="جستجو"
                type="search"
              />
              <InputSearch
                handleChange={(e) => setNameSearch(e.target.value)}
                onFocusChange={handleFocusChange}
                placeholder="سرچ براساس نام  "
                val={nameSearch}
                isDisabled={focusedInput ==="سرچ براساس کدملی" || focusedInput ==="سرچ براساس نام خانوادگی" || focusedInput === "سرچ براساس موبایل"}
              />
              <BtnFilter
                handleChange={() =>loadFiltername({ page: 0 }) }
                textBtn="جستجو"
                type="search"
              />
              <InputSearch
                handleChange={(e) => setFamilySearch(e.target.value)}
                onFocusChange={handleFocusChange}
                placeholder="سرچ براساس نام خانوادگی"
                val={familySearch}
                isDisabled={focusedInput ==="سرچ براساس کدملی" || focusedInput ==="سرچ براساس نام" || focusedInput === "سرچ براساس موبایل"}
              />
              <BtnFilter
                handleChange={() =>loadFilterLastname({ page: 0 }) }
                textBtn="جستجو"
                type="search"
              />
                <InputSearch
                handleChange={(e) => setMobileSearch(e.target.value)}
                onFocusChange={handleFocusChange}
                placeholder="سرچ براساس موبایل"
                val={mobileSearch}
                isDisabled={focusedInput ==="سرچ براساس کدملی" || focusedInput ==="سرچ براساس نام" || focusedInput === "سرچ براساس نام خانوادگی"}
              />
              <BtnFilter
                handleChange={() =>loadFilterMob({ page: 0 }) }
                textBtn="جستجو"
                type="search"
              />
              <BtnClearFilter
                handleChange={() => handleClearFilter()}
                textBtn="حذف فیلتر"
                type="remove"
              />
            </Row>
            <Row gutter={[16, 16]}>
             
                <Col span={24} lg={24} md={24} sm={24} xs={24}>
                
                    <div className={'dashboard-grid'}>
                     {focusedInput === "سرچ براساس کدملی" ?
                      <CustomTable
                      rowClass={"unverified-person-table-row"}
                      rowClassTriggerProperty={"rejectDate"}
                      loading={pendingRequestsLoading}
                      className='special-styled-table'
                      paginationBottom={"bottomCenter"}
                      data={pendingRequests}
                      columns={pendingRequestsColumn}
                      total={PendingRequestsTotalElements}
                      onChange={loadFilterNc}
                      defaultSort={"requestDate"}
                  />
                     :focusedInput === "سرچ براساس نام  "?
                     <CustomTable
                      rowClass={"unverified-person-table-row"}
                      rowClassTriggerProperty={"rejectDate"}
                      loading={pendingRequestsLoading}
                      className='special-styled-table'
                      paginationBottom={"bottomCenter"}
                      data={pendingRequests}
                      columns={pendingRequestsColumn}
                      total={PendingRequestsTotalElements}
                      onChange={loadFiltername}
                      defaultSort={"requestDate"}
                  />
                     :focusedInput === "سرچ براساس نام خانوادگی"?
                     <CustomTable
                     rowClass={"unverified-person-table-row"}
                     rowClassTriggerProperty={"rejectDate"}
                     loading={pendingRequestsLoading}
                     className='special-styled-table'
                     paginationBottom={"bottomCenter"}
                     data={pendingRequests}
                     columns={pendingRequestsColumn}
                     total={PendingRequestsTotalElements}
                     onChange={loadFilterLastname}
                     defaultSort={"requestDate"}
                 />
                     :focusedInput ==="سرچ براساس موبایل"?
                     <CustomTable
                     rowClass={"unverified-person-table-row"}
                     rowClassTriggerProperty={"rejectDate"}
                     loading={pendingRequestsLoading}
                     className='special-styled-table'
                     paginationBottom={"bottomCenter"}
                     data={pendingRequests}
                     columns={pendingRequestsColumn}
                     total={PendingRequestsTotalElements}
                     onChange={loadFilterMob}
                     defaultSort={"requestDate"}
                 />
                     : <CustomTable
                     rowClass={"unverified-person-table-row"}
                     rowClassTriggerProperty={"rejectDate"}
                     loading={pendingRequestsLoading}
                     className='special-styled-table'
                     paginationBottom={"bottomCenter"}
                     data={pendingRequests}
                     columns={pendingRequestsColumn}
                     total={PendingRequestsTotalElements}
                     onChange={loadPendingRequests}
                     defaultSort={"requestDate"}
                 />}
                       
                    </div>
                </Col>
            </Row>
            <Row gutter={[16, 16]}  style={{ marginTop: "10px", alignItems: "baseline" }}>
              <InputExcel
                handleChange={(e) => setNcExcel(e.target.value)}
                placeholder="دانلود براساس کدملی"
                val={ncExcel}
              />
              <BtnExcel
                handleChange={() =>loadFilterExcel({page:0,type:3})}
                textBtn="جستجو"
                
              />
                <InputExcel
                handleChange={(e) => setMobileExcel(e.target.value)}
                placeholder="دانلود براساس موبایل"
                val={mobileExcel}
              />
              <BtnExcel
                handleChange={() =>loadFilterExcel({page:0,type:4})}
                textBtn="جستجو"
                
              />
              <BtnClearFilter
                handleChange={() => {
                  setMobileExcel("");
                  setNcExcel("");
                }}
                textBtn="حذف فیلتر"
                type="remove"
              />
            </Row>
        </>
    );
};

export default PendingRequests;
