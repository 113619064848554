import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import CustomTable from "../../components/Table";
import useStore from "../../hooks/useStore";
import {ColumnProps} from "antd/lib/table/Column";
import {Button, Checkbox, Col, Collapse, Input, Row, Modal} from "antd";
import {RejectDocsAndFieldsRequest, TransactionUserDetailInfo, UserAuthStatusResponse} from "../../api/api";
import RequestDocs, {DocTypes} from "./module/requestDocs";
import useToast from "../../hooks/useToast";
import Loading from "../../layouts/loading/loading";
import moment from "jalali-moment";

const {Panel} = Collapse;
const {TextArea} = Input;

const Confirmation = ({
                          username,
                          visibilityHandler,
                          onFinish,
                          transactionId = ''
                      }: { username: string, visibilityHandler: Function, onFinish: Function, transactionId: string }) => {
        const {t} = useTranslation(['dashboard', 'identification']);
        const [singleRequest, setSingleRequest] = useState<Partial<UserAuthStatusResponse>>({});
        const [singleRequestLoading, setSingleRequestLoading] = useState<boolean>(true);
        const [authedApiState] = useStore()
        const [hasMissingField, setHasMissingField] = useState<boolean>(false);
        const [hasMissingDocs, setHasMissingDocs] = useState<boolean>(false);
        const [missingDocs, setMissingDocs] = useState<string[]>([])
        const [rejectDocsReason, setRejectDocsReason] = useState<string>("")
        const [rejectFieldsReason, setRejectFieldsReason] = useState<string>("")

        const toast = useToast();

        const [confirmModalVisible, setConfirmModalVisible] = useState<boolean>(false);
        const [rejectModalVisible, setRejectModalVisible] = useState<boolean>(false);
        const [requestProcessing, setRequestProcessing] = useState<boolean>(false);

        const [userTransactionDetails, setUserTransactionDetails] = useState<Partial<TransactionUserDetailInfo>>({});
        const [payment, setPayment] = useState<{}>({});
        const [userTransactionDetailsLoading, setUserTransactionDetailsLoading] = useState<boolean>(false);

        const paymentStatus = {
            Success: 'موفق',
            Pending: 'در انتظار',
            Error: 'خطا'
        }
        useEffect(() => {
            loadSingleRequest();
            if (transactionId !== '') loadUserTransactionDetails();
        }, [])

        const [docs, setDocs] = useState([])
        const loadSingleRequest = () => {
            setSingleRequest({});
            setSingleRequestLoading(true);

            authedApiState.apiContainer?.api.userAuthStatus1({username}).then((response) => {

                if (response.status === 200 && response.data) {
                    const data: any = Object.assign({}, response.data);
                    data.verificationDTO["name"] = data.verificationDTO?.firstName + " " + data.verificationDTO?.lastName;
                    data.verificationDTO.userType = t('identification:userType.' + data.verificationDTO.userType);
                    data.verificationDTO.accountType = t('identification:accountType.' + data.verificationDTO.accountType);
                    data.verificationDTO.requestDate = data.verificationDTO.requestDate?.replace(/-/g, "/")
                    data.user.gender = data.user.gender ? t('identification:form.gender.' + data.user.gender) : "";
                    data.user.companyType = data.user.companyType ? t('identification:companyType.' + data.user.companyType) : "";
                    data.verificationDTO["STATUS"] = data.verified ? "تایید شده" : "در حال بررسی";
                    setSingleRequest(data);
                    if (data.pendingDocs?.length > 0) {
                        setDocs(data.pendingDocs);
                    } else if (data.verifiedDocs?.length > 0) {
                        setDocs(data.verifiedDocs);
                    } else if (data.rejectDocs?.length > 0) {
                        setDocs(data.rejectDocs);
                    }
                } else {
                    toast(t('identification:notification.request.fail'), 'error');
                }
                setSingleRequestLoading(false);
            }).catch((error) => {
                if (error.status === 500)
                    toast(t('notification.request.internal.error'), 'error');
                else
                    toast(t('notification.request.some.error'), 'error');
                setSingleRequestLoading(false);
            })
        }

        const loadUserTransactionDetails = () => {
            setUserTransactionDetails({});
            setUserTransactionDetailsLoading(true);
            authedApiState.apiContainer?.api.viewTransactionUserDetailInfo(transactionId).then(({data, status}) => {
                if (status === 200) {
                    setUserTransactionDetails(data);
                    // @ts-ignore
                    setPayment(data.payment);
                } else {
                    toast(t('notification.request.fail'), 'error');
                }
                setUserTransactionDetailsLoading(false);
            }).catch((response) => {
                toast(response?.data?.msg || response?.data?.message, 'error');
                setUserTransactionDetailsLoading(false);
            })
        }


        const singleRequestsColumn: ColumnProps<Record<string, string>>[] = [
            {
                title: 'تاریخ‌درخواست',
                key: 'requestDate',
                dataIndex: 'requestDate', responsive: ['xxl', 'xl', 'lg']
            },
            {
                title: 'نام‌و‌نام‌خانوادگی',
                dataIndex: 'name',
                key: 'name', responsive: ['xxl', 'xl', 'lg', 'md']
            },
            {
                title: 'کد ملی',
                dataIndex: 'nationalCode',
                key: 'nationalCode', responsive: ['xxl', 'xl', 'lg']
            },
            {
                title: 'متقاضی',
                dataIndex: 'accountType',
                key: 'accountType', responsive: ['xxl', 'xl']
            },
            {
                title: 'موبایل',
                key: 'mobile',
                dataIndex: 'mobile', responsive: ['xxl', 'xl']
            },
            {
                title: 'نام‌شرکت',
                key: 'companyName',
                dataIndex: 'companyName', responsive: ['xxl', 'xl', 'lg']
            },
            {
                title: 'اطلاعات تکمیل نشده',
                key: 'incompleteFields',
                dataIndex: 'incompleteFields',
                render: (incompleteFields) => (
                    <>
                        <div style={{color: "#F461B7"}}>
                            {
                                incompleteFields.length === 1 ? incompleteFields[0] :
                                    (incompleteFields.length !== 0 && incompleteFields.length > 1 ? incompleteFields[0] + " و ..." : "---")

                            }
                        </div>
                    </>
                )
            },
            {
                title: 'مدارک تکمیل نشده',
                key: 'incompleteDocs',
                dataIndex: 'incompleteDocs',
                render: (incompleteDocs) => (
                    <>
                        <div style={{color: "#F461B7"}}>
                            {
                                incompleteDocs.length === 1 ? t("dashboard:docs." + incompleteDocs[0]) :
                                    (incompleteDocs.length > 1 && incompleteDocs.length !== 0 ?
                                        t("dashboard:docs." + incompleteDocs[0]) + " و ..." : "---")
                            }
                        </div>
                    </>
                )
            },
            {
                title: 'وضعیت‌هویت',
                key: 'STATUS',
                dataIndex: 'STATUS'
            }
        ]

        const paymentCulomn: ColumnProps<Record<string, string>>[] = [
            {
                title: 'تاریخ پرداخت',
                key: 'date',
                dataIndex: 'date',
                render: item => {
                    return item && moment(item.replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3"), 'YYYY/MM/DD').locale('fa').format('YYYY/MM/DD');
                }
            },
            {
                title: 'زمان پرداخت',
                key: 'time',
                dataIndex: 'time',
                render: item => {
                    return item?.replace(/(\d{2})(\d{2})(\d{2})/, "$1:$2:$3")
                }
            },
            {
                title: 'مبلغ (ریال)',
                key: 'amount',
                dataIndex: 'amount',
                render: item => item?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            {
                title: 'کد ارجاع',
                key: 'saleReferenceId',
                dataIndex: 'saleReferenceId'
            },
            {
                title: 'کارت بانکی',
                key: 'cardHolderPAN',
                dataIndex: 'cardHolderPAN',
                render: item => <p style={{direction: 'ltr'}}>{item}</p>
            },
            {
                title: 'وضعیت پرداخت',
                key: 'status',
                dataIndex: 'status',
                //@ts-ignore
                render: (status: string) => status ? paymentStatus[status] : ''
            },
        ]

        const createPrefix = (content: string) => {
            return (
                <span
                    style={{padding: '10px'}}
                >
                {content}:
            </span>
            )
        }

        const approveRequest = () => {
            setRequestProcessing(true);
            //@ts-ignore
            authedApiState.apiContainer?.api.approveAllDoc({userId: singleRequest.verificationDTO?.userId})
                .then(({data, status}) => {
                    if (status === 200) {
                        toast(t('notification.request.approve.success'), 'success');
                    } else {
                        toast(t('notification.request.approve.fail'), 'error');
                    }
                    setRequestProcessing(false);
                    setConfirmModalVisible(false);
                    visibilityHandler();
                    onFinish();
                }).catch((response) => {
                toast(response?.data?.msg || response?.data?.message, 'error');
                setRequestProcessing(false);
                setConfirmModalVisible(false);
                visibilityHandler();
                onFinish();
            })
        }

        const rejectRequest = () => {
            if (!singleRequest.user?.id) return;
            let body: RejectDocsAndFieldsRequest = {
                userId: singleRequest.user.id,
                docs: hasMissingDocs && missingDocs.length !== 0,
                fields: hasMissingField,
                docs_ids: missingDocs,
                docs_reason: rejectDocsReason,
                field_reason: rejectFieldsReason
            }

            setRequestProcessing(true);
            authedApiState.apiContainer?.api.rejectAllDocs(body).then(({data, status}) => {
                if (status === 200) {
                    toast('رد درخواست موفق', 'success');
                } else {
                    toast(t('notification.request.approve.fail'), 'error');
                }
                setRequestProcessing(false);
                setRejectModalVisible(false);
                visibilityHandler();
                onFinish();
            }).catch((response: any) => {
                setRequestProcessing(false);
                setRejectModalVisible(false);
                visibilityHandler();
                onFinish();
                toast(response.data?.msg, 'error');
            })

        }

        const handleDocsRejectCheckBox = (state: boolean, docType: DocTypes[]) => {
            let clonedMissingDocs = [...missingDocs];
            docType.forEach((doc) => {
                const document = singleRequest.pendingDocs?.find((item) => item.docType === doc);
                if (document && document.id) {
                    if (state) {
                        clonedMissingDocs.push(document.id);
                    } else {
                        clonedMissingDocs = clonedMissingDocs.filter(id => id !== document.id);
                    }
                }
            })
            setMissingDocs(clonedMissingDocs);
        }

        return (
            <>
                {
                    singleRequestLoading ? (<Loading/>) :

                        <>
                            <Row gutter={[16, 16]} justify={'center'}>
                                <Col span={24} lg={24} md={24} sm={24} xs={24}>
                                    <div className={'dashboard-grid'}>
                                        <Row justify={'center'}>
                                            <Col offset={1} >
                                                {singleRequest.verificationDTO ?
                                                    <CustomTable
                                                        loading={singleRequestLoading}
                                                        paginationBottom={"none"}
                                                        data={[singleRequest.verificationDTO as unknown as Record<string, string>]}
                                                        columns={singleRequestsColumn}
                                                        total={1}
                                                        onChange={loadSingleRequest}
                                                        defaultSort={"requestDate"}
                                                    /> : <></>
                                                }
                                            </Col>

                                            <Col span={22} offset={1} className={'confirmationData'}>

                                                <Collapse
                                                    bordered={false}
                                                    defaultActiveKey={[0, 1, 2, 3, 4, 5, 6]}
                                                    expandIconPosition={"left"}>

                                                    {Object.entries(userTransactionDetails).length === 0 ? (<></>) :
                                                        (
                                                            <Panel header={t("identification:pendingRequest.form.0.title")}
                                                                   key="0">
                                                                <Row gutter={[8, 8]}>
                                                                    <Col span={24}>
                                                                        <Row gutter={[8, 8]}>
                                                                            <Input.Group size="large">
                                                                                <Row gutter={[8, 8]}>
                                                                                    <Col lg={6} md={12} sm={24} xs={24}>
                                                                                        <Input
                                                                                            disabled
                                                                                            prefix={createPrefix(t("identification:userType.status"))}
                                                                                            //@ts-ignore
                                                                                            value={t('identification:userType.' + userTransactionDetails?.userType)}/>
                                                                                    </Col>
                                                                                    <Col lg={6} md={12} sm={24} xs={24}>
                                                                                        <Input
                                                                                            prefix={createPrefix(t("identification:form.label.stocks"))}
                                                                                            disabled
                                                                                            value={userTransactionDetails?.stocks?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                                        />
                                                                                    </Col>
                                                                                    <Col lg={6} md={12} sm={24} xs={24}>
                                                                                        <Input
                                                                                            prefix={createPrefix(t("identification:form.label.parValuePerStock"))}
                                                                                            disabled
                                                                                            value={userTransactionDetails?.parValuePerStock?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                                        />
                                                                                    </Col>
                                                                                    <Col lg={6} md={12} sm={24} xs={24}>
                                                                                        <Input
                                                                                            prefix={createPrefix(t("identification:form.label.paidPrice"))}
                                                                                            disabled
                                                                                            value={userTransactionDetails?.paidPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                                        />
                                                                                    </Col>
                                                                                    <Col lg={6} md={12} sm={24} xs={24}>
                                                                                        <Input
                                                                                            prefix={createPrefix(t("identification:form.label.obligationPrice"))}
                                                                                            disabled
                                                                                            value={userTransactionDetails?.obligationPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                                        />
                                                                                    </Col>
                                                                                    <Col lg={6} md={12} sm={24} xs={24}>
                                                                                        <Input
                                                                                            prefix={createPrefix(t("identification:form.label.nonCashPrice"))}
                                                                                            disabled
                                                                                            value={userTransactionDetails?.nonCashPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                                        />
                                                                                    </Col>
                                                                                    <Col lg={6} md={12} sm={24} xs={24}>
                                                                                        <Input
                                                                                            prefix={createPrefix(t("identification:form.label.paymentMethod"))}
                                                                                            disabled
                                                                                            value={userTransactionDetails?.paymentMethod ? 'آنلاین' : '---'}
                                                                                        />
                                                                                    </Col>
                                                                                </Row>
                                                                            </Input.Group>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                            </Panel>
                                                        )}

                                                    {Object.entries(userTransactionDetails).length === 0 ? (<></>) : (
                                                        <Panel header={t("identification:pendingRequest.form.1.title")}
                                                               key="1">
                                                            <Row gutter={[8, 8]} justify={'center'}>
                                                                <Col span={18}>
                                                                    <CustomTable
                                                                        loading={userTransactionDetailsLoading}
                                                                        paginationBottom={"none"}
                                                                        data={[payment]}
                                                                        columns={paymentCulomn}
                                                                        total={1}
                                                                        onChange={loadUserTransactionDetails}
                                                                        defaultSort={"date"}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Panel>
                                                    )}
                                                    {/*Personal Info*/}
                                                    <Panel header={t("identification:pendingRequest.form.2.title")} key="2">
                                                        <Row gutter={[8, 8]}>
                                                            <Col span={24}>
                                                                <Row gutter={[8, 8]}>
                                                                    <Input.Group size="large">
                                                                        <Row gutter={[8, 8]}>
                                                                            <Col lg={6} md={12} sm={24} xs={24}>
                                                                                <Input
                                                                                    disabled
                                                                                    prefix={createPrefix(t("identification:form.label.firstName"))}
                                                                                    value={singleRequest?.verificationDTO?.firstName || 'نامشخص'}/>
                                                                            </Col>
                                                                            <Col lg={6} md={12} sm={24} xs={24}>
                                                                                <Input
                                                                                    prefix={createPrefix(t("identification:form.label.lastName"))}
                                                                                    disabled
                                                                                    value={singleRequest.verificationDTO?.lastName || 'نامشخص'}
                                                                                />
                                                                            </Col>
                                                                            <Col lg={6} md={12} sm={24} xs={24}>
                                                                                <Input
                                                                                    prefix={createPrefix(t("identification:form.label.fatherName"))}
                                                                                    disabled
                                                                                    value={singleRequest.user?.fatherName || 'نامشخص'}/>
                                                                            </Col>
                                                                            <Col lg={6} md={12} sm={24} xs={24}>
                                                                                <Input
                                                                                    prefix={createPrefix(t("identification:form.label.mobile"))}
                                                                                    disabled
                                                                                    value={singleRequest.user?.mobile || 'نامشخص'}/>
                                                                            </Col>
                                                                        </Row>
                                                                    </Input.Group>
                                                                </Row>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Row gutter={[8, 8]}>
                                                                    <Input.Group size="large">
                                                                        <Row gutter={[8, 8]}>
                                                                            <Col lg={6} md={12} sm={24} xs={24}>
                                                                                <Input
                                                                                    prefix={createPrefix(t("identification:form.label.nationalCode"))}
                                                                                    disabled
                                                                                    value={singleRequest.verificationDTO?.nationalCode || 'نامشخص'}/>
                                                                            </Col>
                                                                            <Col lg={6} md={12} sm={24} xs={24}>
                                                                                <Input
                                                                                    prefix={createPrefix(t("identification:form.label.idCardNumber"))}
                                                                                    disabled
                                                                                    value={singleRequest.user?.idCardNumber || 'نامشخص'}/>
                                                                            </Col>
                                                                            <Col lg={6} md={12} sm={24} xs={24}>
                                                                                <Input
                                                                                    prefix={createPrefix(t("identification:form.label.ssnOstan"))}
                                                                                    disabled
                                                                                    value={singleRequest.user?.ssnAddress?.ostan?.name || 'نامشخص'}/>
                                                                            </Col>
                                                                            <Col lg={6} md={12} sm={24} xs={24}>
                                                                                <Input
                                                                                    prefix={createPrefix(t("identification:form.label.ssnShahrestan"))}
                                                                                    disabled
                                                                                    value={singleRequest.user?.ssnAddress?.shahrestan?.name || 'نامشخص'}/>
                                                                            </Col>
                                                                        </Row>
                                                                    </Input.Group>
                                                                </Row>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Row gutter={[8, 8]}>
                                                                    <Input.Group size="large">
                                                                        <Row gutter={[8, 8]}>
                                                                            <Col lg={6} md={12} sm={24} xs={24}>
                                                                                <Input
                                                                                    prefix={createPrefix(t("identification:form.label.birthDate"))}
                                                                                    disabled
                                                                                    value={(singleRequest.user?.birthdate || 'نامشخص').replace(/-/g, "/")}/>
                                                                            </Col>
                                                                            <Col lg={6} md={12} sm={24} xs={24}>
                                                                                <Input
                                                                                    prefix={createPrefix(t("identification:form.label.gender"))}
                                                                                    disabled
                                                                                    value={singleRequest.user?.gender || 'نامشخص'}/>
                                                                            </Col>
                                                                            <Col lg={6} md={12} sm={24} xs={24}>
                                                                                <Input
                                                                                    prefix={createPrefix(t("identification:form.label.maritalStatus"))}
                                                                                    disabled
                                                                                    //@ts-ignore
                                                                                    value={t('identification:form.label.maritalStatus.'
                                                                                        + singleRequest.user?.maritalStatus) || 'نامشخص'}/>
                                                                            </Col>
                                                                            <Col lg={6} md={12} sm={24} xs={24}>
                                                                                <Input
                                                                                    prefix={createPrefix(t("identification:form.label.educationLevel"))}
                                                                                    disabled
                                                                                    //@ts-ignore
                                                                                    value={t('identification:educationLevel.'
                                                                                        + singleRequest.user?.educationLevel) || 'نامشخص'}/>
                                                                            </Col>
                                                                        </Row>
                                                                    </Input.Group>
                                                                </Row>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Row gutter={[8, 8]}>
                                                                    <Input.Group size="large">
                                                                        <Row gutter={[8, 8]}>
                                                                            <Col lg={6} md={12} sm={24} xs={24}>
                                                                                <Input
                                                                                    prefix={createPrefix(t("identification:form.label.dutyStatus"))}
                                                                                    disabled
                                                                                    //@ts-ignore
                                                                                    value={t('identification:dutyType.'
                                                                                        + singleRequest.user?.dutyStatus) || 'نامشخص'}/>
                                                                            </Col>
                                                                            <Col lg={6} md={12} sm={24} xs={24}>
                                                                                <Input
                                                                                    prefix={createPrefix(t("identification:form.label.sacrificeStatus"))}
                                                                                    disabled
                                                                                    //@ts-ignore
                                                                                    value={t('identification:sacrificeType.'
                                                                                        + singleRequest.user?.sacrificeStatus) || 'نامشخص'}/>
                                                                            </Col>
                                                                            <Col lg={6} md={12} sm={24} xs={24}>
                                                                                <Input
                                                                                    prefix={createPrefix(t("identification:form.label.ostan"))}
                                                                                    disabled
                                                                                    value={singleRequest.user?.address?.ostan?.name || 'نامشخص'}/>
                                                                            </Col>
                                                                            <Col lg={6} md={12} sm={24} xs={24}>
                                                                                <Input
                                                                                    prefix={createPrefix(t("identification:form.label.shahrestan"))}
                                                                                    disabled
                                                                                    value={singleRequest.user?.address?.shahrestan?.name || 'نامشخص'}/>
                                                                            </Col>

                                                                        </Row>
                                                                    </Input.Group>
                                                                </Row>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Row gutter={[8, 8]}>
                                                                    <Input.Group size="large">
                                                                        <Row gutter={[8, 8]}>
                                                                            <Col lg={6} md={12} sm={24} xs={24}>
                                                                                <Input
                                                                                    prefix={createPrefix(t("identification:form.label.bakhsh"))}
                                                                                    disabled
                                                                                    value={singleRequest.user?.address?.bakhsh?.name || 'نامشخص'}/>
                                                                            </Col>
                                                                            <Col lg={6} md={12} sm={24} xs={24}>
                                                                                <Input
                                                                                    prefix={createPrefix(t("identification:form.label.dehestan"))}
                                                                                    disabled
                                                                                    value={singleRequest.user?.address?.dehestan?.name || 'نامشخص'}/>
                                                                            </Col>
                                                                            <Col lg={6} md={12} sm={24} xs={24}>
                                                                                <Input
                                                                                    prefix={createPrefix(t("identification:form.label.phone"))}
                                                                                    disabled
                                                                                    value={singleRequest.user?.phone || 'نامشخص'}/>
                                                                            </Col>
                                                                            <Col lg={6} md={12} sm={24} xs={24}>
                                                                                <Input
                                                                                    prefix={createPrefix(t("identification:form.label.postalCode"))}
                                                                                    disabled
                                                                                    value={singleRequest.user?.address?.postalCode || 'نامشخص'}/>
                                                                            </Col>
                                                                        </Row>
                                                                    </Input.Group>
                                                                </Row>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Row gutter={[8, 8]}>
                                                                    <Input.Group size="large">
                                                                        <Row gutter={[8, 8]}>
                                                                            <Col lg={24} md={24} sm={24} xs={24}>
                                                                                <Input
                                                                                    prefix={createPrefix(t("identification:form.label.address"))}
                                                                                    disabled
                                                                                    value={singleRequest.user?.address?.detail || 'نامشخص'}/>
                                                                            </Col>
                                                                        </Row>
                                                                    </Input.Group>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Panel>

                                                    {/*Company Info*/}
                                                    <Panel header={t("identification:pendingRequest.form.3.title")} key="3">
                                                        <Row gutter={[8, 8]}>
                                                            <Col span={24}>
                                                                <Row gutter={[8, 8]}>
                                                                    <Input.Group size="large">
                                                                        <Row gutter={[8, 8]}>
                                                                            <Col lg={6} md={12} sm={24} xs={24}>
                                                                                <Input
                                                                                    prefix={createPrefix(t("identification:form.label.companyName"))}
                                                                                    disabled
                                                                                    value={singleRequest.user?.companyName || 'نامشخص'}/>
                                                                            </Col>
                                                                            <Col lg={6} md={12} sm={24} xs={24}>
                                                                                <Input
                                                                                    prefix={createPrefix(t("identification:form.label.companySSNumber"))}
                                                                                    disabled
                                                                                    value={singleRequest.user?.companySSNumber || 'نامشخص'}/>
                                                                            </Col>
                                                                            <Col lg={6} md={12} sm={24} xs={24}>
                                                                                <Input
                                                                                    prefix={createPrefix(t("identification:form.label.companyRegisterNumber"))}
                                                                                    disabled
                                                                                    value={singleRequest.user?.companyRegisterNumber || 'نامشخص'}/>
                                                                            </Col>

                                                                            <Col lg={6} md={12} sm={24} xs={24}>
                                                                                <Input
                                                                                    prefix={createPrefix(t("identification:form.label.companyRegisterNumber"))}
                                                                                    disabled
                                                                                    //@ts-ignore
                                                                                    value={singleRequest.user?.companyAddress || 'نامشخص'}/>
                                                                            </Col>
                                                                        </Row>
                                                                    </Input.Group>
                                                                </Row>
                                                            </Col>

                                                            <Col span={24}>
                                                                <Row gutter={[8, 8]}>
                                                                    <Input.Group size="large">
                                                                        <Row gutter={[8, 8]}>
                                                                            <Col lg={8} md={12} sm={24} xs={24}>
                                                                                <Input
                                                                                    prefix={createPrefix(t("identification:form.label.companyType"))}
                                                                                    disabled
                                                                                    value={singleRequest.user?.companyType || 'نامشخص'}/>
                                                                            </Col>
                                                                            <Col lg={8} md={12} sm={24} xs={24}>
                                                                                <Input
                                                                                    prefix={createPrefix(t("identification:form.label.companyOstan"))}
                                                                                    disabled
                                                                                    value={singleRequest.user?.companyAddress?.ostan?.name || 'نامشخص'}/>
                                                                            </Col>
                                                                            <Col lg={8} md={12} sm={24} xs={24}>
                                                                                <Input
                                                                                    prefix={createPrefix(t("identification:form.label.companyShahrestan"))}
                                                                                    disabled
                                                                                    value={singleRequest.user?.companyAddress?.shahrestan?.name || 'نامشخص'}/>
                                                                            </Col>
                                                                        </Row>
                                                                    </Input.Group>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Panel>

                                                    {/*Bank Info*/}
                                                    <Panel header={t("identification:pendingRequest.form.4.title")} key="4">
                                                        <Row gutter={[8, 8]}>
                                                            {
                                                                singleRequest.bankAccounts && singleRequest.bankAccounts?.length > 0 ? (
                                                                    singleRequest.bankAccounts?.map((bank: Record<string, any>, index) => {
                                                                        return (
                                                                            <Input.Group size="large">
                                                                                <Row gutter={[8, 8]}>
                                                                                    <Col lg={8} md={12} sm={24} xs={24}>
                                                                                        <Input
                                                                                            prefix={createPrefix(t("identification:form.label.bankName"))}
                                                                                            disabled
                                                                                            value={bank.bankName || 'نامشخص'}/>
                                                                                    </Col>
                                                                                    <Col lg={8} md={12} sm={24} xs={24}>
                                                                                        <Input
                                                                                            style={{direction: "ltr"}}
                                                                                            suffix={<span
                                                                                                style={{padding: "10px"}}>:{t("identification:form.label.cardId")}</span>}
                                                                                            disabled
                                                                                            value={bank.cardId || 'نامشخص'}/>
                                                                                    </Col>
                                                                                    <Col lg={8} md={12} sm={24} xs={24}>
                                                                                        <Input
                                                                                            style={{direction: "ltr"}}
                                                                                            suffix={<span
                                                                                                style={{padding: "10px"}}>:{t("identification:form.label.sheba")}</span>}
                                                                                            disabled
                                                                                            value={bank.sheba || 'نامشخص'}/>
                                                                                    </Col>
                                                                                </Row>
                                                                            </Input.Group>
                                                                        )
                                                                    })) : (<span>اطلاعاتی یافت نشد</span>)}
                                                        </Row>
                                                    </Panel>

                                                    {/*Applied Docs*/}
                                                    <Panel header={t("identification:pendingRequest.form.5.title")} key="5">
                                                        <p>{t("identification:pendingRequest.form.description")}</p>
                                                        <RequestDocs docs={docs}/>
                                                    </Panel>

                                                </Collapse>
                                            </Col>

                                        </Row>

                                    </div>
                                </Col>
                            </Row><br/>


                            {/*Buttons*/}
                            <Row justify="end" gutter={[16, 16]}>
                                <Col>
                                    <button
                                        className={"form-submit-button"}
                                        onClick={() => setRejectModalVisible(true)}
                                    >{t("identification:pendingRequest.form.button.reject")}</button>
                                </Col>
                                <Col>
                                    <button
                                        className={"form-submit-button"}
                                        disabled={singleRequest.incompleteDocs?.length !== 0}
                                        onClick={() => setConfirmModalVisible(true)}
                                    >
                                        {
                                            singleRequest.incompleteDocs?.length !== 0 ? <span>مدارک ناقص است</span> :
                                                t("identification:pendingRequest.form.button.accept")
                                        }
                                    </button>
                                </Col>
                            </Row>


                            <Modal
                                visible={confirmModalVisible}
                                onCancel={() => setConfirmModalVisible(false)}
                                title="تایید درخواست"
                                footer={[

                                    <Button
                                        onClick={() => {
                                            setConfirmModalVisible(false)
                                        }}
                                    >
                                        لغو
                                    </Button>,
                                    <Button type="primary"
                                            disabled={singleRequest.incompleteDocs?.length !== 0}
                                            loading={requestProcessing} onClick={() => {
                                        approveRequest()
                                    }}>
                                        تایید
                                    </Button>
                                ]}
                            >
                                <b>آیا از تایید مدارک اطمینان دارید؟</b>
                            </Modal>

                            <Modal
                                width={"50%"}
                                onCancel={() => setRejectModalVisible(false)}
                                visible={rejectModalVisible}
                                title={t('identification:pendingRequest.form.7.title')}
                                footer={[
                                    <Button
                                        onClick={() => {
                                            setRejectModalVisible(false)
                                        }}
                                    >
                                        لغو
                                    </Button>,
                                    <Button
                                        type="primary"
                                        danger
                                        loading={requestProcessing}
                                        disabled={!hasMissingField}
                                        onClick={() => rejectRequest()}
                                    >
                                        {t("identification:pendingRequest.form.button.finalReject")}
                                    </Button>,
                                ]}
                            >
                                <>
                                    <Row style={{width: '100%'}}>
                                        <Input
                                            onChange={(event) => {
                                                setRejectFieldsReason(event.target.value);
                                                if (event.target.value.length > 0)
                                                    setHasMissingField(true);
                                                else
                                                    setHasMissingField(false);
                                            }}
                                            disabled={false}
                                            placeholder={t("identification:pendingRequest.form7.description")}
                                        />
                                        {/*<Col span={24} lg={24} md={24} sm={24} xs={24}>*/}
                                        {/*    <div className={'dashboard-grid'}>*/}

                                        {/*        <Row>*/}
                                        {/*            <Col span={5} offset={1}>*/}
                                        {/*                <Checkbox onChange={() => {*/}
                                        {/*                    setHasMissingField(!hasMissingField)*/}
                                        {/*                }}*/}
                                        {/*                          checked={hasMissingField}*/}
                                        {/*                >{t("identification:form.label.reject.missing.fields.checkbox")}</Checkbox>*/}
                                        {/*            </Col>*/}
                                        {/*            <Col span={16}>*/}
                                        {/*                <Input*/}
                                        {/*                    onChange={(event) => {*/}
                                        {/*                        setRejectFieldsReason(event.target.value);*/}
                                        {/*                    }}*/}
                                        {/*                    disabled={!hasMissingField}*/}
                                        {/*                    placeholder={t("identification:form.label.reject.missing.fields")}*/}
                                        {/*                />*/}
                                        {/*            </Col>*/}
                                        {/*        </Row>*/}


                                        {/*        /!*Final rejection*!/*/}
                                        {/*        {*/}
                                        {/*            //@ts-ignore*/}
                                        {/*            singleRequest.incompleteDocs?.length === 0 ? (*/}
                                        {/*                    <Row gutter={[8, 8]}>*/}
                                        {/*                        <Col span={5} offset={1}>*/}
                                        {/*                            <Row>*/}
                                        {/*                                <Checkbox onChange={() => {*/}
                                        {/*                                    setHasMissingDocs(!hasMissingDocs)*/}
                                        {/*                                }}*/}
                                        {/*                                          checked={hasMissingDocs}*/}
                                        {/*                                >{t("identification:form.label.reject.missing.docs.checkbox")}</Checkbox>*/}
                                        {/*                            </Row>*/}
                                        {/*                            <Row>*/}
                                        {/*                                <Checkbox disabled={!hasMissingDocs}*/}
                                        {/*                                          onChange={(event) => {*/}
                                        {/*                                              handleDocsRejectCheckBox(event.target.checked, ["NATIONAL_CARD_P_1", "NATIONAL_CARD_P_2"]);*/}
                                        {/*                                          }}*/}
                                        {/*                                >*/}
                                        {/*                                    {t("identification:form.label.nationalId")}</Checkbox>*/}
                                        {/*                            </Row>*/}
                                        {/*                            <Row>*/}
                                        {/*                                <Checkbox disabled={!hasMissingDocs}*/}
                                        {/*                                          onChange={(event) => {*/}
                                        {/*                                              handleDocsRejectCheckBox(event.target.checked, ["ID_CARD_P_1_2", "ID_CARD_P_3_4", "ID_CARD_P_5_6"]);*/}
                                        {/*                                          }}*/}
                                        {/*                                >{t("identification:form.label.personalId")}</Checkbox>*/}
                                        {/*                            </Row>*/}
                                        {/*                            <Row>*/}
                                        {/*                                <Checkbox disabled={!hasMissingDocs}*/}
                                        {/*                                          onChange={(event) => {*/}
                                        {/*                                              handleDocsRejectCheckBox(event.target.checked, ["DUTY_CARD_P_1", "DUTY_CARD_P_1"]);*/}
                                        {/*                                          }}*/}
                                        {/*                                >{t("identification:form.label.dutyCard")}</Checkbox>*/}
                                        {/*                            </Row>*/}
                                        {/*                            <Row>*/}
                                        {/*                                <Checkbox disabled={!hasMissingDocs}*/}
                                        {/*                                          onChange={(event) => {*/}
                                        {/*                                              handleDocsRejectCheckBox(event.target.checked, ["MAIN_BANK_CART"]);*/}
                                        {/*                                          }}*/}
                                        {/*                                >{t("identification:form.label.bankCard")}</Checkbox>*/}
                                        {/*                            </Row>*/}
                                        {/*                        </Col>*/}
                                        {/*                        <Col span={16}>*/}
                                        {/*                            <TextArea disabled={!hasMissingDocs}*/}
                                        {/*                                      onChange={(event) => {*/}
                                        {/*                                          setRejectDocsReason(event.target.value);*/}
                                        {/*                                      }}*/}
                                        {/*                                      placeholder={t("identification:form.label.reject.missing.docs")}*/}
                                        {/*                                      rows={10}/>*/}
                                        {/*                        </Col>*/}
                                        {/*                    </Row>*/}
                                        {/*                ) :*/}
                                        {/*                <div>*/}

                                        {/*                    <Row justify={"center"} align={"middle"}>*/}
                                        {/*                        <Col>*/}
                                        {/*                            تا زمانی که مدارک ناقص هستند امکان رد مدارک وجود ندارد.*/}
                                        {/*                        </Col>*/}
                                        {/*                    </Row>*/}
                                        {/*                </div>*/}
                                        {/*        }*/}
                                        {/*    </div>*/}
                                        {/*</Col>*/}
                                    </Row>

                                </>
                            </Modal>
                        </>
                }
            </>
        )
    }
;

export default Confirmation;
