import {Api, FullRequestParams} from "./api";
import {AxiosInstance, AxiosResponse} from "axios";
import axios from 'axios';
export class ClientApi extends Api<unknown> {

    // @ts-ignore
    constructor(token: string, logout: Function) {
        super({
            secure: true,
            securityWorker: () => new Promise((resolve) => resolve({headers: {Authorization: 'Bearer ' + token}}))
        });
        this.logout = logout
    }

    logout: Function

    request = <T = any, _E = any>({
                                      secure,
                                      path,
                                      type,
                                      query,
                                      format,
                                      body,
                                      ...params
                                  }: FullRequestParams): Promise<AxiosResponse<T>> => {
        return new Promise<AxiosResponse>((resolve, reject) => {
            super.request<T, _E>({
                secure,
                path,
                type,
                query,
                format,
                body,
                ...params
            }).then((response) => { // Request method should be a normal function not lambda
                resolve(response)
            }).catch(({response}: { response: AxiosResponse }) => {
                if (response?.status === 401) {
                    this.logout();
                }
                else {
                    reject(response)
                }
            })

        })
    }
}
///////////////////////////////////
export class ApiHelper {
    private http: AxiosInstance;
    constructor(timeout:number,mToken:string) {
        const baseUrl = process.env.REACT_APP_API_URL;
        const apiUrl = 'api/v1';
        const token = mToken;
        const headers:any = {
            Accept: 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
        };
        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
        }

        this.http = axios.create({
            baseURL: `${baseUrl}/${apiUrl}`,
            timeout: timeout !== undefined ? timeout : 12000,
            headers
        });

        this.http.interceptors.response.use(
            response => response,
            error =>
                // TODO
                // eslint-disable-next-line no-console
                // console.log('----- Error: ' + error);
                Promise.reject(error)
        );
    }

    get = (url:string, data:any) =>
        this.http.get(url, {
            params: data
        });

    post = (url:string, data:any) => this.http.post(url, data);

    error = () => ({
        status: 'fail',
        code: '10500',
        message: 'در حال حاضر ارتباط با سرور برقرار نیست',
        errors: undefined,
        meta: undefined,
        actions: undefined
    });
}

// export default ApiHelper;
export default {ClientApi,ApiHelper};
