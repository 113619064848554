import {Button, Col, Row, Modal, Input} from "antd";
import CustomTable from "../../components/Table";
import {useEffect, useState} from "react";
import useStore from "../../hooks/useStore";
import {ColumnProps} from "antd/lib/table/Column";
import TableActions from "../../components/TableActions";
import useToast from "../../hooks/useToast";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons";


const CompanyParamsConfirm = () => {
    const {t} = useTranslation(['stockParamsConfirmation', 'dashboard']);

    const [pendingRequests, setPendingRequests] = useState<any[]>([]);
    const [pendingRequestsLoading, setPendingRequestsLoading] = useState<boolean>(true);
    const [pendingRequestsTotalElements, setPendingRequestsTotalElements] = useState<number>(10);
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [smsConfirmModal, setSmsConfirmModal] = useState<boolean>(false);
    const [smsRejectModal, setSmsRejectModal] = useState<boolean>(false);
    const [sendingSMS, setSendingSMS] = useState<boolean>(false);
    const [pin, setPin] = useState<string>('');
    const [rejectReason, setRejetReason] = useState<string>('');
    const [targetId, setTargetId] = useState<string>('');
    const [requestInProgress, setRequestInProgress] = useState<boolean>(false);
    const [tableSettings, setTableSettings] = useState({});
    const [inReview, setInReview] = useState<{
        id: string,
        created: "string",
        parValuePerStock: number,
        totalNumberOfStocks: number,
        registeredCapital: number,
        stockSupplyValue: number,
        availableStocks: number,
        offeringPeriod: {
            from: string,
            to: string
        },
        nonMemberOfferingPeriod: {
            from: string,
            to: string
        },
        nonMemberInvestorOfferingPeriod: {
            from: string,
            to: string
        },
        memberOfferingPeriod: {
            from: string,
            to: string
        },
        nonMemberInvestorReturnOnStock: number,
        nonMemberPremiumOnStock: number,
        nonMemberInvestorPremiumOnStock: number,
        investmentContractCommissionFee: number,
        earningsPerShare: number,
        dividendsPerShare: number,
        investmentPeriod: number,
        loan: number,
        status: "PENDING" | "APPROVED" | "REJECT",
        proceedingsDate: string,
        depositRepaymentInstallment: number,
        assemblyProfit: number,
        reason: string,
        membersStock: number,
        nonMembersStock: number,
        nomMembersInvestorStock: number
    }>(
        {
            id: "",
            created: "string",
            parValuePerStock: 0,
            totalNumberOfStocks: 0,
            registeredCapital: 0,
            stockSupplyValue: 0,
            availableStocks: 0,
            offeringPeriod: {
                from: "",
                to: ""
            },
        nonMemberOfferingPeriod: {
        from: "",
            to: ""
    },
    nonMemberInvestorOfferingPeriod: {
        from: "",
            to: ""
    },
    memberOfferingPeriod: {
        from: "",
            to: ""
    },
            nonMemberInvestorReturnOnStock: 0,
            nonMemberPremiumOnStock: 0,
            nonMemberInvestorPremiumOnStock: 0,
            investmentContractCommissionFee: 0,
            earningsPerShare: 0,
            dividendsPerShare: 0,
            investmentPeriod: 0,
            loan: 0,
            status: "PENDING",
            proceedingsDate: "",
            depositRepaymentInstallment: 0,
            assemblyProfit: 0,
            membersStock: 0,
            nonMembersStock: 0,
            nomMembersInvestorStock: 0,
            reason: ""
        });

    const objectDictionary = {
        created: "تاریخ درخواست",
        parValuePerStock: "ارزش اسمی (ریال)",
        totalNumberOfStocks: "تعداد کل سهام",
        registeredCapital: "سرمایه ثبتی (ریال)",
        stockSupplyValue: "ارزش عرضه سهام",
        availableStocks: "تعداد سهام قابل عرضه",
        // offeringPeriod: "بازه عرضه",
        nonMemberOfferingPeriod: "بازه عرضه برای سهامدار غیر عضو",
        nonMemberInvestorOfferingPeriod: "بازه عرضه برای سهامدار غیر عضو سرمایه گذار",
        memberOfferingPeriod: "بازه عرضه برای سهامدار عضو",
        nonMemberInvestorReturnOnStock: "ارزش افزوده سالیانه سرمایه گذاری سهامداران غیرعضر سرمایه‌گذار",
        nonMemberPremiumOnStock: "صرف سهام سهامدار غیرعضو",
        nonMemberInvestorPremiumOnStock: "صرف سهام سهامدار غیرعضو سرمایه‌گذار",
        investmentContractCommissionFee: "کارمزد شرکت در قرارداد سرمایه گذاری قرض الحسه سرمایه گذار",
        earningsPerShare: "سود سالیانه هر سهم",
        dividendsPerShare: "درآمد به ازای هر سهم",
        investmentPeriod: " ماه دوره سرمایه گذاری",
        loan: "قرض الحسنه سرمایه گذار",
        status: "وضعیت",
        proceedingsDate: "تاریخ مصوب صورت جلسه",
        depositRepaymentInstallment: "دوره بازپرداخت",
        assemblyProfit: "سود مجمع",
        reason: "دلیل",
        membersStock: "سهام عضو",
        nonMembersStock: "سهام غیرعضو",
        nomMembersInvestorStock: "سهام سهامدار غیرعضو سرمایه‌گذار",
        memberLimit: "تعداد سهام عضو",
        nonMemberLimit: "تعداد سهام غیرعضو",
        investorLimit: "تعداد سهام سهامدار غیرعضو سرمایه‌گذار"
    }
    const [authedApiState] = useStore()
    const toast = useToast();
    const [timeLeft, setTimeLeft] = useState<number>(0);
    const timeout = 120;

    useEffect(() => {
        loadPendingParamsConfirm({page: 0});
    }, [])

    useEffect(() => {
        setPendingRequests(pendingRequests);
    }, [pendingRequests])

    const loadPendingParamsConfirm = ({page, size, sort, asc}:
                                          { page: number, size?: number, sort?: string, asc?: boolean }) => {
        const query = {
            page, size: size ? size : 5,
            sort: sort ? sort : 'created', asc: asc ? asc : false
        }
        setTableSettings(query);
        setPendingRequests([])
        setPendingRequestsLoading(true)
        authedApiState.apiContainer?.api.companyDetailHistory(query).then(({data, status}) => {
            setPendingRequestsTotalElements(data.totalElements ? data.totalElements : 0);
            if (status === 200) {
                //@ts-ignore
                setPendingRequests(data.content?.map((item: any) => {
                    return {//todo WS
                        created: item.created?.replace(/-/g, "/"),
                        parValuePerStock: item.parValuePerStock,
                        registeredCapital: item.registeredCapital,
                        totalNumberOfStocks: item.totalNumberOfStocks,
                        investorLimit: item.investorLimit,
                        memberLimit: item.memberLimit,
                        nonMemberOfferingPeriod: item.nonMemberOfferingPeriod,
                        nonMemberInvestorOfferingPeriod: item.nonMemberInvestorOfferingPeriod,
                        memberOfferingPeriod: item.memberOfferingPeriod,
                        nonMemberLimit: item.nonMemberLimit,
                        status: item.status,
                        actions: item,
                    }
                }));
                setPendingRequestsLoading(false)
            } else {
                toast(t('notification.request.fail'), 'error');
            }
        }).catch((response) => {
            toast(response?.data?.msg || response?.data?.message, 'error');
            setPendingRequestsLoading(false);
        })
    }

    const approveRequest = () => {
        const body = {
            id: targetId, pin
        }
        return new Promise((resolve, reject) => {
            setRequestInProgress(true);
            //@ts-ignore
            authedApiState.apiContainer?.api.approvePendingDetail(body).then(({data, status}) => {
                if (status === 200) {
                    toast(t('dashboard:notification.request.approve.success'), 'success');
                    resolve(data);
                } else {
                    toast(t('dashboard:notification.request.approve.fail'), 'error');
                    reject(data);
                }
                setRequestInProgress(false);
            }).catch((response: any) => {
                toast(response.data?.message, 'error');
                reject(response);
                setRequestInProgress(false);
            })
        })
    }

    const rejectRequest = () => {
        setRequestInProgress(true);
        return new Promise((resolve, reject) => {
            const body = {
                id: targetId,
                reason: rejectReason,
                pin: pin
            }
            authedApiState.apiContainer?.api.rejectPendingDetail(body).then(({data, status}) => {
                if (status === 200) {
                    toast(t('dashboard:notification.request.approve.success'), 'success');
                    resolve(data);
                } else {
                    toast(t('dashboard:notification.request.approve.fail'), 'error');
                    reject(data);
                }
                setRequestInProgress(false);
            }).catch((response: any) => {
                toast(response.data?.message, 'error');
                reject(response)
                setRequestInProgress(false);
            })
        })
    }

    const pendingRequestsColumn: ColumnProps<Record<string, string>>[] = [
        {
            title: 'تاریخ‌درخواست',
            key: 'created',
            dataIndex: 'created',
            sorter: true
        },
        {
            title: 'ارزش سهمی',
            dataIndex: 'parValuePerStock',
            key: 'parValuePerStock',
            sorter: true
        },
        {
            title: 'سرمایه ثبتی (ریال)',
            dataIndex: 'registeredCapital',
            key: 'registeredCapital',
            sorter: true
        },
        {
            title: 'تعداد کل سهام',
            dataIndex: 'totalNumberOfStocks',
            key: 'totalNumberOfStocks',
        },
        // {
        //     title: 'سهام عضو',
        //     dataIndex: 'memberLimit',
        //     key: 'memberLimit',
        // },
        // {
        //     title: 'سهام غیرعضو',
        //     dataIndex: 'nonMemberLimit',
        //     key: 'nonMemberLimit',
        // },
        // {
        //     title: 'سهام سرمایه‌گذار',
        //     dataIndex: 'investorLimit',
        //     key: 'investorLimit',
        // },
        {
            title: 'جزئیات',
            dataIndex: 'actions',
            key: 'actions',
            render: (item) => (
                <Button type={"text"} onClick={() => {
                    setInReview(item);
                    setModalVisible(true)
                }}>
                    <FontAwesomeIcon icon={faEye}/>
                </Button>
            )
        },
        {
            title: 'اقدامات',
            key: 'actions',
            dataIndex: 'actions',
            render: (item) => (
                <>
                    {
                        item.status === 'PENDING' ? (
                            <TableActions onApprove={approveRequest}
                                          onReject={rejectRequest}
                                          onView={() => {
                                          }}
                                          approveParams={{}}
                                          rejectParams={[]}
                                          viewParams={{}}
                                          approveBtnDsbl={item.status === 'APPROVED'}
                                          rejectBtnDsbl={item.status === 'REJECT'}
                                          record={item}
                                          hasViewBtn={false}
                                          viewBtnDsbl={true}
                                          id={item.id}
                                          hasRejectReason={false}
                                          onFinish={reloadTable}
                                          onApproveClick={() => {
                                              setSmsConfirmModal(true);
                                              setTargetId(item.id);
                                              setPin('');
                                          }}
                                          onRejectClick={() => {
                                              setSmsRejectModal(true);
                                              setTargetId(item.id);
                                              setPin('');
                                              setRejetReason('');
                                          }}
                            />
                        ) : ""
                    }
                    {
                        item.status === 'APPROVED' ? (
                            <span>
                                تایید شده
                            </span>
                        ) : ""
                    }
                    {
                        item.status === 'REJECTED' ? (
                            <span>
                                رد شده
                            </span>
                        ) : ""
                    }

                </>
            )
        }
    ]

    const getReviewValue = (key: string) => {
        if (key.includes("OfferingPeriod")) {
            // @ts-ignore
            return inReview[key]?.to.replace(/-/g, "/") + " - " + inReview[key]?.from.replace(/-/g, "/")
        } else if (key === "status") {
            if (inReview[key] === "PENDING") return "در انتظار تایید";
            else if (inReview[key] === "APPROVED") return "تایید شده";
            else if (inReview[key] === "REJECT") return "رد شده"
        } else if (key === "proceedingsDate" || key === "created") {
            //@ts-ignore
            return inReview[key].replace(/-/g, "/")
        } else {
            //@ts-ignore
            if(inReview[key]===undefined||inReview[key]===null||inReview[key]==="") return "-----"
            //@ts-ignore
            return inReview[key]
        }

    }

    const reloadTable = () => {
        //@ts-ignore
        loadPendingParamsConfirm(tableSettings);
    }

    const sendSMS = (status: "APPROVED" | "REJECTED" | "PENDING") => {
        //@ts-ignore
        setSendingSMS(true);
        authedApiState.apiContainer?.api.generatePin({id: targetId, status: status}).then(({data, status}) => {
            if (status === 200) {
                toast(t('dashboard:notification.request.approve.success'), 'success');
            } else {
                toast(t('dashboard:notification.request.approve.fail'), 'error');
            }
            setSendingSMS(false);
        }).catch((response: any) => {
            toast(response.data?.msg, 'error');
            setSendingSMS(false);
        })
    }

    useEffect(() => {
        timeLeft > 0 && setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
    }, [timeLeft])

    return (<>
        <Modal
            visible={smsConfirmModal}
            onCancel={() => {
                setSmsConfirmModal(false);
            }}
            title="تایید درخواست"
            footer={[

                <Button
                    onClick={() => {
                        setSmsConfirmModal(false)
                        setPin('');
                    }}
                >
                    لغو
                </Button>,
                <Button disabled={pin.length === 5}
                        type="primary" loading={sendingSMS}
                        onClick={() => {
                            sendSMS("APPROVED")
                        }}>
                    ارسال پیامک
                </Button>,
                <Button disabled={pin.length < 5} type="primary" loading={requestInProgress} onClick={() => {
                    approveRequest().then(() => {
                        setSmsConfirmModal(false);
                        //@ts-ignore
                        loadPendingParamsConfirm(tableSettings);
                    }).catch(() => {
                        setSmsConfirmModal(false)
                    })
                }}>
                    تایید
                </Button>
            ]}
        >
            <Row justify={"center"}>
                <Col span={24}>
                    <Input
                        value={pin}
                        maxLength={5}
                        minLength={5}
                        placeholder={"کد تایید"}
                        onChange={(event) => {
                            setPin(event.target.value)
                        }}/>
                </Col>
            </Row>
        </Modal>
        <Modal
            visible={smsRejectModal}
            onCancel={() => {
                setSmsRejectModal(false);
            }}
            title="رد درخواست"
            footer={[
                <Button
                    onClick={() => {
                        setSmsRejectModal(false);
                    }}
                >
                    لغو
                </Button>,
                <Button disabled={pin.length === 5 || (timeLeft <= timeout && timeLeft > 0)}
                        type="primary" loading={sendingSMS}
                        onClick={() => {
                            sendSMS("REJECTED")
                            setTimeLeft(timeout)
                        }}>
                    ارسال پیامک
                    {timeLeft <= timeout && timeLeft > 0 ? " (" + timeLeft + ")" : ""}
                </Button>,
                <Button disabled={pin.length < 5 || rejectReason.length === 0} type="primary" danger
                        loading={requestInProgress} onClick={() => {
                    rejectRequest().then(() => {
                        setSmsRejectModal(false)
                        //@ts-ignore
                        loadPendingParamsConfirm(tableSettings);
                    }).catch(() => {
                        setSmsRejectModal(false);
                    })
                }}>
                    رد
                </Button>
            ]}
        >
            <Row gutter={[8, 8]}>
                <Col span={24}>
                    <Input
                        placeholder={"کد تایید"}
                        value={pin}
                        maxLength={5}
                        minLength={5}
                        onChange={(event) => {
                            setPin(event.target.value)
                        }}/>
                </Col>
                <Col span={24}>
                    <Input
                        placeholder={"دلیل رد درخواست"}
                        value={rejectReason}
                        onChange={(event) => {
                            setRejetReason(event.target.value)
                        }}/>
                </Col>
            </Row>
        </Modal>

        <Modal
            width={'80%'}
            onCancel={() => setModalVisible(false)}
            visible={modalVisible}
            title={"جزئیات"}
            footer={[
                <Button
                    type="primary"
                    loading={requestInProgress}
                    onClick={() => setModalVisible(false)}
                >
                    بستن
                </Button>
            ]}
        >
            {
                Object.entries(objectDictionary).map((item) => {
                    return (
                        <>
                            <Row justify={"center"}>
                                <Col lg={16} md={18} sm={24} xs={24}>
                                    <Row justify={"center"} style={{borderBottom: "1px solid #DFDFEC"}}>
                                        <Col lg={18} md={12} sm={24} xs={24}>
                                            <b>{item[1]}</b>
                                        </Col>
                                        <Col lg={6} md={12} sm={24} xs={24}
                                             style={{direction: "ltr"}}>
                                            {getReviewValue(item[0])}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </>
                    )
                })
            }
        </Modal>
        <Row gutter={[16, 16]}>
            <Col span={24} lg={24} md={24} sm={24} xs={24}>
                <div className={'dashboard-grid'}>
                    <CustomTable
                        loading={pendingRequestsLoading}
                        className='special-styled-table'
                        paginationBottom={"bottomCenter"}
                        data={pendingRequests}
                        columns={pendingRequestsColumn}
                        total={pendingRequestsTotalElements}
                        onChange={loadPendingParamsConfirm}
                        defaultSort={"requestDate"}
                    />
                </div>
            </Col>
        </Row>
    </>)
};

export default CompanyParamsConfirm;
