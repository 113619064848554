import Header from '../../layouts/Header';
import Loading from '../../layouts/loading/loading';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Styled from './styles';
import {useRouteMatch} from "react-router-dom";
// @ts-ignore
import Tabs from 'react-responsive-tabs';
import 'react-responsive-tabs/styles.css';
import {useHistory} from "react-router";
import stockState from "../Home/stockState";
import systemReport from "../Home/systemReport";
import PendingRequests from "../Identification/pendingRequests";
import ApprovedRequests from "../Identification/approvedRequests";
import OrderedRequests from "../Identification/orderedRequests";
import AllRequests from "../Identification/allRequests";
import CompanyParams from "../StockParamsSettings/companyParams";
import PendingBuyRequests from "../BuyList/pending";
import ApprovedBuyRequests from "../BuyList/approved";
import RejectedBuyRequests from "../BuyList/rejected";
import PendingSellRequests from "../SellList/pending";
import ApprovedSellRequests from "../SellList/approved";
import RejectedSellRequests from "../SellList/rejected";
import RejectedCertRequests from "../StockCertRequests/rejected";
import ApprovedCertRequests from "../StockCertRequests/approved";
import PendingCertRequests from "../StockCertRequests/pending";
import FormerSupplies from "../StockParamsSettings/formerSupplies";
import SignatureSettings from "../StockParamsSettings/signatureSettings";
import NotFound from "../../layouts/NotFound/notfound";
import useStore from "../../hooks/useStore";
import CompanyParamsConfirm from "../stockParamsConfirmation/companyParamsConfirm";
import ReceiptsRequests from "../BuyList/receipts";
import ReportsList from "../Report/reportsList";
import UsersManagment from '../UserManagment/usersManagment';

const Dashboard = () => {
        const {t} = useTranslation('dashboard');
        const [loading, setLoading] = useState(true);
        const [store] = useStore();
        useEffect(() => {
            setTimeout(() => {
                setLoading(false)
            }, 1000);
        }, []);
        const match = useRouteMatch<{ tab1: string, tab2: string }>()
        const history = useHistory()
        const routes = [
            {
                id: 'dashboard', title: 'پیشخوان',
                component: () => <></>,
                children: [
                    {id: 'stockState', title: "کلیات آمار سهام و متقاضیان و سهام‌داران", component: stockState},
                    {id: 'systemReport', title: "گزارش عملکرد سامانه", component: systemReport}
                ],
            },
            {
                id: 'identification', title: 'تأیید هویت',
                component: () => <></>,
                children: [
                    {id: 'identityPendingRequests', title: "درخواست‌های جدید", component: PendingRequests},
                    {id: 'identityApprovedRequests', title: "تایید شده", component: ApprovedRequests},
                    {id: 'identityOrderedRequests', title: "درخواست با پیش‌خرید", component: OrderedRequests},
                    {id: 'identityAllRequests', title: "همه درخواست ها", component: AllRequests},
                ],
            },
            {
                id: 'stockParamsSettings', title: "تنظیمات",
                component: () => <></>,
                children: [
                    {id: 'companyParamsSettings', title: "پارامترهای سهام", component: CompanyParams},
                    {id: 'formerSupplies', title: "عرضه‌های پیشین", component: FormerSupplies},
                    {id: 'signatureSettings', title: "تنظیمات امضا", component: SignatureSettings},
                ],
            },
            {
                id: 'buyList', title: "لیست خرید",
                component: () => <></>,
                children: [
                    {id: 'pendingBuyRequests', title: "درخواست‌های جدید", component: PendingBuyRequests},
                    {id: 'approvedBuyRequests', title: "تایید شده", component: ApprovedBuyRequests},
                    {id: 'rejectedBuyRequests', title: "درخواست‌های رد شده", component: RejectedBuyRequests},
                    // {id: 'receiptBuyRequests', title: "فیش های بانکی", component: ReceiptsRequests},
                ],
            },
            {
                id: 'sellList', title: "لیست بازخرید",
                component: () => <></>,
                children: [
                    {id: 'pendingSellRequests', title: "درخواست‌های جدید", component: PendingSellRequests},
                    {id: 'approvedSellRequests', title: "تایید شده", component: ApprovedSellRequests},
                    {id: 'rejectedSellRequests', title: "درخواست‌های رد شده", component: RejectedSellRequests},
                ], 
            },
            {
                id: 'stockCertRequest', title: "درخواست‌های برگ سهام",
                component: () => <></>,
                children: [
                    {id: 'pendingCertRequests', title: "درخواست‌های جدید", component: PendingCertRequests},
                    {id: 'approvedCertRequests', title: "تایید شده", component: ApprovedCertRequests},
                    {id: 'rejectedCertRequests', title: "درخواست‌های رد شده", component: RejectedCertRequests},
                ],
            },
            {
                id: 'stockParamsConfirm', title: "تنظیم پارامترهای سهام",
                component: () => <></>,
                children: [
                    {id: 'companyParamsConfirm', title: "پارامترهای شرکت و عرضه", component: CompanyParamsConfirm},
                ],
            },
            {
                id: 'buyReceiptList', title: "فیش های بانکی",
                component: () => <></>,
                children: [
                    {id: 'receiptBuyRequests', title: "فیش های بانکی اخیر", component: ReceiptsRequests},
                ],
            }
            ,
            {
                id: 'reports', title: "گزارش گیری",
                component: () => <></>,
                children: [
                    {id: 'usersReports', title: "کاربران", component: ReportsList},
                ],
            }
            ,
            {
                id: 'userManagment', title: "مدیریت کاربران",
                component: () => <></>,
                children: [
                    {id: 'usersManagment', title: "مدیریت کاربران", component: UsersManagment},
                ],
            }
        ]
        //1622410577
        /*
        * //مدیرعامل
        CEO,

        // ادمین
        ADMIN,

        //پشتبانی
        SUPPORT,

        //وزارت خانه
        MINISTRY,

        //رییس هیت مدیره
        CHAIRMAN,

        //حساب شرکت
        COMPANY,

        //کاربر عادی
        USER;
        * */
        const userTabAccess = (key: string) => {
            switch (key) {
                case 'dashboard':
                    return store.role === 'CEO'
                        || store.role === 'SUPPORT'
                        || store.role === "CHAIRMAN"
                        || store.role === "MINISTRY"
                        || store.role === "ADMIN"
                        || store.role === "COMPANY";

                case 'reports':
                    return store.role === 'CEO' || store.role === "ADMIN";
                    case 'userManagment':
                        return store.role === 'CEO' || store.role === "ADMIN"|| store.role === "COMPANY";
                case 'identification':
                    return store.role === 'CEO' || store.role === 'SUPPORT'|| store.role === "ADMIN"
                    || store.role === "COMPANY";
                case 'buyList':
                case 'sellList':
                case 'stockCertRequest':
                    return store.role === 'CEO' || store.role === "MINISTRY" || store.role === "ADMIN"
                    || store.role === "COMPANY";
                case 'buyReceiptList':
                    return store.role === 'CEO' || store.role === "MINISTRY" || store.role === "ADMIN"
                    || store.role === "COMPANY";
                case 'stockParamsSettings':
                    return store.role === 'CEO' || store.role === "ADMIN";

                case 'stockParamsConfirm':
                    return store.role === 'CHAIRMAN';
            }
        }

        const userSubTabAccess = (id: string) => {
            switch (id) {
                case 'stockState':
                    return store.role === 'CEO'
                        || store.role === 'SUPPORT'
                        || store.role === 'CHAIRMAN'
                        || store.role === 'MINISTRY'
                        || store.role === "ADMIN"
                        || store.role === "COMPANY"

                case 'identityPendingRequests':
                case 'identityApprovedRequests':
                case 'identityOrderedRequests':
                case 'identityAllRequests':
                    return store.role === 'CEO' || store.role === 'SUPPORT' || store.role === "ADMIN"
                    || store.role === "COMPANY" 
                    case 'usersManagment':
                        return store.role === 'CEO' || store.role === "ADMIN"
                
                case 'usersReports':
                    return store.role === 'CEO'|| store.role === "ADMIN"

                case 'receiptBuyRequests':
                case 'systemReport':
                    return store.role === 'CEO'
                    || store.role === "ADMIN"
                    || store.role === "COMPANY"

                case 'companyParamsSettings':
                case 'formerSupplies':
                 case 'signatureSettings':
                    case 'companyForm':
                    return store.role === 'CEO' || store.role === "ADMIN"

                case 'supplyParamsSettings':
                
                
               
                case 'pendingCertRequests':
                case 'rejectedCertRequests':
                case 'pendingSellRequests':
                case 'rejectedSellRequests':    
                case 'rejectedBuyRequests':
                case 'pendingBuyRequests':
                case 'approvedBuyRequests':
                case 'approvedSellRequests':
                case 'approvedCertRequests':
                    return store.role === 'CEO' || store.role === 'MINISTRY'  || store.role === "ADMIN"
                    || store.role === "COMPANY";


                case 'companyParamsConfirm':
                case 'supplyParamsConfirm':
                    return store.role === 'CHAIRMAN'

                default:
                    return false; 
            }
        } 
        function getTabs(parent?: string) {
            if (parent) {
                return routes.find((item) => item.id === parent)?.children.filter(({id}) => userSubTabAccess(id)).map((tab: any, index: number) => {
                        if (index == 0 && !match.params.tab2)
                            history.push(`/${parent}/${tab.id}`);
                        return {
                            title: tab.title,
                            // @ts-ignore
                            getContent: () => (
                                <div style={{paddingTop: '40px'}}>
                                    <tab.component/>
                                </div>),
                            key: tab.id,
                            tabClassName: 'tab',
                            panelClassName: 'panel',
                        }
                    }
                )
            }
            return routes.filter(({id}) => userTabAccess(id)).map((tab) => (
                {
                    title: tab.title,
                    getContent: () => {
                        if (tab.children && tab.children.length > 0) {
                            const routes = getTabs(tab.id);
                            if (routes?.filter(({key}) => userSubTabAccess(key)).find((route) => route.key === match.params.tab2)) {
                                return (<Tabs
                                    tabsWrapperClass='child-tab-bar'
                                    items={routes}
                                    transform={false}
                                    selectedTabKey={match.params.tab2}
                                    onChange={(key: any) => {
                                        history.push(`/${tab.id}/${key}`);
                                    }}
                                />)
                            } else {
                                return <NotFound/>
                            }
                        } else {
                            return <tab.component/>
                        }
                    },
                    key: tab.id,
                    tabClassName: 'tab',
                    panelClassName: 'panel',
                }
            ));
        }


        return (
            <Styled.Container>
                <Header
                    noPadding={false}
                    title={{color: t('title.color')}}
                    subtitle={t('subtitle')}
                    star={true}
                />
                {loading ? <Loading/> :
                    (routes.filter(({id}) => userTabAccess(id)).find((route) => route.id === match.params.tab1) ?
                            <Tabs
                                tabsWrapperClass='main-tab-bar'
                                items={getTabs()}
                                transform={false}
                                selectedTabKey={match.params.tab1}
                                onChange={(key: any) => {
                                    history.push(`/${key}`);
                                }}
                            /> :
                            <NotFound/>
                    )
                }
            </Styled.Container>
        );
    }
;

export default Dashboard;
