import React, {useEffect, useRef, useState} from 'react';
import {Row, Col, Form, FormInstance, Button, Input} from 'antd';
import DashboardStatItem from "../../components/DashboardStatItem";
import useStore from "../../hooks/useStore";
import {useTranslation} from "react-i18next";
import {Calendar} from "antd-jalali";
import moment from "jalali-moment";
import {TotalStockStatistics} from "../../api/api";
import NumberInput from "../../components/NumberInput";
import useToast from "../../hooks/useToast";
import dayjs from "dayjs";
import jalaliday from "jalaliday";
import Donut from '../../components/Donut';
import RangeInput from "../../components/RangeInput";
import DateInput from "../../components/DateInput";
import styled, { css } from "styled-components";
import CustomTable from '../../components/Table';
import {ColumnProps} from "antd/lib/table/Column";
import TableActions from '../../components/TableActions';

dayjs.extend(jalaliday)
dayjs('jalali')
function SubComponent({chartData}: { chartData: { name: string, y: number, color: string }[] }) {
    return (
        <div>
            <Donut data={chartData}/>
        </div>
    );
}
const Chart = React.memo(SubComponent);
const CompanyParams = () => {
    const {t} = useTranslation('parameters');

    const [companyParams, setCompanyParams] = useState<any>([]);
    const [chartData, setChartData] = useState<{ name: string, y: number, color: string }[]>([]);
    const [stockStatsLoading, setStockStatsLoading] = useState<any>([]);
    const [authedApiState] = useStore();
    const [paramsSubmitted, setParamsSubmitted] = useState<boolean>(false);
    const [editInformation,setEditInformation] = useState<any>();

    // const [paramsFields, setParamsFields] = useState<{
    //     parValuePerStock: number,
    //     totalNumberOfStocks: number, registeredCapital: number
    // }>({
    //     parValuePerStock: 0,
    //     totalNumberOfStocks: 0,
    //     registeredCapital: 0
    // })

    const [store] = useStore();
    const formRef = useRef<FormInstance>(null);
    const toast = useToast()

    const submitSupplyParams = () => {
        const {
            id,
            parValuePerStock,
            totalNumberOfStocks,
            registeredCapital,
            availableStocks,
            stockSupplyValue,
            nonMemberOfferingPeriod,
            nonMemberInvestorOfferingPeriod,
            memberOfferingPeriod,
            nonMemberInvestorReturnOnStock,
            nonMemberPremiumOnStock,
            nonMemberInvestorPremiumOnStock,
            investmentContractCommissionFee,
            earningsPerShare,
            dividendsPerShare,
            investmentPeriod,
            proceedingsDate,
            depositRepaymentInstallments,
            assemblyProfit,
            loan,
             investmentBaseAmount
        } =
            formRef?.current?.getFieldsValue();
        const formData = {
            id:editInformation?.id ?editInformation?.id :id,
            parValuePerStock,
            /** @format int32 */
            totalNumberOfStocks,
            registeredCapital,
            /** @format int32 */
            availableStocks,
            stockSupplyValue,
            nonMemberOfferingPeriod: {
                from: dayjs(nonMemberOfferingPeriod[0]).unix() * 1000,
                to: dayjs(nonMemberOfferingPeriod[1]).unix() * 1000
            },
            nonMemberInvestorOfferingPeriod: {
                  from: dayjs(nonMemberOfferingPeriod[0]).unix() * 1000,
                   to: dayjs(nonMemberOfferingPeriod[1]).unix() * 1000
                },
            // nonMemberInvestorOfferingPeriod: {
            //     from: dayjs(nonMemberInvestorOfferingPeriod[0]).unix() * 1000,
            //     to: dayjs(nonMemberInvestorOfferingPeriod[1]).unix() * 1000
            // },
            memberOfferingPeriod: {
                from: dayjs(memberOfferingPeriod[0]).unix() * 1000,
                to: dayjs(memberOfferingPeriod[1]).unix() * 1000
            },//offeringPeriod: {from: dayjs(offeringPeriod[0]).unix() * 1000, to: dayjs(offeringPeriod[1]).unix() * 1000},
           nonMemberInvestorReturnOnStock:0,
         nonMemberPremiumOnStock:0,
            nonMemberInvestorPremiumOnStock:0,
           investmentContractCommissionFee:0,
            earningsPerShare:0,
          dividendsPerShare:0,
            /** @format int32 */
           investmentPeriod:0,
            proceedingsDate: dayjs(proceedingsDate).format('YYYY-MM-DD').toString(),
           loan:0,
           depositRepaymentInstallments:0, //TODO replace with correct value
            assemblyProfit, //TODO replace with correct value،
          investmentBaseAmount:0
        }
          //@ts-ignore
        store.apiContainer?.api.addCompanyDetail(formData).then(({data, status}) => {
            if (status === 200) {
                // setParamsSubmitted(true);
                toast(t('notification.request.approve.success'), 'success');
                loadPendingRequests();
                loadStockParams();
            } else {
                toast(t('notification.request.approve.fail'), 'error');
            }
        }).catch((response: any) => {
            toast(response.data?.msg, 'error');
        })
    }
   
    useEffect(() => {
        loadStockParams();
        loadStockStats();
    }, [])

    let _chartData = [
        {
            name: "سرمایه‌گذار",
            y: 0,
            color: "#008BFF"
        },
        {
            name: "عضو",
            y: 0,
            color: "#F461B7"
        },
        {
            name: "غیرعضو",
            y: 0,
            color: "#9C9CBD"
        }
    ]

    const setChartDataFn = (data: TotalStockStatistics) => {
        _chartData[0].y = data.free;
        _chartData[1].y = data.total;
        _chartData[2].y = data.registered;
        setChartData(_chartData);
    } 

    const loadStockStats = () => {
        setStockStatsLoading(true)
        authedApiState.apiContainer?.api.tokenStatistics().then(({data, status}) => {
            if (status === 200) {
                setChartDataFn(data);
                setStockStatsLoading(false);
            } else {
                toast(t('notification.request.approve.fail'), 'error');
            }
        }).catch((response: any)=>{
            toast(response.data?.message, 'error');
        })
    }

    const loadStockParams = () => {
        setCompanyParams([])
        authedApiState.apiContainer?.api.companyDetailCurrentApproved().then(({data, status}) => {
            if (status === 200 && data) {
                setCompanyParams(data);
                const obj = {...data}
                const parValuePerStock = obj.parValuePerStock;
                const registeredCapital = obj.registeredCapital;
                const totalNumberOfStocks = obj.totalNumberOfStocks;
            } else {
                toast(t('notification.request.approve.fail'), 'error');
            }
        })
    }

    /////عملیات مربوط به تب تنظیم پارامترههای سهام
      interface TabProps {
        active?: boolean;
      }
      const Tab = styled.button<TabProps>`
      font-size: 20px;
      padding: 10px 60px;
      cursor: pointer;
      opacity: 0.6;
      background: white;
      border: 0;
      outline: 0;
    
      ${({ active }) =>
        active &&
        css`
          border-bottom: 2px solid #5D55A1;
          opacity: 1;
        `}
    
      @media (max-width: 768px) {
        font-size: 16px;
        padding: 5px 30px;
      }
    `;
    
    const ButtonGroup = styled.div`
      display: flex;
      margin-right: 20px;
      flex-wrap: wrap; /* اضافه کردن خط اضافی */
    
      @media (max-width: 768px) {
        margin-right: 0;
        flex-direction: column;
        align-items: center;
      }
    `;

    const types = ["تنظیمات سهامدار عضو و غیرعضو", "تنظیمات سهامدار غیرعضو سرمایه گذار"];
    const [active, setActive] = useState(types[0]);

    ////////////////////////////////////برای عملیات تنظیمات سهام
    const [pendingRequests, setPendingRequests] = useState<any[]>([]);
    const [allRequestsLoading, setAllRequestsLoading] = useState<boolean>(true);

    const deleteRequest = (id: string) => {
            authedApiState.apiContainer?.api.deleteCompanyDetail(id).then(({data, status}) => {
                if (status === 200) {
                    setPendingRequests((prevRequests) => prevRequests.filter((request) => request.id !== id));
                    toast(t('notification.request.approve.success'), 'success');
                    loadPendingRequests()
                } else {
                    toast(t('notification.request.approve.fail'), 'error');
                }
            }).catch((response: any) => {
                toast(response.data?.message, 'error'); 
            })     
    }

    const approveRequest = (id: string) => {
        return new Promise((resolve, reject) => {

            authedApiState.apiContainer?.api.approvedCompanyDetail(id).then(({data, status}) => {
                if (status === 200) {
                    resolve(data);
                    toast(t('notification.request.approve.success'), 'success');
                } else {
                    toast(t('notification.request.approve.fail'), 'error');
                    reject(data);
                }
            }).catch((response: any) => {
                toast(response.data?.msg, 'error');
                reject(response);
            })
        })
    }

    const rejectedRequest = (id: string) => {
        return new Promise((resolve, reject) => {

            authedApiState.apiContainer?.api.rejectedCompanyDetail(id).then(({data, status}) => {
                if (status === 200) {
                    resolve(data);
                    toast(t('notification.request.approve.success'), 'success');
                } else {
                    toast(t('notification.request.approve.fail'), 'error');
                    reject(data);
                }
            }).catch((response: any) => {
                toast(response.data?.msg, 'error');
                reject(response);
            })
        })
    }
  
    const loadPendingRequests = () => {
        setAllRequestsLoading(true)
        authedApiState.apiContainer?.api.listCompanyDetail().then(({data, status}) => {
        if (status === 200) {
        //@ts-ignore
        setPendingRequests(data?.map((item: any) => {
        return {
        created: item.created?.toString().replace(/-/g, "/"),
        parValuePerStock: item.parValuePerStock,
        totalNumberOfStocks:item.totalNumberOfStocks,
        registeredCapital:item.registeredCapital,
        stockSupplyValue:item.stockSupplyValue,
        availableStocks:item.availableStocks,
        actions: item
        }
        }));
        } else {
        toast(t('notification.request.fail'), 'error');
        }
        setAllRequestsLoading(false)
        }).catch((response) => {
        toast(response?.data?.msg || response?.data?.message, 'error');
        setAllRequestsLoading(false);
        })
        };

    const RequestsColumnCompany: ColumnProps<Record<string, string>>[] = [
            {
                title: 'تاریخ‌ ایجاد',
                key: 'created',
                dataIndex: 'created',
                sorter: true
            },
            {
                title: 'ارزش سهمی',
                dataIndex: 'parValuePerStock',
                key: 'parValuePerStock',
            },
            {
                title: 'تعداد کل سهام',
                dataIndex: 'totalNumberOfStocks',
                key: 'totalNumberOfStocks',
                responsive: ['xxl', 'xl', 'lg', 'sm']
            },
            {
                title: 'سرمایه ثبت شده',
                key: 'registeredCapital',
                dataIndex: 'registeredCapital',
                responsive: ['xxl', 'xl', 'lg', 'sm']
            },
            {
                title: 'ارزش عرضه سهام',
                key: 'stockSupplyValue',
                dataIndex: 'stockSupplyValue',
                responsive: ['xxl', 'xl', 'lg', 'sm']
            },
            {
                title: ' سهام موجود',
                key: 'availableStocks',
                dataIndex: 'availableStocks',
                responsive: ['xxl', 'xl', 'lg', 'sm'],
            },
 {
            title: 'اقدامات',
            key: 'actions',
            dataIndex: 'actions',
            render: (item) => (
                <TableActions onApprove={approveRequest}
                              onReject={rejectedRequest}
                              onView={() => {}}
                              approveParams={{}}
                              rejectParams={[]}
                              viewParams={{}}
                              approveBtnDsbl={false}
                              hasViewBtn={false} 
                              record={item}
                              rejectBtnDsbl={false}
                              viewBtnDsbl={false}
                              id={item.id}
                              onFinish={()=>console.log("a")}
                              hasDeleteBtns={true}
                              onDelete={deleteRequest}
                              hasEjectForCompany={true}  
                />
            )
        }
        ]

     useEffect(()=>(loadPendingRequests()),[])

    return (
        <>
            <Form ref={formRef} onFinish={() => submitSupplyParams()}>
                <Row gutter={[16, 16]}>
                    <Col span={6} lg={6} md={12} sm={24} xs={24}>
                        <div className={'dashboard-grid'}>
                            <DashboardStatItem topTitle={t('report.parValuePerStock')}
                                               content={companyParams.parValuePerStock?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                               subtitle={""}
                                               loading={stockStatsLoading}
                            />
                        </div>
                    </Col>
                    <Col span={6} lg={6} md={12} sm={24} xs={24}>
                        <div className={'dashboard-grid'}>
                            <DashboardStatItem topTitle={t('report.totalNumberOfStocks')}
                                               content={companyParams.totalNumberOfStocks?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                               subtitle={" مصوب جلسه " + companyParams.proceedingsDate?.replace(/-/g, "/")}
                                               loading={stockStatsLoading}
                            />
                        </div>
                    </Col>
                    <Col span={6} lg={6} md={12} sm={24} xs={24}>
                        <div className={'dashboard-grid'}>
                            <DashboardStatItem
                                topTitle={t('report.registeredCapital')}
                                content={companyParams.registeredCapital?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                subtitle={""}
                                loading={stockStatsLoading}
                            />
                        </div>
                    </Col>
                    <Col span={6} lg={6} md={12} sm={24} xs={24}>
                        <div className={'dashboard-grid'}>
                            <div className={'header'}>
                                {t('report.calendar')}
                            </div>
                            <Calendar
                                fullscreen={false}
                                headerRender={({value}: { value: moment.Moment }) =>
                                    <div style={{
                                        width: '120px',
                                        paddingTop: '10px',
                                        float: 'left',
                                    }}>
                                        {
                                            moment(value).locale('fa').format('MMMM') + ' ' +
                                            moment(value).locale('fa').format('YYYY')
                                        }
                                    </div>
                                }/>
                        </div>
                    </Col>
                </Row>
                <br/>

                {/*<Row gutter={[16, 16]}>
                    <Col span={24} lg={24} md={24} sm={24} xs={24}>
                        <div className={'dashboard-grid'}>
                            <Row className={'header2'}>
                                <Col span={24} lg={5} md={5} sm={5} xs={5}>
                                    {t('report.settings')}
                                </Col>
                                <Col span={24} lg={18} md={18} sm={18} xs={18}
                                     style={{borderBottom: "1px solid #e9e9f0", height: "10px"}}/>
                            </Row>
                            <Row justify={"center"}>
                                <Col span={24} lg={24} md={24} sm={24} xs={24}>
                                    <div style={{
                                        padding: "15px"
                                    }}>
                                        <Chart chartData={chartData}/>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>*/}

                {
                    paramsSubmitted ? (
                        <Row justify={"center"} align={"middle"} style={{marginTop: '16px'}}>
                          
                            <Col span={24}>
                                
                                <div className={"dashboard-grid"} style={{padding: "15px", position: "relative"}}>
                                
                                    <div style={{
                                        position: "absolute",
                                        top: "50%",
                                        right: "50%",
                                        transform: "translate(50%,-50%)"
                                    }}>
                                        <Row justify={"center"} align={"middle"}>
                                            <Col>
                                                <h2 style={{color: "green"}}>پارامترها با موفقیت ثبت شد</h2>
                                            </Col>
                                        </Row>
                                        <Row justify={"center"} align={"middle"}>
                                            <Col>
                                                <Button type={"primary"}
                                                        onClick={() => setParamsSubmitted(false)}>بازگشت</Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    ) : (
                        <Row style={{marginTop: '16px'}}>
                           
                            <Col span={24}>
                                <div className={"dashboard-grid"} style={{padding: "15px"}}>
                                
                                    <Row className={'header2'} style={{marginBottom: '40px'}}>

                                        <Col span={24} lg={5} md={5} sm={5} xs={5}>
                                            {t('report.stockSettings')}
                                        </Col>
                                        <Col span={24} lg={18} md={18} sm={18} xs={18}
                                             style={{borderBottom: "1px solid #e9e9f0", height: "10px"}}/>
                                                <ButtonGroup>
                                            {types.map((type) => (
                                            <Tab
                                                key={type}
                                                active={active === type}
                                                onClick={() => setActive(type)}
                                            >
                                                {type}
                                            </Tab>
                                            ))}
                                        </ButtonGroup>
                                   </Row>
                                    <Row gutter={[20, 20]}>
                                        {/*<Col lg={8} sm={24} xs={24}>
                                            <Form.Item
                                                name="memberLimit"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'این فیلد مورد نیاز است!',
                                                    },
                                                ]}
                                                hasFeedback
                                            >
                                                <NumberInput withCommas={true} label={'تعداد سهام عضو:'}/>
                                            </Form.Item>
                                        </Col>
                                        <Col lg={8} sm={24} xs={24}>
                                            <Form.Item
                                                name="nonMemberLimit"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'این فیلد مورد نیاز است!',
                                                    },
                                                ]}
                                                hasFeedback
                                            >
                                                <NumberInput withCommas={true} label={'تعداد سهام غیرعضو:'}/>
                                            </Form.Item>
                                        </Col>
                                        <Col lg={8} sm={24} xs={24}>
                                            <Form.Item
                                                name="investorLimit"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'این فیلد مورد نیاز است!',
                                                    },
                                                ]}
                                                hasFeedback
                                            >
                                                <NumberInput withCommas={true} label={'تعداد سهام سرمایه‌گذار:'}/>
                                            </Form.Item>
                                        </Col>*/}
                                    {active ===types[0] && 
                                      <>
                                       <Form.Item   
                                                    name="id"
                                                    valuePropName={editInformation?.id}
                                                >
                                      <Input type='hidden'  value={editInformation?.id}/>
                                      </Form.Item>
                                          <Col lg={8} sm={24} xs={24}>
                                                <Form.Item
                                                    name="parValuePerStock"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'این فیلد مورد نیاز است!',
                                                        },
                                                    ]}
                                                    hasFeedback    
                                                >
                                                    <NumberInput  withCommas={true} label={'ارزش سهمی (ریال):'}  />
                                                </Form.Item>
                                            </Col><Col lg={8} sm={24} xs={24}>
                                                    <Form.Item
                                                        name="totalNumberOfStocks"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'این فیلد مورد نیاز است!',
                                                            },
                                                        ]}
                                                        hasFeedback
                                                    >
                                                        <NumberInput withCommas={true} label={'تعداد کل سهام:'} />   
                                                    </Form.Item>
                                                </Col><Col lg={8} sm={24} xs={24}>
                                                    <Form.Item
                                                        name="registeredCapital"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'این فیلد مورد نیاز است!',
                                                            },
                                                        ]}
                                                        hasFeedback
                                                    >
                                                        <NumberInput withCommas={true} label={'سرمایه ثبتی (ریال):'} />
                                                    </Form.Item>
                                                </Col><Col lg={8} sm={24} xs={24}>
                                                    <Form.Item
                                                        name="proceedingsDate"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'این فیلد مورد نیاز است!',
                                                            },
                                                        ]}
                                                        hasFeedback
                                                    >
                                                        <DateInput className={"params-settings-datepicker"}
                                                            label={"تاریخ مصوب صورتجلسه:"} />
                                                    </Form.Item>
                                                </Col><Col lg={8} sm={24} xs={24}>
                                                    <Form.Item
                                                        name="availableStocks"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'این فیلد مورد نیاز است!',
                                                            },
                                                        ]}
                                                        hasFeedback
                                                    >
                                                        <NumberInput withCommas={true} label={'تعداد سهام قابل عرضه:'} />
                                                    </Form.Item>
                                                </Col><Col lg={8} sm={24} xs={24}>
                                                    <Form.Item
                                                        name="stockSupplyValue"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'این فیلد مورد نیاز است!',
                                                            },
                                                        ]}
                                                        hasFeedback
                                                    >
                                                        <NumberInput withCommas={true} label={'ارزش عرضه سهام (ریال):'} />
                                                    </Form.Item>
                                                </Col><Col lg={8} sm={24} xs={24}>
                                                    <div style={{
                                                        border: "1px solid rgb(208, 201, 223)",
                                                        borderRadius: "5px",
                                                    }}>
                                                        <Form.Item
                                                            name="nonMemberOfferingPeriod"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'این فیلد مورد نیاز است!',
                                                                },
                                                            ]}
                                                            hasFeedback
                                                        >
                                                            <RangeInput className={"params-settings-rangepicker"}
                                                                label={"بازه عرضه برای سهامدار غیر عضو:"} />
                                                        </Form.Item>
                                                    </div>
                                                </Col>
                                                <Col lg={8} sm={24} xs={24}>
                                            <div style={{
                                                border: "1px solid rgb(208, 201, 223)",
                                                borderRadius: "5px",
                                            }}>
                                                <Form.Item
                                                    name="memberOfferingPeriod"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'این فیلد مورد نیاز است!',
                                                        },
                                                    ]}
                                                    hasFeedback
                                                >
                                                    <RangeInput className={"params-settings-rangepicker"}
                                                                label={"بازه عرضه برای سهامدار عضو:"}
                                                                />
                                                </Form.Item>
                                            </div>
                                        </Col> 
                                                </>
                                        }
                {active ===types[1] &&
                    <><Col lg={8} sm={24} xs={24}>
                                                    <div style={{
                                                        border: "1px solid rgb(208, 201, 223)",
                                                        borderRadius: "5px",
                                                    }}>
                                                        <Form.Item
                                                            name="nonMemberInvestorOfferingPeriod"
                                                            rules={[
                                                                {
                                                                    required: false,
                                                                    message: 'این فیلد مورد نیاز است!',
                                                                },
                                                            ]}
                                                            hasFeedback
                                                        >
                                                            <RangeInput className={"params-settings-rangepicker"}
                                                                label={"بازه عرضه برای سهامدار غیر عضو سرمایه گزار:"}
                                                                disable={true} />
                                                        </Form.Item>
                                                    </div>
                                                </Col><Col lg={8} sm={24} xs={24}>
                                                        <Form.Item
                                                            name="nonMemberInvestorReturnOnStock"
                                                            rules={[
                                                                {
                                                                    required: false,
                                                                    message: 'این فیلد مورد نیاز است!',
                                                                },
                                                            ]}
                                                            hasFeedback
                                                        >
                                                            <NumberInput withCommas={true}
                                                                label={'ارزش افزوده سالیانه سرمایه گذاری (درصد):'}
                                                                disable={true} />
                                                        </Form.Item>
                                                    </Col><Col lg={8} sm={24} xs={24}>
                                                        <Form.Item
                                                            name="nonMemberInvestorPremiumOnStock"
                                                            rules={[
                                                                {
                                                                    required: false,
                                                                    message: 'این فیلد مورد نیاز است!',
                                                                },
                                                            ]}
                                                            hasFeedback
                                                        >
                                                            <NumberInput withCommas={true}
                                                                label={'صرف سهام سهام‌دار غیر عضو سرمایه‌گذار (ریال):'}
                                                                disable={true} />
                                                        </Form.Item>
                                                    </Col><Col lg={8} sm={24} xs={24}>
                                                        <Form.Item
                                                            name="nonMemberPremiumOnStock"
                                                            rules={[
                                                                {
                                                                    required: false,
                                                                    message: 'این فیلد مورد نیاز است!',
                                                                },
                                                            ]}
                                                            hasFeedback
                                                        >
                                                            <NumberInput withCommas={true}
                                                                label={'صرف سهام سهام‌دار غیر عضو (ریال):'}
                                                                disable={true} />
                                                        </Form.Item>
                                                    </Col><Col lg={8} sm={24} xs={24}>
                                                        <Form.Item
                                                            name="investmentContractCommissionFee"
                                                            rules={[
                                                                {
                                                                    required: false,
                                                                    message: 'این فیلد مورد نیاز است!',
                                                                },
                                                            ]}
                                                            hasFeedback
                                                        >
                                                            <NumberInput withCommas={true}
                                                                label={'کارمزد شرکت در قرارداد سرمایه گذاری (ریال):'}
                                                                disable={true} />
                                                        </Form.Item>
                                                    </Col><Col lg={8} sm={24} xs={24}>
                                                        <Form.Item
                                                            name="dividendsPerShare"
                                                            rules={[
                                                                {
                                                                    required: false
                                                                },
                                                            ]}
                                                            hasFeedback
                                                        >
                                                            <NumberInput withCommas={true} label={'درآمد به ازای هر سهم (ریال):'}
                                                                disable={true} />
                                                        </Form.Item>
                                                    </Col><Col lg={8} sm={24} xs={24}>
                                                        <Form.Item
                                                            name="earningsPerShare"
                                                            rules={[
                                                                {
                                                                    required: false
                                                                },
                                                            ]}
                                                            hasFeedback
                                                        >
                                                            <NumberInput withCommas={true} label={'درآمد سالیانه هر سهم (ریال):'}
                                                                disable={true} />
                                                        </Form.Item>
                                                    </Col><Col lg={8} sm={24} xs={24}>
                                                        <Form.Item
                                                            name="loan"
                                                            rules={[
                                                                {
                                                                    required: false,
                                                                    message: 'این فیلد مورد نیاز است!',
                                                                },
                                                            ]}
                                                            hasFeedback
                                                        >
                                                            <NumberInput withCommas={true}
                                                                label={'قرض الحسنه سرمایه گذار (ریال):'}
                                                                disable={true} />
                                                        </Form.Item>
                                                    </Col><Col lg={8} sm={24} xs={24}>
                                                        <Form.Item
                                                            name="investmentPeriod"
                                                            rules={[
                                                                {
                                                                    required: false,
                                                                    message: 'این فیلد مورد نیاز است!',
                                                                },
                                                            ]}
                                                            hasFeedback
                                                        >
                                                            <NumberInput withCommas={true} label={'ماه دوره سرمایه گذاری:'}
                                                                disable={true} />
                                                        </Form.Item>
                                                    </Col><Col lg={8} sm={24} xs={24}>
                                                        <Form.Item
                                                            name="depositRepaymentInstallments"
                                                            rules={[
                                                                {
                                                                    required: false,
                                                                    message: 'این فیلد مورد نیاز است!',
                                                                },
                                                            ]}
                                                            hasFeedback
                                                        >
                                                            <NumberInput withCommas={true}
                                                                label={'اقساط بازپرداخت قرض الحسنه (ریال):'}
                                                                disable={true} />
                                                        </Form.Item>
                                                    </Col><Col lg={2} sm={4} xs={4}>
                                                        <Row justify={'center'} align={"middle"}>
                                                            <div style={{
                                                                top: 0,
                                                                right: '70%',
                                                                transform: 'translate(50%, 70%)',
                                                                width: '100%',
                                                                height: '100%',
                                                                position: 'relative',
                                                                fontWeight: 700,
                                                                color: 'rgb(196, 196, 196)'
                                                            }}>
                                                                به ازای هر:
                                                            </div>
                                                        </Row>
                                                    </Col><Col lg={6} sm={20} xs={20}>
                                                        <Form.Item
                                                            name="investmentBaseAmount"
                                                            rules={[
                                                                {
                                                                    required: false,
                                                                    message: 'این فیلد مورد نیاز است!',
                                                                },
                                                            ]}
                                                            hasFeedback
                                                        >
                                                            <NumberInput withCommas={true} label={'میزان سرمایه‌گذاری (ریال):'}
                                                                disable={true} />
                                                        </Form.Item>
                                                    </Col><Col lg={8} sm={24} xs={24}>
                                                        <Form.Item
                                                            name="assemblyProfit" /* TODO we are not sure about this parameter */
                                                            rules={[
                                                                {
                                                                    required: false
                                                                },
                                                            ]}
                                                            hasFeedback
                                                        >
                                                            <NumberInput withCommas={true} label={'سود مجمع (ریال):'}
                                                                disable={true} />
                                                        </Form.Item>
                                                    </Col></>
                }    
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    )
                }
                {
                    paramsSubmitted ? (
                        <></>
                    ) : (
                        <Row style={{marginTop: '16px'}} justify={'center'}>
                            <Col>
                                <button type='submit' className='form-submit-button'>ذخیره تغییرات</button>
                            </Col>
                        </Row>
                    )
                }
            </Form>
            <Row gutter={[16, 16]} style={{marginTop:'20px'}}>
                <Col span={24} lg={24} md={24} sm={24} xs={24}>
                    <div className={'dashboard-grid'}>
                        <CustomTable
                            loading={allRequestsLoading}
                            className='special-styled-table'
                            paginationBottom={"bottomCenter"}
                            data={pendingRequests}
                            columns={RequestsColumnCompany}
                            onChange={loadPendingRequests}
                            defaultSort={"requestDate"}
                        />
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default CompanyParams;
