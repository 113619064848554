import { Col } from "antd";

const BtnClearFilter = ({
  handleChange,

  textBtn,
  type,
}: {
  handleChange: () => void;
  type: "remove" | "search";
  textBtn: string;
}) => {
  return (
    <>
      {/* <Col lg={2} sm={24} xs={24}> */}
      <button
        style={{
          padding: "13px 12px",
          borderRadius: "5%",
          background: `${
            type === "search"
              ? "linear-gradient(90deg, rgba(24,144,255,1) 0%, rgba(111,75,252,0.6726891440169818) 100%)"
              : "linear-gradient(90deg, rgba(156,156,189,1) 0%, rgba(217,32,57,0.8855742980786064) 100%)"
          }`, // تغییر رنگ پس زمینه
          color: "#fff", // تغییر رنگ متن
          border: "none", // حذف حاشیه
          marginRight: "18px", 
          marginTop: "2px",
          marginBottom: "2px",
        }}
        onClick={handleChange}
      >
       <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/>
  <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"/>
</svg>
      </button>
      {/* </Col> */}
      {/* <Col lg={3} sm={24} xs={24}>
        <button
          style={{
            padding: "10px 20px",
            borderRadius: "5%",
            background:
              "linear-gradient(90deg, rgba(156,156,189,1) 0%, rgba(217,32,57,0.8855742980786064) 100%)", // تغییر رنگ پس زمینه
            color: "#fff", // تغییر رنگ متن
            border: "none", // حذف حاشیه
            marginRight: "10px",
          }}
          onClick={handleChange}
        >
          {textBtn}
        </button>
      </Col> */}
    </>
  );
};

export default BtnClearFilter;
