/* eslint-disable */
/* tslint:disable */

/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { generateKeyPair } from "crypto";

export interface EditAccountRequest {
  id?: string;

  /** @format int32 */
  bankId?: number;
  sheba: string;
  cardId: string;
}

export interface BankAccountDTO {
  id?: string;

  /** @format int32 */
  bankId?: number;
  sheba?: string;
  cardId?: string;
  bankName?: string;
}

export interface SellTokenRequest {
  /** @format int32 */
  amount: number;
  transactionId: string;
}

export interface SellTokenResponse {
  id: string;

  /** @format int32 */
  amount: number;

  /** @format int64 */
  price: number;
}

export interface EditPersonalInfoRequest {
  birthDate: string;
  gender: "male" | "female";

  /** @format int32 */
  ostan: number;

  /** @format int32 */
  shahrestan: number;

  /** @format int32 */
  bakhsh?: number;

  /** @format int32 */
  dehestan?: number;
  idCardNumber: string;
  sacrificeStatus:
    | "Normal"
    | "Fighter"
    | "Veteran"
    | "CaptiveFamily"
    | "Released";
  educationLevel:
    | "Illiterate"
    | "ElementarySchool"
    | "MiddleSchool"
    | "HighSchool"
    | "Diploma"
    | "AssociateDegree"
    | "Bachelor"
    | "MA";
  phone: string;

  /** @format int32 */
  ssnAddressShahrestan: number;
  postalCode: string;
  detail: string;
  auth?: boolean;
}

export interface AddressDTO {
  ostan?: OstanDTO;
  shahrestan?: ShahrestanDTO;
  bakhsh?: BakhshDTO;
  shahr?: ShahrDTO;
  dehestan?: DehestanDTO;
  postalCode?: string;
  detail?: string;
}

export interface BakhshDTO {
  /** @format int32 */
  id: number;
  name: string;

  /** @format int32 */
  ostan: number;

  /** @format int32 */
  shahrestan: number;
}

export interface DehestanDTO {
  /** @format int32 */
  id: number;
  name: string;

  /** @format int32 */
  ostan: number;

  /** @format int32 */
  shahrestan: number;

  /** @format int32 */
  bakhsh: number;
}

export interface IDCardSerial {
  /** @format int32 */
  serial: number;
  alphabet: string;

  /** @format int32 */
  number: number;
}

export interface OstanDTO {
  /** @format int32 */
  id: number;
  name: string;
}

export interface ShahrDTO {
  /** @format int32 */
  id: number;
  name: string;

  /** @format int32 */
  ostan: number;

  /** @format int32 */
  shahrestan: number;

  /** @format int32 */
  bakhsh: number;
}

export interface ShahrestanDTO {
  /** @format int32 */
  id: number;
  name: string;

  /** @format int32 */
  ostan: number;
}

export interface UserDTO {
  id?: string;
  firstName: string;
  lastName: string;
  fatherName?: string;
  nationalCode: string;
  birthdate?: string;
  idCardNumber?: string;
  idCardSerial?: IDCardSerial;
  dutyStatus?: "AcademicExemption" | "EndOfDuty";
  maritalStatus?: "Single" | "Married";
  gender?: "male" | "female";

  /** @format int32 */
  dependentsCount?: number;
  sacrificeStatus?:
    | "Normal"
    | "Fighter"
    | "Veteran"
    | "CaptiveFamily"
    | "Released";
  educationLevel?:
    | "Illiterate"
    | "ElementarySchool"
    | "MiddleSchool"
    | "HighSchool"
    | "Diploma"
    | "AssociateDegree"
    | "Bachelor"
    | "MA";
  skills: string[];
  address?: AddressDTO;
  ssnAddress?: AddressDTO;
  companyName?: string;
  companyRegisterNumber?: string;
  companySSNumber?: string;
  companyEcoNumber?: string;
  companyRegYear?: string;
  companyType?: "Cooperative" | "PrivateHeld" | "PublicHeld" | "None";
  companyAddress?: AddressDTO;
  accountType: "Natural" | "Legal";
  userType: "None" | "Member" | "NonMember" | "NonMemberInvestor";
  created?: string;
  email: string;
  mobile: string;
  phone?: string;
  referralCode?: string;
  role:
    | "CEO"
    | "ADMIN"
    | "SUPPORT"
    | "MINISTRY"
    | "CHAIRMAN"
    | "COMPANY"
    | "USER";
  permissions: (
    | "MOBILE_VERIFIED"
    | "DOCS_VERIFIED"
    | "MEMBERSHIP_VERIFIED"
    | "BOARD_MEMBER"
  )[];
  authStep:
    | "PERSONAL_INFO"
    | "COMPANY_INFO"
    | "BANK_INFO"
    | "UPLOAD_DOC_PERSONAL_PHOTO"
    | "UPLOAD_DOC_NATIONAL_CARD"
    | "UPLOAD_DOC_ID_CARD"
    | "UPLOAD_DOC_COMPANY"
    | "UPLOAD_DOC_BANK"
    | "PRE_BUY_STOCK";
  username: string;
}

export interface EditUserCompanyInfoRequest {
  companyName: string;
  companyRegisterNumber?: string;
  companySSNumber?: string;
  companyEcoNumber?: string;

  /** @format int32 */
  companyOstan: number;

  /** @format int32 */
  companyShahr: number;
  companyAddressDetail?: string;
  companyPostalCode: string;
  auth?: boolean;
}

export interface LegalUserFullInfoRequest {
  birthDate: string;
  gender: "male" | "female";

  /** @format int32 */
  ostan: number;

  /** @format int32 */
  shahrestan: number;

  /** @format int32 */
  bakhsh?: number;

  /** @format int32 */
  dehestan?: number;
  sacrificeStatus:
    | "Normal"
    | "Fighter"
    | "Veteran"
    | "CaptiveFamily"
    | "Released";
  educationLevel:
    | "Illiterate"
    | "ElementarySchool"
    | "MiddleSchool"
    | "HighSchool"
    | "Diploma"
    | "AssociateDegree"
    | "Bachelor"
    | "MA";
  phone: string;

  /** @format int32 */
  ssnAddressShahrestan: number;
  detail: string;
  postalCode: string;
  companyName: string;
  companyRegisterNumber: string;

  /** @format int32 */
  companyOstan: number;

  /** @format int32 */
  companyShahr: number;
  companyAddressDetail: string;
  companyPostalCode: string;
  auth?: boolean;
}

export interface RequestStockPaper {
  transactionId: string;
}

export interface AddDocRequest {
  docs: CompactDocRequest[];
  auth?: boolean;
}

export interface CompactDocRequest {
  file_id: string;
  doc_type:
    | "ID_CARD_P_1_2"
    | "ID_CARD_P_3_4"
    | "ID_CARD_P_5_6"
    | "NATIONAL_CARD_P_1"
    | "NATIONAL_CARD_P_2"
    | "DUTY_CARD_P_1"
    | "DUTY_CARD_P_2"
    | "SIGN_SAMPLE"
    | "MANAGING_DIRECTOR_SIGN_SAMPLE"
    | "RECENT_GENERAL_ORDINARY_CHANGE"
    | "RECENT_MD_CHANGE"
    | "FOUNDED_AD"
    | "PERSONAL_PHOTO"
    | "LAST_EDUCATION"
    | "MAIN_BANK_CART";
}

export interface BuyTokenRequest {
  /** @format int32 */
  amount: number;
  user_type?: "None" | "Member" | "NonMember" | "NonMemberInvestor";
  success_url?: string;
  failed_url?: string;
}

export interface BuyTokenResponse {
  id: string;

  /** @format int32 */
  amount: number;

  /** @format int64 */
  price: number;
  refId: string;
  mobNo: string;
  payUrl: string;
}

export interface AddAccountRequest {
  /** @format int32 */
  bankId?: number;
  sheba: string;
  cardId: string;
  auth?: boolean;
}

export interface PermissionRequest {
  userId: string;
  permissions: (
    | "MOBILE_VERIFIED"
    | "DOCS_VERIFIED"
    | "MEMBERSHIP_VERIFIED"
    | "BOARD_MEMBER"
  )[];
}

export interface CreateCompanyUserRequest {
  username: string;
  password: string;
  mobile: string;
}

export interface AssignUserRoleRequest {
  userId: string;
  role:
    | "CEO"
    | "ADMIN"
    | "SUPPORT"
    | "MINISTRY"
    | "CHAIRMAN"
    | "COMPANY"
    | "USER";
}

export interface TransactionDTO {
  id?: string;

  /** @format int32 */
  amount: number;
  totalPrice: number;
  dataToSign?: string;
  transactionStatus:
    | "CREATED"
    | "MODIFIED"
    | "CANCELED"
    | "EXPIRED"
    | "PENDING_FOR_PAY"
    | "PENDING_FOR_VERIFY_DOCS"
    | "PENDING_FOR_VERIFY_MEMBERSHIP"
    | "PENDING_FOR_VERIFY_TRANSACTION"
    | "PENDING_FOR_CREATE_IN_BC"
    | "PENDING_FOR_CEO_SIGN"
    | "PENDING_FOR_SIGN_IN_BC"
    | "REJECT_BC_SIGN"
    | "REJECT_BC"
    | "APPROVED_BC"
    | "REJECT_SIGN"
    | "REJECT_MEMBERSHIP";
  userId?: string;
  username?: string;
  firstName: string;
  lastName: string;
  companyName?: string;
  accountType: "Natural" | "Legal";
  userType: "None" | "Member" | "NonMember" | "NonMemberInvestor";
  verified_docs: boolean;
  verified_membership: boolean;
  createdDate?: string;
  approvedDate?: string;
  rejectDate?: string;
  type: "ISSUE" | "CREATE" | "TRANSFER_BUY" | "TRANSFER_SELL" | "BURN";
  ostan?: string;
  status:
    | "CREATED"
    | "MODIFIED"
    | "CANCELED"
    | "EXPIRED"
    | "PENDING_FOR_PAY"
    | "PENDING_FOR_VERIFY_DOCS"
    | "PENDING_FOR_VERIFY_MEMBERSHIP"
    | "PENDING_FOR_VERIFY_TRANSACTION"
    | "PENDING_FOR_CREATE_IN_BC"
    | "PENDING_FOR_CEO_SIGN"
    | "PENDING_FOR_SIGN_IN_BC"
    | "REJECT_BC_SIGN"
    | "REJECT_BC"
    | "APPROVED_BC"
    | "REJECT_SIGN"
    | "REJECT_MEMBERSHIP";
  nationalCode: string;
  paymentRefId: string;
  /** @format int64 */
  paymentOrderId: number;
  paymentMethod: "ONLINE" | "BANK_RECEIPT";
  paymentStatus: "SUCCESS" | "ERROR" | "PENDING_FOR_VERIFY" | "PENDING_FOR_PAY";
}

export interface ReceiptDTO {
  amount: 0;
  status:
    | "CREATED"
    | "MODIFIED"
    | "CANCELED"
    | "EXPIRED"
    | "PENDING_FOR_PAY"
    | "PENDING_FOR_VERIFY_DOCS"
    | "PENDING_FOR_VERIFY_MEMBERSHIP"
    | "PENDING_FOR_VERIFY_TRANSACTION"
    | "PENDING_FOR_CREATE_IN_BC"
    | "PENDING_FOR_CEO_SIGN"
    | "PENDING_FOR_SIGN_IN_BC"
    | "REJECT_BC_SIGN"
    | "REJECT_BC"
    | "APPROVED_BC"
    | "REJECT_SIGN"
    | "REJECT_MEMBERSHIP";
}

export interface GeneralResponse {
  /** @format int32 */
  code: number;
  msg: string;
  data?: object;
}

export interface SignTokenRequest {
  transactionId: string;
  signMethod: "ENDORSE" | "REJECT";
  data: string;
  signedData: string;
}

export interface RejectTokenRequest {
  transactionId: string;
  reason?: string;
}

export interface RejectMembershipRequest {
  userId: string;
  reason?: string;
}

export interface RejectStockPaperRequest {
  id: string;
  reason?: string;
}

export interface RejectDocsAndFieldsRequest {
  userId: string;
  docs: boolean;
  fields: boolean;
  docs_ids?: string[];
  docs_reason?: string;
  field_reason?: string;
}

export interface RejectUserRequest {
  userId: string;
  reason?: string;
}

export interface SetDocStatusRequest {
  id: string;
  reason: string;
}

export interface UserDocDTO {
  id: string;
  fileId?: string;
  docType:
    | "ID_CARD_P_1_2"
    | "ID_CARD_P_3_4"
    | "ID_CARD_P_5_6"
    | "NATIONAL_CARD_P_1"
    | "NATIONAL_CARD_P_2"
    | "DUTY_CARD_P_1"
    | "DUTY_CARD_P_2"
    | "SIGN_SAMPLE"
    | "MANAGING_DIRECTOR_SIGN_SAMPLE"
    | "RECENT_GENERAL_ORDINARY_CHANGE"
    | "RECENT_MD_CHANGE"
    | "FOUNDED_AD"
    | "PERSONAL_PHOTO"
    | "LAST_EDUCATION"
    | "MAIN_BANK_CART";
  optional: boolean;
  status: "PENDING" | "REJECT" | "APPROVED";
  reason?: string;
  createdDate?: string;
  approvedDate?: string;
  rejectDate?: string;
}

export interface ApproveAllDocsRequest {
  userId: string;
}

export interface CompanyDetailRequest {
  parValuePerStock: number;

  /** @format int32 */
  totalNumberOfStocks: number;
  registeredCapital: number;

  /** @format int32 */
  availableStocks: number;
  stockSupplyValue: number;
  offeringPeriod: DateRange;
  nonMemberInvestorReturnOnStock: number;
  nonMemberPremiumOnStock: number;
  nonMemberInvestorPremiumOnStock: number;
  investmentContractCommissionFee: number;
  // nonMemberOfferingPeriod: DateRange,
  // nonMemberInvestorOfferingPeriod: DateRange,
  // memberOfferingPeriod: DateRange,
  earningsPerShare?: number;
  dividendsPerShare?: number;

  /** @format int32 */
  investmentPeriod: number;
  loan: number;
  proceedingsDate?: string;
  depositRepaymentInstallments: number;
  investmentBaseAmount: number;
  assemblyProfit?: number;
}

export interface DateRange {
  /** @format int64 */
  from: number;

  /** @format int64 */
  to: number;
}

export interface CompanyDetailDTO {
  id?: string;
  created?: string;
  parValuePerStock: number;

  /** @format int32 */
  totalNumberOfStocks: number;
  registeredCapital: number;
  stockSupplyValue: number;

  /** @format int32 */
  availableStocks: number;
  offeringPeriod: DateRangeDTO;
  nonMemberInvestorReturnOnStock: number;
  nonMemberPremiumOnStock: number;
  nonMemberInvestorPremiumOnStock: number;
  investmentContractCommissionFee: number;
  nonMemberOfferingPeriod: DateRangeDTO;
  nonMemberInvestorOfferingPeriod: DateRangeDTO;
  memberOfferingPeriod: DateRangeDTO;
  earningsPerShare?: number;
  dividendsPerShare?: number;

  /** @format int32 */
  investmentPeriod: number;
  loan: number;
  status: "PENDING" | "APPROVED" | "REJECTED";
  proceedingsDate?: string;
  depositRepaymentInstallments: number;
  investmentBaseAmount: number;
  assemblyProfit?: number;
  reason?: string;

  /** @format int32 */
  membersStock: number;

  /** @format int32 */
  nonMembersStock: number;

  /** @format int32 */
  nomMembersInvestorStock: number;
}

export interface DateRangeDTO {
  from?: string;
  to?: string;
}

export interface IssueTokenRequest {
  /** @format int32 */
  amount: number;
}

export interface CreateRoleRequest {
  name: string;
  permissions: ("TRANSFER" | "BURN" | "ISSUE" | "CREATE")[];
}

export interface BlockchainRole {
  id?: string;
  roleId: string;
  name: string;
  permissions: ("TRANSFER" | "BURN" | "ISSUE" | "CREATE")[];
}

export interface RevokeBoardMemberRequest {
  userId: string;
  reason: string;
}

export interface Address {
  /** @format int32 */
  ostan?: number;

  /** @format int32 */
  shahrestan?: number;

  /** @format int32 */
  bakhsh?: number;

  /** @format int32 */
  shahr?: number;

  /** @format int32 */
  dehestan?: number;
  postalCode?: string;
  detail?: string;
}

export interface BlockchainUser {
  id?: string;
  user: User;
  userId?: string;
  enrollmentId?: string;
  bId?: string;
  type: string;
  active: boolean;
  boardMember: boolean;
  enrolled: boolean;
  csr?: string;
  csrFileId?: string;
  certificate?: string;
  certificateFileId?: string;
  role?: BlockchainRole;
  roleId?: string;
  bid?: string;
}

export interface GrantedAuthority {
  authority?: string;
}

export interface User {
  id?: string;
  firstName: string;
  lastName: string;
  fatherName?: string;
  nationalCode: string;

  /** @format int64 */
  birthdate?: number;
  idCardNumber?: string;
  idCardSerial?: IDCardSerial;
  dutyStatus?: "Exemption" | "EndOfDuty";
  maritalStatus?: "Single" | "Married";
  gender?: "male" | "female";

  /** @format int32 */
  dependentsCount?: number;
  sacrificeStatus?:
    | "Normal"
    | "Fighter"
    | "Veteran"
    | "CaptiveFamily"
    | "Released";
  educationLevel?:
    | "Illiterate"
    | "ElementarySchool"
    | "MiddleSchool"
    | "HighSchool"
    | "Diploma"
    | "AssociateDegree"
    | "Bachelor"
    | "MA";
  skills: string[];
  address?: Address;
  ssnAddress?: Address;
  companyName?: string;
  companyRegisterNumber?: string;
  companySSNumber?: string;
  companyEcoNumber?: string;
  companyRegYear?: string;
  companyType?: "Cooperative" | "PrivateHeld" | "PublicHeld" | "None";
  companyAddress?: Address;
  accountType: "Natural" | "Legal";
  userType: "None" | "Member" | "NonMember" | "NonMemberInvestor";

  /** @format int64 */
  created: number;

  /** @format int64 */
  modified: number;
  email: string;
  mobile: string;
  phone?: string;
  referralCode?: string;
  role:
    | "CEO"
    | "ADMIN"
    | "SUPPORT"
    | "MINISTRY"
    | "CHAIRMAN"
    | "COMPANY"
    | "USER";
  permissions: (
    | "MOBILE_VERIFIED"
    | "DOCS_VERIFIED"
    | "MEMBERSHIP_VERIFIED"
    | "BOARD_MEMBER"
  )[];
  authStep:
    | "PERSONAL_INFO"
    | "COMPANY_INFO"
    | "BANK_INFO"
    | "UPLOAD_DOC_PERSONAL_PHOTO"
    | "UPLOAD_DOC_NATIONAL_CARD"
    | "UPLOAD_DOC_ID_CARD"
    | "UPLOAD_DOC_COMPANY"
    | "UPLOAD_DOC_BANK"
    | "PRE_BUY_STOCK";
  username: string;
  password?: string;
  extra?: Record<string, string>;
  enabled: boolean;
  legal: boolean;
  authorities: GrantedAuthority[];
  accountNonExpired: boolean;
  natural: boolean;
  accountNonLocked: boolean;
  credentialsNonExpired: boolean;
}

export interface EnrollBoardMemberRequest {
  csrFileId: string;
}

export interface AssignAsBoardMemberRequest {
  userId: string;
  roleId: string;
  title: string;
}

export interface RejectCompanyDetailsRequest {
  id: string;
  pin: string;
  reason: string;
}

export interface CompanyDetail {
  id?: string;

  /** @format int64 */
  created: number;
  parValuePerStock: number;

  /** @format int32 */
  totalNumberOfStocks: number;
  registeredCapital: number;
  stockSupplyValue: number;

  /** @format int32 */
  availableStocks: number;
  offeringPeriod: DateRange;
  // nonMemberOfferingPeriod: DateRange,
  // nonMemberInvestorOfferingPeriod: DateRange,
  // memberOfferingPeriod: DateRange,
  nonMemberInvestorReturnOnStock: number;
  nonMemberPremiumOnStock: number;
  nonMemberInvestorPremiumOnStock: number;
  investmentContractCommissionFee: number;
  earningsPerShare?: number;
  dividendsPerShare?: number;

  /** @format int32 */
  investmentPeriod: number;
  loan: number;
  status: "PENDING" | "APPROVED" | "REJECTED";

  /** @format int64 */
  proceedingsDate: number;
  depositRepaymentInstallments: number;
  investmentBaseAmount: number;
  assemblyProfit?: number;
  reason?: string;
  pinId?: string;
}

export interface GenerateCompanyDetailsPinRequest {
  id: string;
  status: "PENDING" | "APPROVED" | "REJECTED";
}

export interface ApproveCompanyDetailsRequest {
  id: string;
  pin: string;
}

export interface VerifyMobileRequest {
  mobile: string;
  national_code: string;
  account_type: "Natural" | "Legal";
  company_type?: "Cooperative" | "PrivateHeld" | "PublicHeld" | "None";
  pin: string;
}

export interface LoginResponse {
  username?: string;
  mobile?: string;
  userType?: "None" | "Member" | "NonMember" | "NonMemberInvestor";
  firstName?: string;
  lastName?: string;
  jwt?: string;
}

export interface RegisterSecondStepRequest {
  national_code: string;
  mobile: string;
  account_type: "Natural" | "Legal";
  company_type?: "Cooperative" | "PrivateHeld" | "PublicHeld" | "None";
  first_name: string;
  last_name: string;
  father_name: string;

  /** @format int32 */
  ostan?: number;
  above_18?: boolean;
  accept_rules?: boolean;
  referral_code?: string;
  company_ssn?: string;
  company_name?: string;
}

export interface RegisterRequest {
  captcha: string;
  national_code: string;
  mobile: string;
  account_type: "Natural" | "Legal";
  company_type?: "Cooperative" | "PrivateHeld" | "PublicHeld" | "None";
}

export interface ChangePasswordRequest {
  current: string;
  new: string;
}

export interface LoginRequest {
  national_code: string;
  mobile: string;
  account_type: "Natural" | "Legal";
  company_type?: "Cooperative" | "PrivateHeld" | "PublicHeld" | "None";
  pin: string;
}

export interface LoginPinRequest {
  captcha: string;
  mobile: string;
  national_code: string;
  account_type: "Natural" | "Legal";
  company_type?: "Cooperative" | "PrivateHeld" | "PublicHeld" | "None";
}

export interface StaffLoginRequest {
  captcha?: string;
  username: string;
  mobile: string;
  pin?: string;
  password?: string;
}

export interface CreateStaffUser {
  username: string;
  password: string;
  mobile: string;
}

export interface UserTypeResponse {
  type: "None" | "Member" | "NonMember" | "NonMemberInvestor";
}

export interface PageUserTransactionPendingDTO {
  /** @format int32 */
  totalPages?: number;

  /** @format int64 */
  totalElements?: number;

  /** @format int32 */
  size?: number;
  content?: UserTransactionPendingDTO[];

  /** @format int32 */
  number?: number;
  sort?: Sort;
  pageable?: Pageable;

  /** @format int32 */
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
  empty?: boolean;
}

export interface Pageable {
  /** @format int64 */
  offset?: number;
  sort?: Sort;

  /** @format int32 */
  pageNumber?: number;

  /** @format int32 */
  pageSize?: number;
  paged?: boolean;
  unpaged?: boolean;
}

export interface Sort {
  sorted?: boolean;
  unsorted?: boolean;
  empty?: boolean;
}

export interface UserTransactionPendingDTO {
  date?: string;
  userType?: "None" | "Member" | "NonMember" | "NonMemberInvestor";
  transactionType?:
    | "ISSUE"
    | "CREATE"
    | "TRANSFER_BUY"
    | "TRANSFER_SELL"
    | "BURN";

  /** @format int32 */
  amount?: number;
  totalPrice?: number;
  status?:
    | "CREATED"
    | "MODIFIED"
    | "CANCELED"
    | "EXPIRED"
    | "PENDING_FOR_PAY"
    | "PENDING_FOR_VERIFY_DOCS"
    | "PENDING_FOR_VERIFY_MEMBERSHIP"
    | "PENDING_FOR_VERIFY_TRANSACTION"
    | "PENDING_FOR_CREATE_IN_BC"
    | "PENDING_FOR_CEO_SIGN"
    | "PENDING_FOR_SIGN_IN_BC"
    | "REJECT_BC_SIGN"
    | "REJECT_BC"
    | "APPROVED_BC"
    | "REJECT_SIGN"
    | "REJECT_MEMBERSHIP";
}

export interface PageUserTransactionOfferingDTO {
  /** @format int32 */
  totalPages?: number;

  /** @format int64 */
  totalElements?: number;

  /** @format int32 */
  size?: number;
  content?: UserTransactionOfferingDTO[];

  /** @format int32 */
  number?: number;
  sort?: Sort;
  pageable?: Pageable;

  /** @format int32 */
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
  empty?: boolean;
}

export interface UserTransactionOfferingDTO {
  date?: string;
  userType?: "None" | "Member" | "NonMember" | "NonMemberInvestor";

  /** @format int32 */
  amount?: number;
  parValuePerStock?: number;
  registeredCapital?: number;
  totalPrice?: number;
  transactionType?:
    | "ISSUE"
    | "CREATE"
    | "TRANSFER_BUY"
    | "TRANSFER_SELL"
    | "BURN";
}

export interface PageUserTransactionHistoryDTO {
  /** @format int32 */
  totalPages?: number;

  /** @format int64 */
  totalElements?: number;

  /** @format int32 */
  size?: number;
  content?: UserTransactionHistoryDTO[];

  /** @format int32 */
  number?: number;
  sort?: Sort;
  pageable?: Pageable;

  /** @format int32 */
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
  empty?: boolean;
}

export interface UserTransactionHistoryDTO {
  date?: string;
  userType?: "None" | "Member" | "NonMember" | "NonMemberInvestor";

  /** @format int32 */
  amount?: number;
  parValuePerStock?: number;
  registeredCapital?: number;
  totalPrice?: number;
  sellPrice?: number;
  status?:
    | "CREATED"
    | "MODIFIED"
    | "CANCELED"
    | "EXPIRED"
    | "PENDING_FOR_PAY"
    | "PENDING_FOR_VERIFY_DOCS"
    | "PENDING_FOR_VERIFY_MEMBERSHIP"
    | "PENDING_FOR_VERIFY_TRANSACTION"
    | "PENDING_FOR_CREATE_IN_BC"
    | "PENDING_FOR_CEO_SIGN"
    | "PENDING_FOR_SIGN_IN_BC"
    | "REJECT_BC_SIGN"
    | "REJECT_BC"
    | "APPROVED_BC"
    | "REJECT_SIGN"
    | "REJECT_MEMBERSHIP";
  transactionId?: string;
}

export interface PageStockPaperUserDTO {
  /** @format int32 */
  totalPages?: number;

  /** @format int64 */
  totalElements?: number;

  /** @format int32 */
  size?: number;
  content?: StockPaperUserDTO[];

  /** @format int32 */
  number?: number;
  sort?: Sort;
  pageable?: Pageable;

  /** @format int32 */
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
  empty?: boolean;
}

export interface StockPaperUserDTO {
  transactionId?: string;
  status?: "Pending" | "Accepted" | "Rejected";
  buyDate?: string;
  requestDate?: string;
  userType?: "None" | "Member" | "NonMember" | "NonMemberInvestor";

  /** @format int32 */
  amount?: number;
  fileId?: string;
}

export interface UserAuthStatus {
  verified: boolean;
  verifiedDocs: UserDocDTO[];
  rejectDocs: UserDocDTO[];
  pendingDocs: UserDocDTO[];
  reasons: string[];
}

export interface StockResponse {
  id?: string;
  parValuePerStock: number;
  registeredCapital: number;
  offeringPeriod: DateRange;
  // nonMemberOfferingPeriod: DateRange,
  // nonMemberInvestorOfferingPeriod: DateRange,
  // memberOfferingPeriod: DateRange,
  userType: "None" | "Member" | "NonMember" | "NonMemberInvestor";
  lastTime?: string;

  /** @format int32 */
  totalStock: number;
  totalPaid: number;

  /** @format int32 */
  totalPending: number;

  /** @format int32 */
  remained: number;

  /** @format int32 */
  canBuy: number;
  investment?: UserInvestment;
}

export interface UserInvestment {
  lastDate?: string;
  investmentPrice?: number;

  /** @format int32 */
  amount?: number;

  /** @format int32 */
  period?: number;
  payedPrice?: number;
  month?: string;
}

export interface UserStockStatistics {
  /** @format int32 */
  max: number;

  /** @format int32 */
  min: number;

  /** @format int32 */
  pending: number;

  /** @format int32 */
  owned: number;
  parValue: number;
}

export interface UserTypeStockStatistics {
  /** @format int32 */
  maxMember: number;

  /** @format int32 */
  minMember: number;

  /** @format int32 */
  maxNonMember: number;

  /** @format int32 */
  minNonMember: number;

  /** @format int32 */
  maxNonMemberInvestor: number;

  /** @format int32 */
  minNonMemberInvestor: number;
  parValueMember: number;
  parValueNonMember: number;
  parValueNonMemberInvestor: number;
}

export interface TotalStockStatistics {
  /** @format int32 */
  registered: number;

  /** @format int32 */
  total: number;

  /** @format int32 */
  free: number;

  /** @format int32 */
  memberPending: number;

  /** @format int32 */
  nonmemberPending: number;

  /** @format int32 */
  nonmemberInvestorPending: number;

  /** @format int32 */
  memberOwn: number;

  /** @format int32 */
  nonmemberOwn: number;

  /** @format int32 */
  nonmemberInvestorOwn: number;

  /** @format int32 */
  memberTotal: number;

  /** @format int32 */
  nonMemberTotal: number;
}

export interface PaymentDTO {
  /** @format int64 */
  orderId: number;
  amount: number;
  date: string;
  time: string;
  refId?: string;
  cardHolderPAN?: string;

  /** @format int64 */
  saleReferenceId?: number;
  status: "Pending" | "Error" | "Success";
  verifyMessage?: string;

  /** @format int32 */
  verifyCode?: number;
}

export interface TransactionUserDetailInfo {
  accountType?: "Natural" | "Legal";
  userType?: "None" | "Member" | "NonMember" | "NonMemberInvestor";
  nationalCode?: string;
  firstName?: string;
  lastName?: string;
  ssn?: string;
  fatherName?: string;
  gender?: "male" | "female";
  birthDate?: string;
  sacrificeStatus?:
    | "Normal"
    | "Fighter"
    | "Veteran"
    | "CaptiveFamily"
    | "Released";
  ssnAddress?: AddressDTO;
  educationLevel?:
    | "Illiterate"
    | "ElementarySchool"
    | "MiddleSchool"
    | "HighSchool"
    | "Diploma"
    | "AssociateDegree"
    | "Bachelor"
    | "MA";
  mobile?: string;
  phone?: string;
  address?: AddressDTO;

  /** @format int32 */
  stocks: number;
  parValuePerStock: number;
  paidPrice: number;
  obligationPrice: number;
  nonCashPrice: number;
  paymentMethod: "ONLINE";
  memorandumFileId?: string;
  bankReceiptFileId?: string;
  payment?: PaymentDTO;
}

export interface PageTransactionDTO {
  /** @format int32 */
  totalPages?: number;

  /** @format int64 */
  totalElements?: number;

  /** @format int32 */
  size?: number;
  content?: TransactionDTO[];

  /** @format int32 */
  number?: number;
  sort?: Sort;
  pageable?: Pageable;

  /** @format int32 */
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
  empty?: boolean;
}

export interface PageTransactionDetailDTO {
  /** @format int32 */
  totalPages?: number;

  /** @format int64 */
  totalElements?: number;

  /** @format int32 */
  size?: number;
  content?: TransactionDetailDTO[];

  /** @format int32 */
  number?: number;
  sort?: Sort;
  pageable?: Pageable;

  /** @format int32 */
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
  empty?: boolean;
}

export interface TransactionDetailDTO {
  id?: string;
  createdDate?: string;
  accountType: "Natural" | "Legal";
  userType: "None" | "Member" | "NonMember" | "NonMemberInvestor";
  ostan?: string;
  fullName: string;
  companyName?: string;

  /** @format int32 */
  amount: number;
  totalPrice: number;
  type: string;
}

export interface BankReceiptDetailDTO {
  transactionId: string;
  paymentId: string;

  /** @format int64 */
  orderId: 0;

  /** @format int32 */
  amount: 0;

  date: string;
  time: string;
  refId: string;
  status: "PENDING_FOR_PAY" | "PENDING_FOR_VERIFY" | "ERROR" | "SUCCESS";

  /** @format int64 */
  created: 0;

  /** @format int64 */
  modified: 0;

  paymentMethod: "ONLINE" | "BANK_RECEIPT";
  receiptImageId: string;
  bankId: string;
  bankCode: string;
}

export interface DailyTransactionResponse {
  date?: string;
  highestUserType?: "None" | "Member" | "NonMember" | "NonMemberInvestor";
  highestOstanDemand?: string;

  /** @format int32 */
  stockAmount: number;

  /** @format int32 */
  depositAmount: number;

  /** @format int32 */
  withdrawAmount: number;
}

export interface PageDailyTransactionResponse {
  /** @format int32 */
  totalPages?: number;

  /** @format int64 */
  totalElements?: number;

  /** @format int32 */
  size?: number;
  content?: DailyTransactionResponse[];

  /** @format int32 */
  number?: number;
  sort?: Sort;
  pageable?: Pageable;

  /** @format int32 */
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
  empty?: boolean;
}

export interface PageStockPaperAdminDTO {
  /** @format int32 */
  totalPages?: number;

  /** @format int64 */
  totalElements?: number;

  /** @format int32 */
  size?: number;
  content?: StockPaperAdminDTO[];

  /** @format int32 */
  number?: number;
  sort?: Sort;
  pageable?: Pageable;

  /** @format int32 */
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
  empty?: boolean;
}

export interface StockPaperAdminDTO {
  id: string;
  status: "Pending" | "Accepted" | "Rejected";
  companyName?: string;
  fullName?: string;
  created?: string;
  requestDate?: string;
  acceptDate?: string;
  rejectDate?: string;
  userType: "None" | "Member" | "NonMember" | "NonMemberInvestor";
  accountType: "Natural" | "Legal";
  totalPrice: number;

  /** @format int32 */
  amount: number;
}

export interface UserAuthStatusResponse {
  verified: boolean;
  verifiedDocs: UserDocDTO[];
  rejectDocs: UserDocDTO[];
  pendingDocs: UserDocDTO[];
  incompleteDocs: (
    | "ID_CARD_P_1_2"
    | "ID_CARD_P_3_4"
    | "ID_CARD_P_5_6"
    | "NATIONAL_CARD_P_1"
    | "NATIONAL_CARD_P_2"
    | "DUTY_CARD_P_1"
    | "DUTY_CARD_P_2"
    | "SIGN_SAMPLE"
    | "MANAGING_DIRECTOR_SIGN_SAMPLE"
    | "RECENT_GENERAL_ORDINARY_CHANGE"
    | "RECENT_MD_CHANGE"
    | "FOUNDED_AD"
    | "PERSONAL_PHOTO"
    | "LAST_EDUCATION"
    | "MAIN_BANK_CART"
  )[];
  verificationDTO: UserVerificationDTO;
  user: UserDTO;
  bankAccounts: BankAccountDTO[];
  reasons: string[];
}

export interface UserVerificationDTO {
  userId: string;
  username: string;
  requestDate?: string;
  approvedDate?: string;
  rejectDate?: string;
  firstName: string;
  lastName: string;
  accountType: "Natural" | "Legal";
  userType: "None" | "Member" | "NonMember" | "NonMemberInvestor";
  nationalCode: string;
  mobile: string;
  companyName?: string;
  incompleteFields: string[];
  incompleteDocs: (
    | "ID_CARD_P_1_2"
    | "ID_CARD_P_3_4"
    | "ID_CARD_P_5_6"
    | "NATIONAL_CARD_P_1"
    | "NATIONAL_CARD_P_2"
    | "DUTY_CARD_P_1"
    | "DUTY_CARD_P_2"
    | "SIGN_SAMPLE"
    | "MANAGING_DIRECTOR_SIGN_SAMPLE"
    | "RECENT_GENERAL_ORDINARY_CHANGE"
    | "RECENT_MD_CHANGE"
    | "FOUNDED_AD"
    | "PERSONAL_PHOTO"
    | "MAIN_BANK_CART"
    | "LAST_EDUCATION"
  )[];

  /** @format int32 */
  orderTotalPrice?: number;

  /** @format int32 */
  amount?: number;
}

export interface ModelExcel {
  id: string;
  url: string;
}

export interface PageUserVerificationDTO {
  /** @format int32 */
  totalPages?: number;

  /** @format int64 */
  totalElements?: number;

  /** @format int32 */
  size?: number;
  content?: UserVerificationDTO[];

  /** @format int32 */
  number?: number;
  sort?: Sort;
  pageable?: Pageable;

  /** @format int32 */
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
  empty?: boolean;
}

export interface TokensSummaryResponse {
  /** @format int32 */
  nonmember: number;

  /** @format int32 */
  nonmemberInvestor: number;

  /** @format int32 */
  member: number;
}

export interface StockSummaryResponse {
  memberOfferingPeriod: DateRange;
  nonMemberOfferingPeriod: DateRange;
  nonMemberInvestorOfferingPeriod: DateRange;
  /** @format int32 */
  totalTokens: number;
  stockPrice: number;
}

export interface SummaryStockStatistics {
  /** @format int32 */
  total: number;

  /** @format int32 */
  free: number;

  /** @format int32 */
  reserved: number;

  /** @format int32 */
  bought: number;
}

export interface WalletInfo {
  collection?: string;
  createdAt?: string;
  id?: string;
  isDeleted?: boolean;
  updatedAt?: string;

  /** @format int64 */
  balance?: number;
  status?: string;
  type?: string;
  accountType?: string;
  userType?: string;
  companyType?: string;

  /** @format int32 */
  threshold?: number;

  /** @format int32 */
  statusCode?: number;
  message?: string[];
  error?: string;
}

export interface BTransaction {
  /** @format int32 */
  amount?: number;
  from?: string;
  id?: string;
  to?: string;
  type?: string;
  status?: string;

  /** @format int32 */
  statusCode?: number;
  message?: string[];
  error?: string;
}

export interface BRoleInfo {
  id?: string;
  name?: string;
  permissions?: ("TRANSFER" | "BURN" | "ISSUE" | "CREATE")[];
  isDeleted?: boolean;

  /** @format int32 */
  statusCode?: number;
  message?: string[];
  error?: string;
}

export interface BRequest {
  collection?: string;
  consensusStatus?: string;

  /** @format int64 */
  createdAt?: number;
  endorsers: string[];
  id?: string;
  isDeleted: boolean;
  rejectors: string[];
  requestData?: string;
  requestType?: string;
  signingRules: string[];

  /** @format int64 */
  updatedAt?: number;

  /** @format int32 */
  statusCode?: number;
  message?: string[];
  error?: string;
}

export interface EnrollmentIdResponse {
  eid: string;
}

export interface FileInfoResponse {
  fileId: string;
}

export interface BoardMemberInfo {
  accountType?: string;
  collection?: string;
  companyType?: string;

  /** @format int64 */
  createdAt?: number;
  id?: string;
  isDeleted?: boolean;
  permissions?: string[];
  roleId?: string;

  /** @format int64 */
  updatedAt?: number;
  userType?: string;

  /** @format int32 */
  statusCode?: number;
  message?: string[];
  error?: string;
}

export interface CompactBank {
  /** @format int32 */
  id: number;
  name: string;
}

export interface PageCompanyDetailDTO {
  /** @format int32 */
  totalPages?: number;

  /** @format int64 */
  totalElements?: number;

  /** @format int32 */
  size?: number;
  content?: CompanyDetailDTO[];

  /** @format int32 */
  number?: number;
  sort?: Sort;
  pageable?: Pageable;

  /** @format int32 */
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
  empty?: boolean;
}


//////////////////////مدیریت کاربران
export interface ManagementUsers
{

  id: string,
  firstName: string,
  lastName: string,
  fatherName: string,
  nationalCode: string,
  accountType: string,
  userType: string,
  mobile: string,
  role: string,
  permissions: [],
  username: string
}
export interface SetManagementUsers{
 
 id?: string,
  password?: string,
  mobile?: string,
  username?: string,
  role?: string
  
}

import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  ResponseType,
} from "axios";
import { message } from "antd";
import { strict } from "assert";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<
  FullRequestParams,
  "body" | "method" | "query" | "path"
>;

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    // this.instance = axios.create({...axiosConfig, baseURL: axiosConfig.baseURL || "https://api.parswater.com"});
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || "https://api.parswater.com",
    });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig
  ): AxiosRequestConfig {
    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.instance.defaults.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      formData.append(
        key,
        property instanceof Blob
          ? property
          : typeof property === "object" && property !== null
          ? JSON.stringify(property)
          : `${property}`
      );
      return formData;
    }, new FormData());
  }

  async request<T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = (format && this.format) || void 0;

    if (type === ContentType.FormData && body && typeof body === "object") {
      requestParams.headers.common = { Accept: "*/*" };
      requestParams.headers.post = {};
      requestParams.headers.put = {};

      const formData = this.createFormData(body as Record<string, unknown>);
      return this.instance.request({
        ...requestParams,
        headers: {
          ...(type && type !== ContentType.FormData
            ? { "Content-Type": type }
            : {}),
          ...(requestParams.headers || {}),
        },
        params: query,
        responseType: responseFormat,
        data: formData,
        url: path,
      });
    }
    // else if (type === ContentType.Json && body && typeof body === "string") {
    //     // requestParams.headers.common = {Accept: "*/*"};
    //     // requestParams.headers.post = {};
    //     // requestParams.headers.put = {};
    //
    //     // const formData = this.createFormData(body as Record<string, unknown>);
    //     return this.instance.request({
    //         ...requestParams,
    //         headers: {
    //             ...({"Content-Type":  "application/json"}),
    //             ...(requestParams.headers || {}),
    //         },
    //         params: query,
    //         responseType: responseFormat,
    //         data: body,
    //         url: path,
    //     });
    // }
    else
      return this.instance.request({
        ...requestParams,
        headers: {
          ...(type && type !== ContentType.FormData
            ? { "Content-Type": type }
            : {}),
          ...(requestParams.headers || {}),
        },
        params: query,
        responseType: responseFormat,
        data: body,
        url: path,
      });
  }
}

/**
 * @title Parswater Core
 * @version 1.0.2
 * @baseUrl https://api.parswater.com
 *
 * Parswater backend api documents
 */
export class Api<
  SecurityDataType extends unknown
> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags Bank Accounts
     * @name FetchAllBankAccounts
     * @summary مشاهده تمامی حساب های بانکی
     * @request GET:/api/v1/user/bank/account
     * @secure
     */
    fetchAllBankAccounts: (params: RequestParams = {}) =>
      this.request<BankAccountDTO[], object>({
        path: `/api/v1/user/bank/account`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank Accounts
     * @name EditBankAccount
     * @summary ویرایش حساب بانکی
     * @request PUT:/api/v1/user/bank/account
     * @secure
     */
    editBankAccount: (data: EditAccountRequest, params: RequestParams = {}) =>
      this.request<BankAccountDTO, object>({
        path: `/api/v1/user/bank/account`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank Accounts
     * @name AddBankAccount
     * @summary افزودن حساب بانکی
     * @request POST:/api/v1/user/bank/account
     * @secure
     */
    addBankAccount: (data: AddAccountRequest, params: RequestParams = {}) =>
      this.request<BankAccountDTO, object>({
        path: `/api/v1/user/bank/account`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sell
     * @name SellToken
     * @summary ثبت درخواست خروج از سهامداری (بازخرید)
     * @request POST:/api/v1/user/sell/stock
     * @secure
     */
    sellToken: (data: SellTokenRequest, params: RequestParams = {}) =>
      this.request<SellTokenResponse, object>({
        path: `/api/v1/user/sell/stock`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name EditPersonalInfo
     * @summary ویرایش اطلاعات کاربر
     * @request POST:/api/v1/user/profile/personal-info
     * @secure
     */
    editPersonalInfo: (
      data: EditPersonalInfoRequest,
      params: RequestParams = {}
    ) =>
      this.request<UserDTO, object>({
        path: `/api/v1/user/profile/personal-info`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name EditPersonalInfo1
     * @summary ویرایش اطلاعات شرکت
     * @request POST:/api/v1/user/profile/company-info
     * @secure
     */
    editPersonalInfo1: (
      data: EditUserCompanyInfoRequest,
      params: RequestParams = {}
    ) =>
      this.request<UserDTO, object>({
        path: `/api/v1/user/profile/company-info`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name EditBatchInfo
     * @summary ویرایش اطلاعات کاربر و شرکت
     * @request POST:/api/v1/user/profile/batch-info
     * @secure
     */
    editBatchInfo: (
      data: LegalUserFullInfoRequest,
      params: RequestParams = {}
    ) =>
      this.request<UserDTO, object>({
        path: `/api/v1/user/profile/batch-info`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stock Paper
     * @name StockPaperRequest
     * @summary ثبت درخواست برگه سهام
     * @request POST:/api/v1/user/paper/request
     * @secure
     */
    stockPaperRequest: (data: RequestStockPaper, params: RequestParams = {}) =>
      this.request<object, object>({
        path: `/api/v1/user/paper/request`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Docs
     * @name AddUserDocs
     * @summary ثبت مدارک
     * @request POST:/api/v1/user/docs/submit
     * @secure
     */
    addUserDocs: (data: AddDocRequest, params: RequestParams = {}) =>
      this.request<object, object>({
        path: `/api/v1/user/docs/submit`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Buy Stock
     * @name BuyToken
     * @summary ثبت درخواست خرید سهم
     * @request POST:/api/v1/user/buy/stock
     * @secure
     */
    buyToken: (data: BuyTokenRequest, params: RequestParams = {}) =>
      this.request<BuyTokenResponse, object>({
        path: `/api/v1/user/buy/stock`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name TakePermissionFromUser
     * @summary گرفتن مجوز از کاربر
     * @request POST:/api/v1/staff/user-management/permission/take
     * @secure
     */
    takePermissionFromUser: (
      data: PermissionRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        (
          | "MOBILE_VERIFIED"
          | "DOCS_VERIFIED"
          | "MEMBERSHIP_VERIFIED"
          | "BOARD_MEMBER"
        )[],
        object
      >({
        path: `/api/v1/staff/user-management/permission/take`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name GivePermissionToUser
     * @summary دادن مجوز به کاربر
     * @request POST:/api/v1/staff/user-management/permission/give
     * @secure
     */
    givePermissionToUser: (
      data: PermissionRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        (
          | "MOBILE_VERIFIED"
          | "DOCS_VERIFIED"
          | "MEMBERSHIP_VERIFIED"
          | "BOARD_MEMBER"
        )[],
        object
      >({
        path: `/api/v1/staff/user-management/permission/give`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name CreateCompanyAccount
     * @summary ساخت حساب کاربری شرکت
     * @request POST:/api/v1/staff/user-management/create/company
     * @secure
     */
    createCompanyAccount: (
      data: CreateCompanyUserRequest,
      params: RequestParams = {}
    ) =>
      this.request<UserDTO, object>({
        path: `/api/v1/staff/user-management/create/company`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description پسورد دیفالت موبایل کاربر و یوزرنیم دیفایلت کدملی + پسوند رول است.
     *
     * @tags Users
     * @name CreateSupport
     * @summary ساخت اکانت برای کاربر و تغییر نقش آن
     * @request POST:/api/v1/staff/user-management/assign/role
     * @secure
     */
    createSupport: (data: AssignUserRoleRequest, params: RequestParams = {}) =>
      this.request<UserDTO, object>({
        path: `/api/v1/staff/user-management/assign/role`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CEO Transactions Management
     * @name VerifyTransaction
     * @summary تایید تراکنش
     * @request POST:/api/v1/staff/transaction/verify-transaction/{id}
     * @secure
     */
    verifyTransaction: (id: string, params: RequestParams = {}) =>
      this.request<TransactionDTO, object>({
        path: `/api/v1/staff/transaction/verify-transaction/${id}`,
        method: "POST",
        secure: true,
        ...params,
      }),
    /**
     * No description
     *
     * @tags CEO Transactions Management
     * @name VerifyBankReceipt
     * @summary تایید فیش بانکی
     * @request POST:/api/v1/staff/transaction/verify-bank-receipt
     * @secure
     */
    verifyBankReceipt: (data: any, params: RequestParams = {}) =>
      this.request<ReceiptDTO, object>({
        path: `/api/v1/staff/transaction/verify-bank-receipt`,
        method: "POST",
        body: data,
        secure: true,
        ...params,
      }),
    /**
     * No description
     *
     * @tags CEO Transactions Management
     * @name RejectBankReceipt
     * @summary تایید فیش بانکی
     * @request POST:/api/v1/staff/transaction/reject-bank-receipt
     * @secure
     */
    rejectBankReceipt: (data: any, params: RequestParams = {}) =>
      this.request<GeneralResponse, object>({
        path: `/api/v1/staff/transaction/reject-bank-receipt`,
        method: "POST",
        body: data,
        secure: true,
        ...params,
      }),
    /**
     * No description
     *
     * @tags CEO Transactions Management
     * @name VerifyMembership
     * @summary تایید سهامداری عضو
     * @request POST:/api/v1/staff/transaction/verify-membership/{id}
     * @secure
     */
    verifyMembership: (id: string, params: RequestParams = {}) =>
      this.request<GeneralResponse, object>({
        path: `/api/v1/staff/transaction/verify-membership/${id}`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CEO Transactions Management
     * @name SignToken
     * @summary تایید و امضای تراکنش جهت انتقال سهم
     * @request POST:/api/v1/staff/transaction/sign
     * @secure
     */
    signToken: (data: SignTokenRequest, params: RequestParams = {}) =>
      this.request<TransactionDTO, object>({
        path: `/api/v1/staff/transaction/sign`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CEO Transactions Management
     * @name RejectToken
     * @summary رد تراکنش
     * @request POST:/api/v1/staff/transaction/reject-transaction
     * @secure
     */
    rejectToken: (data: RejectTokenRequest, params: RequestParams = {}) =>
      this.request<
        | "CREATED"
        | "MODIFIED"
        | "CANCELED"
        | "EXPIRED"
        | "PENDING_FOR_PAY"
        | "PENDING_FOR_VERIFY_DOCS"
        | "PENDING_FOR_VERIFY_MEMBERSHIP"
        | "PENDING_FOR_VERIFY_TRANSACTION"
        | "PENDING_FOR_CREATE_IN_BC"
        | "PENDING_FOR_CEO_SIGN"
        | "PENDING_FOR_SIGN_IN_BC"
        | "REJECT_BC_SIGN"
        | "REJECT_BC"
        | "APPROVED_BC"
        | "REJECT_SIGN"
        | "REJECT_MEMBERSHIP",
        object
      >({
        path: `/api/v1/staff/transaction/reject-transaction`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CEO Transactions Management
     * @name RejectMembership
     * @summary رد سهامداری عضو
     * @request POST:/api/v1/staff/transaction/reject-membership
     * @secure
     */
    rejectMembership: (
      data: RejectMembershipRequest,
      params: RequestParams = {}
    ) =>
      this.request<GeneralResponse, object>({
        path: `/api/v1/staff/transaction/reject-membership`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stock Papers
     * @name StockPaperReject
     * @summary رد درخواست برگه سهام
     * @request POST:/api/v1/staff/stock/paper/reject
     * @secure
     */
    stockPaperReject: (
      data: RejectStockPaperRequest,
      params: RequestParams = {}
    ) =>
      this.request<object, object>({
        path: `/api/v1/staff/stock/paper/reject`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stock Papers
     * @name StockPaperAccept
     * @summary قبول درخواست برگه سهام
     * @request POST:/api/v1/staff/stock/paper/accept/{stockId}
     * @secure
     */
    stockPaperAccept: (stockId: string, params: RequestParams = {}) =>
      this.request<object, object>({
        path: `/api/v1/staff/stock/paper/accept/${stockId}`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CEO Verification Docs
     * @name RejectAllDocs
     * @summary رد کاربر بر اساس مدرک و فیلد و دلیل
     * @request POST:/api/v1/staff/docs/reject/batch
     * @secure
     */
    rejectAllDocs: (
      data: RejectDocsAndFieldsRequest,
      params: RequestParams = {}
    ) =>
      this.request<GeneralResponse, object>({
        path: `/api/v1/staff/docs/reject/batch`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CEO Verification Docs
     * @name RejectUser
     * @summary رد کاربر بدون ارسال مدرک
     * @request POST:/api/v1/staff/docs/reject/all
     * @secure
     */
    rejectUser: (data: RejectUserRequest, params: RequestParams = {}) =>
      this.request<GeneralResponse, object>({
        path: `/api/v1/staff/docs/reject/all`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CEO Verification Docs
     * @name PendingDoc
     * @summary تغییر وضعیت مدرک به حالت در حال انتظار
     * @request POST:/api/v1/staff/docs/pending
     * @secure
     */
    pendingDoc: (data: SetDocStatusRequest, params: RequestParams = {}) =>
      this.request<UserDocDTO, object>({
        path: `/api/v1/staff/docs/pending`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CEO Verification Docs
     * @name ApproveAllDoc
     * @summary تایید تمامی مدارک کاربر به صورت یکجا
     * @request POST:/api/v1/staff/docs/approve/all
     * @secure
     */
    approveAllDoc: (data: ApproveAllDocsRequest, params: RequestParams = {}) =>
      this.request<GeneralResponse, object>({
        path: `/api/v1/staff/docs/approve/all`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CEO Company Details
     * @name ReadStockInfo
     * @summary ثبت تاریخچه شرکت و سهامداران
     * @request POST:/api/v1/staff/company/import/history
     * @secure
     */
    readStockInfo: (data: string, params: RequestParams = {}) =>
      this.request<GeneralResponse, object>({
        path: `/api/v1/staff/company/import/history`,
        method: "POST",
        // query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CEO Company Details
     * @name AddCompanyDetail
     * @summary افزودن و بروزرسانی پارامترهای شرکت و عرضه
     * @request POST:/api/v1/staff/company/detail
     * @secure
     */
    addCompanyDetail: (
      data: CompanyDetailRequest,
      params: RequestParams = {}
    ) =>
      this.request<CompanyDetailDTO, object>({
        path: `/api/v1/staff/company/detail`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CEO Company Details
     * @name AddCompanyDetail
     * @summary افزودن و بروزرسانی پارامترهای شرکت و عرضه
     * @request POST:/api/v1/staff/company/detail
     * @secure
     */
    editCompanyDetail: (id: string , params: RequestParams = {}) =>
      this.request<CompanyDetailDTO,object>({
        path:`/api/v1/staff/company/detail/one?${"id="+id}`,
        method: "GET",
        ...params,
      }),

    /**
     * @summary  لیست پارامتر های  ه سهام
     * @request GET:/api/v1/staff/company/detail/list
     */
    listCompanyDetail: (params: RequestParams = {}) =>
      this.request<CompanyDetailDTO, object>({
        path: `/api/v1/staff/company/detail/list`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dev
     * @name delete
     * @request POST:/api/v1/staff/company/detail/delete/${id}
     */
    deleteCompanyDetail: (id: string, params: RequestParams = {}) =>
      this.request<CompanyDetailDTO, object>({
        path: `/api/v1/staff/company/detail/delete/${id}`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dev
     * @name APPROVED
     * @request /api/v1/staff/company/detail/status/${id}/APPROVED
     */
    approvedCompanyDetail: (id: string, params: RequestParams = {}) =>
      this.request<CompanyDetailDTO, object>({
        path: `/api/v1/staff/company/detail/status/${id}/APPROVED`,
        method: "POST",
        ...params,
      }),
    /**
     * No description
     *
     * @tags Dev
     * @name REJECTED
     * @request /api/v1/staff/company/detail/status/${id}/REJECTED
     */
    rejectedCompanyDetail: (id: string, params: RequestParams = {}) =>
      this.request<CompanyDetailDTO, object>({
        path: `/api/v1/staff/company/detail/status/${id}/REJECTED`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *@tags CEO Company Details
     * @name ImportCompanyDetailsHistory
     * @summary افزودن تاریخچه و عرضه های قبلی شرکت
     * @request POST:/api/v1/staff/company/detail/history
     * @secure
     */
    importCompanyDetailsHistory: (
      data: CompanyDetailRequest,
      params: RequestParams = {}
    ) =>
      this.request<CompanyDetailDTO, object>({
        path: `/api/v1/staff/company/detail/history`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description only users that have permission ISSUE can do this action. currently only issue on ceo wallet is acceptable.
     *
     * @tags CEO Blockchain
     * @name IssueToken
     * @summary ساخت (ایشو) توکن در ولت مرکزی
     * @request POST:/api/v1/staff/blockchain/token/issue
     * @secure
     */
    issueToken: (data: IssueTokenRequest, params: RequestParams = {}) =>
      this.request<TransactionDTO, object>({
        path: `/api/v1/staff/blockchain/token/issue`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CEO Blockchain
     * @name CreateRole
     * @summary ساخت نقش در بلاکچین
     * @request POST:/api/v1/staff/blockchain/role
     * @secure
     */
    createRole: (data: CreateRoleRequest, params: RequestParams = {}) =>
      this.request<BlockchainRole, object>({
        path: `/api/v1/staff/blockchain/role`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CEO Blockchain
     * @name RevokeBoardMember
     * @summary عزل نقش از کاربر در بلاکچین
     * @request POST:/api/v1/staff/blockchain/revoke
     * @secure
     */
    revokeBoardMember: (
      data: RevokeBoardMemberRequest,
      params: RequestParams = {}
    ) =>
      this.request<BlockchainUser, object>({
        path: `/api/v1/staff/blockchain/revoke`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CEO Blockchain
     * @name EnrollBoardMember
     * @summary ارسال فایل csr و درخواست عضویت در هیات مدیره و دریافت فایل certificate
     * @request POST:/api/v1/staff/blockchain/enroll
     * @secure
     */
    enrollBoardMember: (
      data: EnrollBoardMemberRequest,
      params: RequestParams = {}
    ) =>
      this.request<BlockchainUser, object>({
        path: `/api/v1/staff/blockchain/enroll`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CEO Blockchain
     * @name AddUserAsBoardMember
     * @summary تخصیص نقش به کاربر در بلاکچین
     * @request POST:/api/v1/staff/blockchain/assign
     * @secure
     */
    addUserAsBoardMember: (
      data: AssignAsBoardMemberRequest,
      params: RequestParams = {}
    ) =>
      this.request<BlockchainUser, object>({
        path: `/api/v1/staff/blockchain/assign`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dev
     * @name DeleteUserByUserIdAndAllRecords
     * @request POST:/api/v1/ops/user/delete/{userId}/{pw}
     */
    deleteUserByUserIdAndAllRecords: (
      userId: string,
      pw: string,
      params: RequestParams = {}
    ) =>
      this.request<GeneralResponse, object>({
        path: `/api/v1/ops/user/delete/${userId}/${pw}`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dev
     * @name MigrateDbRefs
     * @request POST:/api/v1/ops/migrate/dbref/{pw}
     */
    migrateDbRefs: (pw: string, params: RequestParams = {}) =>
      this.request<GeneralResponse, object>({
        path: `/api/v1/ops/migrate/dbref/${pw}`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dev
     * @name MigrateCompanyDetails
     * @request POST:/api/v1/ops/migrate/companyDetails
     */
    migrateCompanyDetails: (params: RequestParams = {}) =>
      this.request<string, object>({
        path: `/api/v1/ops/migrate/companyDetails`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dev
     * @name ResetDb
     * @request POST:/api/v1/ops/db/delete/{pw}
     */
    resetDb: (pw: string, params: RequestParams = {}) =>
      this.request<GeneralResponse, object>({
        path: `/api/v1/ops/db/delete/${pw}`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dev
     * @name EnableBlockchainProcessing
     * @summary فعال / غیر فعال سازی صف پردازش بلاکچین
     * @request POST:/api/v1/ops/blockchain/queue/{status}
     * @secure
     */
    enableBlockchainProcessing: (
      status: "ENABLE" | "DISABLE",
      params: RequestParams = {}
    ) =>
      this.request<string, object>({
        path: `/api/v1/ops/blockchain/queue/${status}`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * @description files send as multipart file request.<br/>tag is optional.
     *
     * @tags Files
     * @name AddFile
     * @summary آپلود فایل
     * @request POST:/api/v1/files/upload
     * @secure
     */
    addFile: (
      data: { file?: File },
      query?: { tag?: string },
      params: RequestParams = {}
    ) =>
      this.request<object, object>({
        path: `/api/v1/files/upload`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chairman
     * @name RejectPendingDetail
     * @summary رد اطلاعات شرکت توسط نماینده مدیرعامل
     * @request POST:/api/v1/chairman/details/reject
     * @secure
     */
    rejectPendingDetail: (
      data: RejectCompanyDetailsRequest,
      params: RequestParams = {}
    ) =>
      this.request<CompanyDetail, object>({
        path: `/api/v1/chairman/details/reject`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chairman
     * @name GeneratePin
     * @summary ساخت پین برای تایید یا رد تراکنش
     * @request POST:/api/v1/chairman/details/pin
     * @secure
     */
    generatePin: (
      data: GenerateCompanyDetailsPinRequest,
      params: RequestParams = {}
    ) =>
      this.request<GeneralResponse, object>({
        path: `/api/v1/chairman/details/pin`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chairman
     * @name ApprovePendingDetail
     * @summary تایید اطلاعات شرکت توسط نماینده مدیرعامل
     * @request POST:/api/v1/chairman/details/approve
     * @secure
     */
    approvePendingDetail: (
      data: ApproveCompanyDetailsRequest,
      params: RequestParams = {}
    ) =>
      this.request<CompanyDetail, object>({
        path: `/api/v1/chairman/details/approve`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentication
     * @name VerifyMobile
     * @summary ثبت نام کاربران - مرحله ۲
     * @request POST:/api/v1/auth/verify/mobile
     */
    verifyMobile: (data: VerifyMobileRequest, params: RequestParams = {}) =>
      this.request<GeneralResponse, object>({
        path: `/api/v1/auth/verify/mobile`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentication
     * @name RefreshToken
     * @summary درخواست توکن جدید
     * @request POST:/api/v1/auth/token/refresh
     * @secure
     */
    refreshToken: (params: RequestParams = {}) =>
      this.request<LoginResponse, object>({
        path: `/api/v1/auth/token/refresh`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentication
     * @name RegisterSecondStep
     * @summary ثبت نام کاربران - مرحله ۳
     * @request POST:/api/v1/auth/step/2
     */
    registerSecondStep: (
      data: RegisterSecondStepRequest,
      params: RequestParams = {}
    ) =>
      this.request<LoginResponse, object>({
        path: `/api/v1/auth/step/2`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentication
     * @name RegisterFirstStep
     * @summary ثبت نام کاربران - مرحله ۱
     * @request POST:/api/v1/auth/step/1
     */
    registerFirstStep: (data: RegisterRequest, params: RequestParams = {}) =>
      this.request<GeneralResponse, object>({
        path: `/api/v1/auth/step/1`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentication
     * @name ChangePassword
     * @summary تغییر پسورد
     * @request POST:/api/v1/auth/password/change
     * @secure
     */
    changePassword: (data: ChangePasswordRequest, params: RequestParams = {}) =>
      this.request<UserDTO, object>({
        path: `/api/v1/auth/password/change`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentication
     * @name Login
     * @summary ورود کاربران - مرحله ۲
     * @request POST:/api/v1/auth/login/user/step/2
     */
    login: (data: LoginRequest, params: RequestParams = {}) =>
      this.request<LoginResponse, object>({
        path: `/api/v1/auth/login/user/step/2`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentication
     * @name VerifyMobile1
     * @summary ورود کاربران - مرحله ۱
     * @request POST:/api/v1/auth/login/user/step/1
     */
    verifyMobile1: (data: LoginPinRequest, params: RequestParams = {}) =>
      this.request<GeneralResponse, object>({
        path: `/api/v1/auth/login/user/step/1`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentication
     * @name Login1
     * @summary ورود ادمین - مرحله ۲
     * @request POST:/api/v1/auth/login/staff/step/2
     * @secure
     */
    login1: (data: StaffLoginRequest, params: RequestParams = {}) =>
      this.request<LoginResponse, object>({
        path: `/api/v1/auth/login/staff/step/2`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description <b>IMPORTANT</b> <br/> ---------- <br/> default pin for first time login is `12345`.<br/> default password is `admin`.<br/> mobile should be send as empty string for first time.<br/> set `mobile` & change `password` after first login!
     *
     * @tags Authentication
     * @name VerifyMobile2
     * @summary ورود ادمین - مرحله ۱
     * @request POST:/api/v1/auth/login/staff/step/1
     * @secure
     */
    verifyMobile2: (data: StaffLoginRequest, params: RequestParams = {}) =>
      this.request<GeneralResponse, object>({
        path: `/api/v1/auth/login/staff/step/1`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentication
     * @name CreateCeoUser
     * @summary ساخت حساب کاربری مدیرعامل
     * @request POST:/api/v1/auth/create/ceo
     */
    createCeoUser: (data: CreateStaffUser, params: RequestParams = {}) =>
      this.request<GeneralResponse, object>({
        path: `/api/v1/auth/create/ceo`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentication
     * @name CreateAdminUser
     * @summary ساخت حساب کاربری ادمین
     * @request POST:/api/v1/auth/create/admin
     */
    createAdminUser: (data: CreateStaffUser, params: RequestParams = {}) =>
      this.request<GeneralResponse, object>({
        path: `/api/v1/auth/create/admin`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name SacrificeStatus
     * @summary مشاهده انواع وضعیت نظام ایثارگری
     * @request GET:/api/v1/user/values/sacrifice-status
     * @secure
     */
    sacrificeStatus: (params: RequestParams = {}) =>
      this.request<Record<string, string>, object>({
        path: `/api/v1/user/values/sacrifice-status`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name Marital
     * @summary مشاهده انواع وضعیت تاهل
     * @request GET:/api/v1/user/values/marital-status
     * @secure
     */
    marital: (params: RequestParams = {}) =>
      this.request<Record<string, string>, object>({
        path: `/api/v1/user/values/marital-status`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name EducationLevelPairs
     * @summary مشاهده انواع وضعیت تحصیل
     * @request GET:/api/v1/user/values/education-levels
     * @secure
     */
    educationLevelPairs: (params: RequestParams = {}) =>
      this.request<Record<string, string>, object>({
        path: `/api/v1/user/values/education-levels`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name GetUserType
     * @summary مشاهده نوع سهامداری کاربر
     * @request GET:/api/v1/user/type
     * @secure
     */
    getUserType: (params: RequestParams = {}) =>
      this.request<UserTypeResponse, object>({
        path: `/api/v1/user/type`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * @description ascending if asc = true else descending
     *
     * @tags User Transactions
     * @name UserPendingTransactions
     * @summary سهام در دست بررسی
     * @request GET:/api/v1/user/transaction/list/pending
     * @secure
     */
    userPendingTransactions: (
      query?: { page?: number; size?: number; sort?: string; asc?: boolean },
      params: RequestParams = {}
    ) =>
      this.request<PageUserTransactionPendingDTO, object>({
        path: `/api/v1/user/transaction/list/pending`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description ascending if asc = true else descending
     *
     * @tags User Transactions
     * @name UserOfferingStockTransactions
     * @summary لیست سهام عرضه شده کاربر
     * @request GET:/api/v1/user/transaction/list/offerings
     * @secure
     */
    userOfferingStockTransactions: (
      query?: { page?: number; size?: number; sort?: string; asc?: boolean },
      params: RequestParams = {}
    ) =>
      this.request<PageUserTransactionOfferingDTO, object>({
        path: `/api/v1/user/transaction/list/offerings`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description ascending if asc = true else descending
     *
     * @tags User Transactions
     * @name UserHistoryStockTransactions
     * @summary تاریخچه سهام
     * @request GET:/api/v1/user/transaction/list/history
     * @secure
     */
    userHistoryStockTransactions: (
      query?: { page?: number; size?: number; sort?: string; asc?: boolean },
      params: RequestParams = {}
    ) =>
      this.request<PageUserTransactionHistoryDTO, object>({
        path: `/api/v1/user/transaction/list/history`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserProfile
     * @summary پروفایل کاربر
     * @request GET:/api/v1/user/profile
     * @secure
     */
    userProfile: (params: RequestParams = {}) =>
      this.request<UserDTO, object>({
        path: `/api/v1/user/profile`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stock Paper
     * @name AllStockPapers
     * @summary لیست برگ سهام کاربر
     * @request GET:/api/v1/user/paper/list
     * @secure
     */
    allStockPapers: (
      query?: { page?: number; size?: number; sort?: string; asc?: boolean },
      params: RequestParams = {}
    ) =>
      this.request<PageStockPaperUserDTO, object>({
        path: `/api/v1/user/paper/list`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Docs
     * @name ViewUserDocs
     * @summary مشاهده مدارک کاربر
     * @request GET:/api/v1/user/docs/view
     * @secure
     */
    viewUserDocs: (params: RequestParams = {}) =>
      this.request<UserDocDTO[], object>({
        path: `/api/v1/user/docs/view`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Docs
     * @name UserAuthStatus
     * @summary مشاهده وضعیت مدارک احراز هویت کاربر
     * @request GET:/api/v1/user/docs/view/auth
     * @secure
     */
    userAuthStatus: (params: RequestParams = {}) =>
      this.request<UserAuthStatus, object>({
        path: `/api/v1/user/docs/view/auth`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Docs
     * @name AllDocTypes
     * @summary مشاهده لیست نوع مدارک قابل آپلود
     * @request GET:/api/v1/user/docs/types
     * @secure
     */
    allDocTypes: (params: RequestParams = {}) =>
      this.request<
        (
          | "ID_CARD_P_1_2"
          | "ID_CARD_P_3_4"
          | "ID_CARD_P_5_6"
          | "NATIONAL_CARD_P_1"
          | "NATIONAL_CARD_P_2"
          | "DUTY_CARD_P_1"
          | "DUTY_CARD_P_2"
          | "SIGN_SAMPLE"
          | "MANAGING_DIRECTOR_SIGN_SAMPLE"
          | "RECENT_GENERAL_ORDINARY_CHANGE"
          | "RECENT_MD_CHANGE"
          | "FOUNDED_AD"
          | "PERSONAL_PHOTO"
          | "MAIN_BANK_CART"
          | "LAST_EDUCATION"
        )[],
        object
      >({
        path: `/api/v1/user/docs/types`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User Dashboard
     * @name GetStockInfo
     * @summary وضعیت سهام من
     * @request GET:/api/v1/user/dashboard/assets
     * @secure
     */
    getStockInfo: (params: RequestParams = {}) =>
      this.request<StockResponse, object>({
        path: `/api/v1/user/dashboard/assets`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * @description for backend tests purpose.
     *
     * @tags Debug Mode
     * @name Status
     * @summary test mode
     * @request GET:/api/v1/test/up
     */
    status: (params: RequestParams = {}) =>
      this.request<GeneralResponse, object>({
        path: `/api/v1/test/up`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stocks
     * @name UserTokenStatistics
     * @summary مشاهده وضعیت سهام کاربر
     * @request GET:/api/v1/stock/statistics/user
     * @secure
     */
    userTokenStatistics: (params: RequestParams = {}) =>
      this.request<UserStockStatistics, object>({
        path: `/api/v1/stock/statistics/user`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stocks
     * @name ByUserType
     * @summary مشاهده وضعیت سهام بر اساس نوع سهامداری
     * @request GET:/api/v1/stock/statistics/user-type
     * @secure
     */
    byUserType: (params: RequestParams = {}) =>
      this.request<UserTypeStockStatistics, object>({
        path: `/api/v1/stock/statistics/user-type`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stocks
     * @name TokenStatistics
     * @summary مشاهده وضعیت سهام موجود در سیستم
     * @request GET:/api/v1/stock/statistics/total
     * @secure
     */
    tokenStatistics: (params: RequestParams = {}) =>
      this.request<TotalStockStatistics, object>({
        path: `/api/v1/stock/statistics/total`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CEO Transactions Management
     * @name ViewTransactionUserDetailInfo
     * @summary مشاهده اطلاعات تکمیلی کاربر
     * @request GET:/api/v1/staff/transaction/user-details/{transactionId}
     * @secure
     */
    viewTransactionUserDetailInfo: (
      transactionId: string,
      params: RequestParams = {}
    ) =>
      this.request<TransactionUserDetailInfo, object>({
        path: `/api/v1/staff/transaction/user-details/${transactionId}`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * @description ascending if asc = true else descending / side can be buy/sell <br/> دسترسی به تراکنش ها بر اساس size و page و sort
     *
     * @tags CEO Transactions Management
     * @name TransactionsList
     * @summary  لیست خرید و لیست بازخرید
     * @request GET:/api/v1/staff/transaction/list/{side}/{status}
     * @secure
     */
    transactionsList: (
      status: "all" | "pending" | "approved" | "reject" | "paid" | "receipts",
      side: "buy" | "sell" | "issue" | "buyissue" | "all",
      query?: { page?: number; size?: number; sort?: string; asc?: boolean },
      params: RequestParams = {}
    ) =>
      this.request<PageTransactionDTO, object>({
        path: `/api/v1/staff/transaction/list/${side}/${status}`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
    /**
     * @description
     *
     * @tags CEO Transactions Management
     * @name ViewBankReceipt
     * @summary  مشاهده اطلاعات فیش بانکی
     * @request GET:/api/v1/staff/transaction/view-bank-receipt/{transactionId}
     * @secure
     */
    viewReceipt: (transactionId: string, params: RequestParams = {}) =>
      this.request<BankReceiptDetailDTO, object>({
        path: `/api/v1/staff/transaction/view-bank-receipt/${transactionId}`,
        method: "GET",
        secure: true,
        ...params,
      }),
    /**
     * @description ascending if asc = true else descending
     *
     * @tags CEO Transactions Management
     * @name TransactionsDetailsList
     * @summary جزییات تراکنش ها
     * @request GET:/api/v1/staff/transaction/detail/list
     * @secure
     */
    transactionsDetailsList: (
      query?: {
        page?: number;
        size?: number;
        sort?: string;
        asc?: boolean;
        withdraw?: boolean;
        deposit?: boolean;
        userType?: "None" | "Member" | "NonMember" | "NonMemberInvestor";
        ostan?: number;
        from?: string;
        to?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageTransactionDetailDTO, object>({
        path: `/api/v1/staff/transaction/detail/list`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description ascending if asc = true else descending
     *
     * @tags CEO Transactions Management
     * @name TransactionsDailyList
     * @summary تراکنش های اخیر
     * @request GET:/api/v1/staff/transaction/daily/list
     * @secure
     */
    transactionsDailyList: (
      query?: {
        page?: number;
        size?: number;
        sort?: string;
        asc?: boolean;
        withdraw?: boolean;
        deposit?: boolean;
        userType?: "None" | "Member" | "NonMember" | "NonMemberInvestor";
        ostan?: number;
        from?: string;
        to?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageDailyTransactionResponse, object>({
        path: `/api/v1/staff/transaction/daily/list`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stock Papers
     * @name AllStockPapers1
     * @summary لیست تمام درخواست های برگه سهام بر حسب وضعیت
     * @request GET:/api/v1/staff/stock/list/{status}
     * @secure
     */
    allStockPapers1: (
      status: "all" | "pending" | "approved" | "reject",
      query?: { page?: number; size?: number; sort?: string; asc?: boolean },
      params: RequestParams = {}
    ) =>
      this.request<PageStockPaperAdminDTO, object>({
        path: `/api/v1/staff/stock/list/${status}`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CEO Verification Docs
     * @name UserAuthStatus1
     * @summary مشاهده وضعیت مدارک احراز هویت کاربر
     * @request GET:/api/v1/staff/docs/view
     * @secure
     */
    userAuthStatus1: (
      query: { username: string },
      params: RequestParams = {}
    ) =>
      this.request<UserAuthStatusResponse, object>({
        path: `/api/v1/staff/docs/view`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CEO Verification Docs
     * @name UserDocs
     * @summary مشاهده مدارک و مستندات کاربر
     * @request GET:/api/v1/staff/docs/user
     * @secure
     */
    userDocs: (
      query: { status?: "PENDING" | "REJECT" | "APPROVED"; username: string },
      params: RequestParams = {}
    ) =>
      this.request<UserDocDTO[], object>({
        path: `/api/v1/staff/docs/user`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description ascending if asc = true else descending
     *
     * @tags CEO Verification Docs
     * @name UsersDocsList
     * @summary لیست مدارک کاربران بر اساس size و page و sort
     * @request GET:/api/v1/staff/docs/list/{status}
     * @secure
     */
    usersDocsList: (
      status: "verified" | "pending" | "ordered" | "all",
      query?: { page?: number; size?: number; sort?: string; asc?: boolean },
      params: RequestParams = {}
    ) =>
      this.request<PageUserVerificationDTO, object>({
        path: `/api/v1/staff/docs/list/${status}`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CEO Dashboard
     * @name TokensSummary
     * @summary تعداد سهام عضو و غیرعضو و سرمایه گذار
     * @request GET:/api/v1/staff/dashboard/summary/tokens
     * @secure
     */
    tokensSummary: (params: RequestParams = {}) =>
      this.request<TokensSummaryResponse, object>({
        path: `/api/v1/staff/dashboard/summary/tokens`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CEO Dashboard
     * @name OfferingSummary
     * @summary تعداد کل سهام و قیمت عرضه و بازه زمانی
     * @request GET:/api/v1/staff/dashboard/summary/offering
     * @secure
     */
    offeringSummary: (params: RequestParams = {}) =>
      this.request<StockSummaryResponse, object>({
        path: `/api/v1/staff/dashboard/summary/offering`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CEO Dashboard
     * @name StockTransactions
     * @summary آمار لحظه ای سهام
     * @request GET:/api/v1/staff/dashboard/stocks
     * @secure
     */
    stockTransactions: (params: RequestParams = {}) =>
      this.request<SummaryStockStatistics, object>({
        path: `/api/v1/staff/dashboard/stocks`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * @description use-> /api/v1/staff/docs/list/pending?args
     *
     * @tags CEO Dashboard
     * @name RecentRegisteredUser
     * @summary لیست متقاضیان
     * @request GET:/api/v1/staff/dashboard/requests
     * @secure
     */
    recentRegisteredUser: (params: RequestParams = {}) =>
      this.request<GeneralResponse, object>({
        path: `/api/v1/staff/dashboard/requests`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * @description لیست ۵ تراکنش آخر
     *
     * @tags CEO Dashboard
     * @name RecentTransactions
     * @summary تراکنش های اخیر
     * @request GET:/api/v1/staff/dashboard/recent-transactions
     * @secure
     */
    recentTransactions: (params: RequestParams = {}) =>
      this.request<TransactionDTO[], object>({
        path: `/api/v1/staff/dashboard/recent-transactions`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CEO Company Details
     * @name CompanyDetailCurrentApproved
     * @summary مشاهده پارامتر های تایید شده فعلی شرکت
     * @request GET:/api/v1/staff/company/detail/current
     * @secure
     */
    companyDetailCurrentApproved: (params: RequestParams = {}) =>
      this.request<CompanyDetailDTO, object>({
        path: `/api/v1/staff/company/detail/current`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CEO Blockchain
     * @name AllBlockchainWallets
     * @summary مشاهده کیف پول های ثبت شده در بلاکچین
     * @request GET:/api/v1/staff/blockchain/wallets/live
     * @secure
     */
    allBlockchainWallets: (params: RequestParams = {}) =>
      this.request<WalletInfo[], object>({
        path: `/api/v1/staff/blockchain/wallets/live`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CEO Blockchain
     * @name AllBlockchainTransactions
     * @summary مشاهده تراکنش های ثبت شده در بلاکچین
     * @request GET:/api/v1/staff/blockchain/transactions/live
     * @secure
     */
    allBlockchainTransactions: (params: RequestParams = {}) =>
      this.request<BTransaction[], object>({
        path: `/api/v1/staff/blockchain/transactions/live`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CEO Blockchain
     * @name SyncBlockchainRoles
     * @summary همگام سازی نقش های دیتابیس بانقش های موجود در بلاکچین
     * @request GET:/api/v1/staff/blockchain/role/sync
     * @secure
     */
    syncBlockchainRoles: (params: RequestParams = {}) =>
      this.request<BlockchainRole[], object>({
        path: `/api/v1/staff/blockchain/role/sync`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CEO Blockchain
     * @name AllLocalRoles
     * @summary مشاهده نقش های بلاکچین موجود در دیتابیس
     * @request GET:/api/v1/staff/blockchain/role/local
     * @secure
     */
    allLocalRoles: (params: RequestParams = {}) =>
      this.request<BlockchainRole[], object>({
        path: `/api/v1/staff/blockchain/role/local`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CEO Blockchain
     * @name AllBlockchainRoles
     * @summary مشاهده نقش های بلاکچین موجود در بلاکچین
     * @request GET:/api/v1/staff/blockchain/role/live
     * @secure
     */
    allBlockchainRoles: (params: RequestParams = {}) =>
      this.request<BRoleInfo[], object>({
        path: `/api/v1/staff/blockchain/role/live`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CEO Blockchain
     * @name AllBlockchainRequests
     * @summary مشاهده درخواست های ثبت شده در بلاکچین
     * @request GET:/api/v1/staff/blockchain/requests/live
     * @secure
     */
    allBlockchainRequests: (params: RequestParams = {}) =>
      this.request<BRequest[], object>({
        path: `/api/v1/staff/blockchain/requests/live`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CEO Blockchain
     * @name ViewEnrollmentId
     * @summary مشاهده enrollmentId یا common name برای ثبت در توکن سخت افزاری
     * @request GET:/api/v1/staff/blockchain/info/enrollment-id
     * @secure
     */
    viewEnrollmentId: (params: RequestParams = {}) =>
      this.request<EnrollmentIdResponse, object>({
        path: `/api/v1/staff/blockchain/info/enrollment-id`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CEO Blockchain
     * @name ViewCertificateFileId
     * @summary مشاهده id فایل certificate
     * @request GET:/api/v1/staff/blockchain/info/certificate/file
     * @secure
     */
    viewCertificateFileId: (params: RequestParams = {}) =>
      this.request<FileInfoResponse, object>({
        path: `/api/v1/staff/blockchain/info/certificate/file`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CEO Blockchain
     * @name ViewCertificate
     * @summary مشاهده فایل certificate به صورت plaintext
     * @request GET:/api/v1/staff/blockchain/info/certificate/dump
     * @secure
     */
    viewCertificate: (params: RequestParams = {}) =>
      this.request<string, object>({
        path: `/api/v1/staff/blockchain/info/certificate/dump`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CEO Blockchain
     * @name AllBlockchainBoardMembers
     * @summary مشاهده اعضای هیات مدیره ثبت شده در بلاکچین
     * @request GET:/api/v1/staff/blockchain/board-members/live
     * @secure
     */
    allBlockchainBoardMembers: (params: RequestParams = {}) =>
      this.request<BoardMemberInfo[], object>({
        path: `/api/v1/staff/blockchain/board-members/live`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Location
     * @name ShahrListByShahrestanId
     * @summary لیست شهر ها بر اساس id شهرستان
     * @request GET:/api/v1/public/locations/shahrestan/{shahrestanId}/shahr
     */
    shahrListByShahrestanId: (
      shahrestanId: string,
      params: RequestParams = {}
    ) =>
      this.request<ShahrDTO[], object>({
        path: `/api/v1/public/locations/shahrestan/${shahrestanId}/shahr`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Location
     * @name DehestanListByShahrestanId
     * @summary لیست دهستان ها بر اساس id شهرستان
     * @request GET:/api/v1/public/locations/shahrestan/{shahrestanId}/dehestan
     */
    dehestanListByShahrestanId: (
      shahrestanId: string,
      params: RequestParams = {}
    ) =>
      this.request<DehestanDTO[], object>({
        path: `/api/v1/public/locations/shahrestan/${shahrestanId}/dehestan`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Location
     * @name BakhshListByShahrestanId
     * @summary لیست بخش ها بر اساس id شهرستان
     * @request GET:/api/v1/public/locations/shahrestan/{shahrestanId}/bakhsh
     */
    bakhshListByShahrestanId: (
      shahrestanId: string,
      params: RequestParams = {}
    ) =>
      this.request<BakhshDTO[], object>({
        path: `/api/v1/public/locations/shahrestan/${shahrestanId}/bakhsh`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Location
     * @name OstanList
     * @summary لیست استان ها
     * @request GET:/api/v1/public/locations/ostan
     */
    ostanList: (params: RequestParams = {}) =>
      this.request<OstanDTO[], object>({
        path: `/api/v1/public/locations/ostan`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Location
     * @name ShahrestanListByOstanId
     * @summary لیست شهرستان ها بر اساس id استان
     * @request GET:/api/v1/public/locations/ostan/{ostanId}/shahrestan
     */
    shahrestanListByOstanId: (ostanId: string, params: RequestParams = {}) =>
      this.request<ShahrestanDTO[], object>({
        path: `/api/v1/public/locations/ostan/${ostanId}/shahrestan`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Location
     * @name ShahrListByOstanId
     * @summary لیست شهر ها بر اساس id استان
     * @request GET:/api/v1/public/locations/ostan/{ostanId}/shahr
     */
    shahrListByOstanId: (ostanId: string, params: RequestParams = {}) =>
      this.request<ShahrDTO[], object>({
        path: `/api/v1/public/locations/ostan/${ostanId}/shahr`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Location
     * @name DehestanListByOstanId
     * @summary لیست دهستان ها بر اساس id استان
     * @request GET:/api/v1/public/locations/ostan/{ostanId}/dehestan
     */
    dehestanListByOstanId: (ostanId: string, params: RequestParams = {}) =>
      this.request<DehestanDTO[], object>({
        path: `/api/v1/public/locations/ostan/${ostanId}/dehestan`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Location
     * @name BakhshListByOstanId
     * @summary لیست بخش ها بر اساس id استان
     * @request GET:/api/v1/public/locations/ostan/{ostanId}/bakhsh
     */
    bakhshListByOstanId: (ostanId: string, params: RequestParams = {}) =>
      this.request<BakhshDTO[], object>({
        path: `/api/v1/public/locations/ostan/${ostanId}/bakhsh`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Location
     * @name ShahrListByBakhshId
     * @summary لیست شهر ها بر اساس id بخش
     * @request GET:/api/v1/public/locations/bakhsh/{bakhshId}/shahr
     */
    shahrListByBakhshId: (bakhshId: string, params: RequestParams = {}) =>
      this.request<ShahrDTO[], object>({
        path: `/api/v1/public/locations/bakhsh/${bakhshId}/shahr`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Location
     * @name DehestanListByBakhshId
     * @summary لیست دهستان ها بر اساس id بخش
     * @request GET:/api/v1/public/locations/bakhsh/{bakhshId}/dehestan
     */
    dehestanListByBakhshId: (bakhshId: string, params: RequestParams = {}) =>
      this.request<DehestanDTO[], object>({
        path: `/api/v1/public/locations/bakhsh/${bakhshId}/dehestan`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Banks
     * @name AllBanks
     * @summary مشاهده همه بانک ها
     * @request GET:/api/v1/public/banks/all
     */
    allBanks: (params: RequestParams = {}) =>
      this.request<CompactBank[], object>({
        path: `/api/v1/public/banks/all`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dev
     * @name DumpStocks
     * @summary نمایش آمار کل سهام و فرمول ها
     * @request GET:/api/v1/ops/stocks/dump
     * @secure
     */
    dumpStocks: (params: RequestParams = {}) =>
      this.request<Record<string, string>, object>({
        path: `/api/v1/ops/stocks/dump`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * @description tag will return as `x-file-tag` header param if exists.
     *
     * @tags Files
     * @name GetFile
     * @summary دانلود/مشاهده فایل
     * @request GET:/api/v1/files/{id}
     * @secure
     */
    getFile: (
      id: string,
      query?: { base64?: boolean },
      params: RequestParams = {}
    ) =>
      this.request<object, object>({
        path: `/api/v1/files/${id}`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chairman
     * @name CompanyDetailHistory
     * @summary مشاهده لیست پارامتر های شرکت و عرضه
     * @request GET:/api/v1/chairman/details/list
     * @secure
     */
    companyDetailHistory: (
      query?: { page?: number; size?: number; sort?: string; asc?: boolean },
      params: RequestParams = {}
    ) =>
      this.request<PageCompanyDetailDTO, object>({
        path: `/api/v1/chairman/details/list`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank Accounts
     * @name DeleteBankAccount
     * @summary حذف حساب بانکی
     * @request DELETE:/api/v1/user/bank/account/{id}
     * @secure
     */
    deleteBankAccount: (
      id: string,
      query?: { auth?: boolean },
      params: RequestParams = {}
    ) =>
      this.request<object, object>({
        path: `/api/v1/user/bank/account/${id}`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),

    /////////////////////////////////////////از اینجا به بعد مربوط به سرچ میباشد

    /**
     * @tags CEO Verification Docs
     * @name searchInDashboard
     * @summary سرچ مدارک کاربران بر اساس نام و نام خ و کد ملی
     * @request GET:/api/v1/staff/docs/listII/pending
     * @secure
     */

    searchNcInDashboard: (
      status: "verified" | "pending" | "ordered" | "all",
      query?: {
        page?: number;
        size?: number;
        sort?: string;
        asc?: boolean;
        nc?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageUserVerificationDTO, object>({
        path: `/api/v1/staff/docs/list/nc/${status}`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
    searchMobInDashboard: (
      status: "verified" | "pending" | "ordered" | "all",
      query?: {
        page?: number;
        size?: number;
        sort?: string;
        asc?: boolean;
        mob?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageUserVerificationDTO, object>({
        path: `/api/v1/staff/docs/list/mob/${status}`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
    searchNameInDashboard: (
      status: "verified" | "pending" | "ordered" | "all",
      query?: {
        page?: number;
        size?: number;
        sort?: string;
        asc?: boolean;
        name?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageUserVerificationDTO, object>({
        path: `/api/v1/staff/docs/list/name/${status}`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
    searchLastNameInDashboard: (
      status: "verified" | "pending" | "ordered" | "all",
      query?: {
        page?: number;
        size?: number;
        sort?: string;
        asc?: boolean;
        last?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageUserVerificationDTO, object>({
        path: `/api/v1/staff/docs/list/last/${status}`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
    /**
     * @description ascending if asc = true else descending
     * @tags CEO Verification Docs
     * @name searchInRecentTransactionInDashboard
     * @summary سرچ تراکنش های اخیرپیشخوان بر اساس نام و نام خ
     * @request GET:/api/v1/staff/transaction/daily/listII
     * @secure
     */
    searchInRecentTransactionInDashboard: (
      query?: { name?: string; family?: string },
      params: RequestParams = {}
    ) =>
      this.request<PageDailyTransactionResponse, object>({
        path: `/api/v1/staff/transaction/daily/listII`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @tags CEO Verification Docs
     * @name searchTransactionDetailsInDashboard
     * @summary سرچ  جزئیات تراکنش های پیشخوان بر اساس نام و نام خ
     * @request GET:api/v1/staff/transaction/detail/listII
     * @secure
     */
    searchTransactionDetailsInDashboard: (
      query?: { name?: string; family?: string },
      params: RequestParams = {}
    ) =>
      this.request<PageTransactionDetailDTO, object>({
        path: `/api/v1/staff/transaction/detail/listII`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @tags CEO Verification Docs
     * @name searchInNewAuthenticationRequests
     * @summary سرچ  تایید هویت /درخواست‌های جدید بر اساس نام و نام خ و کد ملی
     * @request GET:/api/v1/staff/docs/listII/pending
     * @secure
     */
    searchNcInNewAuthenticationRequests: (
      status: "verified" | "pending" | "ordered" | "all",
      query?: {
        page?: number;
        size?: number;
        sort?: string;
        asc?: boolean;
        nc?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageUserVerificationDTO, object>({
        path: `/api/v1/staff/docs/list/nc/${status}`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
    searchNameInNewAuthenticationRequests: (
      status: "verified" | "pending" | "ordered" | "all",
      query?: {
        page?: number;
        size?: number;
        sort?: string;
        asc?: boolean;
        name?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageUserVerificationDTO, object>({
        path: `/api/v1/staff/docs/list/name/${status}`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
    searchMobInNewAuthenticationRequests: (
      status: "verified" | "pending" | "ordered" | "all",
      query?: {
        page?: number;
        size?: number;
        sort?: string;
        asc?: boolean;
        mob?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageUserVerificationDTO, object>({
        path: `/api/v1/staff/docs/list/mob/${status}`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
    searchLastNameInNewAuthenticationRequests: (
      status: "verified" | "pending" | "ordered" | "all",
      query?: {
        page?: number;
        size?: number;
        sort?: string;
        asc?: boolean;
        last?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageUserVerificationDTO, object>({
        path: `/api/v1/staff/docs/list/last/${status}`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
    /**
     * @tags CEO Verification Docs
     * @name searchInVerifiedAuthentication
     * @summary سرچ  تایید هویت /تایید شده بر اساس نام و نام خ
     * @request GET:/api/v1/staff/docs/listII/verified
     * @secure
     */
    searchNameInVerifiedAuthentication: (
      status: "verified" | "pending" | "ordered" | "all",
      query?: {
        page?: number;
        size?: number;
        sort?: string;
        asc?: boolean;
        name?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageUserVerificationDTO, object>({
        path: `/api/v1/staff/docs/list/name/${status}`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
    searchMobInVerifiedAuthentication: (
      status: "verified" | "pending" | "ordered" | "all",
      query?: {
        page?: number;
        size?: number;
        sort?: string;
        asc?: boolean;
        mob?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageUserVerificationDTO, object>({
        path: `/api/v1/staff/docs/list/mob/${status}`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
    searchLastNameInVerifiedAuthentication: (
      status: "verified" | "pending" | "ordered" | "all",
      query?: {
        page?: number;
        size?: number;
        sort?: string;
        asc?: boolean;
        last?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageUserVerificationDTO, object>({
        path: `/api/v1/staff/docs/list/last/${status}`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
    /**
     * @tags CEO Verification Docs
     * @name searchPrePurchaseRequestIdentityVerification
     * @summary سرچ  تایید هویت /درخواست پیش خرید بر اساس نام و نام خ
     * @request GET:/api/v1/staff/docs/listII/ordered
     * @secure
     */
    searchNamePrePurchaseRequestIdentityVerification: (
      status: "verified" | "pending" | "ordered" | "all",
      query?: {
        page?: number;
        size?: number;
        sort?: string;
        asc?: boolean;
        name?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageUserVerificationDTO, object>({
        path: `/api/v1/staff/docs/list/name/${status}`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    searchLastNamePrePurchaseRequestIdentityVerification: (
      status: "verified" | "pending" | "ordered" | "all",
      query?: {
        page?: number;
        size?: number;
        sort?: string;
        asc?: boolean;
        last?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageUserVerificationDTO, object>({
        path: `/api/v1/staff/docs/list/last/${status}`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @tags CEO Verification Docs
     * @name searchAllRequestsForAuthentication
     * @summary سرچ  تایید هویت /همه درخواست ها بر اساس کد ملی
     * @request GET:/api/v1/staff/docs/listII/all
     * @secure
     */
    searchNcAllRequestsForAuthentication: (
      query?: {
        page?: number;
        size?: number;
        sort?: string;
        asc?: boolean;
        nc?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageUserVerificationDTO, object>({
        path: `/api/v1/staff/docs/list/nc/all`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
    searchNameAllRequestsForAuthentication: (
      status: "verified" | "pending" | "ordered" | "all",
      query?: {
        page?: number;
        size?: number;
        sort?: string;
        asc?: boolean;
        name?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageUserVerificationDTO, object>({
        path: `/api/v1/staff/docs/list/name/${status}`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
    searchMobAllRequestsForAuthentication: (
      status: "verified" | "pending" | "ordered" | "all",
      query?: {
        page?: number;
        size?: number;
        sort?: string;
        asc?: boolean;
        mob?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageUserVerificationDTO, object>({
        path: `/api/v1/staff/docs/list/mob/${status}`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
    searchLastNameAllRequestsForAuthentication: (
      status: "verified" | "pending" | "ordered" | "all",
      query?: {
        page?: number;
        size?: number;
        sort?: string;
        asc?: boolean;
        last?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageUserVerificationDTO, object>({
        path: `/api/v1/staff/docs/list/last/${status}`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     
     * @tags CEO Verification Docs
     * @name searchBuyListRequests
     * @summary سرچ لیست خرید /درخواست های جدید بر اساس نام و نام خ 
     * @request GET:/api/v1/staff/transaction/listII/buyissue/pending
     * @secure
     */

    searchLastNameOrNameBuyListRequests: (
      status: "verified" | "pending" | "ordered" | "all",
      query?: {
        page?: number;
        size?: number;
        sort?: string;
        asc?: boolean;
        type: number;
        search: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageUserVerificationDTO, object>({
        path: `/api/v1/staff/transaction/list/new/buyissue/${status}`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
    /**
    
     * @tags CEO Verification Docs
     * @name searchInApprovedBuyList
     * @summary سرچ لیست خرید /تایید شده بر اساس نام و نام خ و کد ملی
     * @request GET:/api/v1/staff/transaction/listII/buyissue/approved
     * @secure
     */
    searchInApprovedBuyList: (
      query?: {
        page?: number;
        size?: number;
        sort?: string;
        asc?: boolean;
        type: number;
        search: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageUserVerificationDTO, object>({
        path: `/api/v1/staff/transaction/list/new/buyissue/approved`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**

     * @tags CEO Verification Docs
     * @name searchInRejectedBuyList
     * @summary سرچ لیست خرید /رد شده بر اساس نام و نام خ 
     * @request GET:/api/v1/staff/transaction/listII/buyissue/approved
     * @secure
     */
    searchInRejectedBuyList: (
      query?: {
        page?: number;
        size?: number;
        sort?: string;
        asc?: boolean;
        type: number;
        search: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageUserVerificationDTO, object>({
        path: `/api/v1/staff/transaction/list/new/buyissue/reject`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
    /**
     * @tags CEO Verification Docs
     * @name searchSellListRequests
     * @summary سرچ لیست بازخرید /درخواست های جدید بر اساس نام و نام خ
     * @request GET:/api/v1/staff/transaction/listII/sell/pending
     * @secure
     */
    searchSellListRequests: (
      query?: {
        page?: number;
        size?: number;
        sort?: string;
        asc?: boolean;
        type: number;
        search: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageTransactionDTO, object>({
        path: `/api/v1/staff/transaction/list/new/sell/pending`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
    /**
     
     * @tags CEO Verification Docs
     * @name searchInApprovedSellList
     * @summary سرچ لیست بازخرید /تایید شده بر اساس نام و نام خ 
     * @request GET:/api/v1/staff/transaction/listII/sell/approved
     * @secure
     */
    searchInApprovedSellList: (
      query?: {
        page?: number;
        size?: number;
        sort?: string;
        asc?: boolean;
        type: number;
        search: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageTransactionDTO, object>({
        path: `/api/v1/staff/transaction/list/new/sell/approved`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
    /**
     * @tags CEO Verification Docs
     * @name searchInRejectedSellList
     * @summary سرچ لیست بازخرید /رد شده بر اساس نام و نام خ
     * @request GET:/api/v1/staff/transaction/listII/sell/reject
     * @secure
     */
    searchInRejectedSellList: (
      query?: {
        page?: number;
        size?: number;
        sort?: string;
        asc?: boolean;
        type: number;
        search: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageTransactionDTO, object>({
        path: `/api/v1/staff/transaction/list/new/sell/reject`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
    /**
     * @tags CEO Verification Docs
     * @name searchBankSlipsRecentBankSlips
     * @summary سرچ فیش های بانکی/فیش های بانکی اخیر نام و نام خ
     * @request GET:/api/v1/staff/transaction/listII/all/receipts
     * @secure
     */

    searchBankSlipsRecentBankSlips: (
      query?: {
        page?: number;
        size?: number;
        sort?: string;
        asc?: boolean;
        type: number;
        search: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageTransactionDTO, object>({
        path: `/api/v1/staff/transaction/list/new/all/receipts`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
    /**
     * @tags CEO Verification Docs
     * @name searchUserReporting
     * @summary سرچ گزارش گیری/کاربران نام و نام خ
     * @request GET:/api/v1/staff/docs/listII/all
     * @secure
     */

    searchNameUserReporting: (
      query?: {
        page?: number;
        size?: number;
        sort?: string;
        asc?: boolean;
        name?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageTransactionDTO, object>({
        path: `/api/v1/staff/docs/list/name/all`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    searchLastNameUserReporting: (
      query?: {
        page?: number;
        size?: number;
        sort?: string;
        asc?: boolean;
        last?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageTransactionDTO, object>({
        path: `/api/v1/staff/docs/list/last/all`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
    searchUserReporting: (
      query?: {
        name?: string;
        family?: string;
        page?: number;
        size?: number;
        sort?: string;
        asc?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageUserVerificationDTO, object>({
        path: `/api/v1/staff/docs/listII/all`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
    ///////////////////////////////////////////این قسمت مربوط به اکسله
    /**
     * @name DwExcelMobInDashboard
     * @summary اکسل /داشیورد کد ملی و  موبایل
     * @request GET:/api/v1/staff/docs/list/mob/file/${status}
     * @secure
     */
    DwExcelInDashboard: (
      status: "verified" | "pending" | "ordered" | "all",
      query?: {
        page?: number;
        size?: number;
        sort?: string;
        asc?: boolean;
        type?: number;
        search: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ModelExcel, object>({
        path: `/api/v1/staff/docs/list/file/${status}`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
    /**
     * @name DwExcelMobInDashboard
     * @summary اکسل /احرازهویت کد ملی و  موبایل
     * @request GET:/api/v1/staff/docs/list/mob/file/${status}
     * @secure
     */
    DwExcelInIdentityPending: (
      status: "verified" | "pending" | "ordered" | "all",
      query?: { type?: number; search?: string },
      params: RequestParams = {}
    ) =>
      this.request<ModelExcel, object>({
        path: `/api/v1/staff/docs/list/file/${status}`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
    /**
     * @name DwExcelMobInDashboard
     * @summary اکسل /لیست خرید کد ملی و  موبایل
     * @request GET:/api/v1/staff/docs/list/mob/file/${status}
     * @secure
     */
    DwExcelInBuylistPending: (
      status: "approved" | "pending" | "rejected" | "all",
      query?: { type?: number; search?: string },
      params: RequestParams = {}
    ) =>
      this.request<ModelExcel, object>({
        path: `/api/v1/staff/transaction/list/file/buyissue/${status}`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
    /**
     * @name DwExcelMobInDashboard
     * @summary اکسل /لیست باز خرید کد ملی و  موبایل
     * @request GET:/api/v1/staff/docs/list/mob/file/${status}
     * @secure
     */
    DwExcelInSellListPending: (
      status: "approved" | "pending" | "rejected" | "all",
      query?: { type?: number; search?: string },
      params: RequestParams = {}
    ) =>
      this.request<ModelExcel, object>({
        path: `/api/v1/staff/transaction/list/file/sell/${status}`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
    /**
     * @name DwExcelMobInDashboard
     * @summary اکسل /فیش بانکی کد ملی و  موبایل
     * @request GET:/api/v1/staff/docs/list/mob/file/${status}
     * @secure
     */
    DwExcelInreceiptBuyList: (
      query?: { type?: number; search?: string },
      params: RequestParams = {}
    ) =>
      this.request<ModelExcel, object>({
        path: `/api/v1/staff/transaction/list/file/buyissue/receipts`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
    /**
     * @name DwExcelMobInDashboard
     * @summary اکسل /گزارش گیری کد ملی و  موبایل
     * @request GET:/api/v1/staff/docs/list/mob/file/${status}
     * @secure
     */
    DwExcelInUsersReportsList: (
      status: "approved" | "pending" | "ordered" | "all",
      query?: { type?: number; search?: string },
      params: RequestParams = {}
    ) =>
      this.request<ModelExcel, object>({
        path: `/api/v1/staff/docs/list/file/${status}`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
    /**
     * @name DwExcelMobInDashboard
     * @summary اکسل /جهت دانلود نتیجه سرچ
     * @request GET:/api/v1/staff/docs/list/mob/file/${status}
     * @secure
     */
    DownloadExcel: (object: string | undefined, params: RequestParams = {}) =>
      this.request<[], object>({
        path: `/api/v1/files/report/${object}`,
        method: "GET",
        secure: true,
        ...params,
      }),
      //////////////////////////////////مدیریت کاربران
      //ManagementUsers
      GetAllManagmentUsers: (
        query?: {
          page?: number;
          size?: number;
          sort?: string;
          asc?: boolean;
        },
        params: RequestParams = {}
      ) =>
        this.request<ManagementUsers[], object>({
          path: `/api/v1/user/list`,
          method: "GET",
          query: query,
          secure: true,
          ...params,
        }),
       
        setUserManagment: (data:  {id?: string,
          password?: string,
          mobile?: string,
          username?: string,
          role?: string}, params: RequestParams = {}) =>
        this.request<object, object>({
          path: `/api/v1/user/set`,
          method: "POST",
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
        setUserManagmentPassword: (data:  {id:string,password:string,newpassword:string,mobile:string,username:string,firstName:string,lastname:string,role:string }, params: RequestParams = {}) =>
        this.request<object, object>({
          path: `/api/v1/user/set`,
          method: "POST", 
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }), 

  };
}
