import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Loading from "../../layouts/loading/loading";
import {Col, Row, Checkbox, Select} from "antd";
import CustomTable from "../../components/Table";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import useStore from "../../hooks/useStore";

import dayjs from 'dayjs'
import jalaliday from 'jalaliday'
import PersianRangePicker from "../../components/PersianRangePicker";
import {ColumnProps} from "antd/lib/table/Column";
import useToast from "../../hooks/useToast";
import {faTimes, faCheck} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

dayjs.extend(jalaliday)
dayjs('jalali')

type UserType = "Member" | "NonMember" | "NonMemberInvestor" | "Investor";
const Tab1Subtab2Module = () => {
    const {t} = useTranslation('dashboard');
    const toast = useToast();
    const [tokensSummary, setTokensSummary] = useState<any>({})

    const [tokensSummaryLoading, setTokensSummaryLoading] = useState<boolean>(true)
    const [transactionsLoading, setTransactionsLoading] = useState<boolean>(true)
    const [transactions, setTransactions] = useState<any>()

    const [transactionsTotalElements, setTransactionsTotalElements] = useState<number | undefined>(0)
    const [transactionsDetailLoading, setTransactionsDetailLoading] = useState<boolean>(true)
    const [transactionsDetail, setTransactionsDetail] = useState<any>()

    const [transactionsDetailTotalElements, setTransactionsDetailTotalElements] = useState<number | undefined>(0)
    const [deposit, setDeposit] = useState<boolean>(false);
    const [withdraw, setWithdraw] = useState<boolean>(false);
    const [userType, setUserType] = useState<string>("none");

    const [province, setProvince] = useState<number>(0);
    const screens = useBreakpoint();
    const {Option} = Select;
    const [authedApiState] = useStore()

    const [allStates, setAllStates] = useState<{ id: number, name: string }[]>([])
    const [tableCntlState, setTableCntlState] = useState<{ recent: boolean, detail: boolean }>({
        recent: true,
        detail: false
    })

    const [dateRange, setDateRange] = useState<{ from: string, to: string }>({from: "", to: ""})
    const [currentTrsDetailPage, setCurrentTrsDetailPage] = useState<number>(1);

    const [currentTrsPage, setCurrentTrsPage] = useState<number>(1);
    const [filters, setFilters] = useState<{
        withdraw?: boolean;
        deposit?: boolean;
        userType?: UserType;
        stateId?: string;
        from?: string;
        to?: string;
    }>({})

    useEffect(() => {
        setCurrentTrsPage(1)
        setCurrentTrsDetailPage(1)
        setDateRange({
            from: dayjs().calendar('jalali').locale('fa').toString(),
            to: dayjs().calendar('jalali').locale('fa').toString()
        })
        loadTokensSummary();
        loadAllStates();
        loadTransactions({page: 0});
        loadTransactionsDetails({page: 0});
    }, [])

    useEffect(() => {
        if (tableCntlState.recent) loadTransactions({page: currentTrsPage - 1, ...filters});
        else loadTransactionsDetails({page: currentTrsDetailPage - 1, ...filters});
    }, [tableCntlState, filters])

    const filterChange = (item: "withdraw" | "deposit" | "userType" | "stateId" | "fromTo",
                          value: string| number | boolean | string[]) => {
        let clonedFilters = {...filters}
        if (item === "withdraw") {
            //@ts-ignore
            if (value) clonedFilters[item] = value;
            else delete clonedFilters[item];
        } else if (item === "deposit") {
            //@ts-ignore
            if (value) clonedFilters[item] = value;
            else delete clonedFilters[item];
        } else if (item === "userType") {
            if (value !== 'none') {
                // @ts-ignore
                setUserType(value);
                // @ts-ignore
                clonedFilters[item] = value;
            } else if (value === 'none') delete clonedFilters[item];
        } else if (item === "stateId") {
            if (value !== 0) {
                // @ts-ignore
                setProvince(value);
                // @ts-ignore
                clonedFilters[item] = value;
            } else if (value === 0) delete clonedFilters[item];
        }

        if (tableCntlState.recent) setCurrentTrsPage(1)
        else setCurrentTrsDetailPage(1)

        setFilters(clonedFilters);
    }

    const loadAllStates = () => {
        authedApiState.apiContainer?.api.ostanList().then(({data, status}) => {
            if (status === 200) {
                setAllStates(data);
            } else {
                toast(t('notification.request.fail'), 'error');
            }
        }).catch((response) => {
            toast(response?.data?.msg || response?.data?.message, 'error');
        })
        setTableCntlState({recent: true, detail: false})
    }

    const loadTokensSummary = () => {
        authedApiState.apiContainer?.api.tokensSummary().then(({data, status}) => {
            if (status === 200) {
                setTokensSummaryLoading(false)
                setTokensSummary(data);
            } else {
                toast(t('notification.request.fail'), 'error');
            }
        }).catch((response) => {
            toast(response?.data?.msg || response?.data?.message, 'error');
            setTokensSummaryLoading(false)
        })
        setTableCntlState({recent: true, detail: false})
    }

    const handleRangePicker = ({from, to}: { from: dayjs.Dayjs|'', to: dayjs.Dayjs|'' }) => {
        let clonedDateRange = {...dateRange};
        let clonedFilters = {...filters};

        if(from === '' || to === '') {
            delete clonedFilters['from'];
            delete clonedFilters['to'];
            setFilters(clonedFilters);
            return;
        }
        // @ts-ignore
        clonedDateRange.from = from.calendar('jalali').locale('fa').toString();
        // @ts-ignore
        clonedDateRange.to = to.calendar('jalali').locale('fa').toString();

        // @ts-ignore
        clonedFilters.from = from.calendar('jalali').locale('fa').format('YYYY-MM-DD').toString();
        // @ts-ignore
        clonedFilters.to = to.calendar('jalali').locale('fa').format('YYYY-MM-DD').toString();

        setFilters(clonedFilters);
        setDateRange(clonedDateRange);
    }

    const loadTransactions = ({page, size, sort, asc}:
                                  { page: number, size?: number, sort?: string, asc?: boolean }) => {
        const query = {
            page,
            size: size ? size : 5,
            sort: sort ? sort : 'date',
            asc: asc ? asc : false
        }

        setTransactionsLoading(false);
        setCurrentTrsPage(query.page + 1);
        authedApiState.apiContainer?.api.transactionsDailyList(query).then(({data, status}) => {
            if (status === 200) {
                setTransactionsTotalElements(data.totalElements);
                setTransactions(data.content?.map((item: any) => {
                    return {
                        date: item.date.replace(/-/g, "/"),
                        highestUserType: t('userType.' + item.highestUserType),
                        highestOstanDemand: item.highestOstanDemand || 'نامشخص',
                        stockAmount: parseInt(item.stockAmount) >= 0 ? (parseInt(item.stockAmount) === 0 ? 0 : item.stockAmount + " +") : item.stockAmount + " -",
                        depositAmount: item.depositAmount === 0 ? 0 : item.depositAmount + " -",
                        withdrawAmount: item.withdrawAmount === 0 ? 0 : item.withdrawAmount + " +"
                    }
                }))
            } else {
                toast(t('دریافت اطلاعات با مشکل مواجه شد'), 'error');
            }
            setTransactionsLoading(false)
        }).catch((response) => {
            toast(response?.data?.msg || response?.data?.message, 'error');
            setTransactionsLoading(false)
        })
    }

    const loadTransactionsDetails = (
        {page, size, sort, asc, withdraw, deposit, userType, stateId, from, to}:
            {
                page: number, size?: number, sort?: string, asc?: boolean,
                withdraw?: boolean, deposit?: boolean,
                userType?: UserType,
                stateId?: string, from?: string, to?: string
            }
    ) => {
        const query = {
            page,
            size: size ? size : 5,
            sort: sort ? sort : 'requestDate',
            asc: asc ? asc : false,
            withdraw: withdraw ? withdraw : undefined,
            deposit: deposit ? deposit : undefined,
            userType: userType ? userType : undefined,
            stateId: stateId ? parseInt(stateId) : undefined,
            from: from ? from : undefined,
            to: to ? to : undefined,
        } as {
            page: number;
            size?: number;
            sort?: string;
            asc?: boolean;
            withdraw?: boolean;
            deposit?: boolean;
            userType?: "None" | "Member" | "NonMember" | "NonMemberInvestor";
            stateId?: number;
            from?: string;
            to?: string;
        }

        setTransactionsDetailLoading(true);
        setCurrentTrsDetailPage(query.page + 1);
        authedApiState.apiContainer?.api.transactionsDetailsList(query).then(({data, status}) => {
            setTransactionsDetailTotalElements(data.totalElements);
            if (status === 200) {
                setTransactionsDetail(data.content?.map((item: any) => {
                    return {
                        type: item.type,
                        createdDate: item.createdDate.replace(/-/g, "/"),
                        accountType: t('accountType.' + item.accountType),
                        userType: t('userType.' + item.userType),
                        ostan: item.ostan || 'نامشخص',
                        fullName: item.fullName,
                        companyName: item.companyName?item.companyName:'---',
                        amount: item,
                        totalPrice: item,
                        item: item,
                    }
                }))
            } else {
                toast(t('دریافت اطلاعات با مشکل مواجه شد'), 'error');
            }
            setTransactionsDetailLoading(false)
        }).catch((response) => {
            toast(response?.data?.msg || response?.data?.message, 'error');
            setTransactionsDetailLoading(false)
        })
    }

    const transactionsColumn: ColumnProps<Record<string, string>>[] = [
        {
            title: 'تاریخ تراکنش',
            dataIndex: 'date',
            key: 'date',
            sorter: true,
            responsive: ['xxl', 'xl', 'lg', 'sm']
        },
        {
            title: 'بیشترین نوع سهامداری',
            dataIndex: 'highestUserType',
            key: 'highestUserType',
            responsive: ['xxl', 'xl', 'lg', 'sm'],
        },
        {
            title: 'بیشترین استان درخواستی',
            key: 'highestOstanDemand',
            dataIndex: 'highestOstanDemand',
            responsive: ['xxl', 'xl', 'lg', 'sm']
        },
        {
            title: 'تعداد کل سهام',
            key: 'stockAmount',
            dataIndex: 'stockAmount',
            sorter: true,
            responsive: ['xxl', 'xl', 'lg', 'sm'],
            render: item => item.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        {
            title: 'کل پرداختی ها',
            key: 'depositAmount',
            sorter: true,
            dataIndex: 'depositAmount',
            render: (item) => (
                <span style={{color: "#EA1601"}}>{item.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
            )
        },
        {
            title: 'کل واریزی‌ها',
            key: 'withdrawAmount',
            sorter: true,
            dataIndex: 'withdrawAmount',
            render: (item) => (
                <span style={{color: "#64BC26"}}>{item.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
            )
        }
    ]

    const transactionsDetailColumn: ColumnProps<Record<string, string>>[] = [
        {
            title: 'واریز/پرداخت',
            key: 'type',
            dataIndex: 'type',
        },
        {
            title: 'تاریخ تراکنش',
            dataIndex: 'createdDate',
            key: 'createdDate',
            responsive: ['xxl', 'xl', 'lg', 'md', 'sm']
        },
        {
            title: 'متقاضی',
            dataIndex: 'accountType',
            key: 'accountType',
            responsive: ['xxl', 'xl', 'lg', 'sm'],
            sorter: true
        },
        {
            title: 'سهامداری',
            key: 'userType',
            dataIndex: 'userType',
            responsive: ['xxl', 'xl', 'lg', 'md', 'sm']
        },
        {
            title: 'استان',
            key: 'ostan',
            dataIndex: 'ostan',
        },
        {
            title: 'نام و نام خانوادگی',
            key: 'fullName',
            dataIndex: 'fullName',
            responsive: ['xxl', 'xl', 'lg', 'md', 'sm']
        },
        {
            title: 'نام شرکت',
            key: 'companyName',
            dataIndex: 'companyName',
            responsive: ['xxl', 'xl', 'lg', 'md', 'sm']
        },
        {
            title: 'تعداد سهم',
            key: 'amount',
            dataIndex: 'amount',
            responsive: ['xxl', 'xl', 'lg', 'md', 'sm'],
            render: item => (
                <span
                    style={{
                        color: item.type === 'پرداخت'?"#EA1601":"#64BC26"
                    }}
                >{item.amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{item.type === 'پرداخت'?' - ':' + '}</span>

            )
        },
        {
            title: 'مبلغ تراکنش (ریال)',
            key: 'totalPrice',
            dataIndex: 'totalPrice',
            responsive: ['xxl', 'xl', 'lg', 'md', 'sm'],
            render: (item) => (
                <span
                    style={{
                        color: item.type === 'پرداخت'?"#EA1601":"#64BC26"
                    }}
                >{item.totalPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{item.type === 'پرداخت'?' - ':' + '}</span>
            )
        },
        {
            title: 'خروج از سهامداری',
            key: 'type',
            dataIndex: 'type',
            responsive: ['xxl', 'xl', 'lg', 'md', 'sm'],
            render: (item) => (
                <FontAwesomeIcon
                    style={{color: "#D0C9DF"}}
                    icon={item === 'پرداخت'?faCheck:faTimes}/>
            )
        }
    ]

    return (
        <>
            <Row gutter={[16, 16]}>
                <Col span={18} lg={18} md={18} sm={24} xs={24}>
                    <div className={'dashboard-grid'}>
                        <div className={'header'}>
                            {t('dashboard.stat.item.totalSoldShares')}
                        </div>
                        <Row className={'element-content'}>
                            <Col span={24} lg={8} md={8} sm={24} xs={24}>
                                {
                                    tokensSummaryLoading ? (<Loading/>) : (
                                        <>
                                            <div className={"element-content-title"}>
                                                {tokensSummary.nonmember}
                                            </div>
                                            <div className={"element-content-subtitle"}>
                                                {t("dashboard.totalSoldShares.nonMember")}
                                            </div>
                                        </>
                                    )
                                }
                            </Col>
                            <Col span={24} lg={8} md={8} sm={24} xs={24}>

                                <div style={{
                                    borderRight: screens.lg || screens.md ? "1px dashed #5D55A1" : "unset",
                                    borderLeft: screens.lg || screens.md ? "1px dashed #5D55A1" : "unset",
                                    borderTop: !screens.lg && !screens.md && (screens.sm || screens.xs) ? "1px dashed #5D55A1" : "unset",
                                    borderBottom: !screens.lg && !screens.md && (screens.sm || screens.xs) ? "1px dashed #5D55A1" : "unset",
                                }}>
                                    {
                                        tokensSummaryLoading ? (<Loading/>) : (
                                            <>
                                                <div className={"element-content-title"}>
                                                    {tokensSummary.nonmemberInvestor}
                                                </div>
                                                <div className={"element-content-subtitle"}>
                                                    {t("dashboard.totalSoldShares.nonMemberInvestor")}
                                                </div>
                                            </>
                                        )
                                    }

                                </div>

                            </Col>
                            <Col span={24} lg={8} md={8} sm={24} xs={24}>
                                {
                                    tokensSummaryLoading ? (<Loading/>) : (
                                        <>
                                            <div className={"element-content-title"}>
                                                {tokensSummary.member}
                                            </div>
                                            <div className={"element-content-subtitle"}>
                                                {t("dashboard.totalSoldShares.member")}
                                            </div>
                                        </>
                                    )
                                }

                            </Col>
                        </Row>

                    </div>
                </Col>
                <Col span={6} lg={6} md={6} sm={24} xs={24}>
                    <div className={'dashboard-grid'}>
                        <div className={'header'}>
                            {t('dashboard.stat.item.calendarInterval')}
                        </div>

                        <PersianRangePicker
                            allowClear={true}
                            hasYearPreset={true}
                            hasMonthPreset={true}
                            hasWeekPreset={true}
                            hasToday={true}
                            onChange={handleRangePicker}
                            value={[dayjs(dateRange.from), dayjs(dateRange.to)]}
                        />
                    </div>
                </Col>
            </Row>

            <Row gutter={[16, 16]} style={{marginTop: '16px'}}>
                <Col span={24} lg={24} md={24} sm={24} xs={24}>
                    <div className={'dashboard-grid'}>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={5}>
                                <button
                                    className={tableCntlState.recent ? " table-control-button-active" : "table-control-button"}
                                    onClick={() => {
                                        setTableCntlState({recent: true, detail: false})
                                    }}
                                    style={{marginTop: '20px', width: 'calc(100% - 40px)'}}
                                >
                                    {t("dashboard.button.recent-transactions")}
                                </button>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={5}>
                                <button
                                    className={tableCntlState.detail ? " table-control-button-active" : "table-control-button"}
                                    style={{marginTop: '20px', width: 'calc(100% - 40px)'}}
                                    onClick={() => {
                                        setTableCntlState({recent: false, detail: true})
                                    }}
                                >
                                    {t("dashboard.button.transactions-details")}
                                </button>
                            </Col>
                            <Col lg={10} md={24} sm={24} xs={24} offset={screens.lg ? 4 : 0}>
                                <Row className={"table-checkbox-container"} style={{margin: '20px'}}>
                                    <Col span={24} lg={6} md={6} sm={6} xs={6}>
                                        <Checkbox onChange={() => {
                                            filterChange("deposit", !deposit);
                                            setDeposit(!deposit);
                                        }}
                                                  value={deposit}
                                                  checked={deposit}
                                        >{t("dashboard.button.deposits")}</Checkbox>
                                    </Col>
                                    <Col span={24} lg={6} md={6} sm={6} xs={6}>
                                        <Checkbox
                                            onChange={() => {
                                                filterChange("withdraw", !withdraw);
                                                setWithdraw(!withdraw);
                                            }}
                                            value={withdraw}
                                            checked={withdraw}
                                        >{t("dashboard.button.withdraw")}
                                        </Checkbox>
                                    </Col>
                                    <Col span={24} lg={6} md={6} sm={6} xs={6}>
                                        <Select defaultValue="none"
                                                placeholder={t("dashboard.button.investmentType")}
                                                style={{width: 100}}
                                                onChange={(value) => {
                                                    filterChange("userType", value)
                                                    setUserType(value)
                                                }}
                                                value={userType}
                                                clearIcon={true}
                                        >
                                            <Select.Option key={'none'} value="none" style={{color: ""}}>{userType === 'none'?'نوع‌سهامداری':'هیچکدام'}</Select.Option>
                                            <Select.Option key={'investment.member'}
                                                    value="Member">{t("investment.member")}</Select.Option>
                                            <Select.Option key={'investment.nonMember'}
                                                    value="NonMember">{t("investment.nonMember")}</Select.Option>
                                            <Select.Option key={'investment.nonMemberInvestor'}
                                                    value="NonMemberInvestor">{t("investment.nonMemberInvestor")}</Select.Option>
                                        </Select>

                                    </Col>
                                    <Col span={24} lg={6} md={6} sm={6} xs={6}>
                                        <Select
                                            showSearch
                                            defaultValue={0}
                                            value={province}
                                            placeholder={t("dashboard.button.province")}
                                            optionFilterProp="children"
                                            style={{width: 100}}
                                            onChange={(value) => {
                                                filterChange("stateId", value)
                                                setProvince(value);
                                            }}
                                        >
                                            <Select.Option key={0} value={0}>استان</Select.Option>
                                            {
                                                allStates.map((state: { id: number, name: string }) =>
                                                    <Select.Option key={state.id}
                                                            value={state?.id}>{state.name}</Select.Option>)
                                            }
                                        </Select>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            {
                                tableCntlState.recent ? (
                                    <Col span={24}>
                                        <CustomTable
                                            onChange={loadTransactions}
                                            loading={transactionsLoading}
                                            total={transactionsTotalElements}
                                            paginationBottom={"bottomCenter"}
                                            className={'special-styled-table'}
                                            data={transactions}
                                            columns={transactionsColumn}
                                            defaultSort={"date"}
                                            current={currentTrsPage}
                                            filters={filters}
                                        />
                                    </Col>
                                ) : <></>
                            }
                            {
                                tableCntlState.detail ? (
                                    <Col span={24}>
                                        <CustomTable
                                            onChange={loadTransactionsDetails}
                                            loading={transactionsDetailLoading}
                                            total={transactionsDetailTotalElements}
                                            paginationBottom={"bottomCenter"}
                                            className={'special-styled-table'}
                                            data={transactionsDetail}
                                            columns={transactionsDetailColumn}
                                            defaultSort={"createdDate"}
                                            current={currentTrsDetailPage}
                                            filters={filters}
                                        />
                                    </Col>
                                ) : <></>
                            }
                        </Row>

                    </div>
                </Col>
            </Row>
        </>
    )

};

export default Tab1Subtab2Module;
