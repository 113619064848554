import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Col, Row} from "antd";
import CustomTable from "../../components/Table";
import useStore from "../../hooks/useStore";
import {ColumnProps} from "antd/lib/table/Column";
import useToast from "../../hooks/useToast";
import BtnClearFilter from '../../components/BtnClearFilter';
import InputSearch from '../../components/InputSearch';
import BtnFilter from '../../components/BtnFilter';
import BtnExcel from '../../components/BtnExcel';
import * as XLSX from 'xlsx';
import InputExcel from '../../components/InputExcel';

const RejectedBuyRequests = () => {
    const {t} = useTranslation('identification');
    const [PendingRequestsTotalElements, setPendingRequestsTotalElements] = useState<number>(0)
    const [pendingRequests, setPendingRequests] = useState<any[]>([]);
    const [pendingRequestsLoading, setPendingRequestsLoading] = useState<boolean>(true);
    const [nameSearch, setNameSearch] = useState<string>("");
    const [familySearch, setFamilySearch] = useState<string>("");
    const [ncExcel, setNcExcel] = useState<any>();
    const [mobileExcel, setMobileExcel] = useState<any>()
    const [authedApiState] = useStore()
    const toast = useToast();

    useEffect(() => {
        loadPendingRequests({page: 0, size: 5});
    }, [])

    const loadPendingRequests = ({page, size, sort, asc}:
                                     { page: number, size?: number, sort?: string, asc?: boolean }) => {
        const query = {
            page, size: size ? size : 5,
            sort: sort ? sort : 'requestDate', asc:asc?asc:false
        }
        
        setPendingRequests([])
        setPendingRequestsLoading(true)
        authedApiState.apiContainer?.api.transactionsList("reject", 'buy', query).then(({data, status}) => {
            //@ts-ignore
            setPendingRequestsTotalElements(data.totalElements)
            if (status === 200) {
                //@ts-ignore
                setPendingRequests(data.content?.map((item: any) => {
                    return {
                        name: item.firstName + ' ' + item.lastName,
                        createdDate: item.createdDate.replace(/-/g, "/"),
                        accountType: t('accountType.' + item.accountType),
                        userType: t('userType.' + item.userType),
                        identityStatus: item.approvedDate ? "تایید شده" : "در حال بررسی",
                        requestDate: item.requestDate?.replace(/-/g, "/"),
                        rejectDate: item.rejectDate?.replace(/-/g, "/"),
                        companyName: item.companyName?item.companyName:'---',
                        amount: item.amount,
                        totalPrice: item.totalPrice,
                    }
                }));
                setNameSearch("");
                setFamilySearch("");
            } else {
                toast(t('notification.request.fail'), 'error');
            }
            setPendingRequestsLoading(false)
        }).catch((response) => {
            toast(response?.data?.msg || response?.data?.message, 'error');
            setPendingRequestsLoading(false);
        })
    };

    const pendingRequestsColumn: ColumnProps<Record<string, string>>[] = [
        {
            title: 'تاریخ‌درخواست',
            key: 'createdDate',
            dataIndex: 'createdDate',
            sorter: true
        },
        {
            title: 'نام‌و‌نام‌خانوادگی',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'متقاضی',
            dataIndex: 'accountType',
            key: 'accountType',
            sorter: true,
            responsive: ['xxl', 'xl', 'lg', 'sm']
        },
        {
            title: 'وضعیت هویت',
            key: 'identityStatus',
            dataIndex: 'identityStatus',
        },
        {
            title: 'نام‌شرکت',
            key: 'companyName',
            dataIndex: 'companyName',
            responsive: ['xxl', 'xl', 'lg', 'sm']
        },
        {
            title: 'نوع سهام‌داری',
            key: 'userType',
            dataIndex: 'userType',
            responsive: ['xxl', 'xl', 'lg', 'sm'],
        },
        {
            title: 'تعداد رزرو',
            key: 'amount',
            dataIndex: 'amount',
            responsive: ['xxl', 'xl', 'lg', 'sm'],
            render: item => item?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        {
            title: 'مبلغ پرداختی (ریال)',
            key: 'totalPrice',
            dataIndex: 'totalPrice',
            responsive: ['xxl', 'xl', 'lg', 'sm'],
            render: item => item?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        {
            title: 'تاریخ عدم تایید',
            key: 'rejectDate',
            dataIndex: 'rejectDate',
            sorter: true,
            responsive: ['xxl', 'xl', 'lg', 'sm']
        },
    ]
    ////////////////////////////////////////سرچ براساس نام
 const loadFilter = ({
    page,
    size,
    sort,
    asc,
    type,
    search
  }: {
    page?: number;
    size?: number;
    sort?: string;
    asc?: boolean;
    type:number;
    search?:string;
  }) => {
    const query = {
      page,
      size: size ? size : 5,
      sort: sort ? sort : "requestDate",
      asc: asc ? asc : false,
      type:type,
      search:search ? search :familySearch ?nameSearch :nameSearch
    };
    setPendingRequests([])
          setPendingRequestsLoading(true);
    authedApiState.apiContainer?.api
      .searchInRejectedBuyList(query)
      .then(({ data, status }) => {
         //@ts-ignore
         setPendingRequestsTotalElements(data.totalElements)
         if (status === 200) {
             //@ts-ignore
             setPendingRequests(data.content?.map((item: any) => {
                 return {
                     name: item.firstName + ' ' + item.lastName,
                     nationalCode: item.nationalCode,
                     approvedDate: item.approvedDate?.replace(/-/g, "/"),
                     createdDate: item.createdDate?.replace(/-/g, "/"),
                     accountType: t('accountType.' + item.accountType),
                     paymentMethod: item.paymentMethod==='ONLINE' ? 'درگاه بانکی' : 'فیش بانکی',
                     userType: t('userType.' + item.userType),
                     companyName: item.companyName ? item.companyName : '---',
                     amount: item.amount,
                     totalPrice: item.totalPrice,
                     actions: item
                 }
             }));
         } else {
             toast(t('notification.request.fail'), 'error');
         }
         setPendingRequestsLoading(false);
     }).catch((response) => {
         toast(response?.data?.msg || response?.data?.message, 'error');
         setPendingRequestsLoading(false);
     })
  };

    /////////////////////////////////////اکسل 
    const loadFilterExcel = ({
      search,
      type,
    }: {
      type?: number; 
      search?:string;
    }) => {
      const query = {
        type:type,
        search:search ? search :mobileExcel ?mobileExcel :ncExcel
      };
      authedApiState.apiContainer?.api
        .DwExcelInBuylistPending("rejected", query)
        .then(({ data, status }) => {
          if (status === 200) {
            DwFilterExcel(data.id)
          }
        })
        .catch((response: any) => {
          toast(response?.data?.msg || response?.data?.message, "error");
        });
    };
    const DwFilterExcel = async (data1: string) => {
      try {
        const response = await authedApiState.apiContainer?.api.DownloadExcel(data1);
        if (response) {
          const { data, status } = response;
    
          if (status === 200 && data) 
          {
            const excelData = data.map((item: any) => Object.values(item)); // Convert data to array of arrays
            const headers = ['تاریخ‌درخواست', 'نام', 'نام خانوادگی','متقاضی','کدملی','موبایل','نام‌شرکت']; // Set the headers for columns
            const workbook = XLSX.utils.book_new();
            XLSX.utils.sheet_add_aoa(workbook, [headers, ...excelData], {origin: 0}); // Add headers and data to worksheet
            XLSX.utils.book_append_sheet(workbook, workbook, 'Sheet1'); // Add worksheet to workbook
    
            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' }); // Generate Excel buffer
    
            const blobData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }); // Convert buffer to Blob
    
            const url = window.URL.createObjectURL(blobData);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'downloaded_file.xlsx'; // Set the appropriate file name and extension
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };
  ////////////////*************////////////
  const [focusedInput, setFocusedInput] = useState<string | null>(null);

  const handleFocusChange = (placeholder: string) => {
    setFocusedInput(placeholder);
  };

  const handleClearFilter = () => {
    setFocusedInput(null);
    loadPendingRequests({page: 0, size: 5});
  };
    return (
        <>

<Row gutter={[16, 16]}  style={{ marginBottom: "20px", alignItems: "baseline" }}>
              
              <InputSearch
                handleChange={(e) => setNameSearch(e.target.value)}
                onFocusChange={handleFocusChange}
                placeholder="سرچ براساس نام  "
                val={nameSearch}
                isDisabled={focusedInput ==="سرچ براساس نام خانوادگی" }
              />
              <BtnFilter
                handleChange={() =>loadFilter({ page: 0,type:1 }) }
                textBtn="جستجو"
                type="search"
              />
              <InputSearch
                handleChange={(e) => setFamilySearch(e.target.value)}
                onFocusChange={handleFocusChange}
                placeholder="سرچ براساس نام خانوادگی"
                val={familySearch}
                isDisabled={focusedInput ==="سرچ براساس نام  "}
              />
              <BtnFilter
                handleChange={() =>loadFilter({ page: 0,type:2 }) }
                textBtn="جستجو"
                type="search"
              />

              <BtnClearFilter
                handleChange={handleClearFilter}
                textBtn="حذف فیلتر"
                type="remove"
              />
            </Row>
            <Row gutter={[16, 16]}>
                <Col span={24} lg={24} md={24} sm={24} xs={24}>
                    <div className={'dashboard-grid'}>
                 {focusedInput === "سرچ براساس نام  "?
                  <CustomTable
                  loading={pendingRequestsLoading}
                  className='special-styled-table'
                  paginationBottom={"bottomCenter"}
                  data={pendingRequests}
                  columns={pendingRequestsColumn}
                  total={PendingRequestsTotalElements}
                  onChange={() =>loadFilter({ page: 0,type:1 })}
                  defaultSort={"requestDate"}
              />
                 :focusedInput === "سرچ براساس نام خانوادگی"?
                 <CustomTable
                 loading={pendingRequestsLoading}
                 className='special-styled-table'
                 paginationBottom={"bottomCenter"}
                 data={pendingRequests}
                 columns={pendingRequestsColumn}
                 total={PendingRequestsTotalElements}
                 onChange={() =>loadFilter({ page: 0,type:2 })}
                 defaultSort={"requestDate"}
             />
                 : <CustomTable
                 loading={pendingRequestsLoading}
                 className='special-styled-table'
                 paginationBottom={"bottomCenter"}
                 data={pendingRequests}
                 columns={pendingRequestsColumn}
                 total={PendingRequestsTotalElements}
                 onChange={loadPendingRequests}
                 defaultSort={"requestDate"}
             />}
                    </div>
                </Col>
            </Row>
            <Row gutter={[16, 16]}  style={{ marginTop: "10px", alignItems: "baseline" }}>
              <InputExcel
                handleChange={(e) => setNcExcel(e.target.value)}
                placeholder="دانلود براساس کدملی"
                val={ncExcel}
              />
              <BtnExcel
                handleChange={() =>loadFilterExcel({type:3})}
                textBtn="جستجو"
                
              />
                <InputExcel
                handleChange={(e) => setMobileExcel(e.target.value)}
                placeholder="دانلود براساس موبایل"
                val={mobileExcel}
              />
              <BtnExcel
                handleChange={() =>loadFilterExcel({type:4})}
                textBtn="جستجو"
                
              />
              <BtnClearFilter
                handleChange={() => {
                  setMobileExcel("");
                  setNcExcel("");
                }}
                textBtn="حذف فیلتر"
                type="remove"
              />
            </Row>
        </>
    )
};

export default RejectedBuyRequests;
