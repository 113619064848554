import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useStore from "../../hooks/useStore";
import { Row, Col } from "antd";
import DashboardStatItem from "../../components/DashboardStatItem";
import { Calendar } from "antd-jalali";
import moment from "jalali-moment";
import ColumnChart from "../../components/ColumnChart";
import CustomTable from "../../components/Table";
import { ColumnProps } from "antd/lib/table/Column";
import useToast from "../../hooks/useToast";
import TableActions from "../../components/TableActions";
import InputSearch from "../../components/InputSearch";
import BtnClearFilter from "../../components/BtnClearFilter";
import BtnFilter from "../../components/BtnFilter";
import BtnExcel from "../../components/BtnExcel";
import * as XLSX from 'xlsx';
import InputExcel from "../../components/InputExcel";


const StockStatModule = () => {
  const { t } = useTranslation("dashboard");
  const [authedApiState] = useStore();
  const [store] = useStore();
  const [stockSummary, setStockSummary] = useState<any>({});
  const [summaryLoading, setSummaryLoading] = useState<boolean>(true);
  const [recentTransactions, setRecentTransactions] = useState<any>();
  const [recentTransactionsLoading, setRecentTransactionsLoading] = useState<
    boolean
  >(true);
  const [pendingRequests, setPendingRequests] = useState<any>();
  const [pendingRequestsLoading, setPendingRequestsLoading] = useState<boolean>(
    true
  );
  const [instantStat, setInstantStat] = useState<any>();
  const [
    PendingRequestsTotalElements,
    setPendingRequestsTotalElements,
  ] = useState<number>(0);
  const [tableSettings, setTableSettings] = useState({});
  const toast = useToast();
  const [ncSearch, setNcSearch] = useState<any>();
  const [nameSearch, setNameSearch] = useState<string>("");
  const [familySearch, setFamilySearch] = useState<string>("");
  const [mobileSearch, setMobileSearch] = useState<any>();
  const [ncExcel, setNcExcel] = useState<any>();
  const [mobileExcel, setMobileExcel] = useState<any>();

  useEffect(() => {
    loadOfferingSummary();
    loadRecentTransaction();
    loadPendingRequests({ page: 0 });
    loadInstantStat();
  }, []);

  const loadOfferingSummary = () => {
    setSummaryLoading(true);
    authedApiState.apiContainer?.api
      .offeringSummary()
      .then(({ data, status }) => {
        if (status === 200) {
          setStockSummary(data);
        } else {
          toast(t("notification.request.fail"), "error");
        }
        setSummaryLoading(false);
      })
      .catch((response) => {
        toast(response?.data?.msg || response?.data?.message, "error");
        setSummaryLoading(false);
      });
  };

  const loadInstantStat = () => {
    authedApiState.apiContainer?.api
      .stockTransactions()
      .then(({ data, status }) => {
        if (status === 200) {
          const stat = [
            {
              label: "کل سهام",
              value: data.total,
              color: "#7754FF",
            },
            {
              label: "رزرو شده",
              value: data.reserved,
              color: "#F461B7",
            },
            {
              label: "خریداری شده",
              value: data.bought,
              color: "#9C9CBD",
            },
            {
              label: "قابل خرید",
              value: data.free,
              color: "#0071F2",
            }, //TODO check values and labels are match?
          ];
          setInstantStat(stat);
        } else {
          toast(t("notification.request.fail"), "error");
        }
      })
      .catch((response) => {
        toast(response?.data?.msg || response?.data?.message, "error");
      });
  };

  const loadRecentTransaction = () => {
    setRecentTransactions([]);
    setRecentTransactionsLoading(true);
    authedApiState.apiContainer?.api
      .recentTransactions()
      .then(({ data, status }) => {
        if (status === 200) {
          setRecentTransactions(
            data.map((item) => {
              return {
                name: item.firstName + " " + item.lastName,
                accountType: t("accountType." + item.accountType),
                userType: t("userType." + item.userType),
                item: item,
                createdDate: item.createdDate?.toString().replace(/-/g, "/"),
              };
            })
          );
        } else {
          toast(t("notification.request.fail"), "error");
        }
        setRecentTransactionsLoading(false);
      })
      .catch((response) => {
        toast(response?.data?.msg || response?.data?.message, "error");
        setRecentTransactionsLoading(false);
      });
  };

  const loadPendingRequests = ({
    page,
    size,
    sort,
    asc,
  }: {
    page: number;
    size?: number;
    sort?: string;
    asc?: boolean;
  }) => {
    const query = {
      page,
      size: size ? size : 5,
      sort: sort ? sort : "requestDate",
      asc: asc ? asc : false,
    };
    setTableSettings(query);
    setPendingRequests([]);
    setPendingRequestsLoading(true);
    authedApiState.apiContainer?.api
      .usersDocsList("pending", query)
      .then(({ data, status }) => {
        //@ts-ignore
        setPendingRequestsTotalElements(data.totalElements); 
        if (status === 200 ) {
          setPendingRequests(
            data.content?.map((item: any) => {
              return {
                name: item.firstName + " " + item.lastName,
                accountType: t("accountType." + item.accountType),
                nationalCode: item.nationalCode,
                mobile: item.mobile,
                requestDate: item.requestDate?.toString().replace(/-/g, "/"),
                companyName: item.companyName ? item.companyName : "---",
                incompleteFields: item.incompleteFields,
                incompleteDocs: item.incompleteDocs,
                actions: item,
              };
            })
          );
        } else {
          toast(t("notification.request.approve.fail"), "error");
        }
        setPendingRequestsLoading(false);
      })
      .catch((response: any) => {
        toast(response?.data?.msg || response?.data?.message, "error");
        setPendingRequestsLoading(false);
      });
  };

  const approveUserRequest = (userId: string) => {
    return new Promise((resolve, reject) => {
      authedApiState.apiContainer?.api
        .approveAllDoc({ userId })
        .then(({ data, status }) => {
          if (status === 200) {
            resolve(data);
            toast(t("notification.request.approve.success"), "success");
          } else {
            toast(t("notification.request.approve.fail"), "error");
            reject(data);
          }
        })
        .catch((response: any) => {
          toast(response?.data?.msg || response?.data?.message, "error");
          reject(response);
        });
    });
  };

  const rejectUserRequest = (userId: string, reason: string) => {
    return new Promise((resolve, reject) => {
      const body = { userId, reason };
      authedApiState.apiContainer?.api
        .rejectUser(body)
        .then(({ data, status }) => {
          if (status === 200) {
            resolve(data);
            toast(t("notification.request.approve.success"), "success");
          } else {
            toast(t("notification.request.approve.fail"), "error");
            reject(data);
          }
        })
        .catch((response: any) => {
          toast(response?.data?.msg || response?.data?.message, "error");
          reject(response);
        });
    });
  };

  const recentTransactionColumn: ColumnProps<Record<string, string>>[] = [
    {
      title: "نام‌و‌نام‌خانوادگی",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "تاریخ",
      dataIndex: "createdDate",
      key: "createdDate",
      responsive: ["xxl", "xl", "lg", "sm"],
    },
    {
      title: "متقاضی",
      dataIndex: "accountType",
      key: "accountType",
      responsive: ["xxl", "xl", "lg", "sm"],
    },
    {
      title: "سهام‌داری",
      key: "userType",
      dataIndex: "userType",
      responsive: ["xxl", "xl", "lg", "sm"],
    },
    {
      title: "تعداد‌سهم",
      key: "item",
      dataIndex: "item",
      render: (item) =>
        item.amount
          ?.toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          .concat(item.type === "TRANSFER_SELL" ? " - " : " + "),
    },
  ];

  const pendingRequestsColumn: ColumnProps<Record<string, string>>[] = [
    {
      title: "تاریخ‌درخواست",
      key: "requestDate",
      dataIndex: "requestDate",
      sorter: true,
    },
    {
      title: "نام‌و‌نام‌خانوادگی",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "متقاضی",
      dataIndex: "accountType",
      key: "accountType",
      responsive: ["xxl", "xl", "lg", "sm"],
      sorter: true,
    },
    {
      title: "کدملی",
      key: "nationalCode",
      dataIndex: "nationalCode",
      responsive: ["xxl", "xl", "lg", "md", "sm"],
    },
    {
      title: "موبایل",
      key: "mobile",
      dataIndex: "mobile",
      responsive: ["xxl", "xl", "lg", "md", "sm"],
    },
    {
      title: "نام‌شرکت",
      key: "companyName",
      dataIndex: "companyName",
      responsive: ["xxl", "xl", "lg", "md", "sm"],
    },
    {
      title: "اطلاعات تکمیل نشده",
      key: "incompleteFields",
      dataIndex: "incompleteFields",
      responsive: ["xxl", "xl", "lg", "md", "sm"],
      render: (incompleteFields) => (
        <>
          <div style={{ color: "#F461B7" }}>
            {incompleteFields.length === 1
              ? incompleteFields[0]
              : incompleteFields.length !== 0 && incompleteFields.length > 1
              ? incompleteFields[0] + " و ..."
              : "---"}
          </div>
        </>
      ),
    },
    {
      title: "مدارک تکمیل نشده",
      key: "incompleteDocs",
      dataIndex: "incompleteDocs",
      responsive: ["xxl", "xl", "lg", "md", "sm"],
      render: (incompleteDocs) => (
        <>
          <div style={{ color: "#F461B7" }}>
            {incompleteDocs.length === 1
              ? t("docs." + incompleteDocs[0])
              : incompleteDocs.length > 1 && incompleteDocs.length !== 0
              ? t("docs." + incompleteDocs[0]) + " و ..."
              : "---"}
          </div>
        </>
      ),
    },
    {
      title: "اقدامات",
      key: "actions",
      dataIndex: "actions",
      render: (item: any) => (
        <>
          {store.role !== "CHAIRMAN" && store.role !== "MINISTRY" ? (
            <TableActions
              onApprove={approveUserRequest}
              onReject={rejectUserRequest}
              onView={() => {}}
              approveParams={{}}
              rejectParams={[]}
              viewParams={{}}
              approveBtnDsbl={item.approvedDate}
              record={item}
              rejectBtnDsbl={item.rejectDate}
              viewBtnDsbl={true}
              id={item.userId}
              onFinish={reloadTable}
            />
          ) : (
            <>---</> 
          )}
        </>
      ),
    },
  ];

  const reloadTable = () => {
     //@ts-ignore
      loadPendingRequests(tableSettings);
    setNcSearch(null);
    setNameSearch("");
    setFamilySearch("");
    setMobileSearch(null);
  };

  //************ Members ************
  let memberOfferingDateSubTitle = "";
  let memberOfferingDateTitle = <div />;
  const memberFromDate = moment(
    stockSummary?.memberOfferingPeriod?.from
  ).locale("fa");
  const memberToDate = moment(stockSummary?.memberOfferingPeriod?.to).locale(
    "fa"
  );

  if (memberFromDate.isValid() && memberToDate.isValid()) {
    if (
      memberFromDate.format("YYYY").toString() ===
      memberToDate.format("YYYY").toString()
    ) {
      if (memberFromDate.format("MM") === memberToDate.format("MM")) {
        memberOfferingDateSubTitle =
          memberFromDate.format("MMMM").toString() +
          " " +
          memberFromDate.format("YYYY").toString();
        memberOfferingDateTitle = (
          <div>
            {memberFromDate.format("DD")} الی {memberToDate.format("DD")}
          </div>
        );
      } else {
        memberOfferingDateSubTitle = memberFromDate.format("YYYY");
        memberOfferingDateTitle = (
          <div style={{ fontSize: "35px", marginBottom: "10px" }}>
            {memberFromDate.format("DD MMMM")}
            <br />
            الی
            <br /> {memberToDate.format("DD MMMM")}
          </div>
        );
      }
    } else {
      memberOfferingDateTitle = (
        <div style={{ fontSize: "35px", marginBottom: "30px" }}>
          {memberFromDate.format("DD/MM/YYYY")} <br /> الی <br />
          {memberToDate.format("DD/MM/YYYY")}
        </div>
      );
    }
  }
  //************ end Members ************
  // ************ Non Members ************
  let nonMemberOfferingDateSubTitle = "";
  let nonMemberOfferingDateTitle = <div />;
  const nonMemberFromDate = moment(
    stockSummary?.nonMemberOfferingPeriod?.from
  ).locale("fa");
  const nonMemberToDate = moment(
    stockSummary?.nonMemberOfferingPeriod?.to
  ).locale("fa");
  // try {
  //     alert(JSON.stringify(stockSummary));
  // } catch (e) {
  //
  // }
  if (nonMemberFromDate.isValid() && nonMemberToDate.isValid()) {
    if (
      nonMemberFromDate.format("YYYY").toString() ===
      nonMemberToDate.format("YYYY").toString()
    ) {
      if (nonMemberFromDate.format("MM") === nonMemberToDate.format("MM")) {
        nonMemberOfferingDateSubTitle =
          nonMemberFromDate.format("MMMM").toString() +
          " " +
          nonMemberFromDate.format("YYYY").toString();
        nonMemberOfferingDateTitle = (
          <div>
            {nonMemberFromDate.format("DD")} الی {nonMemberToDate.format("DD")}
          </div>
        );
      } else {
        nonMemberOfferingDateSubTitle = nonMemberFromDate.format("YYYY");
        nonMemberOfferingDateTitle = (
          <div style={{ fontSize: "35px", marginBottom: "10px" }}>
            {nonMemberFromDate.format("DD MMMM")}
            <br />
            الی
            <br /> {nonMemberToDate.format("DD MMMM")}
          </div>
        );
      }
    } else {
      nonMemberOfferingDateTitle = (
        <div style={{ fontSize: "35px", marginBottom: "30px" }}>
          {nonMemberFromDate.format("DD/MM/YYYY")} <br /> الی <br />
          {nonMemberToDate.format("DD/MM/YYYY")}
        </div>
      );
    }
  }
  //************ end Non Members ************
  //************ Non Members Investor ************
  let nonMemberInvestorOfferingDateSubTitle = "";
  let nonMemberInvestorOfferingDateTitle = <div />;
  const nonMemberInvestorFromDate = moment(
    stockSummary?.nonMemberInvestorOfferingPeriod?.from
  ).locale("fa");
  const nonMemberInvestorToDate = moment(
    stockSummary?.nonMemberInvestorOfferingPeriod?.to
  ).locale("fa");

  if (
    nonMemberInvestorFromDate.isValid() &&
    nonMemberInvestorToDate.isValid()
  ) {
    if (
      nonMemberInvestorFromDate.format("YYYY").toString() ===
      nonMemberInvestorToDate.format("YYYY").toString()
    ) {
      if (
        nonMemberInvestorFromDate.format("MM") ===
        nonMemberInvestorToDate.format("MM")
      ) {
        nonMemberInvestorOfferingDateSubTitle =
          nonMemberInvestorFromDate.format("MMMM").toString() +
          " " +
          nonMemberInvestorFromDate.format("YYYY").toString();
        nonMemberInvestorOfferingDateTitle = (
          <div>
            {nonMemberInvestorFromDate.format("DD")} الی{" "}
            {nonMemberInvestorToDate.format("DD")}
          </div>
        );
      } else {
        nonMemberInvestorOfferingDateSubTitle = nonMemberInvestorFromDate.format(
          "YYYY"
        );
        nonMemberInvestorOfferingDateTitle = (
          <div style={{ fontSize: "35px", marginBottom: "10px" }}>
            {nonMemberInvestorFromDate.format("DD MMMM")}
            <br />
            الی
            <br /> {nonMemberInvestorToDate.format("DD MMMM")}
          </div>
        );
      }
    } else {
      nonMemberInvestorOfferingDateTitle = (
        <div style={{ fontSize: "35px", marginBottom: "30px" }}>
          {nonMemberInvestorFromDate.format("DD/MM/YYYY")} <br /> الی <br />
          {nonMemberInvestorToDate.format("DD/MM/YYYY")}
        </div>
      );
    }
  }
////////////////////////////////////////سرچ براساس کد ملی
  const loadFilterNc = ({
    nc,
    page,
    size,
    sort,
    asc,
  }: {
    page?: number;
    size?: number;
    sort?: string;
    asc?: boolean;
    nc?: number; 
  }) => {
    const query = {
      page,
      size: size ? size : 5,
      sort: sort ? sort : "requestDate",
      asc: asc ? asc : false,
      nc: nc ? nc : ncSearch ,
    };
    setTableSettings(query);
    setPendingRequests([]);
    setPendingRequestsLoading(true);
    authedApiState.apiContainer?.api
      .searchNcInDashboard("pending", query)
      .then(({ data, status }) => {
        setPendingRequestsTotalElements(data.totalElements!!); 
        if (status === 200) {
          setPendingRequests(
            data.content?.map((item: any) => {
              return {
                name: item.firstName + " " + item.lastName,
                accountType: t("accountType." + item.accountType),
                nationalCode: item.nationalCode,
                mobile: item.mobile,
                requestDate: item.requestDate?.toString().replace(/-/g, "/"),
                companyName: item.companyName ? item.companyName : "---",
                incompleteFields: item.incompleteFields,
                incompleteDocs: item.incompleteDocs,
                actions: item,
              };
            })
          ); 
          
        } else {
          toast(t("notification.request.approve.fail"), "error");
        }
        setPendingRequestsLoading(false); 
      })
      .catch((response: any) => {
        toast(response?.data?.msg || response?.data?.message, "error");
        setPendingRequestsLoading(false);
      });
  };
  ////////////////////////////////////////سرچ براساس موبایل
  const loadFilterMob = ({
    mob,
    page,
    size,
    sort,
    asc,
  }: {
    page?: number;
    size?: number;
    sort?: string;
    asc?: boolean;
    mob?: number; 
  }) => {
    const query = {
      page,
      size: size ? size : 5,
      sort: sort ? sort : "requestDate",
      asc: asc ? asc : false,
      mob: mob ? mob : mobileSearch,
    };
    setTableSettings(query);

    setPendingRequests([]);
    setPendingRequestsLoading(true);
    authedApiState.apiContainer?.api
      .searchMobInDashboard("pending", query)
      .then(({ data, status }) => {
        setPendingRequestsTotalElements(data.totalElements!!); 
        if (status === 200) {
          
          setPendingRequests(
            data.content?.map((item: any) => {
              return {
                name: item.firstName + " " + item.lastName,
                accountType: t("accountType." + item.accountType),
                nationalCode: item.nationalCode,
                mobile: item.mobile,
                requestDate: item.requestDate?.toString().replace(/-/g, "/"),
                companyName: item.companyName ? item.companyName : "---",
                incompleteFields: item.incompleteFields,
                incompleteDocs: item.incompleteDocs,
                actions: item,
              };
            })
          );
         
        } else {
          toast(t("notification.request.approve.fail"), "error");
        }
        setPendingRequestsLoading(false); 
      })
      .catch((response: any) => {
        toast(response?.data?.msg || response?.data?.message, "error");
        setPendingRequestsLoading(false);
      });
  };
 ////////////////////////////////////////سرچ براساس نام
 const loadFiltername = ({
  name,
  page,
  size,
  sort,
  asc,
}: {
  page?: number;
  size?: number;
  sort?: string;
  asc?: boolean;
  name?: string; 
}) => {
  const query = {
    page,
    size: size ? size : 5,
    sort: sort ? sort : "requestDate",
    asc: asc ? asc : false,
    name: name ? name : nameSearch,
  };
  setTableSettings(query);
  setPendingRequests([]);
  setPendingRequestsLoading(true);
  authedApiState.apiContainer?.api
    .searchNameInDashboard("pending", query)
    .then(({ data, status }) => {
      setPendingRequestsTotalElements(data.totalElements!!); 
      if (status === 200) {
        setPendingRequests(
          data.content?.map((item: any) => {
            return {
              name: item.firstName + " " + item.lastName,
              accountType: t("accountType." + item.accountType),
              nationalCode: item.nationalCode,
              mobile: item.mobile,
              requestDate: item.requestDate?.toString().replace(/-/g, "/"),
              companyName: item.companyName ? item.companyName : "---",
              incompleteFields: item.incompleteFields,
              incompleteDocs: item.incompleteDocs,
              actions: item,
            };
          })
        );
      
      } else {
        toast(t("notification.request.approve.fail"), "error");
      }
      setPendingRequestsLoading(false); 
    })
    .catch((response: any) => {
      toast(response?.data?.msg || response?.data?.message, "error");
      setPendingRequestsLoading(false);
    });
};
////////////////////////////////////////سرچ براساس نام خانوادگی
const loadFilterLastname = ({
  last,
  page,
  size,
  sort,
  asc,
}: {
  page?: number;
  size?: number;
  sort?: string;
  asc?: boolean;
  last?: string; 
}) => {
  const query = {
    page,
    size: size ? size : 5,
    sort: sort ? sort : "requestDate",
    asc: asc ? asc : false,
    last: last ? last : familySearch,
  };
  setTableSettings(query);
  setPendingRequests([]);
  setPendingRequestsLoading(true);
  authedApiState.apiContainer?.api
    .searchLastNameInDashboard("pending", query)
    .then(({ data, status }) => {
      setPendingRequestsTotalElements(data.totalElements!!); 
      if (status === 200) {
        
        setPendingRequests(
          data.content?.map((item: any) => {
            return {
              name: item.firstName + " " + item.lastName,
              accountType: t("accountType." + item.accountType),
              nationalCode: item.nationalCode,
              mobile: item.mobile,
              requestDate: item.requestDate?.toString().replace(/-/g, "/"),
              companyName: item.companyName ? item.companyName : "---",
              incompleteFields: item.incompleteFields,
              incompleteDocs: item.incompleteDocs,
              actions: item,
            };
          })
        );
      
      } else {
        toast(t("notification.request.approve.fail"), "error");
      }
      setPendingRequestsLoading(false); 
    })
    .catch((response: any) => {
      toast(response?.data?.msg || response?.data?.message, "error");
      setPendingRequestsLoading(false);
    });
};
 
/////////////////////////////////////اکسل 
const loadFilterExcel = ({
  type,
  search,
  page,
  size,
  sort,
  asc,
}: {
  page?: number;
  size?: number;
  sort?: string;
  asc?: boolean;
  type?: number; 
  search?:string;
}) => {
  const query = {
    page,
    size: size ? size : 5,
    sort: sort ? sort : "requestDate",
    asc: asc ? asc : false,
    search: search ? search : ncExcel?ncExcel:mobileExcel,
    type:type
  };
  authedApiState.apiContainer?.api
    .DwExcelInDashboard("pending", query)
    .then(({ data, status }) => {
      if (status === 200) {
        DwFilterExcel(data.id)
      }
    })
    .catch((response: any) => {
      toast(response?.data?.msg || response?.data?.message, "error");
    });
};
const DwFilterExcel = async (data1: string) => {
  try {
    const response = await authedApiState.apiContainer?.api.DownloadExcel(data1);
    if (response) {
      const { data, status } = response;

      if (status === 200 && data) 
      {
        const excelData = data.map((item: any) => Object.values(item)); // Convert data to array of arrays
        const headers = ['نام', 'نام خانوادگی','کدملی','مدارک تکمیل نشده','اطلاعات تکمیل نشده','تاریخ رد درخواست','متقاضی','تاریخ‌درخواست','موبایل']; // Set the headers for columns
        const workbook = XLSX.utils.book_new();
        XLSX.utils.sheet_add_aoa(workbook, [headers, ...excelData], {origin: 0}); // Add headers and data to worksheet
        XLSX.utils.book_append_sheet(workbook, workbook, 'Sheet1'); // Add worksheet to workbook

        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' }); // Generate Excel buffer

        const blobData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }); // Convert buffer to Blob

        const url = window.URL.createObjectURL(blobData);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'downloaded_file.xlsx'; // Set the appropriate file name and extension
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      }
    }
  } catch (response: any) {
    toast(response?.data?.msg || response?.data?.message, "error");
  }
};

  //************ end Non Members Investor ************
  const [focusedInput, setFocusedInput] = useState<string | null>(null);

  const handleFocusChange = (placeholder: string) => {
    setFocusedInput(placeholder);
  };

  const handleClearFilter = () => {
    setFocusedInput(null);
    reloadTable();
  };
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={6} lg={12} md={12} sm={24} xs={24}>
          <div className={"dashboard-grid"}>
            <DashboardStatItem
              topTitle={t("dashboard.stat.item.totalTokens")}
              content={stockSummary?.totalTokens
                ?.toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              subtitle=""
              loading={summaryLoading}
            />
          </div>
        </Col>
        <Col span={6} lg={12} md={12} sm={24} xs={24}>
          <div className={"dashboard-grid"}>
            <DashboardStatItem
              topTitle={t("dashboard.stat.item.stockPrice")}
              content={stockSummary?.stockPrice
                ?.toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              subtitle=""
              loading={summaryLoading}
            />
          </div>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
        <Col span={6} lg={6} md={12} sm={24} xs={24}>
          <div className={"dashboard-grid"}>
            <DashboardStatItem
              topTitle={t("dashboard.stat.item.memberOfferingPeriod")}
              content={memberOfferingDateTitle}
              subtitle={memberOfferingDateSubTitle}
              loading={summaryLoading}
            />
          </div>
        </Col>
        <Col span={6} lg={6} md={12} sm={24} xs={24}>
          <div className={"dashboard-grid"}>
            <DashboardStatItem
              topTitle={t("dashboard.stat.item.nonMemberOfferingPeriod")}
              content={nonMemberOfferingDateTitle}
              subtitle={nonMemberOfferingDateSubTitle}
              loading={summaryLoading}
            />
          </div>
        </Col>
        <Col span={6} lg={6} md={12} sm={24} xs={24}>
          <div className={"dashboard-grid"}>
            <DashboardStatItem
              topTitle={t(
                "dashboard.stat.item.nonMemberInvestorOfferingPeriod"
              )}
              content={nonMemberInvestorOfferingDateTitle}
              subtitle={nonMemberInvestorOfferingDateSubTitle}
              loading={summaryLoading}
            />
          </div>
        </Col>
        <Col span={6} lg={6} md={12} sm={24} xs={24}>
          <div className={"dashboard-grid"}>
            <div className={"header"}>{t("dashboard.stat.item.calendar")}</div>
            <Calendar
              fullscreen={false}
              headerRender={({ value }: { value: moment.Moment }) => (
                <div
                  style={{
                    width: "120px",
                    paddingTop: "10px",
                    float: "left",
                  }}
                >
                  {moment(value).locale("fa").format("MMMM") +
                    " " +
                    moment(value).locale("fa").format("YYYY")}
                </div>
              )}
            />
          </div>
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
        <Col span={6} lg={12} md={12} sm={24} xs={24}>
          <div className={"dashboard-grid"}>
            <Row className={"header2"}>
              <Col span={24} lg={5} md={5} sm={5} xs={5}>
                {t("dashboard.recentTransactions")}
              </Col>
              <Col
                span={24}
                lg={18}
                md={18}
                sm={18}
                xs={18}
                style={{ borderBottom: "1px solid #e9e9f0", height: "10px" }}
              />
            </Row>
            <div className={"general-styled-table"}>
              <CustomTable
                onChange={() => {}}
                total={5}
                loading={recentTransactionsLoading}
                paginationBottom={"none"}
                data={recentTransactions}
                columns={recentTransactionColumn}
                forceSM={true}
                defaultSort={"requestDate"}
              />
            </div>
          </div>
        </Col>
        <Col span={6} lg={12} md={12} sm={24} xs={24}>
          <div className={"dashboard-grid"}>
            <Row className={"header2"}>
              <Col span={24} lg={5} md={5} sm={5} xs={5}>
                {t("dashboard.instantStat")}
              </Col>
              <Col
                span={24}
                lg={18}
                md={18}
                sm={18}
                xs={18}
                style={{ borderBottom: "1px solid #e9e9f0", height: "10px" }}
              />
            </Row>
            <div className={"dashboard-chart"}>
              <ColumnChart style={{ height: "100%" }} data={instantStat} />
            </div>
          </div>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: "10px", alignItems: "baseline" }}>
      <InputSearch
        handleChange={(e) => setNcSearch(e.target.value)}
        onFocusChange={handleFocusChange}
        placeholder="سرچ براساس کدملی"
        val={ncSearch}
        isDisabled={focusedInput ==="سرچ براساس نام" || focusedInput ==="سرچ براساس نام خانوادگی" || focusedInput === "سرچ براساس موبایل"}
      />
      <BtnFilter
        handleChange={() => loadFilterNc({ page: 0 })}
        textBtn="جستجو"
        type="search"
      />
      <InputSearch
        handleChange={(e) => setNameSearch(e.target.value)}
        onFocusChange={handleFocusChange}
        placeholder="سرچ براساس نام"
        val={nameSearch}
        isDisabled={focusedInput ==="سرچ براساس کدملی" || focusedInput ==="سرچ براساس نام خانوادگی" || focusedInput === "سرچ براساس موبایل"}
      />
       <BtnFilter
        handleChange={() => loadFiltername({ page: 0 })}
        textBtn="جستجو"
        type="search"
      />
      <InputSearch
        handleChange={(e) => setFamilySearch(e.target.value)}
        onFocusChange={handleFocusChange}
        placeholder="سرچ براساس نام خانوادگی"
        val={familySearch}
        isDisabled={focusedInput ==="سرچ براساس کدملی" || focusedInput ==="سرچ براساس نام" || focusedInput === "سرچ براساس موبایل"}
      />
       <BtnFilter
        handleChange={() => loadFilterLastname({ page: 0 })}
        textBtn="جستجو"
        type="search"
      />
       <InputSearch
        handleChange={(e) => setMobileSearch(e.target.value)}
        onFocusChange={handleFocusChange}
        placeholder="سرچ براساس موبایل"
        val={mobileSearch}
        isDisabled={focusedInput ==="سرچ براساس کدملی" || focusedInput ==="سرچ براساس نام" || focusedInput === "سرچ براساس نام خانوادگی"}
      />
       <BtnFilter
        handleChange={() => loadFilterMob({ page: 0 })}
        textBtn="جستجو"
        type="search"
      />
      
      {/* Repeat the same pattern for other InputSearch components */}
      <BtnClearFilter
        handleChange={handleClearFilter}
        textBtn="حذف فیلتر"
        type="remove"
      />
    </Row>
      <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
        <Col span={24} lg={24} md={24} sm={24} xs={24}>
          <div className={"dashboard-grid"}>
            <Row className={"header2"}>
              <Col span={24} lg={5} md={5} sm={5} xs={5}>
                {t("dashboard.requests")}
              </Col>
              <Col
                span={24}
                lg={18}
                md={18}
                sm={18}
                xs={18}
                style={{ borderBottom: "1px solid #e9e9f0", height: "10px" }}
              />
            </Row>
{focusedInput === "سرچ براساس کدملی" ?
<div>
              <CustomTable
                loading={pendingRequestsLoading}
                className="special-styled-table"
                paginationBottom={"bottomCenter"}
                data={pendingRequests}
                columns={pendingRequestsColumn}
                total={PendingRequestsTotalElements}
                onChange={loadFilterNc}
                defaultSort={"requestDate"}
              />
            </div>
:focusedInput === "سرچ براساس نام"?
<div>
              <CustomTable
                loading={pendingRequestsLoading}
                className="special-styled-table"
                paginationBottom={"bottomCenter"}
                data={pendingRequests}
                columns={pendingRequestsColumn}
                total={PendingRequestsTotalElements}
                onChange={loadFiltername}
                defaultSort={"requestDate"}
              />
            </div>
:focusedInput === "سرچ براساس نام خانوادگی"?
<div>
              <CustomTable
                loading={pendingRequestsLoading}
                className="special-styled-table"
                paginationBottom={"bottomCenter"}
                data={pendingRequests}
                columns={pendingRequestsColumn}
                total={PendingRequestsTotalElements}
                onChange={loadFilterLastname}
                defaultSort={"requestDate"}
              />
            </div>
:focusedInput === "سرچ براساس موبایل"?
<div>
              <CustomTable
                loading={pendingRequestsLoading}
                className="special-styled-table"
                paginationBottom={"bottomCenter"}
                data={pendingRequests}
                columns={pendingRequestsColumn}
                total={PendingRequestsTotalElements}
                onChange={loadFilterMob}
                defaultSort={"requestDate"}
              />
            </div>
:
<div>
              <CustomTable
                loading={pendingRequestsLoading}
                className="special-styled-table"
                paginationBottom={"bottomCenter"}
                data={pendingRequests}
                columns={pendingRequestsColumn}
                total={PendingRequestsTotalElements}
                onChange={reloadTable}
                defaultSort={"requestDate"}
              />
            </div>
}
          
            {/* <div>
              <CustomTable
                loading={pendingRequestsLoading}
                className="special-styled-table"
                paginationBottom={"bottomCenter"}
                data={pendingRequests}
                columns={pendingRequestsColumn}
                total={PendingRequestsTotalElements}
                onChange={reloadTable}
                defaultSort={"requestDate"}
              />
            </div> */}
          </div>
          {/* <Row gutter={[16, 16]}  style={{ marginTop: "20px", alignItems: "baseline" }}>
              <InputSearch
                handleChange={(e) => setNcSearch(e.target.value)}
                placeholder="دانلود براساس کدملی"
                val={ncSearch}
              />
               <BtnClearFilter
                handleChange={() =>loadFilterNc({ page: 0 }) }
                textBtn="دانلود اکسل"
                type="search"
              />
                <InputSearch
                handleChange={(e) => setMobileSearch(e.target.value)}
                placeholder="دانلود براساس موبایل"
                val={mobileSearch}
              />
              <BtnClearFilter
                handleChange={() =>loadFilterNc({ page: 0 }) }
                textBtn="دانلود اکسل"
                type="search"
              />
              <BtnClearFilter
                handleChange={() => reloadTable()}
                textBtn="حذف فیلتر"
                type="remove"
              />
            </Row> */}
        </Col>
      </Row>
      <Row gutter={[16, 16]}  style={{ marginTop: "10px", alignItems: "baseline" }}>
              <InputExcel
                handleChange={(e) => setNcExcel(e.target.value)}
                placeholder="دانلود براساس کدملی"
                val={ncExcel}
              
              />
              <BtnExcel
                handleChange={() =>loadFilterExcel({ page: 0,type:3})}
                textBtn="جستجو"
                
              />
                <InputExcel
                handleChange={(e) => setMobileExcel(e.target.value)}
                placeholder="دانلود براساس موبایل"
                val={mobileExcel}
                
              />
              <BtnExcel
                handleChange={() =>loadFilterExcel({ page: 0,type:4})}
                textBtn="جستجو"
                
              />
              <BtnClearFilter
                handleChange={() => {
                  setMobileExcel("");
                  setNcExcel("");
                }}
                textBtn="حذف فیلتر"
                type="remove"
              />
            </Row>
    </>
  );
};
export default StockStatModule;
