import {Col, Row, Upload} from "antd";
import {useEffect, useState} from "react";
import UploadFrame from '../../assets/images/upload_photo_frame.svg';
import useToast from "../../hooks/useToast";
import {useTranslation} from "react-i18next";
import {faCloudUploadAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import useStore from "../../hooks/useStore";

const FormerSupplies = () => {
    const {t} = useTranslation('parameters')
    const [store] = useStore();
    const [fileUploaded, setFileUploaded] = useState<boolean>(false);
    const [fileUploadedResp, setFileUploadedResp] = useState<string>("");
    const [fileUploading, setFileUploading] = useState<boolean>(false);
    const [file, setFile] = useState<File[]>([]);
    const toast = useToast()

    useEffect(() => {
        // readStockInfo
        if (fileUploadedResp && fileUploadedResp.length > 0) {
            store.apiContainer?.api.readStockInfo(fileUploadedResp).then(({data, status}) => {
                if (status === 200) {
                    toast("داده ها با موفقیت ثبت گردید", "success")
                    setFileUploaded(true)
                    // @ts-ignore
                    // setFileUploadedResp(data.id.toString());
                } else {
                    toast("مشکل در ثبت داده ها", "error")
                }
                setFileUploading(false);
            }).catch((response) => {
                toast((response.data?.msg) ? response.data.msg : "مشکل در ثبت داده ها", 'error');
                setFileUploading(false);
            })
        }
    }, [fileUploadedResp])

    const props = {
        name: 'file',
        multiple: false,
        disabled: fileUploaded,
        maxCount: 1,
        onRemove: (file: any) => {
            setFile([])
        },
        beforeUpload: (_file: File) => {
            if (_file.type !== 'text/csv' &&
                _file.type !== 'application/vnd.ms-excel' &&
                _file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                toast("فایل از انواع مذکور نیست", "error")
                return
            }
            if (_file.size / 1024 / 1024 > 10) {
                toast("فایل بزرگتر از حد مجاز", "error")
                return;
            }
            setFile([_file]);
            return false;
        }
    };

    const handleUpload = () => {
        if (file.length === 0) return;
        setFileUploading(true);
        store.apiContainer?.api.addFile({file: file[0]}).then(({data, status}) => {
            if (status === 200) {
                // toast("فایل با موفقیت آپلود شد", "success")
                setFileUploaded(true)
                // @ts-ignore
                setFileUploadedResp(data.id.toString());
            } else {
                toast("مشکل در آپلود فایل", "error")
            }
            setFileUploading(false);
        }).catch((response) => {
            toast((response.data?.msg) ? response.data.msg : "مشکل درآپلود فایل", 'error');
            setFileUploading(false);
        })
    }

    return (
        <>
            <Row gutter={[16, 16]}>
                <Col span={24} lg={24} md={24} sm={24} xs={24}>
                    <div className={'dashboard-grid'}>
                        <Row className={'header2'}>
                            <Col span={6}>
                                {t('upload.formerSuppplies')}
                            </Col>
                            <Col span={18}
                                 style={{borderBottom: "1px solid #e9e9f0", height: "10px"}}/>
                        </Row>
                        <Row justify={"center"}>
                            <Col lg={15} sm={24}>
                                <Row justify={"center"}>
                                    <Col lg={12} sm={12}>
                                        <Upload {...props} >
                                            <div style={{
                                                margin: "10% auto",
                                                padding: "50px 120px 50px 120px",
                                                border: "1px dashed #D0C9DF",
                                                borderRadius: "5px",
                                            }}>
                                                <p>فایل از نوع CSV/EXCEL</p>
                                                <p>حجم فایل حداکثر 10MB</p>
                                            </div>
                                        </Upload>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={8}>
                                <div className={"upload-side-icon"} style={{marginRight: "10px"}}>
                                    <img src={UploadFrame} alt={''}
                                         style={{
                                             width: "200px",
                                             position: "absolute",
                                             left: "50%",
                                             top: "50%",
                                             transform: "translate(-50%, -50%)"
                                         }}/>
                                    <FontAwesomeIcon
                                        icon={faCloudUploadAlt}
                                        style={{
                                            fontSize: "80px",
                                            position: "absolute",
                                            left: "50%",
                                            top: "50%",
                                            transform: "translate(-50%, -50%)"
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <br/>
                    </div>
                </Col>
            </Row><br/>
            <Row justify={"center"} gutter={[16, 16]}>
                <Col>
                    <button
                        className={'form-submit-button'}
                        onClick={handleUpload}
                        disabled={file.length === 0 || fileUploaded}
                    >
                        {fileUploading ? 'در حال ارسال' : 'آپلود'}
                    </button>
                    <br/>
                </Col>
            </Row>
        </>
    )
}

export default FormerSupplies;
