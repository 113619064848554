import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Col, Row} from "antd";
import CustomTable from "../../components/Table";
import useStore from "../../hooks/useStore";
import {ColumnProps} from "antd/lib/table/Column";
import useToast from "../../hooks/useToast";


const RejectedCertRequests = () => {
    const {t} = useTranslation('identification');
    const [PendingRequestsTotalElements, setPendingRequestsTotalElements] = useState<number>(0)
    const [pendingRequests, setPendingRequests] = useState<any[]>([]);
    const [pendingRequestsLoading, setPendingRequestsLoading] = useState<boolean>(true);
    const [authedApiState] = useStore()
    const toast = useToast();

    useEffect(() => {
        loadRejectedRequests({page: 0});
    }, [])

    const loadRejectedRequests = ({page, size, sort, asc}:
         { page: number, size?: number, sort?: string, asc?: boolean }) => {
        const query = {
            page, size: size ? size : 5,
            sort: sort ? sort : 'requestDate', asc:asc?asc:false
        }
        
        setPendingRequests([])
        setPendingRequestsLoading(true)
        authedApiState.apiContainer?.api.allStockPapers1("reject", query).then(({data, status}) => {
            //@ts-ignore
            setPendingRequestsTotalElements(data.totalElements)
            if (status === 200) {
                //@ts-ignore
                setPendingRequests(data.content?.map((item: any) => {
                    return {
                        fullName: item.fullName,
                        accountType: t('accountType.'+ item.accountType),
                        userType: t('userType.'+ item.userType),
                        requestDate: item.requestDate?.toString().replace(/-/g, "/"),
                        rejectDate: item.rejectDate?.toString().replace(/-/g, "/"),
                        companyName: item.companyName?item.companyName:'---',
                        amount: item.amount
                    }
                }));
            } else {
                toast(t('notification.request.fail'), 'error');
            }

            setPendingRequestsLoading(false)
        }).catch((response) => {
            toast(response?.data?.msg || response?.data?.message, 'error');
            setPendingRequestsLoading(false);
        })
    };

    const pendingRequestsColumn: ColumnProps<Record<string, string>>[] = [
        {
            title: 'تاریخ‌درخواست',
            key: 'requestDate',
            dataIndex: 'requestDate',
        },
        {
            title: 'نام‌و‌نام‌خانوادگی',
            dataIndex: 'fullName',
            key: 'fullName',
        },
        {
            title: 'متقاضی',
            dataIndex: 'accountType',
            key: 'accountType',
        },
        {
            title: 'نام‌شرکت',
            key: 'companyName',
            dataIndex: 'companyName',
            responsive: ['xxl', 'xl', 'lg', 'sm']
        },
        {
            title: 'نوع سهام‌داری',
            key: 'userType',
            dataIndex: 'userType',
            responsive: ['xxl', 'xl', 'lg', 'sm']
        },
        {
            title: 'تعداد سهم',
            key: 'amount',
            dataIndex: 'amount',
            responsive: ['xxl', 'xl', 'lg', 'sm'],
            render: item => item?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        {
            title: 'تاریخ عدم تایید',
            key: 'rejectDate',
            dataIndex: 'rejectDate',
            responsive: ['xxl', 'xl', 'lg', 'sm']
        },
    ]

    return (
        <>
            <Row gutter={[16, 16]}>
                <Col span={24} lg={24} md={24} sm={24} xs={24}>
                    <div className={'dashboard-grid'}>
                        <CustomTable
                            loading={pendingRequestsLoading}
                            className='special-styled-table'
                            paginationBottom={"bottomCenter"}
                            data={pendingRequests}
                            columns={pendingRequestsColumn}
                            total={PendingRequestsTotalElements}
                            onChange={loadRejectedRequests}
                            defaultSort={"requestDate"}
                        />
                    </div>
                </Col>
            </Row>
        </>
    )
};

export default RejectedCertRequests;
