import styled, { css } from 'styled-components';
import MediaQuery from '../../utils/MediaQuery';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1570px;
  padding: 0px 20px 15px 20px;
  gap: 16px;

  ${({ noPadding }) =>
    noPadding
      ? css`
          padding: 0 20px;
        `
      : css`
          ${MediaQuery.styling(
            'md',
            css`
              padding: 10px 20px 15px 20px;
            `
          )};

          ${MediaQuery.styling(
            'xs',
            css`
              padding: 5px 20px 15px 20px;
            `
          )};
        `};

  & > .title {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
    text-align: center;
  }
`;

const Title = styled('h2')`
  font-size: 35px;
  font-weight: 700;
  color: #9c9cbd;
  padding: 0;
  margin: 0;

  ${({ colorful }) =>
    colorful &&
    css`
      background: -webkit-linear-gradient(0deg, #8055fa 20%, #ee61bb 80%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    `};

  ${MediaQuery.styling(
    'lg',
    css`
      font-size: 35px;
    `
  )};

  ${MediaQuery.styling(
    'md',
    css`
      font-size: 28px;
    `
  )};

  ${MediaQuery.styling(
    'sm',
    css`
      font-size: 24px;
    `
  )};

  ${MediaQuery.styling(
    'xs',
    css`
      font-size: 20px;
    `
  )};
`;

const Subtitle = styled('div')`
  font-size: 15px;
  font-weight: 500;
  color: #d0c9df;

  ${MediaQuery.styling(
    'lg',
    css`
      font-size: 16px;
    `
  )};

  ${MediaQuery.styling(
    'sm',
    css`
      font-size: 15px;
    `
  )};
`;

const Star = styled('div')`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .star {
    position: absolute;
    left: auto;
    right: auto;
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: 50% 50%;
    background-attachment: scroll;

    &.star-1 {
      background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.8509 29.7017C12.2882 29.7017 10.21 27.6244 10.21 25.0608C10.21 21.9895 7.71223 19.4917 4.64089 19.4917C2.07819 19.4917 0 17.4145 0 14.8509C0 12.2872 2.07819 10.21 4.64089 10.21C7.71223 10.21 10.21 7.71223 10.21 4.64089C10.21 2.07726 12.2882 0 14.8509 0C17.4136 0 19.4917 2.07726 19.4917 4.64089C19.4917 7.71223 21.9895 10.21 25.0608 10.21C27.6235 10.21 29.7017 12.2872 29.7017 14.8509C29.7017 17.4145 27.6235 19.4917 25.0608 19.4917C21.9895 19.4917 19.4917 21.9895 19.4917 25.0608C19.4917 27.6244 17.4136 29.7017 14.8509 29.7017Z' fill='%237754FF'/%3E%3C/svg%3E");
      top: 126px;
      width: 30px;
      height: 30px;
      transform: translateX(-655px);
    }
    &.star-2 {
      background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.8509 29.7017C12.2882 29.7017 10.21 27.6244 10.21 25.0608C10.21 21.9895 7.71223 19.4917 4.64089 19.4917C2.07819 19.4917 0 17.4145 0 14.8509C0 12.2872 2.07819 10.21 4.64089 10.21C7.71223 10.21 10.21 7.71223 10.21 4.64089C10.21 2.07726 12.2882 0 14.8509 0C17.4136 0 19.4917 2.07726 19.4917 4.64089C19.4917 7.71223 21.9895 10.21 25.0608 10.21C27.6235 10.21 29.7017 12.2872 29.7017 14.8509C29.7017 17.4145 27.6235 19.4917 25.0608 19.4917C21.9895 19.4917 19.4917 21.9895 19.4917 25.0608C19.4917 27.6244 17.4136 29.7017 14.8509 29.7017Z' fill='%237754FF'/%3E%3C/svg%3E");
      top: 167px;
      width: 18px;
      height: 18px;
      transform: translateX(-731px);
    }
    &.star-3 {
      background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 27 27' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.2646 26.5301C5.95057 26.5301 0 20.5796 0 13.2655C0 5.95055 5.95057 0 13.2646 0C20.5796 0 26.5301 5.95055 26.5301 13.2655C26.5301 20.5796 20.5796 26.5301 13.2646 26.5301ZM13.2646 9.28178C11.0685 9.28178 9.28178 11.0695 9.28178 13.2655C9.28178 15.4616 11.0685 17.2483 13.2646 17.2483C15.4607 17.2483 17.2483 15.4616 17.2483 13.2655C17.2483 11.0695 15.4616 9.28178 13.2646 9.28178Z' fill='%23F461B7'/%3E%3C/svg%3E%0A");
      top: 209px;
      width: 26px;
      height: 26px;
      transform: translateX(-676px);
    }
    &.star-4 {
      background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.8509 29.7017C12.2882 29.7017 10.21 27.6244 10.21 25.0608C10.21 21.9895 7.71223 19.4917 4.64089 19.4917C2.07819 19.4917 0 17.4145 0 14.8509C0 12.2872 2.07819 10.21 4.64089 10.21C7.71223 10.21 10.21 7.71223 10.21 4.64089C10.21 2.07726 12.2882 0 14.8509 0C17.4136 0 19.4917 2.07726 19.4917 4.64089C19.4917 7.71223 21.9895 10.21 25.0608 10.21C27.6235 10.21 29.7017 12.2872 29.7017 14.8509C29.7017 17.4145 27.6235 19.4917 25.0608 19.4917C21.9895 19.4917 19.4917 21.9895 19.4917 25.0608C19.4917 27.6244 17.4136 29.7017 14.8509 29.7017Z' fill='%237754FF'/%3E%3C/svg%3E");
      top: 209px;
      width: 30px;
      height: 30px;
      transform: translateX(722px);
    }
    &.star-5 {
      background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 27 27' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.2646 26.5301C5.95057 26.5301 0 20.5796 0 13.2655C0 5.95055 5.95057 0 13.2646 0C20.5796 0 26.5301 5.95055 26.5301 13.2655C26.5301 20.5796 20.5796 26.5301 13.2646 26.5301ZM13.2646 9.28178C11.0685 9.28178 9.28178 11.0695 9.28178 13.2655C9.28178 15.4616 11.0685 17.2483 13.2646 17.2483C15.4607 17.2483 17.2483 15.4616 17.2483 13.2655C17.2483 11.0695 15.4616 9.28178 13.2646 9.28178Z' fill='%23F461B7'/%3E%3C/svg%3E%0A");
      top: 266px;
      width: 18px;
      height: 18px;
      transform: translateX(671px);
    }
  }
`;

const Styled = {
  Container,
  Title,
  Subtitle,
  Star
};

export default Styled;
