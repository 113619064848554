import React, {useEffect, useState} from "react";
import {nanoid} from 'nanoid'
import {DatePicker as DatePickerJalali} from "antd-jalali";

export interface InputProps {
    value?: string;
    onChange?: (value: string[] | null) => void;
    style?: React.CSSProperties,
    label: string,
    className?: string
}

const RangeInput = ({label, value, onChange, className}: InputProps) => {
    const changeHandler = (value:any) => {
        onChange && onChange(value)
    }
    const [id, setId] = useState<string>()
    useEffect(() => {
        setId(nanoid())
    }, [])

    return (
        <>
            <div style={{position: "relative"}}>
                <label
                    htmlFor={id}
                    style={{
                        backgroundColor: "white",
                        paddingLeft: "5px",
                        paddingRight: "5px",
                        top: "-12px",
                        right: "15px",
                        position: "absolute",
                        zIndex: 10,
                        color: "#c4c4c4",
                        fontSize: "16px"
                    }}
                >
                    {label}
                </label>

                <DatePickerJalali
                    id={id}
                    placeholder={""}
                    format={"YYYY/MM/DD"}
                    className={className}
                    style={{
                        border: "1px solid rgb(208, 201, 223)",
                        padding: "5px",
                        width: "100%",
                        height: "60px",
                        borderRadius: "5px",
                        fontSize: "16px !important",
                        color:"rgb(93, 85, 161) !important",
                        textAlign: "center !important"
                    }}
                    value={value}
                    onChange={(e:any) => {
                        changeHandler(e)
                    }}
                />
            </div>
        </>
    )
}
export default RangeInput;
