class KeyA {
    constructor() {}

    getResponse(func, event) {
        if (event.source !== window) {
            func({command:"error",message:event.data?.text?.message})
            return;
        }
        if (event.data.src && (event.data.src === "bg-keya.js")) {
            func(event.data["text"]);
        }else{
            func({command:"error",message:event.data?.text?.message})
        }
    }

    post_message(jsonData, func) {
        jsonData["src"] = "keyax.js";
        window.addEventListener("message", this.getResponse.bind(null, func));
        window.postMessage(jsonData, "*");
    }

    KeyA3_Sign_PKCS(pkcs_lib_path_name, user_pin, data, data_len, func) {
        let jsonData = {};
        jsonData["command"] = "keya3_sign_pkcs";
        jsonData["pkcs_lib_path_name"] = pkcs_lib_path_name;
        jsonData["user_pin"] = user_pin;
        jsonData["data"] = data;
        jsonData["data_len"] = data_len;

        this.post_message(jsonData, func);
    }

    KeyA3_Sign_CSP(challenge, func) {
        let jsonData = {};
        jsonData["command"] = "keya3_sign_csp";
        jsonData["datatosign"] = challenge;

        this.post_message(jsonData, func);
    }

    KeyA_GetInfo(func) {
        let jsonData = {};
        jsonData["command"] = "keya_getinfo";

        this.post_message(jsonData, func);
    }
}

export default KeyA;