import React, {useEffect, useState} from 'react';

var Highcharts = require('highcharts/highstock');

type ChartItem = { name: string, y: number, color: string }
// @ts-ignore
window.Highcharts = Highcharts;

const Donut = ({data}: { data: ChartItem[] }) => {
    /*const series:SeriesXrangeOptions[] = [{
        name: 'گازها',
        data: [
            {
                name: 'عضو',
                y: 0.9,
                color: '#3498db'
            },
            {
                name: 'غیرعضو',
                y: 78.1,
                color: '#9b59b6'
            },
            {
                name: 'سرمایه‌گذار',
                y: 20.9,
                color: '#2ecc71'
            }
        ],
        type: 'xrange'
    }]*/


    const highChartsRender = () => {
        const sum = data.reduce((acm, item) => item.y + acm, 0)
        Highcharts.chart({
            chart: {
                type: 'pie',
                renderTo: 'atmospheric-composition'
            },
            title: {
                verticalAlign: 'middle',
                floating: true,
                text: sum.toString(),
                style: {
                    fontSize: '40px',
                }
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        format: '{point.name}: {point.y}'
                    },
                    innerSize: '70%'
                }
            },
            series: [{data, name: 'سهم'}],
            tooltip: {
                useHTML: true
            },
        });
    }

    useEffect(() => {
        highChartsRender();
    }, [data])


    return (
        <div id="atmospheric-composition"/>
    );
}

export default Donut;
