import Logo from '../../assets/images/logo.svg';
import React, {useEffect,useState} from 'react';
import Styled from './styles';

const Loading = ({
                     color,
                     full = false,
                     isRelative = true
                 }: { color?: string, full?: boolean, isRelative?: boolean }) => {
    useEffect(() => {
        if (full) {
            document.body.style.overflow = 'hidden';
        }
        return () => {
            if (full) {
                document.body.removeAttribute('style');
            }
        };
    }, [full]);

    return (
        // @ts-ignore
        <Styled.Container isFull={full} isRelative={isRelative}>
            {full && <img src={Logo} alt={''}/>}
            <Styled.Content color={color}>
                <span className={'dot1'}/>
                <span className={'dot2'}/>
                <span className={'dot3'}/>
            </Styled.Content>
        </Styled.Container>
    );
};

export default Loading;
