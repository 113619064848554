import Container from '../layouts/Container';
import Content from '../layouts/Content';
import Navbar from '../layouts/navbar/navbar';
import React, {useEffect, useState} from 'react';
import 'antd/dist/antd.css';

import {
    BrowserRouter as Router,
} from 'react-router-dom';
import Private from './private';
import Public from './public';
import {storeActions, authedApiStore} from "../hooks/useStore";
import useStore from "../hooks/useStore";
import Footer from "../layouts/Footer/footer";
import Loading from "../layouts/loading/loading";


/*const _ScrollToTop = (props: any) => {
    const {pathname} = useLocation();
    useEffect(() => {
        try {
            window.scroll({
                top: 400,
                left: 0,
                behavior: 'smooth'
            });
        } catch (error) {
            window.scrollTo(0, 400);
        }
    }, [pathname]);
    return props.children;
};*/
//const ScrollToTop = withRouter(_ScrollToTop);

const Index = () => {

    const [isLoading, setIsLoading] = useState(false);

    const onGetUserData = () => {
        authedApiStore.state.apiContainer?.api.userProfile().then(({data}) => {
            if (data && data.id) {
                setIsLoading(false);
                storeActions.setUser(data)
            }
            else {
                handleNotAuthenticated();
            }
        }).catch(()=>{
            handleNotAuthenticated();
        })
    };

    const [authedApiState] = useStore()
    useEffect(() => {
        if (authedApiState.loggedIn) {
            storeActions.initiate()
            onGetUserData();
        }
    }, [])

    const isAuthenticated = authedApiState.loggedIn;

    // useEffect(() => {
    //   const _today = Date.now();
    //   const _deadline = Date.parse('21 Apr 2021');
    //   const _days = Math.ceil((_deadline - _today) / 86400000);
    //   const _opacity = _days > 0 ? (_days >= 100 ? 1 : _days / 100) : 0;
    //   if (_opacity < 1) document.body.style.opacity = _opacity;
    // });

    const handleNotAuthenticated = () => {
        storeActions.logout()
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    };

    return (
        <>{isLoading ?
            <Loading/> :
            <Router>
                <Container>
                    <Navbar/>
                    <Content>
                        <Public isAuthenticated={isAuthenticated}/>
                        <Private isAuthenticated={isAuthenticated}/>
                    </Content>
                    <Footer/>
                </Container>
            </Router>
        }
        </>
    )

};

export default Index;
