import styled, { css } from 'styled-components';
import MediaQuery from '../../utils/MediaQuery';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  flex: 1;
  background: #f0f0f8;
  overflow: hidden;
  padding: 100px 0;
  gap: 50px;

  ${MediaQuery.styling(
    'md',
    css`
      gap: 25px;
    `
  )};
`;

const Title = styled('h2')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 250px;
  background: #c71720;
  color: #fff;
  margin: 0;
  border-radius: 250px;
  border: 10px solid #fff;
  box-shadow: 0 0 0 10px #c71720;
  font-size: 64px;
  font-weight: 700;

  ${MediaQuery.styling(
    'md',
    css`
      width: 150px;
      height: 150px;
      font-size: 48px;
    `
  )};
`;

const Subtitle = styled('h3')`
  font-size: 48px;
  font-weight: 700;
  color: #767b87;
  margin: 0;

  ${MediaQuery.styling(
    'md',
    css`
      font-size: 32px;
    `
  )};
`;

const Styled = {
  Container,
  Title,
  Subtitle
};

export default Styled;
