import {Input} from "antd";
import React, {ChangeEvent, useEffect, useState} from "react";
import {nanoid} from 'nanoid'
import dayjs from "dayjs";
import jalaliday from 'jalaliday'
import {DatePicker as DatePickerJalali} from "antd-jalali";
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'

dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)
dayjs.extend(jalaliday)
dayjs('jalali')

export interface InputProps {
    value?: dayjs.Dayjs[];
    onChange?: (value: string[] | null) => void;
    style?: React.CSSProperties,
    label: string,
    className?:string,
    disable?:boolean,
}

const RangeInput = ({label, value, onChange, className,disable}: InputProps) => {
    const changeHandler = (value:any) => {
        onChange && onChange(value)
    }
    const [id, setId] = useState<string>()
    useEffect(() => {
        setId(nanoid())
    }, [])

    const currentYear = [
        dayjs().calendar('jalali').startOf('year').locale('fa'),
        dayjs().calendar('jalali').endOf('year').locale('fa')
    ]
    const currentMonth = [

        dayjs().calendar('jalali').startOf('month').locale('fa'),
        dayjs().calendar('jalali').endOf('month').locale('fa')
   
    ]
    const currentWeek = [
     
        dayjs().calendar('jalali').startOf('week').locale('fa'),
        dayjs().calendar('jalali').endOf('week').locale('fa')
    ]
    const today = [
        dayjs().calendar('jalali').startOf('day').locale('fa'),
        dayjs().calendar('jalali').endOf('day').locale('fa')
    ]
    
    let ranges: Record<string, dayjs.Dayjs[]> = {}
    ranges["سال جاری"] = currentYear;
    ranges["ماه جاری"] = currentMonth;
    ranges["هفته جاری"] = currentWeek;
    ranges["امروز"] = today;
        //@ts-ignore

    return (
        <>
            <div style={{position: "relative"}}>
                <label
                    htmlFor={id}
                    style={{
                        backgroundColor: "white",
                        paddingLeft: "5px",
                        paddingRight: "5px",
                        top: "-29px",
                        right: "15px",
                        position: "absolute",
                        zIndex: 10,
                        color: "#c4c4c4",
                        fontSize: "16px",
                    }}
                >
                    {label}
                </label>

                <DatePickerJalali.RangePicker
                    disabled={disable}
                    id={id}
                    className={className}
                    format={"YYYY/MM/DD"}
                    ranges={ranges}
                    bordered={false}
                    allowClear={false}
                    onChange={(val: DatePickerJalali) => changeHandler(val)}
                    value={
                        [
                            (value && value[0] ? value[0] : dayjs()).calendar('jalali').locale('fa'),
                            (value && value[1] ? value[1] : dayjs()).calendar('jalali').locale('fa')
                        ]
                    }
                />
            </div>
        </>
    )
}
export default RangeInput;
