import React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const ToastProvider = () => (
  <ToastContainer
    position={'top-right'}
    closeButton={false}
    rtl={true}
    newestOnTop={true}
    className={'pw-toast'}
    toastClassName={'toast-content'}
    bodyClassName={'toast-body'}
  />
);

const useToast = () => {
  return (message, type = 'info') => toast[type](message);
};

export default useToast;
