class Formatter {
  static changeNumberToEn = (input: string) => {
    const persian = ['۰','۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹']
    input = persian.reduce((acm, digit, index) => acm.replace(digit,index.toString()), input)
    const arabic = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩']
    return arabic.reduce((acm, digit, index) => acm.replace(digit, index.toString()), input)
  };

  static isNationalCode = (value: string) => {
    let r = 0;
    let checkDigit = 0;
    let nationalCodeStr = value.toString();
    if (
        (nationalCodeStr &&
            nationalCodeStr.length === 10 &&
            nationalCodeStr.split('').every((char: string) => char === nationalCodeStr[0])) ||
        nationalCodeStr.length > 10
    ) {
      return ['کد ملی نامعتبر است', true];
    }
    if (nationalCodeStr.length <= 8 || Number.parseInt(nationalCodeStr) <= 0x98968a) {
      return ['کد ملی نامعتبر است', true];
    }
    nationalCodeStr = ['00', nationalCodeStr.trim()].join('');
    nationalCodeStr = nationalCodeStr.substring(
        nationalCodeStr.length - 10,
        nationalCodeStr.length
    );
    checkDigit = Number.parseInt(nationalCodeStr.substring(9, 10));
    let nationalCodeDigits = nationalCodeStr.split('');
    let sum = 0;
    for (let i = 0; i < 9; i += 1) {
      sum += Number.parseInt(nationalCodeDigits[i]) * (10 - i);
    }
    r = sum % 11;
    if ((r > 1 ? 11 - r : r) === checkDigit) {
      return ['', false];
    } else {
      return ['کد ملی نامعتبر است', true];
    }
  };

  static isMobileNumber = (value: string) => {
    if (value && value.length > 0 && value.match(/(([9])[0-9]{9})/gi)) {
      const v = value.match(/((9)[0-9]{9})/gi);
      return [
        '',
        !(!!v && true && v.length > 0 && v[0].length === 10 && (v[0] === value || ['0', v[0]].join('') === value))
      ];
    }
    return ['لطفا شماره تلفن همراه را به صورت صحیح وارد کنید', true];
  };

  static formatPhoneNumber = (
      phoneNumber: string,
      removeZero = false,
      countryCode = undefined
  ) => {
    let formatedPhoneNumber = '';
    for (let i = phoneNumber.length - 1; i >= 0; i--) {
      if (
          (removeZero &&
              ((i === 0 && phoneNumber.charAt(i) !== '0') || i !== 0)) ||
          !removeZero
      ) {
        if (
            i === phoneNumber.length - 4 ||
            i === phoneNumber.length - 7 ||
            (countryCode !== undefined && i === phoneNumber.length - 10)
        ) {
          formatedPhoneNumber =
              ' ' + phoneNumber.charAt(i) + formatedPhoneNumber;
          continue;
        }
        formatedPhoneNumber = phoneNumber.charAt(i) + formatedPhoneNumber;
      }
    }
    if (countryCode !== undefined) {
      formatedPhoneNumber = countryCode + formatedPhoneNumber;
    }

    return formatedPhoneNumber;
  };

  static thousandSeparator = (num: number) => {
    if (num || num === 0)
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  };
}

export default Formatter;
