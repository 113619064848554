import { Col, Input } from "antd";
import React from "react";

const InputSearch = ({
  val,
  handleChange,
  onFocusChange,
  isDisabled,
  placeholder,
}: {
  val: string;
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  placeholder: string;
  onFocusChange: (placeholder: string) => void;
  isDisabled: boolean;
}) => {
       
  return (
    <>
      <Col lg={5} sm={15} xs={15}>
        <Input
          type="text"
          placeholder={placeholder}
          value={val}
          onChange={handleChange}
          onFocus={() => onFocusChange(placeholder)}
          disabled={isDisabled}
          style={{
           padding: "9px 29px 10px 11px",
            marginRight: "13px",
            marginLeft:"13px",
            borderRadius: "0%",
            border: "1px solid #ccc", // اضافه کردن حاشیه
            fontSize: "16px", // اضافه کردن اندازه فونت
          }}
        />
      </Col>
    </>
  );
};

export default InputSearch;
