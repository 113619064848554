import { Col } from "antd";

const BtnFilter = ({
  handleChange,

  textBtn,
  type,
}: {
  handleChange: () => void;
  type: "remove" | "search";
  textBtn: string;
}) => {
  return (
    <>
      {/* <Col lg={2} sm={24} xs={24}> */}
      <button
        style={{
          padding: "12px 12px",
          
          background: `${
            type === "search"
              ? "linear-gradient(90deg, rgba(24,144,255,1) 0%, rgba(111,75,252,0.6726891440169818) 100%)"
              : "linear-gradient(90deg, rgba(156,156,189,1) 0%, rgba(217,32,57,0.8855742980786064) 100%)"
          }`, // تغییر رنگ پس زمینه
          color: "#fff", // تغییر رنگ متن
          border: "none", // حذف حاشیه
          marginRight: "5px", 
          marginTop: "2px",
          marginBottom: "2px",
        }}
        onClick={handleChange}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-search"
          viewBox="0 0 16 16"
        >
          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
        </svg>
      </button>
      {/* </Col> */}
      {/* <Col lg={3} sm={24} xs={24}>
        <button
          style={{
            padding: "10px 20px",
            borderRadius: "5%",
            background:
              "linear-gradient(90deg, rgba(156,156,189,1) 0%, rgba(217,32,57,0.8855742980786064) 100%)", // تغییر رنگ پس زمینه
            color: "#fff", // تغییر رنگ متن
            border: "none", // حذف حاشیه
            marginRight: "10px",
          }}
          onClick={handleChange}
        >
          {textBtn}
        </button>
      </Col> */}
    </>
  );
};

export default BtnFilter;
