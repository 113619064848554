import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import Dashboard from "../pages/Dashboard";

const Private = ({isAuthenticated}: { isAuthenticated: boolean }) => {
    if (isAuthenticated)
        return (
            <Switch>
                <Route
                    exact
                    path="/"
                    render={() => <Redirect to="/dashboard" path={"/dashboard"}/>}
                />
                <Route exact={true} path={'/:tab1/:tab2?'} component={Dashboard}/>
            </Switch>
        )
    else
        return <Redirect to={'/login/'}/>
}
export default Private;
