import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Col, Row, Modal, Button, Input} from "antd";
import CustomTable from "../../components/Table";
import useStore from "../../hooks/useStore";
import useToast from '../../hooks/useToast';
import {ColumnProps} from "antd/lib/table/Column";
import TableActions from "../../components/TableActions";
import BtnClearFilter from '../../components/BtnClearFilter';
import InputSearch from '../../components/InputSearch';
import BtnFilter from '../../components/BtnFilter';
import BtnExcel from '../../components/BtnExcel';
import * as XLSX from 'xlsx';
import InputExcel from '../../components/InputExcel';

const PendingSellRequests = () => {
    const {t} = useTranslation('identification');
    const [PendingRequestsTotalElements, setPendingRequestsTotalElements] = useState<number>(0)
    const [pendingRequests, setPendingRequests] = useState<any[]>([]);
    const [pendingRequestsLoading, setPendingRequestsLoading] = useState<boolean>(true);
    const [tableSettings, setTableSettings] = useState({})
    const [rejectModalVisible, setRejectModalVisible] = useState(false)
    const [rejecting, setRejecting] = useState(false)
    const [targetId, setTargetId] = useState(0)
    const [rejectReason, setRejectReason] = useState('')
    const [nameSearch, setNameSearch] = useState<string>("");
    const [familySearch, setFamilySearch] = useState<string>("");
    const [ncExcel, setNcExcel] = useState<any>();
    const [mobileExcel, setMobileExcel] = useState<any>()

    const toast = useToast();
    const [authedApiState] = useStore()

    useEffect(() => {
        loadPendingRequests({page: 0});
    }, [])

    const loadPendingRequests = ({page, size, sort, asc}:
                                     { page: number, size?: number, sort?: string, asc?: boolean }) => {
        const query = {
            page, size: size ? size : 5,
            sort: sort ? sort : 'requestDate', asc: asc ? asc : false
        }
        setTableSettings(query);
        setPendingRequests([])
        setPendingRequestsLoading(true)
        authedApiState.apiContainer?.api.transactionsList("pending", "sell", query).then(({data, status}) => {
            //@ts-ignore
            setPendingRequestsTotalElements(data.totalElements)
            if (status === 200) {
                //@ts-ignore
                setPendingRequests(data.content?.map((item: any) => {
                    return {
                        name: item.firstName + ' ' + item.lastName,
                        createdDate: item.createdDate?.toString().replace(/-/g, "/"),
                        accountType: t('accountType.' + item.accountType),
                        userType: t('userType.' + item.userType),
                        requestDate: item.requestDate,
                        companyName: item.companyName ? item.companyName : '---',
                        amount: item.amount,
                        totalPrice: item.totalPrice,
                        actions: item
                    }
                }));
                setNameSearch("");
                setFamilySearch("");
            } else {
                toast(t('notification.request.fail'), 'error');
            }
            setPendingRequestsLoading(false)
        }).catch((response) => {
            toast(response?.data?.msg || response?.data?.message, 'error');
            setPendingRequestsLoading(false);
        })
    };

    const approveUserRequest = (id: string) => {
        return new Promise((resolve, reject) => {
            //@ts-ignore
            authedApiState.apiContainer?.api.verifyTransaction(id).then(({data, status}) => {
                if (status === 200) {
                    toast(t('notification.request.approve.success'), 'success');
                    resolve(data);
                } else {
                    toast(t('notification.request.approve.fail'), 'error');
                    reject(data);
                }
            }).catch((response: any) => {
                reject(response)
                toast(response.data?.msg, 'error');
            })
        })
    }

    const rejectUserRequest = () => {
        const body = {transactionId: targetId, reason: rejectReason}
        setRejecting(true);
        return new Promise((resolve, reject) => {
            //@ts-ignore
            authedApiState.apiContainer?.api.rejectToken(body).then(({data, status}) => {
                if (status === 200) {
                    resolve(data);
                    toast(t('notification.request.approve.success'), 'success');
                } else {
                    toast(t('notification.request.approve.fail'), 'error');
                    reject(data);
                }
                setRejecting(false);
                reloadTable();
            }).catch((response: any) => {
                toast(response.data?.msg, 'error');
                reject(response)
                setRejecting(false);
            })
        })
    }

    const pendingRequestsColumn: ColumnProps<Record<string, string>>[] = [
        {
            title: 'تاریخ‌درخواست',
            key: 'createdDate',
            dataIndex: 'createdDate',
            sorter: true
        },
        {
            title: 'نام‌و‌نام‌خانوادگی',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'متقاضی',
            dataIndex: 'accountType',
            key: 'accountType',
            sorter: true,
            responsive: ['xxl', 'xl', 'lg', 'sm']
        },
        {
            title: 'نام‌شرکت',
            key: 'companyName',
            dataIndex: 'companyName',
            responsive: ['xxl', 'xl', 'lg', 'sm']
        },
        {
            title: 'نوع سهام‌داری',
            key: 'userType',
            dataIndex: 'userType',
            sorter: true,
            responsive: ['xxl', 'xl', 'lg', 'sm']
        },
        {
            title: 'تعداد رزرو',
            key: 'amount',
            dataIndex: 'amount',
            responsive: ['xxl', 'xl', 'lg', 'sm'],
            render: item => item.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        {
            title: 'مبلغ پرداختی (ریال)',
            key: 'totalPrice',
            dataIndex: 'totalPrice',
            responsive: ['xxl', 'xl', 'lg', 'sm'],
            render: item => item.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        {
            title: 'اقدامات',
            key: 'actions',
            dataIndex: 'actions',
            render: (item) => (
                <>
                    <TableActions onApprove={approveUserRequest}
                                  onReject={() => {
                                  }}
                                  onView={() => {
                                  }}
                                  approveParams={{}}
                                  rejectParams={[]}
                                  viewParams={{}}
                                  approveBtnDsbl={item.approvedDate}
                                  rejectBtnDsbl={item.rejectDate}
                                  record={item}
                                  viewBtnDsbl={true}
                                  id={item.id}
                                  onFinish={reloadTable}
                                  onRejectClick={() => {
                                      setTargetId(item.id);
                                      setRejectReason('');
                                      setRejectModalVisible(true);
                                  }}
                    />
                </>
            )
        }
    ]

    const reloadTable = () => {
        setTimeout(()=>{
            //@ts-ignore
            loadPendingRequests(tableSettings)
            setNameSearch("");
            setFamilySearch("");
        },1000)
    }
   
    ////////////////////////////////////////سرچ براساس نام
    const loadFilter = ({
    page,
    size,
    sort,
    asc,
    type,
    search
  }: {
    page?: number;
    size?: number;
    sort?: string;
    asc?: boolean;
    type:number;
    search?:string;
  })  => {
    const query = {
      page,
      size: size ? size : 5,
      sort: sort ? sort : "requestDate",
      asc: asc ? asc : false,
      type:type,
      search:search ? search : nameSearch ? nameSearch : familySearch
    };
    setPendingRequests([])
          setPendingRequestsLoading(true);
    authedApiState.apiContainer?.api
      .searchSellListRequests(query)
      .then(({ data, status }) => {
        //@ts-ignore
        setPendingRequestsTotalElements(data.totalElements)
        if (status === 200) {
            //@ts-ignore
            setPendingRequests(data.content?.map((item: any) => {
                return {
                    name: item.firstName + ' ' + item.lastName,
                    createdDate: item.createdDate?.toString().replace(/-/g, "/"),
                    accountType: t('accountType.' + item.accountType),
                    userType: t('userType.' + item.userType),
                    requestDate: item.requestDate,
                    companyName: item.companyName ? item.companyName : '---',
                    amount: item.amount,
                    totalPrice: item.totalPrice,
                    actions: item
                }
            }));
        } else {
            toast(t('notification.request.fail'), 'error');
        }
        setPendingRequestsLoading(false)
    }).catch((response) => {
        toast(response?.data?.msg || response?.data?.message, 'error');
        setPendingRequestsLoading(false);
    })
    };

   /////////////////////////////////////اکسل 
   const loadFilterExcel = ({
    search,
    type,
  }: {
    type?: number; 
    search?:string;
  }) => {
    const query = {
      type:type,
      search:search ? search :mobileExcel ?mobileExcel :ncExcel
    };
    authedApiState.apiContainer?.api
      .DwExcelInSellListPending("pending", query)
      .then(({ data, status }) => {
        if (status === 200) {
          DwFilterExcel(data.id)
        }
      })
      .catch((response: any) => {
        toast(response?.data?.msg || response?.data?.message, "error");
      });
   };

  const DwFilterExcel = async (data1: string) => {
    try {
      const response = await authedApiState.apiContainer?.api.DownloadExcel(data1);
      if (response) {
        const { data, status } = response;
  
        if (status === 200 && data) 
        {
          const excelData = data.map((item: any) => Object.values(item)); // Convert data to array of arrays
          const headers = ['تاریخ‌درخواست', 'نام', 'نام خانوادگی','متقاضی','کدملی','موبایل','نام‌شرکت']; // Set the headers for columns
          const workbook = XLSX.utils.book_new();
          XLSX.utils.sheet_add_aoa(workbook, [headers, ...excelData], {origin: 0}); // Add headers and data to worksheet
          XLSX.utils.book_append_sheet(workbook, workbook, 'Sheet1'); // Add worksheet to workbook
  
          const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' }); // Generate Excel buffer
  
          const blobData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }); // Convert buffer to Blob
  
          const url = window.URL.createObjectURL(blobData);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'downloaded_file.xlsx'; // Set the appropriate file name and extension
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

 /////////////////************/////////////
 const [focusedInput, setFocusedInput] = useState<string | null>(null);

  const handleFocusChange = (placeholder: string) => {
    setFocusedInput(placeholder);
  };

  const handleClearFilter = () => {
    setFocusedInput(null);
    reloadTable();
  };
  
    return (
        <>
            <Modal
                visible={rejectModalVisible}
                onCancel={() => {
                    setRejectModalVisible(false);
                }}
                title="رد درخواست"
                footer={[
                    <Button
                        onClick={() => {
                            setRejectModalVisible(false)
                        }}
                    >
                        لغو
                    </Button>,
                    <Button type="primary" danger disabled={rejectReason.length < 10} loading={rejecting}
                            onClick={() => {
                                rejectUserRequest().then(() => {
                                    setRejectModalVisible(false);
                                }).catch(() => {
                                    setRejectModalVisible(false)
                                })
                            }}>
                        رد درخواست
                    </Button>
                ]}
            >
                <Row justify={"center"}>
                    <Col span={24}>
                        <Input placeholder={'علت رد درخواست'} value={rejectReason} onChange={(event) => {
                            setRejectReason(event.target.value);
                        }}/>
                    </Col>
                </Row>
            </Modal>
            <Row gutter={[16, 16]}  style={{ marginBottom: "20px", alignItems: "baseline" }}>
              <InputSearch
                handleChange={(e) => setNameSearch(e.target.value)}
                onFocusChange={handleFocusChange}
                placeholder="سرچ براساس نام"
                val={nameSearch}
                isDisabled={focusedInput ==="سرچ براساس نام خانوادگی" }
              />
              <BtnFilter
                handleChange={() =>loadFilter({ page: 0, type:1 }) }
                textBtn="جستجو"
                type="search"
              />
              <InputSearch
                handleChange={(e) => setFamilySearch(e.target.value)}
                onFocusChange={handleFocusChange}
                placeholder="سرچ براساس نام خانوادگی"
                val={familySearch}
                isDisabled={focusedInput ==="سرچ براساس نام" }
              />
              <BtnFilter
                handleChange={() =>loadFilter({ page: 0 ,type:2}) }
                textBtn="جستجو"
                type="search"
              />
              <BtnClearFilter
                handleChange={() => handleClearFilter()}
                textBtn="حذف فیلتر"
                type="remove"
              />
            </Row>
            <Row gutter={[16, 16]}>
                <Col span={24} lg={24} md={24} sm={24} xs={24}>
                    <div className={'dashboard-grid'}>
                    {focusedInput === "سرچ براساس نام  "?
                     <CustomTable
                     rowClass={"verified-person-table-row"}
                     loading={pendingRequestsLoading}
                     className='special-styled-table'
                     paginationBottom={"bottomCenter"}
                     data={pendingRequests}
                     columns={pendingRequestsColumn}
                     total={PendingRequestsTotalElements}
                     onChange={() =>loadFilter({ page: 0, type:1 })}
                     defaultSort={"requestDate"}
                 />
                    :focusedInput === "سرچ براساس نام خانوادگی"?
                    <CustomTable
                     rowClass={"verified-person-table-row"}
                     loading={pendingRequestsLoading}
                     className='special-styled-table'
                     paginationBottom={"bottomCenter"}
                     data={pendingRequests}
                     columns={pendingRequestsColumn}
                     total={PendingRequestsTotalElements}
                     onChange={() =>loadFilter({ page: 0, type:2 })}
                     defaultSort={"requestDate"}
                 />
                    :<CustomTable
                    rowClass={"verified-person-table-row"}
                    loading={pendingRequestsLoading}
                    className='special-styled-table'
                    paginationBottom={"bottomCenter"}
                    data={pendingRequests}
                    columns={pendingRequestsColumn}
                    total={PendingRequestsTotalElements}
                    onChange={loadPendingRequests}
                    defaultSort={"requestDate"}
                />}     
                    </div>
                </Col>
            </Row>
            <Row gutter={[16, 16]}  style={{ marginTop: "10px", alignItems: "baseline" }}>
              <InputExcel
                handleChange={(e) => setNcExcel(e.target.value)}
                placeholder="دانلود براساس کدملی"
                val={ncExcel}
              />
              <BtnExcel
                handleChange={() =>loadFilterExcel({type:3})}
                textBtn="جستجو"
              />
                <InputExcel
                handleChange={(e) => setMobileExcel(e.target.value)}
                placeholder="دانلود براساس موبایل"
                val={mobileExcel}
              />
              <BtnExcel
                handleChange={() =>loadFilterExcel({type:4})}
                textBtn="جستجو"
              />
              <BtnClearFilter
                handleChange={() => {
                  setMobileExcel("");
                  setNcExcel("");
                }}
                textBtn="حذف فیلتر"
                type="remove"
              />
            </Row>
        </>
    )
};

export default PendingSellRequests;
