import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";
import CustomTable from "../../components/Table";
import useStore from "../../hooks/useStore";
import { ColumnProps } from "antd/lib/table/Column";
import useToast from "../../hooks/useToast";
import BtnClearFilter from "../../components/BtnClearFilter";
import InputSearch from "../../components/InputSearch";
import BtnFilter from "../../components/BtnFilter";
import BtnExcel from "../../components/BtnExcel";
import * as XLSX from 'xlsx';
import InputExcel from "../../components/InputExcel";

const ApprovedRequests = () => {
  const { t } = useTranslation("identification");
  const [
    VerifiedRequestsTotalElements,
    setVerifiedRequestsTotalElements,
  ] = useState<number>(0);
  const [verifiedRequests, setVerifiedRequests] = useState<any[]>([]);
  const [verifiedRequestsLoading, setVerifiedRequestsLoading] = useState<
    boolean
  >(true);
  const [nameSearch, setNameSearch] = useState<string>("");
  const [familySearch, setFamilySearch] = useState<string>("");
  const [mobileSearch, setMobileSearch] = useState<any>();
  const [status, setStatus] = useState<boolean>(false);
  const [ncExcel, setNcExcel] = useState<any>();
  const [mobileExcel, setMobileExcel] = useState<any>();
  const [authedApiState] = useStore();
  const toast = useToast();

  useEffect(() => {
    loadVerifiedRequests({ page: 0 });
  }, []);

  const loadVerifiedRequests = ({
    page,
    size,
    sort,
    asc,
  }: {
    page: number;
    size?: number;
    sort?: string;
    asc?: boolean;
  }) => {
    const query = {
      page,
      size: size ? size : 5,
      sort: sort ? sort : "approvedDate",
      asc: asc ? asc : false,
    };

    setVerifiedRequests([]);
    setVerifiedRequestsLoading(true);

    authedApiState.apiContainer?.api
      .usersDocsList("verified", query)
      .then(({ data, status }) => {
        //@ts-ignore
        setVerifiedRequestsTotalElements(data.totalElements);
        if (status === 200) {
          //@ts-ignore
          setVerifiedRequests(data.content?.map((item: any) => {

            return {
                name: item.firstName + ' ' + item.lastName,
                accountType: t('accountType.' + item.accountType),
                userType: t('userType.' + item.userType),
                nationalCode: item.nationalCode,
                mobile: item.mobile,
                requestDate: item.requestDate.replace(/-/g, "/"),
                companyName: item.companyName?item.companyName:'---',
                approvedDate: item.approvedDate.replace(/-/g, "/"),
            }
        }));
          setNameSearch("");
          setFamilySearch("");
          setMobileSearch("");
        } else {
          toast(t("notification.request.fail"), "error");
        }
        setVerifiedRequestsLoading(false);
      })
      .catch((response) => {
        toast(response?.data?.msg || response?.data?.message, "error");
        setVerifiedRequestsLoading(false);
      });
  };

  const verifiedRequestsColumn: ColumnProps<Record<string, string>>[] = [
    {
      title: "تاریخ‌درخواست",
      key: "requestDate",
      dataIndex: "requestDate",
      sorter: true,
    },
    {
      title: "نام‌و‌نام‌خانوادگی",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "متقاضی",
      dataIndex: "userType",
      key: "userType",
      sorter: true,
      responsive: ["xxl", "xl", "lg", "sm"],
    },
    {
      title: "نوع‌سهام‌داری",
      key: "accountType",
      dataIndex: "accountType",
      responsive: ["xxl", "xl", "lg", "sm"],
    },
    {
      title: "موبایل",
      key: "mobile",
      dataIndex: "mobile",
      responsive: ["xxl", "xl", "lg", "sm"],
    },
    {
      title: "نام‌شرکت",
      key: "companyName",
      dataIndex: "companyName",
      responsive: ["xxl", "xl", "lg", "sm"],
    },
    {
      title: "تاریخ تایید",
      key: "approvedDate",
      dataIndex: "approvedDate",
      sorter: true,
    },
  ];

  ////////////////////////////////////////سرچ براساس موبایل
  const loadFilterMob = ({
    mob,
    page,
    size,
    sort,
    asc,
  }: {
    page?: number;
    size?: number;
    sort?: string;
    asc?: boolean;
    mob?: number;
  }) => {
    const query = {
      page,
      size: size ? size : 5,
      sort: sort ? sort : "requestDate",
      asc: asc ? asc : false,
      mob: mob ? mob : mobileSearch,
    };
    setVerifiedRequests([]);
    setVerifiedRequestsLoading(true);
    authedApiState.apiContainer?.api
      .searchMobInNewAuthenticationRequests("verified", query)
      .then(({ data, status }) => {
        //@ts-ignore
        setVerifiedRequestsTotalElements(data.totalElements);
        if (status === 200) {
          //@ts-ignore
          setVerifiedRequests(data.content?.map((item: any) => {

            return {
                name: item.firstName + ' ' + item.lastName,
                accountType: t('accountType.' + item.accountType),
                userType: t('userType.' + item.userType),
                nationalCode: item.nationalCode,
                mobile: item.mobile,
                requestDate: item.requestDate.replace(/-/g, "/"),
                companyName: item.companyName?item.companyName:'---',
                approvedDate: item.approvedDate.replace(/-/g, "/"),
            }
        }));
        } else {
          toast(t("notification.request.fail"), "error");
        }
        setVerifiedRequestsLoading(false);
      })
      .catch((response) => {
        toast(response?.data?.msg || response?.data?.message, "error");
        setVerifiedRequestsLoading(false);
      });
  };
  ////////////////////////////////////////سرچ براساس نام
  const loadFiltername = ({
    name,
    page,
    size,
    sort,
    asc,
  }: {
    page?: number;
    size?: number;
    sort?: string;
    asc?: boolean;
    name?: string;
  }) => {
    const query = {
      page,
      size: size ? size : 5,
      sort: sort ? sort : "requestDate",
      asc: asc ? asc : false,
      name: name ? name : nameSearch,
    };
    setVerifiedRequests([]);
    setVerifiedRequestsLoading(true);
    authedApiState.apiContainer?.api
      .searchNameInNewAuthenticationRequests("verified", query)
      .then(({ data, status }) => {
        //@ts-ignore
        setVerifiedRequestsTotalElements(data.totalElements);
        if (status === 200) {
          //@ts-ignore
          setVerifiedRequests(data.content?.map((item: any) => {

            return {
                name: item.firstName + ' ' + item.lastName,
                accountType: t('accountType.' + item.accountType),
                userType: t('userType.' + item.userType),
                nationalCode: item.nationalCode,
                mobile: item.mobile,
                requestDate: item.requestDate.replace(/-/g, "/"),
                companyName: item.companyName?item.companyName:'---',
                approvedDate: item.approvedDate.replace(/-/g, "/"),
            }
        }));
        } else {
          toast(t("notification.request.fail"), "error");
        }
        setVerifiedRequestsLoading(false);
      })
      .catch((response) => {
        toast(response?.data?.msg || response?.data?.message, "error");
        setVerifiedRequestsLoading(false);
      });
  };
  ////////////////////////////////////////سرچ براساس نام خانوادگی
  const loadFilterLastname = ({
    last,
    page,
    size,
    sort,
    asc,
  }: {
    page?: number;
    size?: number;
    sort?: string;
    asc?: boolean;
    last?: string;
  }) => {
    const query = {
      page,
      size: size ? size : 5,
      sort: sort ? sort : "requestDate",
      asc: asc ? asc : false,
      last: last ? last : familySearch,
    };
    setVerifiedRequests([]);
    setVerifiedRequestsLoading(true);
    authedApiState.apiContainer?.api
      .searchLastNameInNewAuthenticationRequests("verified", query)
      .then(({ data, status }) => {
        //@ts-ignore
        setVerifiedRequestsTotalElements(data.totalElements);
        if (status === 200) {
          //@ts-ignore
          setVerifiedRequests(data.content?.map((item: any) => {

            return {
                name: item.firstName + ' ' + item.lastName,
                accountType: t('accountType.' + item.accountType),
                userType: t('userType.' + item.userType),
                nationalCode: item.nationalCode,
                mobile: item.mobile,
                requestDate: item.requestDate.replace(/-/g, "/"),
                companyName: item.companyName?item.companyName:'---',
                approvedDate: item.approvedDate.replace(/-/g, "/"),
            }
        })); 
        } else {
          toast(t("notification.request.fail"), "error");
        }
        setVerifiedRequestsLoading(false);
      })
      .catch((response) => {
        toast(response?.data?.msg || response?.data?.message, "error");
        setVerifiedRequestsLoading(false);
      });
  };
  /////////////////////////////////////اکسل 
const loadFilterExcel = ({
  search,
  type,
}: {
  type?: number; 
  search?:string;
}) => {
  const query = {
    type:type,
    search:search ? search :mobileExcel ?mobileExcel :ncExcel
  };
  authedApiState.apiContainer?.api
    .DwExcelInIdentityPending("verified", query)
    .then(({ data, status }) => {
      if (status === 200) {
        DwFilterExcel(data.id)
      }
    })
    .catch((response: any) => {
      toast(response?.data?.msg || response?.data?.message, "error");
    });
};

const DwFilterExcel = async (data1: string) => {
  try {
    const response = await authedApiState.apiContainer?.api.DownloadExcel(data1);
    if (response) {
      const { data, status } = response;

      if (status === 200 && data) 
      {
        const excelData = data.map((item: any) => Object.values(item)); // Convert data to array of arrays
        const headers = ['نام', 'نام خانوادگی','کدملی','تاریخ رد درخواست','متقاضی','موبایل','تاریخ‌درخواست']; // Set the headers for columns
        const workbook = XLSX.utils.book_new();
        XLSX.utils.sheet_add_aoa(workbook, [headers, ...excelData], {origin: 0}); // Add headers and data to worksheet
        XLSX.utils.book_append_sheet(workbook, workbook, 'Sheet1'); // Add worksheet to workbook

        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' }); // Generate Excel buffer

        const blobData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }); // Convert buffer to Blob

        const url = window.URL.createObjectURL(blobData);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'downloaded_file.xlsx'; // Set the appropriate file name and extension
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      }
    }
  } catch (error) {
    console.error(error);
  }
};

const [focusedInput, setFocusedInput] = useState<string | null>(null);

  const handleFocusChange = (placeholder: string) => {
    setFocusedInput(placeholder);
  };

  const handleClearFilter = () => {
    setFocusedInput(null);
    loadVerifiedRequests({ page: 0 });
  };

  return (
    <>
      <Row
        gutter={[16, 16]}
        style={{ marginBottom: "20px", alignItems: "baseline" }}
      >
        <InputSearch
          handleChange={(e) => setNameSearch(e.target.value)}
          onFocusChange={handleFocusChange}
          placeholder="سرچ براساس نام  "
          val={nameSearch}
          isDisabled={ focusedInput ==="سرچ براساس نام خانوادگی" || focusedInput === "سرچ براساس موبایل"}
        />
        <BtnFilter
          handleChange={() => loadFiltername({ page: 0 })}
          textBtn="جستجو"
          type="search"
        />
        <InputSearch
          handleChange={(e) => setFamilySearch(e.target.value)}
          onFocusChange={handleFocusChange}
          placeholder="سرچ براساس نام خانوادگی"
          val={familySearch}
          isDisabled={ focusedInput ==="سرچ براساس نام  " || focusedInput === "سرچ براساس موبایل"}
        />
        <BtnFilter
          handleChange={() => loadFilterLastname({ page: 0 })}
          textBtn="جستجو"
          type="search"
        />
        <InputSearch
          handleChange={(e) => setMobileSearch(e.target.value)}
          onFocusChange={handleFocusChange}
          placeholder="سرچ براساس موبایل"
          val={mobileSearch}
          isDisabled={focusedInput ==="سرچ براساس نام  "|| focusedInput ==="سرچ براساس نام خانوادگی" }
        />
        <BtnFilter
          handleChange={() => loadFilterMob({ page: 0 })}
          textBtn="جستجو"
          type="search"
        />
        <BtnClearFilter
          handleChange={() => handleClearFilter() }
          textBtn="حذف فیلتر"
          type="remove"
        />
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24} lg={24} md={24} sm={24} xs={24}>
          <div className={"dashboard-grid"}>
            <Row>
              <Col span={24}>
                {focusedInput === "سرچ براساس نام  "?
                   <CustomTable
                   loading={verifiedRequestsLoading}
                   className="special-styled-table"
                   paginationBottom={"bottomCenter"}
                   data={verifiedRequests}
                   columns={verifiedRequestsColumn}
                   total={VerifiedRequestsTotalElements}
                   onChange={loadFiltername}
                   defaultSort={"approvedDate"}
                 />
                :focusedInput === "سرچ براساس نام خانوادگی"?
                <CustomTable
                   loading={verifiedRequestsLoading}
                   className="special-styled-table"
                   paginationBottom={"bottomCenter"}
                   data={verifiedRequests}
                   columns={verifiedRequestsColumn}
                   total={VerifiedRequestsTotalElements}
                   onChange={loadFilterLastname}
                   defaultSort={"approvedDate"}
                 />
                :focusedInput === "سرچ براساس موبایل"?
                <CustomTable
                   loading={verifiedRequestsLoading}
                   className="special-styled-table"
                   paginationBottom={"bottomCenter"}
                   data={verifiedRequests}
                   columns={verifiedRequestsColumn}
                   total={VerifiedRequestsTotalElements}
                   onChange={loadFilterMob}
                   defaultSort={"approvedDate"}
                 />
                :<CustomTable
                loading={verifiedRequestsLoading}
                className="special-styled-table"
                paginationBottom={"bottomCenter"}
                data={verifiedRequests}
                columns={verifiedRequestsColumn}
                total={VerifiedRequestsTotalElements}
                onChange={loadVerifiedRequests}
                defaultSort={"approvedDate"}
              />}
                
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row gutter={[16, 16]}  style={{ marginTop: "10px", alignItems: "baseline" }}>
              <InputExcel
                handleChange={(e) => setNcExcel(e.target.value)}
                placeholder="دانلود براساس کدملی"
                val={ncExcel}
              />
              <BtnExcel
                 handleChange={() =>loadFilterExcel({type:3})}
                textBtn="جستجو"
              />
                <InputExcel
                handleChange={(e) => setMobileExcel(e.target.value)}
                placeholder="دانلود براساس موبایل"
                val={mobileExcel}
              />
              <BtnExcel
                 handleChange={() =>loadFilterExcel({type:4})}
                textBtn="جستجو"              
              />
              <BtnClearFilter
                handleChange={() => {
                  setMobileExcel("");
                  setNcExcel("");
                }}
                textBtn="حذف فیلتر"
                type="remove"
              />
            </Row>
    </>
  );
};

export default ApprovedRequests;
