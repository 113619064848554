import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {Col, Image, Row, Tabs} from "antd";
import useStore from "../../../hooks/useStore";
import Loading from "../../../layouts/loading/loading";
import useToast from "../../../hooks/useToast";

const {TabPane} = Tabs;

export type DocTypes =
    "ID_CARD_P_1_2"
    | "ID_CARD_P_3_4"
    | "ID_CARD_P_5_6"
    | "NATIONAL_CARD_P_1"
    | "NATIONAL_CARD_P_2"
    | "DUTY_CARD_P_1"
    | "DUTY_CARD_P_2"
    | "SIGN_SAMPLE"
    | "MANAGING_DIRECTOR_SIGN_SAMPLE"
    | "RECENT_GENERAL_ORDINARY_CHANGE"
    | "RECENT_MD_CHANGE"
    | "FOUNDED_AD"
    | "PERSONAL_PHOTO"
    | "LAST_EDUCATION"
    | "MAIN_BANK_CART";

const RequestDocs = ({docs}: Record<string, any>) => {
    const {t} = useTranslation('identification')
    const [authedApiState] = useStore()
    const toast = useToast();

    useEffect(() => {
        handleAllTabChanges();
    }, [docs])

    const [photos, setPhotos] = useState<{ [key in DocTypes]: { data: string, loading: boolean } }>(
        {
            NATIONAL_CARD_P_1: {data: "", loading: true},
            NATIONAL_CARD_P_2: {data: "", loading: true},
            ID_CARD_P_1_2: {data: "", loading: true},
            ID_CARD_P_3_4: {data: "", loading: true},
            ID_CARD_P_5_6: {data: "", loading: true},
            DUTY_CARD_P_1: {data: "", loading: true},
            DUTY_CARD_P_2: {data: "", loading: true},
            LAST_EDUCATION: {data: "", loading: true},
            MAIN_BANK_CART: {data: "", loading: true},
            PERSONAL_PHOTO: {data: "", loading: true},
            FOUNDED_AD: {data: "", loading: true},
            RECENT_MD_CHANGE: {data: "", loading: true},
            RECENT_GENERAL_ORDINARY_CHANGE: {data: "", loading: true},
            MANAGING_DIRECTOR_SIGN_SAMPLE: {data: "", loading: true},
            SIGN_SAMPLE: {data: "", loading: true},
        }
    )
    useEffect(() => {
        let clonedPhotos = {...photos}
        Object.entries(clonedPhotos).map((key: [string, { data: string, loading: boolean }]) => {
            //@ts-ignore
            clonedPhotos[key[0]].loading = !!docs.find((item: any) => item.docType === key[0]);
        })
        setPhotos(clonedPhotos)
    }, [])
    const handleAllTabChanges = () => {
        handleTabChange("personalPhoto");
        handleTabChange("nationalCard");
        handleTabChange("dutyCard");
        handleTabChange("idCard");
        handleTabChange("bankCard");
        handleTabChange("foundedAd");
        handleTabChange("recentMdChange");
        handleTabChange("recentGeneralOrdinaryChange");
    }

    const returnEmptyImage = (loading: boolean = false) => {
        return (
            <div
                style={{
                    width: "200px",
                    height: "200px",
                    lineHeight: "200px",
                    textAlign: "center",
                    border: "1px solid grey",
                    backgroundColor: "#D0C9DF",
                    borderRadius: "5px",
                    color: "white"
                }}
            >
                {
                    loading ?
                        (<div
                            style={{
                                position: "relative",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%,-50%)"
                            }}
                        >
                            <Loading/>
                        </div>) :
                        (<b>عکسی یافت نشد</b>)
                }
            </div>
        )
    }

    const loadDocuemnt = (docType: DocTypes) => {
        if (docs.length === 0) return;
        const _docType = docType;
        const _document = docs?.find(({docType}: { docType: string }) => (docType === _docType))

        let clonedPhotos = {...photos}
        if (!_document) {
            return;
        }

        if (clonedPhotos[docType].data.length > 0) return;

        clonedPhotos[docType].loading = true;
        setPhotos(clonedPhotos);

        authedApiState.apiContainer?.api.getFile(_document.fileId, {base64: true}).then(({data, status}) => {
            clonedPhotos[docType].data = "data:image/jpg;base64, " + data;
            clonedPhotos[docType].loading = false;
            setPhotos(clonedPhotos);
        }).catch((response) => {
            toast(response?.data?.msg || response?.data?.message, 'error');
            clonedPhotos[docType].loading = false;
            setPhotos(clonedPhotos);
        })
    }

    const handleTabChange = (key: string) => {
        if (key === "personalPhoto") {
            loadDocuemnt("PERSONAL_PHOTO");
        }
        if (key === "nationalCard") {
            loadDocuemnt("NATIONAL_CARD_P_1");
            loadDocuemnt("NATIONAL_CARD_P_2");
        }
        if (key === "dutyCard") {
            loadDocuemnt("DUTY_CARD_P_1");
            loadDocuemnt("DUTY_CARD_P_2");
        }
        if (key === "idCard") {
            loadDocuemnt("ID_CARD_P_1_2");
            loadDocuemnt("ID_CARD_P_3_4");
            loadDocuemnt("ID_CARD_P_5_6");
        }
        if (key === "bankCard") {
            loadDocuemnt("MAIN_BANK_CART");
            loadDocuemnt("LAST_EDUCATION");
        }
        if (key === "foundedAd") {
            loadDocuemnt("FOUNDED_AD");
        }
        if (key === "recentMdChange") {
            loadDocuemnt("RECENT_MD_CHANGE");
        }
        if (key === "recentGeneralOrdinaryChange") {
            loadDocuemnt("RECENT_GENERAL_ORDINARY_CHANGE");
        }
    }

    return (
        <>
            <Row style={{minHeight: "220px"}}>
                <Col span={2}>
                    <Row>
                        <div style={{width: "100%", height: "60px"}}/>
                    </Row>
                    <div className={"requestDocs"}>
                        <span>مدارک شخصی</span>
                    </div>
                </Col>
                <Col span={22}>
                    <Tabs defaultActiveKey="personalPhoto" onChange={(key) => {
                        handleTabChange(key)
                    }}>
                        <TabPane
                            tab={t("pendingRequest.form.4.personalDocs.tab.1")}
                            key="personalPhoto">
                            <Row justify={"start"}>
                                <Col span={6}>
                                    {
                                        photos.PERSONAL_PHOTO.loading ? (
                                            returnEmptyImage(true)
                                        ) : (
                                            photos.PERSONAL_PHOTO.data.length > 0 ? (
                                                <Image width={200} src={photos.PERSONAL_PHOTO.data}/>
                                            ) : (
                                                returnEmptyImage()
                                            )
                                        )
                                    }
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane
                            tab={t("pendingRequest.form.4.personalDocs.tab.2")}
                            key="nationalCard">
                            <Row>
                                <Col span={6}>
                                    {
                                        photos.NATIONAL_CARD_P_1.loading ? (
                                            returnEmptyImage(true)
                                        ) : (
                                            photos.NATIONAL_CARD_P_1.data.length > 0 ? (
                                                <Image width={200} src={photos.NATIONAL_CARD_P_1.data}/>
                                            ) : (
                                                returnEmptyImage()
                                            )
                                        )
                                    }
                                </Col>
                                <Col span={6}>
                                    {
                                        photos.NATIONAL_CARD_P_2.loading ? (
                                            returnEmptyImage(true)
                                        ) : (
                                            photos.NATIONAL_CARD_P_2.data.length > 0 ? (
                                                <Image width={200} src={photos.NATIONAL_CARD_P_2.data}/>
                                            ) : (
                                                returnEmptyImage()
                                            )
                                        )
                                    }
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane
                            tab={t("pendingRequest.form.4.personalDocs.tab.3")}
                            key="dutyCard">
                            <Row>
                                <Col span={6}>
                                    {
                                        photos.DUTY_CARD_P_1.loading ? (
                                            returnEmptyImage(true)
                                        ) : (
                                            photos.DUTY_CARD_P_1.data.length > 0 ? (
                                                <Image width={200} src={photos.DUTY_CARD_P_1.data}/>
                                            ) : (
                                                returnEmptyImage()
                                            )
                                        )
                                    }
                                </Col>
                                <Col span={6}>
                                    {
                                        photos.DUTY_CARD_P_2.loading ? (
                                            returnEmptyImage(true)
                                        ) : (
                                            photos.DUTY_CARD_P_2.data.length > 0 ? (
                                                <Image width={200} src={photos.DUTY_CARD_P_2.data}/>
                                            ) : (
                                                returnEmptyImage()
                                            )
                                        )
                                    }
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane
                            tab={t("pendingRequest.form.4.personalDocs.tab.4")}
                            key="idCard">
                            <Row>
                                <Col span={6}>
                                    {
                                        photos.ID_CARD_P_1_2.loading ? (
                                            returnEmptyImage(true)
                                        ) : (
                                            photos.ID_CARD_P_1_2.data.length > 0 ? (
                                                <Image width={200} src={photos.ID_CARD_P_1_2.data}/>
                                            ) : (
                                                returnEmptyImage()
                                            )
                                        )
                                    }
                                </Col>
                                <Col span={6}>
                                    {
                                        photos.ID_CARD_P_3_4.loading ? (
                                            returnEmptyImage(true)
                                        ) : (
                                            photos.ID_CARD_P_3_4.data.length > 0 ? (
                                                <Image width={200} src={photos.ID_CARD_P_3_4.data}/>
                                            ) : (
                                                returnEmptyImage()
                                            )
                                        )
                                    }
                                </Col>
                                <Col span={6}>
                                    {
                                        photos.ID_CARD_P_5_6.loading ? (
                                            returnEmptyImage(true)
                                        ) : (
                                            photos.ID_CARD_P_5_6.data.length > 0 ? (
                                                <Image width={200} src={photos.ID_CARD_P_5_6.data}/>
                                            ) : (
                                                returnEmptyImage()
                                            )
                                        )
                                    }
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane
                            tab={t("pendingRequest.form.4.personalDocs.tab.5")}
                            key="education">
                            <Row>
                                <Col span={6}>
                                    {
                                        photos.LAST_EDUCATION.loading ? (
                                            returnEmptyImage(true)
                                        ) : (
                                            photos.LAST_EDUCATION.data.length > 0 ? (
                                                <Image width={200} src={photos.LAST_EDUCATION.data}/>
                                            ) : (
                                                returnEmptyImage()
                                            )
                                        )
                                    }
                                </Col>
                            </Row>
                        </TabPane>
                    </Tabs>
                </Col>
            </Row><br/>

            <Row style={{minHeight: "220px"}}>
                <Col span={2}>
                    <Row>
                        <div style={{width: "100%", height: "60px"}}/>
                    </Row>
                    <div className={"requestDocs"}>
                        <span>مدارک بانکی</span>
                    </div>
                </Col>
                <Col span={22}>
                    <Tabs defaultActiveKey="bankCard1" onChange={(key) => {
                        handleTabChange(key)
                    }}>
                        <TabPane
                            tab={t("pendingRequest.form.4.bankInfo.tab.1")}
                            key="bankCard1">
                            <Row>
                                {
                                    photos.MAIN_BANK_CART.loading ? (
                                        returnEmptyImage(true)
                                    ) : (
                                        photos.MAIN_BANK_CART.data.length > 0 ? (
                                            <Image width={200} src={photos.MAIN_BANK_CART.data}/>
                                        ) : (
                                            returnEmptyImage()
                                        )
                                    )
                                }

                            </Row>
                        </TabPane>
                    </Tabs>
                </Col>
            </Row><br/>

            <Row style={{minHeight: "220px"}}>
                <Col span={2}>
                    <Row>
                        <div style={{width: "100%", height: "60px"}}/>
                    </Row>
                    <div className={"requestDocs"}>
                        <span>مدارک حقوقی</span>
                    </div>
                </Col>
                <Col span={22}>
                    <Tabs defaultActiveKey="foundedAd">
                        <TabPane
                            tab={t("pendingRequest.form.4.legalDocs.tab.1")}
                            key="foundedAd">
                            <Row>

                                {
                                    photos.FOUNDED_AD.loading ? (
                                        returnEmptyImage(true)
                                    ) : (
                                        photos.FOUNDED_AD.data.length > 0 ? (
                                            <Image width={200} src={photos.FOUNDED_AD.data}/>
                                        ) : (
                                            returnEmptyImage()
                                        )
                                    )
                                }
                            </Row>
                        </TabPane>
                        <TabPane
                            tab={t("pendingRequest.form.4.legalDocs.tab.2")}
                            key="recentMdChange">
                            <Row>

                                {
                                    photos.RECENT_MD_CHANGE.loading ? (
                                        returnEmptyImage(true)
                                    ) : (
                                        photos.RECENT_MD_CHANGE.data.length > 0 ? (
                                            <Image width={200} src={photos.RECENT_MD_CHANGE.data}/>
                                        ) : (
                                            returnEmptyImage()
                                        )
                                    )
                                }
                            </Row>
                        </TabPane>
                        <TabPane
                            tab={t("pendingRequest.form.4.legalDocs.tab.3")}
                            key="recentGeneralOrdinaryChange">
                            <Row>
                                <Col span={24}>
                                    {
                                        photos.RECENT_GENERAL_ORDINARY_CHANGE.loading ? (
                                            returnEmptyImage(true)
                                        ) : (
                                            photos.RECENT_GENERAL_ORDINARY_CHANGE.data.length > 0 ? (
                                                <Image width={200} src={photos.RECENT_GENERAL_ORDINARY_CHANGE.data}/>
                                            ) : (
                                                returnEmptyImage()
                                            )
                                        )
                                    }
                                </Col>
                            </Row>
                        </TabPane>

                    </Tabs>
                </Col>
            </Row><br/>
        </>
    )
}
export default RequestDocs;
