import { useEffect, useState } from "react";
import { Button, Col, Row, Space, Modal, Radio, RadioChangeEvent, Input } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faEye,
  faKey,
  faTimes,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Confirmation from "../pages/Identification/confirmation";
const TableActions = ({
  onApprove,
  onReject,
  onDelete, // Add onDelete function
  approveBtnDsbl,
  rejectBtnDsbl,
  deleteBtnDsbl, // Add deleteBtnDsbl boolean
  id,
  record,
  hasViewBtn = true,
  hasRejectReason = true,
  onFinish,
  onApproveClick,
  onRejectClick,
  onDeleteClick, // Add onDeleteClick function
  hasActionBtns = true,
  hasDeleteBtns = false, // delete
  transactionId = "",
  hasEjectForCompany = false,
  onChangePassword,//pass
  hasPassBtns=false,//pass
  passwordChangeBtnDsbl,//pass
  
}: {
  onApprove: Function;
  onReject: Function;
  onDelete?: Function; // Add onDelete prop
  onView?: Function;
  approveParams: Record<string, any>;
  rejectParams: string[] | Function;
  deleteParams?: Record<string, any>; // Add deleteParams prop
  viewParams?: Record<string, any>;
  approveBtnDsbl: boolean;
  rejectBtnDsbl: boolean;
  deleteBtnDsbl?: boolean; // Add deleteBtnDsbl prop
  viewBtnDsbl?: boolean;
  id: string;
  transactionId?: string;
  hasViewBtn?: boolean;
  hasActionBtns?: boolean;
  hasDeleteBtns?: boolean;
  record: Record<string, string>;
  hasRejectReason?: boolean;
  onFinish: Function;
  onApproveClick?: Function;
  onRejectClick?: Function;
  onDeleteClick?: Function; // Add onDeleteClick prop
  hasEjectForCompany?: boolean;
  onChangePassword?: Function;//pass
  passwordChangeBtnDsbl?: boolean;//pass
  hasPassBtns?:boolean;//pass

}) => {
  const [
    confirmationModalVisibility,
    setConfirmationModalVisibility,
  ] = useState<boolean>(false);
  const [approveModalVisible, setApproveModalVisible] = useState<boolean>(
    false
  );
  const [rejectModalVisible, setRejectModalVisible] = useState<boolean>(false);
  const [approveButtonLoading, setApproveButtonLoading] = useState<boolean>(
    false
  );
  const [rejectButtonLoading, setRejectButtonLoading] = useState<boolean>(
    false
  );
  const [rejectValue, setRejectValue] = useState(0);
  const [rejectReasonIsFilled, setRejectReasonIsFilled] = useState<boolean>(
    false
  );
//delete
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false); //delete
  const [deleteButtonLoading, setDeleteButtonLoading] = useState<boolean>(
    false
  ); 
  //pass
  const [passwordModalVisible, setPasswordModalVisible] = useState<boolean>(false);
  const [passwordButtonLoading, setPasswordButtonLoading] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState('');

  const handlePasswordOk = () => {
    setPasswordButtonLoading(true);
    return new Promise((resolve, reject) => {
      if(onChangePassword){
         Promise.resolve( onChangePassword(id, newPassword ))
        .then(() => {
          setPasswordModalVisible(false);
          setPasswordButtonLoading(false);
          resolve(true);
          onFinish();
          setNewPassword("")
        })
        .catch(() => {
          // Handle error
          setPasswordModalVisible(false);
          setPasswordButtonLoading(false);
          reject(false);
          setNewPassword("")
        });
      }else{
        reject(false)
        setNewPassword("");
        
      }
    }).catch(() => {
      // Handle errors if needed
      setNewPassword("")
    });
  };

  const showPasswordModal = () => {
    setPasswordModalVisible(true);
  };

//pass
  const passwordModal = () => {
    return (
      <Modal
        visible={passwordModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            لغو
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={passwordButtonLoading}
            onClick={handlePasswordOk}
          >
            تغییر رمز
          </Button>,
        ]}
      >
        
        {/* <Input
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          placeholder="رمزقدیمی"
          style={{marginBottom:"3px",marginTop:"18px"}}
        /> */}
        <Input
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          placeholder="رمزجدید"
        />
      </Modal>
    );
  };


  useEffect(() => {
    if (!hasRejectReason) setRejectReasonIsFilled(true);
  }, []);
  

  //delete
  const handleDeleteOk = () => {
    setDeleteButtonLoading(true);

    return new Promise((resolve, reject) => {
      if (onDelete) {
        Promise.resolve(onDelete(id)) // Ensure onDelete returns a Promise
          .then(() => {
            setDeleteModalVisible(false);
            setDeleteButtonLoading(false);
            resolve(true);
            onFinish();
          })
          .catch(() => {
            setDeleteModalVisible(false);
            setDeleteButtonLoading(false);
            reject(false);
          });
      } else {
        reject(false); // Handling the case when onDelete is not defined
      }
    }).catch(() => {
      // Handle errors if needed
    });
  };

  //delete
  const showDeleteModal = () => {
    setDeleteModalVisible(true);
  };
  //delete
  const deleteModal = () => {
    return (
      <Modal
        visible={deleteModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            لغو
          </Button>,
          <Button
            key="submit"
            type="primary"
            danger
            loading={deleteButtonLoading}
            onClick={handleDeleteOk}
          >
            حذف
          </Button>,
        ]}
      >
        <b>آیا مطمئن هستید؟</b>
      </Modal>
    );
  };

  // تابع برای نشان دادن مدال تایید
  const handleView = () => {
    setConfirmationModalVisibility(true);
  };

  // لیست دلایل رد کردن پیش‌فرض

  const predefinedRejectReasons = [
    {
      msg: "لطفا دلیل رد کردن را مشخص کنید.(الزامی)",
      value: 0,
    },
    {
      msg: "نقص در مدارک",
      value: 1,
    },
    {
      msg: "نقص در اطلاعات ارسالی",
      value: 2,
    },
    {
      msg: "نقص در مدارک و اطلاعات ارسالی",
      value: 3,
    },
  ];

  // تابع برای تایید عملیات تایید

  const handleApproveOk = () => {
    setApproveButtonLoading(true);
    return new Promise((resolve, reject) => {
      onApprove(id)
        .then(() => {
          setApproveModalVisible(false);
          setApproveButtonLoading(false);
          resolve(true);
          onFinish();
        })
        .catch(() => {
          setApproveModalVisible(false);
          setApproveButtonLoading(false);
          reject(false);
        });
    }).catch((response) => {
      /*console.log('Oops errors!',response)*/
    });
  };

  // تابع برای تایید عملیات رد کردن

  const handleRejectOk = () => {
    setRejectButtonLoading(true);

    return new Promise((resolve, reject) => {
      onReject(id, predefinedRejectReasons[rejectValue].msg)
        .then(() => {
          setRejectModalVisible(false);
          setRejectButtonLoading(false);
          resolve(true);
          onFinish();
        })
        .catch(() => {
          setRejectModalVisible(false);
          setRejectButtonLoading(false);
          reject(false);
        });
    }).catch(() => {
      /*console.log('Oops errors!')*/
    });
  };

  // تابع برای کنسل کردن مدال ها

  const handleCancel = () => {
    setApproveModalVisible(false);
    setRejectModalVisible(false);
    setRejectReasonIsFilled(false);
    setDeleteModalVisible(false);
    setDeleteButtonLoading(false); // Reset loading state if needed
    setPasswordModalVisible(false);
    setNewPassword("");
  };

  // نمایش مدال تایید

  const showApproveModal = () => {
    setApproveModalVisible(true);
  };

  // نمایش مدال رد کردن

  const showRejectModal = () => {
    setRejectModalVisible(true);
  };

  // تابعی برای نمایش مدال تایید

  const approveModal = () => {
    return (
      <Modal
        visible={approveModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            لغو
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={approveButtonLoading}
            onClick={handleApproveOk}
          >
            تایید
          </Button>,
        ]}
      >
        <p>آیا مطمئن هستید؟</p>
      </Modal>
    );
  };

  // تابعی برای تغییر دلیل رد کردن

  const rejectReasonChanged = (event: RadioChangeEvent) => {
    if (event.target.value === 0) return;
    setRejectReasonIsFilled(true);
    setRejectValue(event.target.value);
  };

  // تابعی برای نمایش مدال رد کردن

  const rejectModal = () => {
    if (hasEjectForCompany === true) {
      return (
        <Modal
          visible={rejectModalVisible}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              لغو
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={rejectButtonLoading}
              onClick={handleRejectOk}
            >
              تایید
            </Button>,
          ]}
        >
          <p>آیا مطمئن هستید؟</p>
        </Modal>
      );
    } else {
      return (
        <Modal
          visible={rejectModalVisible}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              لغو
            </Button>,
            <Button
              type="primary"
              danger
              loading={rejectButtonLoading}
              disabled={!rejectReasonIsFilled}
              onClick={handleRejectOk}
            >
              تایید
            </Button>,
          ]}
        >
          <b>آیا مطمئن هستید؟</b>
          <br />
          <br />
          <Radio.Group
            onChange={(event) => rejectReasonChanged(event)}
            value={rejectValue}
          >
            <Space direction="vertical">
              {hasRejectReason &&
                predefinedRejectReasons.map(
                  (reason: { msg: string | any; value: number }, index) => {
                    return (
                      <Radio
                        value={reason.value}
                        key={index}
                        disabled={reason.value === 0}
                      >
                        {reason.msg}
                      </Radio>
                    );
                  }
                )}
            </Space>
          </Radio.Group>
        </Modal>
      );
    }
  };

  // تابعی برای بستن مدال تایید

  const closeConfirmationModal = () => {
    setConfirmationModalVisibility(false);
  };

  return (
    <>
      {/* نمایش مدال تایید */}
      {approveModal()}
      {/* نمایش مدال رد کردن */}
      {rejectModal()}
      {deleteModal()}
      
      <Row>
        {/* نمایش دکمه مشاهده */}
        {hasViewBtn ? (
          <Col span={hasActionBtns ? 6 : 12}>
            <Button
              style={{ marginRight: "-16px" }}
              type={"text"}
              onClick={() => {
                handleView();
              }}
            >
              <FontAwesomeIcon icon={faEye} />
            </Button>
            {/* مدال مشاهده */}
            <Modal
              title="اطلاعات کاربر"
              centered
              visible={confirmationModalVisibility}
              onOk={() => setConfirmationModalVisibility(false)}
              onCancel={() => setConfirmationModalVisibility(false)}
              footer={[]}
              width={"95%"}
              style={{ height: "95vh", overflowY: "hidden" }}
              bodyStyle={{ height: "calc(95vh - 55px)", overflowY: "auto" }}
            >
              {/* در اینجا اطلاعات کاربر  قرار می گیرد */}
              <Confirmation
                username={record.username}
                visibilityHandler={closeConfirmationModal}
                onFinish={onFinish}
                transactionId={transactionId}
              />
            </Modal>
          </Col>
        ) : (
          <></>
        )}
        {/* نمایش دکمه های عملیات */}
        {hasActionBtns ? (
          <>
            <Col span={hasActionBtns ? 6 : 12}>
              {/* نمایش دکمه تایید */}
              <Button
                //style={{ paddingRight: "2px", paddingLeft: "5px" }}
                type={"text"}
                disabled={approveBtnDsbl}
                onClick={() =>
                  onApproveClick ? onApproveClick() : showApproveModal()
                }
              >
                <FontAwesomeIcon
                  style={{ color: approveBtnDsbl ? "#D0C9DF" : "#00D1C6" }}
                  icon={faCheck}
                />
              </Button>
            </Col>

            <Col span={hasEjectForCompany ? 6 : 12}>
              {/* نمایش دکمه رد کردن */}
              <Button
                style={{ paddingRight: "6px" }}
                type={"text"}
                disabled={rejectBtnDsbl}
                onClick={() =>
                  onRejectClick ? onRejectClick() : showRejectModal()
                }
              >
                <FontAwesomeIcon
                  style={{ color: rejectBtnDsbl ? "#D0C9DF" : "#F461B7" }}
                  icon={faTimes}
                />
              </Button>
            </Col>
          </>
        ) : (
          <></>
        )}
   
     
        {hasDeleteBtns ? (
          <Col span={hasDeleteBtns ? 6 : 12}>
            {/* Show the delete button */}
            <Button
              style={{}}
              type={"text"}
              disabled={deleteBtnDsbl}
              onClick={() =>
                onDeleteClick ? onDeleteClick() : showDeleteModal()
              }
            >
              <FontAwesomeIcon
                style={{ color: deleteBtnDsbl ? "#D0C9DF" : "#FF4D4F" }}
                icon={faTrash}
              />
            </Button>
          </Col>
        ) : (
          <></>
        )}
       </Row>
      {hasPassBtns?   
      <Button
        type={"text"}
        disabled={passwordChangeBtnDsbl}
        onClick={() => showPasswordModal()}
      >
        <FontAwesomeIcon
          style={{ color: passwordChangeBtnDsbl ? "#D0C9DF" : "#1890ff" }}
          icon={faKey}
        />
      
      </Button> :<></>}
   

      {passwordModal()}
    
    </>
  );
};

export default TableActions;
