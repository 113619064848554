import { Button, Col, Form, Input, Modal, Row, Select } from "antd";
import { ColumnProps } from "antd/lib/table/Column";
import TableActions from "../../components/TableActions";
import CustomTable from "../../components/Table";
import { useEffect, useState } from "react";
import useStore from "../../hooks/useStore";
import { useTranslation } from "react-i18next";
import useToast from "../../hooks/useToast";

const UsersManagment = () => {
  
   useEffect(() => {
    loadGetAllUsers({page: 0});
   }, [])

  const {t} = useTranslation('identification');
  const [authedApiState] = useStore();
  const toast = useToast();
  const [pendingRequests, setPendingRequests] = useState<any[]>([]);
  const [pendingRequestsLoading, setPendingRequestsLoading] = useState<boolean>(
    true
  );
  const [
    PendingRequestsTotalElements,
    setPendingRequestsTotalElements,
  ] = useState<number>(0);
  const[isModalvisible,setIsModalVisible]=useState(false);
  const[formData,setFormData]=useState<{id?:string,mobile:string,newpassword:string,password:string,role:string,username:string,firstName:string,lastname:string}>({id:"",mobile:"",newpassword:"",password:"",role:"",username:"",firstName:"",lastname:""});
  const[formChangePassword,setFormChangePassword]=useState<{id:string,password:string,newpassword:string,mobile:string,username:string,firstName:string,lastname:string,role:string }>({id:"",password:"0",newpassword:"",mobile:"", role:"",username:"",firstName:"",lastname:""});
 
  const showAddUserModal=()=>
  {
    setIsModalVisible(true);
  };

  const handleCancel =()=>{
    setIsModalVisible(false)
  };

  const handleAddUser = () => {
    try {
      authedApiState.apiContainer?.api
          .setUserManagment(formData)
          .then(({ data, status }) => {
              if (status === 200) {
                toast(t('notification.request.approve.success'), 'success');
                setIsModalVisible(false);
                loadGetAllUsers({page: 0});
                setFormData({firstName:"",lastname:"",mobile:"",newpassword:"",password:"",role:"",username:""})
              } else {
                 toast(t('notification.request'), 'error');
                  setIsModalVisible(false);
              }
          }).catch((response) => {
              toast(response?.data?.msg || response?.data?.message, 'error');
          });
       } catch (error) {
      console.error('An error occurred: ', error);
      }
  };

  const handleChangePassUser = (id:string,newpassword:string)=> {
    try {
      authedApiState.apiContainer?.api
          .setUserManagmentPassword({id:id,password:"0",newpassword:newpassword,mobile:"", role:"",username:"",firstName:"",lastname:""})
          .then(({ data, status }) => {
              if (status === 200) {
                toast(t('notification.request.approve.success'), 'success');
                setIsModalVisible(false);
                setFormChangePassword({id:id,password:"0",newpassword:"",mobile:"", role:"",username:"",firstName:"",lastname:""})
              } else {
                toast(t('notification.request'), 'error');
                  setIsModalVisible(false);
              }
          }).catch((response) => {
              toast(response?.data?.msg || response?.data?.message, 'error');
          });
         } catch (error) {
         console.error('An error occurred: ', error);
        }
  };
 
  const loadGetAllUsers = ({
    page,
    size,
    sort,
    asc,
  }: {
    page: number;
    size?: number;
    sort?: string;
    asc?: boolean;
  }) => {
    const query = {
      page,
      size: size ? size : 5,
      sort: sort ? sort : "lastname",
      asc: asc ? asc : false,
    };
    setPendingRequestsLoading(true);
    authedApiState.apiContainer?.api
      .GetAllManagmentUsers(query)
      .then(({ data, status }) => {
        //@ts-ignore
        setPendingRequestsTotalElements(data.totalElements);
        if (status === 200) {
            //@ts-ignore
            setPendingRequests(data.content?.map((item: any) => {
                return {
                    firstName: item?.firstName,
                    lastName: item?.lastName,
                    fatherName: item?.fatherName,
                    nationalCode: item?.nationalCode,
                    accountType: t('accountType.'+ item?.accountType),
                    userType: t('userType.'+ item?.userType),
                    mobile: item?.mobile,
                    role: item?.role,
                    permissions: item?.permissions, 
                    username: item?.username,
                    actions: item
                }
            }));
            data.filter((item)=>item.id===formChangePassword.id);
        }
        else {
            toast(t('notification.request.fail'), 'error');
        }
        setPendingRequestsLoading(false)
      }).catch((response) => {
        toast(response?.data?.msg || response?.data?.message, 'error');
        setPendingRequestsLoading(false);
      })
  };

  const RequestsColumn: ColumnProps<Record<string, string>>[] = [
    {
      title: "نام‌",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "موبایل",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "نقش",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "نام کاربری",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "اقدامات",
      key: "actions",
      dataIndex: "actions",
      render: (item) => (
        <TableActions
          onApprove={() => console.log()}
          onReject={() => console.log()}
          id={item.id} 
          onView={() => {}}
          approveParams={{}}
          rejectParams={[]}
          hasViewBtn={false}
          hasActionBtns={false}
          viewParams={{}}
          approveBtnDsbl={true}
          record={{}}
          rejectBtnDsbl={true}
          viewBtnDsbl={false}
          onFinish={() => console.log()}
          hasEjectForCompany={true}
          onChangePassword={handleChangePassUser}
          hasPassBtns={true}
        />
      ),
    },
  ];

  const { Option } = Select;

  return (
    <>
      <Row justify={"end"} align={"middle"}>
        <Col>
          <Button type={"primary"} onClick={showAddUserModal}>
            افزودن کاربر
          </Button>
        </Col>
      </Row>
      <Modal title="افزودن کاربر" visible={isModalvisible} onCancel={handleCancel} onOk={handleAddUser}>
        <Form onValuesChange={handleAddUser}>
        <Input placeholder="نام" style={{marginBottom:"10px"}} onChange={e=>setFormData({id:"",role:formData?.role,mobile:e.target.value,username:e.target.value,newpassword:formData?.newpassword,firstName:e.target.value,lastname:"",password:formData?.password})}/>
            <Input placeholder="نام کاربری" style={{marginBottom:"10px"}} onChange={e=>setFormData({id:"",role:formData?.role,mobile:formData?.mobile,username:e.target.value,newpassword:formData?.newpassword,firstName:formData?.firstName,lastname:"",password:formData?.password})}/>
            <Input placeholder="موبایل" style={{marginBottom:"10px"}} onChange={e=>setFormData({id:"",role:formData?.role,mobile:e.target.value,username:formData?.username,newpassword:formData?.newpassword,firstName:formData?.firstName,lastname:"",password:formData?.password})}/>
            <Input placeholder="رمز" style={{marginBottom:"10px"}} onChange={e=>setFormData({id:"",role:formData?.role,mobile:formData?.mobile,username:formData?.username,newpassword:e.target.value,firstName:formData?.firstName,lastname:"",password:formData?.password})}/>
        <Input placeholder="تکرار رمز" style={{marginBottom:"10px"}} onChange={e=>setFormData({id:"",role:formData?.role,mobile:formData?.mobile,username:formData?.username,password:e.target.value,newpassword:formData?.newpassword,firstName:formData?.firstName,lastname:""})}/>
            <Select placeholder="نقش" style={{ marginBottom: "10px" }} onChange={(value:any)=>setFormData({id:"",role:value,mobile:formData?.mobile,username:formData?.username,newpassword:formData?.newpassword,firstName:formData?.firstName,lastname:"",password:formData?.password})}>
              <Option value="CEO" >مدیرعامل</Option>
              <Option value="ADMIN" >ادمین</Option>
              <Option value="COMPANY" >اپراتور</Option>
       </Select>
        </Form>
      </Modal>
      <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
        <Col span={24} lg={24} md={24} sm={24} xs={24}>
          <div className={"dashboard-grid"}>
            <CustomTable
              loading={pendingRequestsLoading}
              className="special-styled-table"
              paginationBottom={"bottomCenter"}
              data={pendingRequests}
              columns={RequestsColumn}
              onChange={loadGetAllUsers}
              defaultSort={"lastname"}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default UsersManagment;
