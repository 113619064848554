import {ToastProvider} from './hooks/useToast';
import React from 'react';
import ReactDOM from 'react-dom';
import './assets/styles/global.css';
import './i18n';
import Index from './routes';
import {unregister} from './serviceWorker.js';
import faIR from 'antd/lib/locale/fa_IR';
import {ConfigProvider} from 'antd';
import moment from "jalali-moment";


const Root = () => {
    return (
        <ConfigProvider locale={faIR} direction="rtl">
            <ToastProvider/><Index/>
            {/*{*/}
            {/*    moment().unix() < 1627327799? <Index/>:<div*/}
            {/*        style={{*/}
            {/*            position: "absolute",*/}
            {/*            top: "50%",*/}
            {/*            left: "50%",*/}
            {/*            transform: "translate(-50%,-50%)",*/}
            {/*            color: "red",*/}
            {/*            fontSize: "70px"*/}
            {/*        }}*/}
            {/*    >{atob('IFVOREVSIERFVkVMT1BNRU5UIA==')}</div>*/}
            {/*}*/}
        </ConfigProvider>
    );
}

ReactDOM.render(<Root/>, document.getElementById('root'));

unregister();
