import Logo from '../../assets/images/logo_new.svg';
import Notification from '../../assets/images/notification.png';
import Profile from '../../assets/images/profile.svg';
import useClick from '../../hooks/useClick';
import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
import {Link} from 'react-router-dom';
import Format from '../../utils/formatter';
import Styled from './styles';
import useStore, {storeActions} from "../../hooks/useStore";

const Navbar = () => {
    const dropDownRef = useRef(false);
    const history = useHistory();
    const {t} = useTranslation('layouts');
    const [isDropDown, setIsDropDown] = useClick(dropDownRef);
    const [toggle, setToggle] = useState(false);
    //@ts-ignore TODO
    const [authedApiState] = useStore()
    const {user} = authedApiState;

    useEffect(() => {
        if (toggle) document.body.style.overflow = 'hidden';
        else document.body.removeAttribute('style');
    }, [toggle]);

    const onLogout = () => {
        storeActions.logout()
        history.push('/login/');
    };

    return (
        <Styled.Nav>
            <Styled.Container>
                <Styled.Toggle
                    className={`${toggle ? 'active' : ''}`}
                    onClick={() => setToggle(!toggle)}
                >
                    <span className={'bar'}/>
                    <span className={'bar'}/>
                    <span className={'bar'}/>
                </Styled.Toggle>

                <Styled.Logo to={'/login'}>
                    <img src={Logo} alt={''}/>
                </Styled.Logo>

                <Styled.Menu className={`${toggle ? 'toggle' : ''}`}>
                    {toggle && (
                        <span className={'close'} onClick={() => setToggle(false)}>
              {t('nabbar.toggle.close')}
            </span>
                    )}
                </Styled.Menu>

                <Styled.Actions>
                    {authedApiState.loggedIn ? (
                        <>
                            <Styled.Profile
                                //@ts-ignore todo
                                ref={dropDownRef}
                                isDropDown={isDropDown}
                                //@ts-ignore todo
                                onClick={() => setIsDropDown(!isDropDown)}
                            >
                                <img src={Profile} alt={''}/>
                                <span className={'text'}>
                                    {`${user?.firstName} ${user?.lastName}`}
                                </span>
                            </Styled.Profile>

                            <div className={'notification'}>
                                <span className={'counter'}>0</span>
                                <img src={Notification} alt={''}/>
                            </div>

                            <Styled.DropDown
                                //@ts-ignore
                                isDropDown={isDropDown}>
                                <div className={'details'}>
                                    <div className={'avatar'}/>
                                    <div className={'text'}>
                                        <div className={'user'}>
                                            {`${user?.firstName} ${user?.lastName}`}
                                            {user?.mobile &&
                                            <span className={'mobile'}>
                                                | {Format.formatPhoneNumber('0' + user?.mobile)}
                                            </span>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className={'buttons'}>
                                    <Link to={'/dashboard/'} className={'dashboard'}>
                                        {t('navbar.dropdown.dashboard')}
                                    </Link>
                                    <div className={'logout'} onClick={onLogout}>
                                        {t('navbar.dropdown.logout')}
                                    </div>
                                </div>
                            </Styled.DropDown>
                        </>
                    ) : (
                        <></>
                    )}
                </Styled.Actions>
            </Styled.Container>
        </Styled.Nav>
    );
};

export default Navbar;
